import React, { useState } from "react"
import { Typography } from "@mui/material"

function Paragraph({ text, maxCharCount }) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpansion = () => {
    setExpanded(!expanded)
  }

  const displayText = expanded ? text : text.slice(0, maxCharCount)

  return (
    <>
      <p className="m-0">{displayText}</p>
      {text.length > maxCharCount && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.secondary.main,
            cursor:"pointer"
          })}
          variant="tagItalic"
          onClick={toggleExpansion}>
          {expanded ? "...Read Less" : "...Read More"}
        </Typography>
      )}
    </>
  )
}

export default Paragraph
