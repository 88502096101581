import React from "react";
import {
  //  Dialog,
  DialogTitle,
  Typography
} from "@mui/material";
import Card from "components/Card/Card";
import { useTheme } from "@mui/system";
import DailyStatsBody from "./components/DailyStatsBody";
import Modal from "components/Modal/Modal";

function DailyStatsModal({ open, setOpen, content }) {
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    card: {
      margin: "0",
      borderRadius: "12px",
      backgroundColor: theme.palette.background.default,
      paddingTop: "8px",
      paddingBottom: "8px"
    },
    wrapper: {
      "& .MuiPaper-root": {
        borderRadius: "12px",
        backgroundColor: theme.palette.background.default
      }
    },
    title: {
      textAlign: "center",
      paddingBottom: "15px"
    }
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Card style={styles.card}>
        <DialogTitle sx={styles.title}>
          <Typography variant="h3Italic">
            {content.day}, {content.month} {content.date}
            {content.date === 1
              ? "st"
              : content.date === 2
              ? "nd"
              : content.date === 3
              ? "rd"
              : "th"}
          </Typography>
        </DialogTitle>
        <DailyStatsBody content={content} />
      </Card>
    </Modal>
  );
}

export default DailyStatsModal;
