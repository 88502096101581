import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import CustomDivider from "components/StyledComponents/Divider"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import BasicDetails from "./components/basicDetails"
import TastingNotes from "./components/tastingNotes"
import WineCharacteristics from "./components/wineCharacteristics"
import MenuPairing from "./components/menuPairing"

const ViewTastingNotes = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const history = useHistory()
  const tastingNotesData = useSelector((store) => store?.dmv?.tastingNotes?.tastingNotesData || {})

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "View Tasting Notes - DMV"
  }, [])

  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header title="Tasting Notes" breadCrumbs={breadCrumbs} back={true}>
            <AppButton
              onClick={() => history.push("/super-admin/dashboard/tasting-notes-config/list/edit")}>
              Edit Tasting Notes
            </AppButton>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <BasicDetails data={tastingNotesData?.wine ?? {}} />
          <CustomDivider className="mt-4 mb-4" />
          <TastingNotes data={tastingNotesData?.tasting_notes ?? []} />
          <CustomDivider className="mt-4 mb-4" />
          <MenuPairing
            data={tastingNotesData?.menu_pairing ?? []}
            alcoholType={tastingNotesData?.wine?.alcohol_type}
          />
          <CustomDivider className="mt-4 mb-4" />
          <WineCharacteristics
            data={tastingNotesData?.characteristics ?? []}
            alcoholType={tastingNotesData?.wine?.alcohol_type}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ViewTastingNotes
