import { Box, Grid, Typography } from "@mui/material"
import Card from "components/Card/Card"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import AppSelect from "components/StyledComponents/AppSelect"
import React, { useEffect, useState } from "react"

const WineCharacteristicsForm = ({
  formik,
  index,
  arrayHelper,
  characteristic,
  isEditMode,
  alcoholType
}) => {
  const [wineLevelOptions, setWineLevelOptions] = useState(wineLevels)
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)

  const handleDeleteCharacteristic = () => {
    arrayHelper.remove(index)
  }

  const onCharacteristicChange = (e) => {
    formik.setFieldValue(`wineCharateristicsSet[${index}].level`, "")
    formik.setFieldValue(`wineCharateristicsSet[${index}].characteristic`, e.target.value)
  }

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen((prev) => !prev)
  }

  const onDeleteClick = () => {
    if (isEditMode) {
      toggleDeletePopup()
    } else {
      arrayHelper.remove(index)
    }
  }

  useEffect(() => {
    if (characteristic === "Body" || characteristic === "Sweetness") {
      setWineLevelOptions(wineLevelsBody)
    } else {
      setWineLevelOptions(wineLevels)
    }
  }, [characteristic])

  return (
    <Card className="mt-4">
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Characteristic"
        onDelete={handleDeleteCharacteristic}
        onClose={toggleDeletePopup}
      />
      <Box className="d-flex justify-content-between align-items-center mb-3">
        <Typography variant="h2">{`Characteristic ${index + 1}`}</Typography>
        {formik.values?.wineCharateristicsSet?.length > 1 ? (
          <Typography role="button" onClick={onDeleteClick} color="error" className="body1Regular">
            Delete
          </Typography>
        ) : null}
      </Box>
      <Grid container spacing={4} key={`characteristic${index}`}>
        <Grid item sm={12} md={6} lg={4}>
          <AppSelect
            label={`Characteristic`}
            isRequired={true}
            fullWidth
            menuItems={alcoholType === "wine" ? wineCharacteristics : spiritCharacteristics}
            value={characteristic}
            onChange={onCharacteristicChange}
            error={formik.errors?.wineCharateristicsSet?.[index]?.characteristic}
            touched={formik.touched?.wineCharateristicsSet?.[index]?.characteristic}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <AppSelect
            label="Level"
            fullWidth
            isRequired={true}
            menuItems={wineLevelOptions}
            value={formik.values.wineCharateristicsSet[index].level}
            onChange={formik.handleChange(`wineCharateristicsSet[${index}].level`)}
            error={formik.errors?.wineCharateristicsSet?.[index]?.level}
            touched={formik.touched?.wineCharateristicsSet?.[index]?.level}
            disabled={alcoholType === "" ? true : false}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default WineCharacteristicsForm

const wineCharacteristics = [
  {
    label: "Sweetness",
    value: "Sweetness"
  },
  {
    label: "Tannin",
    value: "Tannin"
  },
  {
    label: "Alcohol",
    value: "Alcohol"
  },
  {
    label: "Body",
    value: "Body"
  },
  {
    label: "Acidity",
    value: "Acidity"
  }
]

const spiritCharacteristics = [
  {
    label: "Sweetness",
    value: "Sweetness"
  },
  {
    label: "Alcohol",
    value: "Alcohol"
  },
  {
    label: "Body",
    value: "Body"
  },
  {
    label: "Finish",
    value: "Finish"
  }
]

const wineLevels = [
  {
    label: "Low",
    value: "Low"
  },
  {
    label: "Medium-",
    value: "Medium-"
  },
  {
    label: "Medium",
    value: "Medium"
  },
  {
    label: "Medium+",
    value: "Medium+"
  },
  {
    label: "High",
    value: "High"
  }
]

const wineLevelsBody = [
  {
    label: "Light",
    value: "Light"
  },
  {
    label: "Medium-",
    value: "Medium-"
  },
  {
    label: "Medium",
    value: "Medium"
  },
  {
    label: "Medium+",
    value: "Medium+"
  },
  {
    label: "Full",
    value: "Full"
  }
]
