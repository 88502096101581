import QuestionButtonNav from "components/QuestionButtonNav"
import {
  trackTriviaAddNewQuestion,
  trackTriviaAutoSuggestionQuestion,
  trackTriviaGenerateQuestionClick
} from "helpers/google_event_trackers"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"

const tabItems = {
  ADD_QUESTION: "ADD",
  GENERATE_QUESTION: "GENERATE",
  AUTO_SUGGEST: "AUTO"
}

function QuestionNavBar(props) {
  const [menu] = useState(menuNav)
  const dispatch = useDispatch()
  const { full_name } = useSelector((store) => store.user?.userData)

  function handleButtonNav(item) {
    dispatch(learningDevelopmentActions.setQuestionMenuLink(item))
    if (item === "Add") {
      trackTriviaAddNewQuestion(full_name)
    } else if (item === "GENERATE") {
      trackTriviaGenerateQuestionClick(full_name)
    } else if (item === "AUTO") {
      trackTriviaAutoSuggestionQuestion(full_name)
    }
  }

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <QuestionButtonNav
            style={{ marginRight: 12 }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
          </QuestionButtonNav>
        )
      })}
    </>
  )
}

export default QuestionNavBar

const menuNav = [
  {
    name: "Add Question",
    isActive: true,
    value: tabItems?.ADD_QUESTION
  },
  {
    name: "Generate Question",
    isActive: false,
    value: tabItems?.GENERATE_QUESTION
  },
  {
    name: "Auto Suggestion",
    isActive: false,
    value: tabItems?.AUTO_SUGGEST
  }
]
