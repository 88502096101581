import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Backdrop,
  List,
  ListItem
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import TastingNoteIcon from "assets/icons/TastingNoteIcon.svg";
import { useTheme } from "@mui/system";

function MonthlyTastingNote({ month, editable, pdfUrl, pdfThumbnail }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const handleClick = () => {
    if (editable) setAnchorEl((prev) => !prev);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const styles = {
    box: {
      paddingTop: "8px",
      paddingBottom: "8px",
      marginLeft: "16px",
      marginRight: "16px",
      position: "relative"
    },
    icon: {
      marginRight: "6px",
      position: "relative",
      bottom: "2px"
    },
    moreIcon: {
      padding: "0",
      color: theme.palette.text.primary,
      "&:hover": { color: theme.palette.aqua.main }
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      position: "relative"
    },
    pdfUrl: {
      marginTop: "16px",
      marginBottom: "8px",
      width: "100%",
      objectFit:"fill",
      height: "100%",
      borderRadius: "12px"
    },
    menu: {
      position: "absolute",
      top: "27px",
      right: "0",
      backgroundColor: theme.palette.background.secondary,
      zIndex: "1400",
      borderRadius: "12px",
      width: "13rem",
      padding: "0.5rem",
      cursor: "pointer"
    },
    deleteBtn: {
      color: theme.palette.error.main
    }
  };

  const openPdf = () => {
    handleClose();
    const newWindow = window.open(pdfUrl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box sx={styles.box}>
      <Box sx={styles.titleContainer}>
        <Box>
          <img src={TastingNoteIcon} alt="Calendar Icon" style={styles.icon} />
          <Typography variant="body1Regular">{month} Tasting Note</Typography>
        </Box>
        {editable ? (
          <IconButton
            onClick={handleClick}
            sx={styles.moreIcon}
            aria-label="Options">
            <MoreVert />
          </IconButton>
        ) : null}
        <Backdrop
          sx={{ color: "#fff", zIndex: 1300 }}
          open={open}
          onClick={handleClose}></Backdrop>
        {open && (
          <List sx={styles.menu}>
            <ListItem onClick={openPdf}>
              <Typography variant="body1Regular">View</Typography>
            </ListItem>
          </List>
        )}
      </Box>
      {/* <Box>
        <iframe
          src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
          style={styles.pdfUrl}
          frameBorder="0"
          scrolling="auto"></iframe>
      </Box> */}
      <Box>
        <img
          src={`${pdfThumbnail}`}
          style={styles.pdfUrl}
          frameBorder="0"
          scrolling="auto"></img>
      </Box>
    </Box>
  );
}
export default React.memo(MonthlyTastingNote);
