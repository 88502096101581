import { createSlice } from "@reduxjs/toolkit";
import { revertAllGM } from "../actions";

const initialState = {
  suggestQuestion: {
    image: null,
    triviaCategories: [],
    isQuestionUploaded: false,
    nextContestList: []
  },
  leaderBoardListGM: {
    totalPages: 1,
    data: []
  },
  top5ranks:[],
  triviaAnalytics: {
    dashboardData: null,
    calendarData: [],
    correctAnswers: [],
    incorrectAnswers: []
  },
  suggestedQuestionsTableData:[]
}

const gm = createSlice({
  name: "gm",
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(revertAllGM, () => initialState),
  reducers: {
    setTriviaCategories: (state, action) => {
      state.suggestQuestion.triviaCategories = action.payload;
    },
    setSuggestedQuestionUploaded: (state, action) => {
      state.suggestQuestion.isQuestionUploaded = action.payload;
    },
    setSuggestedQuestionImage: (state, action) => {
      state.suggestQuestion.image = action.payload;
    },
    setNextContestList: (state, action) => {
      state.suggestQuestion.nextContestList = action.payload;
    },
    setLeaderBoardListGM: (state, action) => {
      state.leaderBoardListGM = action.payload;
    },
    setTriviaDashboardAnalytics: (state, action) => {
      state.triviaAnalytics.dashboardData = action.payload
    },
    setGMCalendarData: (state, action) => {
      state.triviaAnalytics.calendarData = action.payload
    },
    setGMCorrectAnswers: (state, action) => {
      state.triviaAnalytics.correctAnswers = action.payload
    },
    setGMIncorrectAnswers: (state, action) => {
      state.triviaAnalytics.incorrectAnswers = action.payload
    },
    setTop5Ranks:(state,action) =>{
      state.top5ranks = action.payload
    },
    setSuggestedQuestionsTableData:(state, action) => {
      state.suggestedQuestionsTableData = action.payload
    }
  }
});

export default gm;
export const GMActions = gm.actions;
