import React, { Fragment } from "react";
import { List, ListItem, Typography } from "@mui/material";
import Card from "components/Card/Card";
import Divider from "components/StyledComponents/Divider";
function TrTrendCardView({ data, heading }) {
  const style = {
    itemsList: {
      marginRight: "24px"
    },
    month: {
      marginRight: "6px"
    }
  };

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 12px 0" }}>
          <Typography variant="h2">{heading}</Typography>
        </ListItem>

        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 0px 0" }}>
          <Typography variant="tag1" sx={{ opacity: 0.6 }}>
            Restaurant
          </Typography>
          <List sx={{ display: "flex", justifyContent: "space-between" }}>
            {BoxHeaderGrid.map((item, id) => (
              <ListItem style={style.itemsList} key={id}>
                <Typography variant="tag1" sx={{ opacity: 0.6 }}>
                  {item.field}
                </Typography>
              </ListItem>
            ))}
          </List>
        </ListItem>
        <Divider />
        {data.fieldData.map((res, index) => (
          <Fragment key={index}>
            <ListItem
              sx={{ justifyContent: "space-between", padding: "6px 0 0px 0" }}>
              <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
                {res.name}
              </Typography>
              <List sx={{ display: "flex", justifyContent: "space-between" }}>
                {res.monthData.map((item, id) => (
                  <ListItem style={style.month} key={id}>
                    <Typography variant="body1Regular">{item}</Typography>
                  </ListItem>
                ))}
              </List>
            </ListItem>
            {index !== data.fieldData.length - 1 ? <Divider /> : null}
          </Fragment>
        ))}
        {data.total.map((res, index) => (
          <Fragment key={index}>
            <ListItem
              sx={{
                justifyContent: "space-between",
                padding: "0px 0 0px 0",
                backgroundColor: "#272a2c",
                borderRadius: "12px"
              }}>
              <Typography
                className="ms-3"
                variant="body1Regular"
                sx={{ opacity: 0.6 }}>
                {res.name}
              </Typography>
              <List sx={{ display: "flex", justifyContent: "space-between" }}>
                {res.monthData.map((item, id) => (
                  <ListItem style={style.month} key={id}>
                    <Typography variant="body1Regular">{item}</Typography>
                  </ListItem>
                ))}
              </List>
            </ListItem>
            {index !== data.total.length - 1 ? <Divider /> : null}
          </Fragment>
        ))}
      </List>
    </Card>
  );
}

export default TrTrendCardView;

const BoxHeaderGrid = [
  {
    id: 1,
    field: "Oct"
  },
  {
    id: 2,
    field: "Noc"
  },
  {
    id: 3,
    field: "Dec"
  },
  {
    id: 4,
    field: "jan"
  }
];
