import ButtonNav from "components/ButtonNav"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"

const tabItems = {
  DINNER: "dinner",
  LUNCH: "lunch",
  BRUNCH: "brunch",
  BAR_MENU: "bar menu",
  WINE: "wine by glass",
  SPIRITS: "spirits"
}

function MenuNavBar(props) {
  const [menu] = useState(menuNav)
  const dispatch = useDispatch()

  function handleButtonNav(item) {
    let itemsLink = {}
    if (props.isEdit) {
      itemsLink = { item: item, category: "Entrees", list: "Detailed View" }
    } else {
      itemsLink = {
        item: item,
        category: "Entrees",
        list: "Summary View"
      }
    }
    dispatch(marketingActions.setMenuLink(itemsLink))
  }

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <ButtonNav
            style={{ marginRight: 12 }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
          </ButtonNav>
        )
      })}
    </>
  )
}

export default MenuNavBar

const menuNav = [
  {
    name: "Dinner",
    isActive: true,
    value: tabItems?.DINNER
  },
  {
    name: "Lunch",
    isActive: false,
    value: tabItems?.LUNCH
  },
  {
    name: "Brunch",
    isActive: false,
    value: tabItems?.BRUNCH
  },
  {
    name: "Bar Menu",
    isActive: false,
    value: tabItems?.BAR_MENU
  },
  {
    name: "Wine By Glass",
    isActive: false,
    value: tabItems?.WINE
  },
  {
    name: "Spirits",
    isActive: false,
    value: tabItems?.SPIRITS
  }
]
