import React from "react";
import Card from "components/Card/Card";
import { Box, Dialog, styled, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import UploadTabUser from "./UploadTabUser";
import frontlineCSV from "assets/frontlineUsers.xlsx"

function UploadBulkUserModal({ open, onClose }) {
  const theme = useTheme();

  const styles = {
    card: {
      margin: 0
    },
    headingContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center"
    },
    divider: {
      backgroundColor: theme.palette.secondary.main,
      height: 3,
      opacity: 1,
      position: "relative",
      top: "12px",
      left: "-23%",
      width: "150%"
    },
    mainDivider: {
      backgroundColor: theme.palette.background.main,
      opacity: 1,
      marginTop: "10px"
    },
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    },
    anchor: {
      color: theme.palette.secondary.main
    }
  };

  const StyledDialog = styled(Dialog)(() => ({
    "& .MuiPaper-root": {
      backgroundColor: "transparent"
    }
  }));

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <Card style={styles.card}>
        <IconButton onClick={onClose} sx={styles.closeBtn}>
          <CloseIcon sx={{ height: "14px", width: "14px" }} />
        </IconButton>
        {/* Headings */}

        <Box>
          <UploadTabUser onClose={onClose}></UploadTabUser>
        </Box>
        <Box className="mt-3 d-flex text-center justify-content-center">
          <a href={frontlineCSV} target="_blank" rel="noreferrer">
            <Typography variant="body2regular" style={styles.anchor}>Download CSV Sample</Typography>
          </a>
        </Box>
      </Card>
    </StyledDialog>
  );
}

export default UploadBulkUserModal;
