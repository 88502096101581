import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./CorrectAnswerModal.module.scss";
import Card from "components/Card/Card";

function ModalContent({ modalContent }) {
  const { id, options, question } = modalContent;

  return (
    <Card style={{ margin: 0, width: "30vw" }}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="body1Regular">{id}</Typography>
        <ArrowForwardIcon sx={{ marginLeft: "14px" }} />
        <Typography variant="h4" sx={{ marginLeft: "6px" }}>
          {question}
        </Typography>
      </Box>
      <Box className={styles.answerBox}>
        {options.map((option, index) => {
          return (
            <Box
              key={index}
              className={`${styles.option} ${
                option?.is_correct ? styles.optionCorrect : ""
              }`}>
              <div className={styles.optionNumber}>
                <Typography variant="tag1">
                  {(index + 10).toString(36).toUpperCase()}
                </Typography>
              </div>
              <Typography
                className={styles.optionName}
                sx={{ marginLeft: "10px" }}
                variant="body2Regular">
                {option?.answer}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Card>
  );
}

export default ModalContent;
