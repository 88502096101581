import { Box, styled, Typography, useTheme } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "components/Card/Card";
import CustomDivider from "components/StyledComponents/Divider";
import { useHistory } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

const StyledContainer = styled(Box)(() => ({
  marginTop: 12,
  width: "100%",
  "& .listItem": {
    marginBottom: 12
  },
  "& .rank": {
    height: 30,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 4
  }
}));

const RankList = ({ list = [], rankColor }) => {
  return (
    <StyledContainer>
      {list.map((item, index) => (
        <Box
          key={`${rankColor}-${index}`}
          className="d-flex justify-content-between align-items-center listItem">
          <Box className="d-flex align-items-center">
            <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
              {item.label}
            </Typography>
          </Box>
          <Typography variant="body1Regular">{item.value}</Typography>
        </Box>
      ))}
    </StyledContainer>
  );
};

const TopFrontlineMembers = ({ routeNext }) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Card className="d-flex flex-column">
      <Box className="w-100 d-flex justify-content-between">
        <Typography variant="h2">
          Your Top Frontline Team Members
          <ErrorIcon className="ms-2"></ErrorIcon>{" "}
        </Typography>
      </Box>
      <Typography
        variant="caption1"
        sx={{ color: theme.palette.secondary.main, fontSize: 12, mt: 3 }}>
        SCOTT HATTENBERG, SERVER
      </Typography>
      <Box className="d-flex justify-content-between align-items-center">
        <RankList list={topList} rankColor={theme.palette.green.main} />
        <span onClick={() => history.push(routeNext)} className="ms-3">
          <IconButtonWithBorder>
            <ArrowForwardIosIcon
              sx={{
                color: "white",
                fontSize: "small"
              }}
            />
          </IconButtonWithBorder>
        </span>
      </Box>

      <CustomDivider />
      <Typography
        variant="caption1"
        sx={{ color: theme.palette.secondary.main, fontSize: 12, mt: 3 }}>
        MICHAEL LEWIS, TR
      </Typography>
      <Box className="d-flex justify-content-between align-items-center">
        <RankList list={companyList} rankColor={theme.palette.green.main} />
        <span onClick={() => history.push(routeNext)} className="ms-3">
          <IconButtonWithBorder>
            <ArrowForwardIosIcon
              sx={{
                color: "white",
                fontSize: "small"
              }}
            />
          </IconButtonWithBorder>
        </span>
      </Box>
      <CustomDivider className="mb-1" />
      <Typography
        variant="caption1"
        sx={{ color: theme.palette.secondary.main, fontSize: 12, mt: 1 }}>
        MAEGAN MARTIN, BARTENDER
      </Typography>
      <Box className="d-flex justify-content-between align-items-center">
        <RankList list={bottomList} rankColor={theme.palette.green.main} />
        <span onClick={() => history.push(routeNext)} className="ms-3">
          <IconButtonWithBorder>
            <ArrowForwardIosIcon
              sx={{
                color: "white",
                fontSize: "small"
              }}
            />
          </IconButtonWithBorder>
        </span>
      </Box>
    </Card>
  );
};

export default TopFrontlineMembers;

const topList = [
  {
    label: "Tampa Bay",
    value: "14.3%"
  },
  {
    label: "Rank",
    value: "5 of 50"
  }
];

const companyList = [
  {
    label: "Sarasota",
    value: "15.0%"
  },
  {
    label: "Rank",
    value: "12 of 984"
  }
];

const bottomList = [
  {
    label: "Virginia Beach",
    value: "5.1%"
  },
  {
    label: "Rank",
    value: "48 of 50"
  }
];
