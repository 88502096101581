import React from 'react'
import { Container } from 'react-bootstrap'
import mainLogo from "assets/icons/Logo2x.png"
import dmvLogo from "assets/icons/DMVLogo.svg"
import "./emailVerify.scss"

function EmailVerify() {
  return (
    <div className='Main-Container'>
      <Container fluid className='header-con'>
        <div className="logo-con text-center">
          <img src={mainLogo} alt="" />
        </div>
        <div className="text-center poweredbyCon">
          <img src={dmvLogo} alt="" />
          <p className="text-white mb-0">Gamification Platform Powered by DM Ventures</p>
        </div>
      </Container>
      <Container className='mt-5'>
        <div className="text-center text-white pt-4">
            <h2>Success!</h2>
        </div>
        <div className="text-center text-white pt-2">
            <p className=" mt-4 mb-4">Your email has been successfully verified.</p>
            <p className=" mt-2 mb-4"> Hop back into the app and start your journey.</p>
        </div>
    </Container>
    </div>
  )
}

export default EmailVerify