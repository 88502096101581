import React, { Fragment } from "react"
import { Box, Divider } from "@mui/material"
import Question from "./Question"
import styles from "../../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss"
import { useTheme } from "@mui/system"
import SuggestedBy from "./SuggestedBy"
import { Col, Row } from "react-bootstrap"
import CategoryCard from "../../QuestionBank/components/CategoryCard"
import ImageDisplay from "components/ImageDisplayer"

function TriviaQuestions({ questions, triviaQuestionCount, isIncorrect }) {
  const theme = useTheme()
  const [questionCategoryCount, setQuestionCategoryCount] = React.useState([])

  const style = {
    box: {
      borderRadius: "0",
      padding: "0",
      background: "none"
    },
    divider: {
      background: theme.palette.text.primary,
      height: "2px",
      opacity: 0.12
    }
  }

  React.useEffect(() => {
    const newData = [
      { title: "All", category: "ALL", count: questions?.length },
      { title: "Food", category: "ALL", count: triviaQuestionCount?.Food },
      { title: "Beverage", category: "ALL", count: triviaQuestionCount?.Beverage },
      { title: "Wine Region", category: "ALL", count: triviaQuestionCount?.Wine_Region },
      { title: "Special", category: "ALL", count: triviaQuestionCount?.Special }
    ]

    setQuestionCategoryCount(newData)
  }, [triviaQuestionCount, questions])

  return (
    <Fragment>
      {!!questions?.length && (
        <Row>
          <Col lg="12">
            <Box className="d-flex w-100 flex-wrap">
              {questionCategoryCount?.map((option, index) => (
                <CategoryCard
                  key={index}
                  title={option?.title}
                  category={option?.category}
                  count={option?.count}
                />
              ))}
            </Box>
          </Col>
        </Row>
      )}
      <Divider sx={style.divider} />
      {questions?.map((question, index) => (
        <Fragment key={question?.id}>
          <Box key={question?.id} className={styles.questionCtr} sx={style.box}>
            <Question
              question={question}
              status={question?.status}
              index={index}
              isIncorrect={isIncorrect}
            />
            <div className="d-flex">
              <div
                className="me-5 p-2 d-flex align-items-center justify-content-center"
                style={{ width: "200px", height: "auto" }}>
                {question?.image ? (
                  <img
                    className="img-fluid"
                    style={{ borderRadius: "6px" }}
                    src={question.image}></img>
                ) : (
                  <div
                    className="p-4"
                    style={{ border: "1px solid", borderRadius: "8px", width: "250px" }}>
                    <ImageDisplay />
                  </div>
                )}
              </div>
              {question?.is_suggested && <SuggestedBy question={question} index={index} />}
            </div>
          </Box>
          {question?.id !== questions?.length && <Divider sx={style.divider} />}
        </Fragment>
      ))}
    </Fragment>
  )
}

export default TriviaQuestions
