import { Divider, styled } from "@mui/material";

const CustomDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  opacity: 0.12,
  borderBottom: 0,
  height: 2,
  borderRadius: 2
}));

export default CustomDivider;
