import React, { Fragment, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeaderBoardList from "components/LeaderboardComponents/LeaderBoardList/LeaderBoardList";
import MonthlySwag from "components/LeaderboardComponents/MonthlySwag/MonthlySwag";
import Card from "components/Card/Card";
import TShirt from "assets/images/TShirt.png";
import sweatshirt from "assets/images/sweatShirt.png";
import Header from "components/Header/Header";
import alert from "assets/icons/alert.svg";
import { useState } from "react";
import { createBreadcrumbs } from "helpers/breadCrumbs";

const LeaderBoardColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 170
  },
  {
    id: "Employee Class",
    label: "Employee Class  ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Trivia Questions Answered Correctly",
    label: "Trivia Questions Answered Correctly  ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Signups / Upgrades",
    label: "Signups / Upgrades ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Fairness Factor",
    label: "Fairness Factor",
    align: "center",
    minWidth: 100
  },
  {
    id: "Coins Earned",
    label: "Coins Earned",
    align: "center",
    icon: alert,
    minWidth: 170
  }
];

const LeaderBoardRows = [
  {
    id: 1,
    rank: 1,
    name: "Scott Hatteberg",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "TR",
    correctAnswers: "10",
    signUp_Upgrades: "8",
    coins: "80",
    fairnessFactor: "1.0x"
  },
  {
    id: 2,
    rank: 2,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    employeeClass: "Server",
    correctAnswers: "6",
    signUp_Upgrades: "4",
    coins: "72",
    fairnessFactor: "3.0x"
  },
  {
    id: 3,
    rank: 3,
    name: "Marcella Ellis",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    employeeClass: "Server",
    correctAnswers: "6",
    signUp_Upgrades: "4",
    coins: "72",
    fairnessFactor: "3.0x"
  },
  {
    id: 4,
    rank: 4,
    name: "Albert Flores",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    employeeClass: "TR",
    correctAnswers: "8",
    signUp_Upgrades: "5",
    coins: "40",
    fairnessFactor: "1.0x"
  },
  {
    id: 5,
    rank: 5,
    name: "Devon Lane",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    employeeClass: "Server",
    correctAnswers: "5",
    signUp_Upgrades: "2",
    coins: "30",
    fairnessFactor: "3.0x"
  },
  {
    id: 6,
    rank: 6,
    name: "Scott Hatteberg",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    employeeClass: "TR",
    correctAnswers: "10",
    signUp_Upgrades: "8",
    coins: "80",
    fairnessFactor: "1.0x"
  },
  {
    id: 7,
    rank: 7,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    employeeClass: "Server",
    correctAnswers: "6",
    signUp_Upgrades: "4",
    coins: "72",
    fairnessFactor: "3.0x"
  },
  {
    id: 8,
    rank: 8,
    name: "Marcella Ellis",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    employeeClass: "Server",
    correctAnswers: "6",
    signUp_Upgrades: "4",
    coins: "72",
    fairnessFactor: "3.0x"
  },
  {
    id: 9,
    rank: 9,
    name: "Albert Flores",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    employeeClass: "TR",
    correctAnswers: "8",
    signUp_Upgrades: "5",
    coins: "40",
    fairnessFactor: "1.0x"
  },
  {
    id: 10,
    rank: 10,
    name: "Devon Lane",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    employeeClass: "Server",
    correctAnswers: "5",
    signUp_Upgrades: "2",
    coins: "30",
    fairnessFactor: "3.0x"
  }
];
const winnersData = [
  {
    id: 1,
    monthName: "February 22’",
    shirtType: "Sweatshirt",
    shirtImg: sweatshirt,
    winnerList: [
      {
        id: 1,
        userImg: "https://randomuser.me/api/portraits/men/80.jpg",
        userName: "DAVID JUSTICE"
      },
      {
        id: 2,
        userImg: "https://randomuser.me/api/portraits/men/9.jpg",
        userName: "Joe Taylor"
      },
      {
        id: 3,
        userImg: "https://randomuser.me/api/portraits/women/54.jpg",
        userName: "Cindy McKay"
      },
      {
        id: 4,
        userImg: "https://randomuser.me/api/portraits/women/93.jpg",
        userName: "Jennifer Giambi"
      },
      {
        id: 5,
        userImg: "https://randomuser.me/api/portraits/women/15.jpg",
        userName: "Ramon Hernandez"
      }
    ]
  },
  {
    id: 2,
    monthName: "January 22’",
    shirtType: "T-Shirt",
    shirtImg: TShirt,
    winnerList: [
      {
        id: 1,
        userImg: "https://randomuser.me/api/portraits/men/80.jpg",
        userName: "DAVID JUSTICE"
      },
      {
        id: 2,
        userImg: "https://randomuser.me/api/portraits/men/9.jpg",
        userName: "Joe Taylor"
      },
      {
        id: 3,
        userImg: "https://randomuser.me/api/portraits/women/54.jpg",
        userName: "Cindy McKay"
      },
      {
        id: 4,
        userImg: "https://randomuser.me/api/portraits/women/93.jpg",
        userName: "Jennifer Giambi"
      },
      {
        id: 5,
        userImg: "https://randomuser.me/api/portraits/women/15.jpg",
        userName: "Ramon Hernandez"
      }
    ]
  },
  {
    id: 3,
    monthName: "December 22’",
    shirtType: "Sweatshirt",
    shirtImg: sweatshirt,
    winnerList: [
      {
        id: 1,
        userImg: "https://randomuser.me/api/portraits/men/80.jpg",
        userName: "DAVID JUSTICE"
      },
      {
        id: 2,
        userImg: "https://randomuser.me/api/portraits/men/9.jpg",
        userName: "Joe Taylor"
      },
      {
        id: 3,
        userImg: "https://randomuser.me/api/portraits/women/54.jpg",
        userName: "Cindy McKay"
      },
      {
        id: 4,
        userImg: "https://randomuser.me/api/portraits/women/93.jpg",
        userName: "Jennifer Giambi"
      },
      {
        id: 5,
        userImg: "https://randomuser.me/api/portraits/women/15.jpg",
        userName: "Ramon Hernandez"
      }
    ]
  }
];

function AreaDirectorLeaderBoard() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Top 5 Leaderboard";
  }, []);

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="Leaderboard" breadCrumbs={breadCrumbs} />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <LeaderBoardList
              columns={LeaderBoardColumns}
              rows={LeaderBoardRows}></LeaderBoardList>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0 mt-4  ">
        <Row>
          <Col lg="12">
            <Card>
              <MonthlySwag winnerData={winnersData}></MonthlySwag>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AreaDirectorLeaderBoard;
