import React from "react";
import { List, ListItem, Typography } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import Card from "components/Card/Card";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomDivider from "components/StyledComponents/Divider";

function GMTriviaAnalyticsSnapshotUI({ analyticsData ,routeNext, date="",style = {} }) {
  const history = useHistory();

  return (
    <Card style={{ paddingBottom: 12,...style }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Trivia Analytics {date ? `for ${date} `:""}</Typography>
          <span onClick={() => history.push(routeNext)}>
            <IconButtonWithBorder aria-label="Trivia Analytics">
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Active Players
          </Typography>
          <Typography variant="body1Regular">{analyticsData?.active_players ? analyticsData?.active_players.toFixed(0) : "-"} </Typography>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Change From Previous Week
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            {`${ analyticsData?.stat?.previous_active_players_change ? analyticsData?.stat?.previous_active_players_change.toFixed(0) +"%" : "-"}`}
          </Typography>
        </ListItem>
        <CustomDivider />
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Correct Answers
          </Typography>
          <Typography variant="body1Regular">
            {`${analyticsData?.stat?.percentage_correct ? analyticsData?.stat?.percentage_correct.toFixed(0) +"%" : "-" }`}
          </Typography>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Change From Previous Week
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            {`${analyticsData?.stat?.percentage_correct_previous_week ? analyticsData?.stat?.percentage_correct_previous_week.toFixed(0)+"%" : "-"}`}
          </Typography>
        </ListItem>
      </List>
    </Card>
  );
}

export default GMTriviaAnalyticsSnapshotUI;

// const data = {
//   activePlayers: 50,
//   activePlayerChanges: 20,
//   correctAnswers: 70,
//   correctAnswerChanges: 30
// };
