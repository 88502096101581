import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./adminUserList.module.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoIcon from "@mui/icons-material/Info";
import avatar from "assets/images/user/avatarUser.svg"
import { useRef } from "react";
import { Backdrop, Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/system";
import Chip from '@mui/material/Chip';
import { useHistory } from "react-router-dom";
import AppPagination from "components/StyledComponents/AppPagination";
import { useDispatch } from "react-redux";
import { superAdminAction } from "redux/superAdmin/slice/user";
import { toTitleCase } from "helpers/functions";
// import moment from "moment";
import TableNoRecords from "components/TableNoRecords";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { appActions } from "redux/app/slice/app";


const orderStat = {
  ASC: "hire_date",
  DESC: "-hire_date"
}


const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}));

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const AdminUserListTable = ({ columns = [], rows = [], setPage, currentPage, totalPage }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const rowId = useRef(null);
  const history = useHistory()
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch()
  const themes = useTheme();
  const [order, setOrder] = useState(orderStat.DESC)
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const onOptionsClick = (e, id) => {
    e.stopPropagation();
    rowId.current = id;
    setAnchorEl(e.currentTarget);
    dispatch(superAdminAction.setAdminUserID(rowId.current));
  };

  function handleSort() {
    if (order === orderStat.DESC) {
      setOrder("hire_date")
      dispatch(appActions.setOrderBy("hire_date"))
    } else {
      setOrder("-hire_date")
      dispatch(appActions.setOrderBy("-hire_date"))
    }
  }
  

  useEffect(() => {
    setOrder("-hire_date")
  }, [])

  useEffect(() => {
  }, [order])


  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", }}>
        <TableContainer sx={{ maxHeight: 700 }}>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}{" "}
                      {column?.tooltip && (
                        <Tooltip title={column.tooltip}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                      {column?.isSorting && (
                        <span onClick={handleSort} style={{cursor:"pointer"}}>
                          {order === '-hire_date' ?
                            <ArrowDownwardIcon fontSize="small" ></ArrowDownwardIcon>
                            :
                            <ArrowUpwardIcon fontSize="small"></ArrowUpwardIcon>
                          }
                        </span>

                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? rows?.map((row, index) => {
                return (
                  <TableRow
                    sx={
                      row?.status == "DEACTIVATED"
                        ? { opacity: "0.6" }
                        : { opacity: "1" }
                    }
                    key={row.id}>

                    <TableCell align="center">
                      <div className={styles.rank}>{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      <div className={`${styles.tableAvatar}`}>
                        <div
                          className={`${styles.tableAvatar__img}`}>
                          <img src={row?.image_url ? row?.image_url : avatar} />
                        </div>
                        <div className="d-flex flex-column">
                          <Typography variant="body2Regular">
                            {row?.full_name ?? "-"}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2Regular">
                        {toTitleCase(row?.user_type?.name ?? "-") ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2Regular">{row?.role?.name ?? "-"}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2Regular">
                        {row?.restaurant ?? "-"}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="center">
                      <Typography variant="body2Regular">
                        {row?.hire_date ? moment(new Date(row?.hire_date)).format("MMM DD, YYYY") : "-"}
                      </Typography>
                    </TableCell> */}
                    <TableCell align="center">
                      <Chip variant="filled" color="secondary" label={`${row?.status}`} />
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={(e) => e.stopPropagation()}>
                      <span onClick={(e) => onOptionsClick(e, row?.id)}>
                        <MoreHorizIcon
                          sx={() => ({
                            height: "24px",
                            width: "24px"
                          })}
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })
                :
                <TableNoRecords colSpan={8} label="No Users Found" />
              }
            </TableBody>
          </CustomTable>
        </TableContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: 1300 }}
          open={openMenu}
          onClick={handleMenuClose}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              sx: {
                marginTop: "15px",
                backgroundColor: themes.palette.background.default,
                borderRadius: "12px",
                "& .MuiList-root": {
                  backgroundColor: themes.palette.background.default,
                  width: "225px",
                  boxSizing: "border-box",
                  padding: "10px 0"
                }
              }
            }}>
            <StyledMenuItem
              onClick={() =>
                history.push("/super-admin/dashboard/admin-user-list/view-admin-user")
              }>
              <Typography variant="body1Regular">View</Typography>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() =>
                history.push("/super-admin/dashboard/admin-user-list/edit-admin-user")
              }>
              <Typography variant="body1Regular">Edit</Typography>
            </StyledMenuItem>
            {/* <StyledMenuItem onClick={toggleDeletePopup}>
            <Typography
              sx={{ color: themes.palette.red.main }}
              variant="body1Regular">
              Delete
            </Typography>
          </StyledMenuItem> */}
          </Menu>
        </Backdrop>
      </Paper>
      {
        rows?.length ?
          <AppPagination
            onPageChange={setPage}
            totalPages={totalPage}
            currentPage={currentPage}
          />
          : ""
      }
    </>
  );
};

export default AdminUserListTable;
