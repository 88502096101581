import {
  Box,
  Dialog,
  DialogContent,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import CheckBox from "components/CheckBox";
import React from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import AppButton from "components/StyledComponents/AppButton";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: 667,
    padding: 24,
    boxSizing: "border-box"
  }
}));

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    },
    "&:not(:first-of-type)": {
      textAlign: "center"
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  "& .MuiTableRow-head": {
    borderTop: `2px solid ${theme.palette.primary.main}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const WeeklyMeetingsPopup = ({ open, onClose = () => {} }) => {
  const handleSubmit = () => {
    onClose();
  };

  const getTableRows = () => {
    let data = [];
    for (let i = 0; i < 8; ++i) {
      data.push(
        <TableRow key={`tableRow${i}`}>
          <TableCell>
            <Box className="d-flex">
              <Typography variant="body1Regular">{`Week ${i + 1}`}</Typography>
              {rows[i]?.isSkipped ? (
                <Box className="d-flex align-items-center ms-2">
                  <WarningRoundedIcon color="red" sx={{ width: 15 }} />
                  <Typography
                    variant="tag1"
                    className="ms-1"
                    sx={(theme) => ({
                      color: theme.palette.red.main
                    })}>
                    Meeting Skipped
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </TableCell>
          <TableCell>
            <Typography variant="body1Regular">
              <CheckBox checked={rows[i]?.isOneToOneMeeting} />
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1Regular">
              {rows[i]?.managerName ?? "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1Regular">
              {rows[i]?.meetingDate ?? "-"}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
    return data;
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="">
      <DialogContent>
        <Box className="d-flex justify-content-center mb-3">
          <Typography variant="h3Italic">Weekly Meetings</Typography>
        </Box>

        <TableContainer>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.name}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{getTableRows()}</TableBody>
          </CustomTable>
        </TableContainer>
        <Box className="d-flex justify-content-center">
          <AppButton
            onClick={handleSubmit}
            styles={{ padding: "13px 70px", marginTop: 26 }}>
            Submit
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default WeeklyMeetingsPopup;

const columns = [
  {
    id: 1,
    align: "left",
    name: "Weeks",
    minWidth: 210
  },
  {
    id: 2,
    align: "center",
    name: "One on one meeting",
    minWidth: 100
  },
  {
    id: 3,
    align: "center",
    name: "Manager Name"
  },
  {
    id: 4,
    align: "right",
    name: "Meeting Date"
  }
];

const rows = [
  {
    id: 1,
    isOneToOneMeeting: true,
    managerName: "Ryan Russell",
    meetingDate: "Oct 01, 2021"
  },
  {
    id: 2,
    isSkipped: true
  },
  {
    id: 1,
    isOneToOneMeeting: true,
    managerName: "Ryan Russell",
    meetingDate: "Oct 15, 2021"
  },
  {
    id: 1,
    isOneToOneMeeting: true,
    managerName: "Ryan Russell",
    meetingDate: "Oct 22, 2021"
  },
  {
    id: 1,
    isOneToOneMeeting: true,
    managerName: "Ryan Russell",
    meetingDate: "Oct 29, 2021"
  },
  {
    id: 1,
    isOneToOneMeeting: true,
    managerName: "Ryan Russell",
    meetingDate: "Nov 05, 2022"
  }
];
