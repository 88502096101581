import React, { useState } from "react";
import { useEffect } from "react";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import Card from "components/Card/Card";
import { Col, Container, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import BasePeriodNav from "../BasePeriodNavBar";
import SalesTable from "./salesTable";
import AverageCheckTable from "./averageCheckTable";

function POSData() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "POS Data";
  }, []);

  return (
    <>
      <BasePeriodNav
        breadCrumbs={breadCrumbs}
        isPOSActive={true}></BasePeriodNav>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Container fluid className="mt-3">
          <Row className="m-0">
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-between"
              style={{ height: "50px" }}>
              <Typography variant="h2">Sales for 5 weeks</Typography>
            </Col>
          </Row>
        </Container>
        <Container style={{ minWidth: "100%" }}>
          <Row>
            <Col lg="12">
              <SalesTable
                food={food}
                inviteRows={inviteRows}
                rows={rows}
                columns={columns}></SalesTable>
            </Col>
          </Row>
        </Container>
      </Card>
      <Container fluid className="mt-5">
        <Row className="m-0">
          <Col
            lg="12"
            className="d-flex align-items-center justify-content-between"
            style={{ height: "50px" }}>
            <Typography variant="h2">Average Check</Typography>
          </Col>
        </Row>
      </Container>
      <Container className="mt-2" style={{ minWidth: "100%" }}>
        <Row>
          <Col lg="12">
            <AverageCheckTable></AverageCheckTable>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default POSData;

const columns = [
  {
    id: "Category",
    label: "Category",
    minWidth: 200
  },
  {
    id: "3/7/22 - 3/13/22",
    label: "3/7/22 - 3/13/22",
    align: "center",
    minWidth: 100
  },
  {
    id: "3/14/22 - 3/20/22",
    label: "3/14/22 - 3/20/22",
    align: "center",
    minWidth: 100
  },
  {
    id: "3/21/21 - 3/27/22",
    label: "3/21/21 - 3/27/22",
    align: "center",
    minWidth: 70
  },
  {
    id: "3/28/22 - 4/3/22",
    label: "3/28/22 - 4/3/22",
    align: "center",
    minWidth: 100
  },
  {
    id: "4/4/22 - 4/10/22",
    label: "4/4/22 - 4/10/22",
    align: "center",
    minWidth: 80
  },
  {
    id: "Total",
    label: "Total",
    align: "center",
    minWidth: 80
  }
];

const rows = [
  {
    id: 1,
    employeeClass: "Non-Members",
    date1: 1200,
    date2: 1140,
    date3: 1560,
    date4: 1680,
    date5: 1920
  },
  {
    id: 2,
    employeeClass: "Members",
    date1: 800,
    date2: 960,
    date3: 1040,
    date4: 1120,
    date5: 1280
  }
];

const food = [
  {
    id: 1,
    employeeClass: "Beverage",
    date1: 12000,
    date2: 11409,
    date3: 15609,
    date4: 16809,
    date5: 19209
  },
  {
    id: 2,
    employeeClass: "Food",
    date1: 8009,
    date2: 9609,
    date3: 10409,
    date4: 11209,
    date5: 12809
  }
];

const inviteRows = [
  {
    id: 2,
    invitations: "Invite Rate",
    Invitedate1: 12,
    Invitedate2: 8.0,
    Invitedate3: 15.0,
    Invitedate4: 16.0,
    Invitedate5: 20
  },
  {
    id: 2,
    invitations: "Invites",
    Invitedate1: 345,
    Invitedate2: 543,
    Invitedate3: 654,
    Invitedate4: 345,
    Invitedate5: 345
  },
  {
    id: 2,
    invitations: "Upgrade Rate",
    Invitedate1: 12,
    Invitedate2: 8.0,
    Invitedate3: 15.0,
    Invitedate4: 16.0,
    Invitedate5: 20
  },
  {
    id: 2,
    invitations: "Upgrades",
    Invitedate1: 34,
    Invitedate2: 54,
    Invitedate3: 65,
    Invitedate4: 34,
    Invitedate5: 34
  }
];
