import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import CheckBox from "components/CheckBox";
import React from "react";
import styles from "./ApprovePerformanceTable.module.scss";

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root:nth-last-of-type(-n+2)": {
      borderBottom: "none"
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #373737`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const TableFooter = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 6,
  marginBottom: 10,
  "& .MuiTableCell-root": {
    backgroundColor: "transparent",
    "&:first-of-type": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "&:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6
    }
  }
}));

const ApprovePerformanceTable = ({
  rows = [],
  columns = [],
  selectedEmployees = [],
  setSelectedEmployees = () => {},
  selectedEmployeesCount,
  setSelectedEmployeesCount
}) => {
  const onEmployeeSelect = (index, id) => {
    let previousData = [...selectedEmployees];
    if (previousData[index]) {
      setSelectedEmployeesCount((prev) => prev - 1);
      previousData[index] = false;
    } else {
      setSelectedEmployeesCount((prev) => prev + 1);
      previousData[index] = id;
    }
    setSelectedEmployees(previousData);
  };

  const handleAllSelct = () => {
    if (selectedEmployeesCount === rows.length) {
      setSelectedEmployees([]);
      setSelectedEmployeesCount(0);
    } else {
      setSelectedEmployees(rows.map((el) => el.id));
      setSelectedEmployeesCount(rows.length);
    }
  };

  const getColor = (rank) => {
    switch (Math.floor(rank / 9)) {
      case 0:
        return "#3ADB76";
      case 1:
        return "#9CC43B";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <CheckBox
                  checked={selectedEmployeesCount === rows.length}
                  onClick={handleAllSelct}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                    {column.icon && (
                      <img
                        className={styles.columnIcon}
                        src={column.icon}
                        alt=""
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>
                  <CheckBox
                    checked={selectedEmployees[index]}
                    onClick={() => onEmployeeSelect(index, row.id)}
                  />
                </TableCell>
                <TableCell>
                  <div
                    className={styles.rank}
                    style={{ borderColor: getColor(row.rank) }}>
                    {row.rank}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={`${styles.tableAvatar}`}>
                    <div
                      className={`${styles.tableAvatar__img} ${
                        row.rank === 1 && styles.borderImg
                      }`}>
                      <img src={row.image} />
                    </div>
                    <div className="d-flex flex-column">
                      <Typography variant="body2Regular">{row.name}</Typography>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">
                    {row.percentGoal + "%"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">
                    {row.lostOpportunity}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">{row.jobClass}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">{row.nonWCM}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">
                    {row.inviteRate?.toFixed(1) + "%"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">
                    {row.benchmarkInviteRate?.toFixed(1) + "%"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">
                    {row.performanceGap?.toFixed(1) + "%"}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2Regular">{row.tenure}</Typography>
                </TableCell>
              </TableRow>
            ))}
            <TableFooter>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.lostOpportunity, 0)}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableFooter>
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  );
};

export default ApprovePerformanceTable;
