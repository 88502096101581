const { styled, Box } = require("@mui/material")

export const UploadFieldContainer = styled(Box)(({ theme }) => ({
  height: 50,
  boxSizing: "border-box",
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 6,
  overflow: "hidden",
  cursor: "pointer",
  "&.error": {
    border: `1px solid #A9402F`
  },
  "&.disabled": {
    cursor: "default"
  },
  "& .noImageDataContainer": {
    padding: 6,
    "& .placeholder": {
      ...theme.typography.body1Regular,
      cursor: "default",
      flexGrow: 1,
      overflow: "hidden",
      backgroundColor: "transparent",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      padding: "0px 16px"
    },
    "& button": {
      flexShrink: 0
    }
  },
  "& .imageDataContainer": {
    padding: "7px 16px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    cursor: "auto",
    justifyContent: "space-between",
    alignItems: "center",
    "& .imageDataContainerInner": {
      display: "flex",
      cursor: "pointer",
      maxWidth: "calc(100% - 34px)",
      "& .imageContainer": {
        height: 32,
        width: 32,
        backgroundColor: theme.palette.background.main,
        borderRadius: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      },
      "& .imageData": {
        display: "flex",
        flexDirection: "column",
        marginLeft: 12,
        overflow: "hidden",
        "& span": {
          flexShrink: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      }
    }
  }
}))
