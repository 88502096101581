import React, { useEffect, useState } from "react"
import { Grid, List, ListItem, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Card from "components/Card/Card"
import { useTheme } from "@mui/system"
import moment from "moment"

function FeatureItemOfMonthSnapShot({
  listData = [],
  nextRoute = "/marketing/dashboard/featured-Items-of-the-Month"
}) {
  const history = useHistory()
  const theme = useTheme()
  const [imgWidth, setImgWidth] = useState({})

  const classes = {
    featureItemContainer: {
      border: `1px solid `,
      borderColor: theme.palette.secondary.main,
      padding: 16,
      display: "flex",
      alignItems: "center",
      position: "relative",
      margin: 16,
      borderRadius: 12,
      height: 120
    },
    featureItemContainerSpirit: {
      border: `1px solid `,
      borderColor: theme.palette.secondary.main,
      padding: 16,
      display: "flex",
      alignItems: "center",
      position: "relative",
      margin: 16,
      borderRadius: 12,
      height: 120,
      background:
        "radial-gradient(53.47% 65.24% at 99.09% 90.85%, rgba(173, 145, 111, 0.20) 0%, rgba(173, 145, 111, 0.00) 100%), radial-gradient(62% 108.38% at 1.39% 0%, rgba(173, 145, 111, 0.20) 0%, rgba(139, 110, 75, 0.00) 100%), var(--V1, #231E1B)"
    },
    wineMonth: {
      textTransform: "uppercase",
      color: theme.palette.secondary.main
    },
    wineBottle: {
      position: "absolute",
      height: 120,
      bottom: 20
    },
    wineImage: {
      height: "100%"
    },
    wineDetail: {
      marginLeft: "50px"
    },
    mainContainer: {
      rowGap: "35px"
    }
  }

  useEffect(() => {
    const handleImageLoad = (e) => {
      const newWidth = e.target.offsetWidth + 16
      const imgId = e.target.getAttribute("data-id")
      setImgWidth((prevWidths) => ({
        ...prevWidths,
        [imgId]: `${newWidth}px`
      }))
    }

    const images = document.querySelectorAll(".wineImage")
    images.forEach((img) => {
      if (img.complete) {
        handleImageLoad({ target: img })
      } else {
        img.addEventListener("load", handleImageLoad)
      }
    })

    return () => {
      images.forEach((img) => {
        img.removeEventListener("load", handleImageLoad)
      })
    }
  }, [listData])

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 12px 0" }}>
          <Typography variant="h2">Featured Items Of The Month</Typography>
          <span onClick={() => history.push(nextRoute)}>
            <IconButtonWithBorder aria-label="Wine Of The Month">
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>
      </List>
      <Grid spacing={{ xs: 2, md: 3 }} container style={classes.mainContainer} className="p-4 ">
        {listData.length ? (
          listData?.slice(0, 3).map((res) => (
            <Grid key={res.id} item xs={12} sm={12} md={6} lg={4} className="p-0">
              <div
                style={
                  res?.alcohol_type !== "spirit"
                    ? classes.featureItemContainer
                    : classes.featureItemContainerSpirit
                }>
                <div style={classes.wineBottle}>
                  {res?.month ? (
                    <img
                      data-id={res.id}
                      className="wineImage"
                      style={classes.wineImage}
                      src={res?.wine_image}
                      alt=""
                      loading="eager"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ marginLeft: imgWidth?.[res.id] || "50px" }}>
                  <div className="mb-2">
                    <Typography variant="caption2" style={{ opacity: 0.6 }}>
                      {moment(res?.month).format("MMMM yy") ?? "-"}
                    </Typography>
                  </div>
                  <div style={classes.nameWine}>
                    <Typography variant="caption2" style={classes.wineMonth}>
                      {res?.name
                        ? res?.name?.length > 26
                          ? `${res?.name.slice(0, 26)}...`
                          : res?.name
                        : "-"}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          ))
        ) : (
          <Typography variant="caption2" style={{ opacity: 0.6 }}>
            No data Found
          </Typography>
        )}
      </Grid>
    </Card>
  )
}

export default FeatureItemOfMonthSnapShot
