import { Box } from "@mui/material"
import DMVGenerateQuestion from "components/DMVGenerateQuestion"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React from "react"
import { Col, Row } from "react-bootstrap"

const DMVGenerate = () => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Header title="Menu" breadCrumbs={createBreadcrumbs()} back={true}></Header>
        </Col>
      </Row>
      <Box sx={{ width: "100%" }}>
        <DMVGenerateQuestion />
      </Box>
    </>
  )
}

export default DMVGenerate
