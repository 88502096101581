import DMVButtonNav from "components/DMVNavBar/components/DMVButtonNav"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { DMVActions } from "redux/DMV/slice/user"

const tabItems = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

function DMVGenerateQuestionNavBar(props) {
  const [menu] = useState(menuNav)
  const dispatch = useDispatch()

  function handleButtonNav(item) {
    dispatch(DMVActions.setDMVGenerate_Question_MenuLink(item))
  }

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <DMVButtonNav
            style={{ marginRight: 12 }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
          </DMVButtonNav>
        )
      })}
    </>
  )
}

export default DMVGenerateQuestionNavBar

const menuNav = [
  {
    name: "Wine Region",
    isActive: true,
    value: tabItems?.WINE_REGION
  },
  {
    name: "Beverage",
    isActive: false,
    value: tabItems?.BEVERAGE
  },
  { name: "Spirits", isActive: false, value: tabItems.SPIRITS }
]
