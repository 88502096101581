import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import { validationSchemaEvent } from "helpers/validationSchema"
import { useHistory } from "react-router-dom"
import EventForm from "../EventForm"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { editEventAction } from "redux/superAdmin/actions"
import { convertDate, initDateConvert, payloadDate } from "helpers/functions"

const initialValues = {
  name: "",
  startDate: "",
  startTime: "",
  endTime: "",
  rsvpDate: "",
  rsvpTime: "",
  eventType: "Online",
  eventLink: "",
  address: "",
  description: "",
  notificationDate: "",
  notificationTime: "",
  uploadThumbnail: "",
  notificationContent: ""
}

const EditEvent = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialFormValue, setInitialFormValue] = useState(initialValues)
  const { notificationImageId } = useSelector((store) => store?.superAdmin?.notifications || [])
  const { eventDetails } = useSelector((store) => store?.superAdmin ?? {})
  const history = useHistory()
  const dispatch = useDispatch()
  const zone_name = moment.tz.guess()

  function initFormData() {
    // Event Start Date and Time
    const eventStartTimeStamp = initDateConvert(eventDetails?.start_date, eventDetails?.start_time)
    const eventStartTime = moment(eventStartTimeStamp).format("HH:mm:ss")
    // Event End Date and Time
    const eventEndTimeStamp = initDateConvert(eventDetails?.start_date, eventDetails?.end_time)
    const eventEndTime = moment(eventEndTimeStamp).format("HH:mm:ss")
    // Event RSVP Date and Time
    const eventRSVPTimeStamp = initDateConvert(
      eventDetails?.rsvp_deadline_date,
      eventDetails?.rsvp_deadline_time
    )
    const eventRSVPTime = moment(eventRSVPTimeStamp).format("HH:mm:ss")
    // Event Notification Date and Time
    const eventNotificationTimeStamp = initDateConvert(
      eventDetails?.notification_data?.scheduled_date,
      eventDetails?.notification_data?.scheduled_time
    )
    const eventNotificationTime = moment(eventNotificationTimeStamp).format("HH:mm:ss")
    const initData = {
      name: eventDetails?.name || "",
      startDate: eventStartTimeStamp || "",
      startTime: eventStartTime || "",
      endTime: eventEndTime || "",
      rsvpDate: eventRSVPTimeStamp || "",
      rsvpTime: eventRSVPTime || "",
      eventType: eventDetails?.event_type || "Online",
      eventLink: eventDetails?.link || "",
      address: eventDetails?.address || "",
      description: eventDetails?.description || "",
      notificationDate: eventNotificationTimeStamp || "",
      notificationTime: eventNotificationTime || "",
      uploadThumbnail: eventDetails?.notification_data?.image_url
        ? {
            id: "",
            url: eventDetails?.notification_data?.image_url ?? ""
          }
        : "",
      notificationContent: eventDetails?.notification_data?.content || ""
    }
    setInitialFormValue(initData)
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Edit Event"
    initFormData()
  }, [eventDetails])

  function handleSubmit(values) {
    // Event Start Date and Time
    const EventStartTimeStamp = convertDate(values?.startDate, values?.startTime)
    const EventStartTimeSplit = EventStartTimeStamp.split(" ")
    const EventStartDate = payloadDate(EventStartTimeSplit)
    // Event End Date and Time
    const EventEndTimeStamp = convertDate(values?.startDate, values?.endTime)
    const EventEndTimeSplit = EventEndTimeStamp.split(" ")
    const EventEndDate = payloadDate(EventEndTimeSplit)
    // Event RSVP Date and Time
    const EventRSVPTimeStamp = convertDate(values?.rsvpDate, values?.rsvpTime)
    const EventRSVPTimeSplit = EventRSVPTimeStamp.split(" ")
    const EventRSVPDate = payloadDate(EventRSVPTimeSplit)
    // Event Notification Date and Time
    const EventNotificationTimeStamp = convertDate(
      values?.notificationDate,
      values?.notificationTime
    )
    const EventNotificationTimeSplit = EventNotificationTimeStamp.split(" ")
    const EventNotificationDate = payloadDate(EventNotificationTimeSplit)
    const payload = {
      name: values?.name || "",
      start_date: EventStartDate || "",
      start_time: EventStartTimeSplit[4] || "",
      end_date: EventEndDate || "",
      end_time: EventEndTimeSplit[4] || "",
      rsvp_deadline_date: EventRSVPDate || "",
      rsvp_deadline_time: EventRSVPTimeSplit[4] || "",
      event_type: values?.eventType,
      link: values?.eventLink || "",
      address: values?.address || "",
      description: values?.description || "",
      notification_data: {
        scheduled_date: EventNotificationDate || "",
        scheduled_time: EventNotificationTimeSplit[4] || "",
        ...(notificationImageId
          ? { image_id: notificationImageId }
          : values.uploadThumbnail
          ? {}
          : { image_id: "" }),
        content: values?.notificationContent || "",
        time_zone: zone_name,
        status: "scheduled"
      }
    }
    const onClose = () => {
      history.replace("/super-admin/dashboard/event-listing")
    }
    dispatch(editEventAction({ payload: payload, onClose: onClose }))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Edit Event" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaEvent}
        initialValues={initialFormValue}>
        {(formik) => <EventForm formik={formik} btnText="Update Event"></EventForm>}
      </Formik>
    </>
  )
}

export default EditEvent
