import React, { useState } from "react";
import { useEffect } from "react";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import Card from "components/Card/Card";
import { Col, Container, Row } from "react-bootstrap";
import { Typography } from "@mui/material";
import AppButtonFilled from "components/StyledComponents/AppButtonFilled";
import BasePeriodNav from "../BasePeriodNavBar";

function BasePeriodGraph() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Base Period Graph";
  }, []);

  return (
    <>
      <BasePeriodNav
        breadCrumbs={breadCrumbs}
        isPeriodGraphActive={true}></BasePeriodNav>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Container fluid className="mt-3">
          <Row className="m-0">
            <Col
              lg="12"
              className="d-flex align-items-center justify-content-between"
              style={{ height: "50px" }}>
              <Typography className="ms-3" variant="h2"></Typography>
              <AppButtonFilled
                onClick={() =>
                  history.push("/dmv/dashboard/performance-management")
                }>
                See Improvements
              </AppButtonFilled>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  );
}

export default BasePeriodGraph;
