import * as React from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Backdrop, Menu, MenuItem, styled } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { useState } from "react"
import { useRef } from "react"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import moment from "moment"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import TableNoRecords from "components/TableNoRecords"
import { deleteRaffleContestAction } from "redux/marketing/actions"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))

const RaffleTable = ({ columns, rows }) => {
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)
  const openMenu = Boolean(anchorEl)
  const themes = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const rowId = useRef(null)
  const [isDelete, setIsDelete] = useState(true)
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const onOptionsClick = (e, id, raffleDate, raffleTime) => {
    e.stopPropagation()
    rowId.current = id
    setAnchorEl(e.currentTarget)
    dispatch(marketingActions.setRaffleID(id))
    const newDate = convertDate(raffleDate, raffleTime)
    const currentDate = moment(new Date()).format("DD MMM YYYY")
    if (newDate === currentDate) {
      setIsDelete(false)
    } else {
      setIsDelete(true)
    }
  }
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: `${themes.palette.background.main}`,
      "& .MuiPaper-root": {
        backgroundColor: "red"
      },
      "& .MuiTableRow-hover": {
        backgroundColor: "red"
      },
      "& .MuiTableCell-root": {
        padding: "16px 16px",
        backgroundColor: `${themes.palette.background.main}`,
        borderBottom: "1px solid #353535"
      }
    }
  }))

  const classes = useStyles()
  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen)
  }

  const handleDelete = () => {
    dispatch(deleteRaffleContestAction(rowId.current))
  }

  const formatTime = (date, time) => {
    if (!date || !time) return ""
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().format("hh:mm A")
  }

  function convertDate(date, time) {
    const forDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().format("DD MMM YYYY")
  }

  useEffect(() => {}, [isDelete])

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Raffle"
        onDelete={handleDelete}
        onClose={toggleDeletePopup}
      />
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table className={classes.root} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length ? (
              <>
                {rows.map((row) => {
                  return (
                    <TableRow key={row?.id}>
                      <TableCell align="left">{convertDate(row?.start_date, row?.time)}</TableCell>
                      <TableCell align="center">{formatTime(row?.start_date, row?.time)}</TableCell>
                      {/* <TableCell align="center">
                        {convertDate(row?.wine_club_dinner, row?.dinner_time)}
                      </TableCell>
                      <TableCell align="center">
                        {formatTime(row?.wine_club_dinner, row?.dinner_time)}
                      </TableCell> */}
                      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                        <span
                          onClick={(e) => onOptionsClick(e, row.id, row?.start_date, row?.time)}>
                          <MoreHorizIcon
                            sx={() => ({
                              height: "24px",
                              width: "24px"
                            })}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            ) : (
              <TableNoRecords colSpan={6} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          PaperProps={{
            sx: {
              marginTop: "15px",
              backgroundColor: themes.palette.background.default,
              borderRadius: "12px",
              "& .MuiList-root": {
                backgroundColor: themes.palette.background.default,
                width: "225px",
                boxSizing: "border-box",
                padding: "10px 0"
              }
            }
          }}>
          <StyledMenuItem
            onClick={() => history.push("/marketing/dashboard/contest-configuration/edit")}>
            <Typography variant="body1Regular">Edit</Typography>
          </StyledMenuItem>
          {isDelete && (
            <StyledMenuItem onClick={toggleDeletePopup}>
              <Typography sx={{ color: themes.palette.red.main }} variant="body1Regular">
                Delete
              </Typography>
            </StyledMenuItem>
          )}
        </Menu>
      </Backdrop>
    </Paper>
  )
}

export default RaffleTable
