import { Backdrop, Grid, List, Typography } from "@mui/material";
import Header from "components/Header/Header";
import AppButton from "components/StyledComponents/AppButton";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { useTheme } from "@mui/system";
import avatar from 'assets/images/user/avatarUser.svg';
import { useDispatch } from 'react-redux';
import { getFrontlineUsersDataAction, updateUserStatusAction } from "redux/superAdmin/actions";
import { useSelector } from 'react-redux';
import { toTitleCase } from "helpers/functions";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog";

const userStatus = {
    activated: "Activated",
    deactivated: "Deactivated"
}

const ViewFrontLineUser = () => {
    const [breadCrumbs, setBreadcrumbs] = useState([]);
    const history = useHistory()
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const { frontlineUserData } = useSelector((store) => store?.superAdmin?.frontlineUser ?? {})
    const [buttonState, setButtonState] = useState("");



    const styles = {
        list: {
            display: "flex",
            flexDirection: "column",
            marginTop: "14px",
            marginBottom: "18px"
        },
        label: {
            color: theme.palette.text.secondary,
            opacity: 0.6,
            marginBottom: "4px"
        },
        userImage: {
            width: "120px",
            height: "120px",
            objectFit: "cover",
            borderRadius: "50%"
        }
    };

    useEffect(() => {
        setBreadcrumbs(createBreadcrumbs());
        dispatch(getFrontlineUsersDataAction())
    }, []);

    function handleEditContest() {
        history.push("/super-admin/dashboard/frontline-user-list/edit-frontline-user")
    }

    function getButtonStatus(status) {
        if (!status) return;
        if (status === userStatus?.activated) {
            return "Deactivate"
        } else {
            return "Activate";
        }
    }

    function handleActivate() {
        if (buttonState === userStatus.activated) {
            dispatch(updateUserStatusAction(false))
            setButtonState("Deactivate")
        } else {
            dispatch(updateUserStatusAction(true))
            setButtonState("Activated")
        }
    }

    useEffect(() => {
        getButtonStatus();
        if (!frontlineUserData?.status) return;
        setButtonState(frontlineUserData?.status)
    }, [buttonState, frontlineUserData])

    const handleClose = () => {
        setAnchorEl(null);
    };
    const toggleActivePopup = () => {
        handleClose();
        setIsDeletePopupOpen(!isDeletePopupOpen);
    };

    function handleDelete() {
        handleActivate();
    }


    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: 1300 }}
                open={open}
                onClick={handleClose}></Backdrop>
            <DeleteConfirmationDialog
                open={isDeletePopupOpen}
                title="Change Status"
                description={`Are you sure you want to ${frontlineUserData?.status === "Activated" ? "Deactivate" : "Activate"} this account ?`}
                btnText={`${frontlineUserData?.status === "Activated" ? "Deactivate" : "Activate"}`}
                onDelete={handleDelete}
                onClose={toggleActivePopup}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Header title="View Frontline User" breadCrumbs={breadCrumbs}>
                        <AppButton onClick={handleEditContest} styles={{ padding: "14px 70px" }} >
                            Edit User
                        </AppButton>
                    </Header>
                </Grid>
            </Grid>
            <Container fluid className="mt-4 mb-4 ps-0"
            >
                <Row>
                    <Col lg="4">
                        <img src={frontlineUserData?.image_url ? frontlineUserData?.image_url : avatar} style={styles.userImage} alt="" />
                    </Col>
                </Row>
            </Container>
            <Container fluid
            >
                <Row>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                USER STATUS
                            </Typography>
                            <Typography variant="body1Regular">
                                {frontlineUserData?.status ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                ROLE
                            </Typography>
                            <Typography variant="body1Regular">
                                {toTitleCase(frontlineUserData?.role_name) ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                JOB CLASS
                            </Typography>
                            <Typography variant="body1Regular">
                                {toTitleCase(frontlineUserData?.job_class) ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                FULL NAME
                            </Typography>
                            <Typography variant="body1Regular">{toTitleCase(frontlineUserData?.full_name) ?? "-"}</Typography>
                        </List>
                    </Col>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                CONTACT NUMBER
                            </Typography>
                            <Typography variant="body1Regular">
                                +{frontlineUserData?.mobile ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                DATE OF HIRE
                            </Typography>
                            <Typography variant="body1Regular">
                                {frontlineUserData?.hired_date ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                ADDRESS
                            </Typography>
                            <Typography variant="body1Regular">
                                {frontlineUserData?.address ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                REPORTING MANAGER
                            </Typography>
                            <Typography variant="body1Regular">
                                {toTitleCase(frontlineUserData?.reporting_manager ?? "-") ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                EMAIL
                            </Typography>
                            <Typography variant="body1Regular">
                                {frontlineUserData?.email ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                RESTAURANT
                            </Typography>
                            <Typography variant="body1Regular">
                                {toTitleCase(frontlineUserData?.rest_name) ?? "-"}
                            </Typography>
                        </List>
                    </Col>
                    {/* <Col lg="4" className="ps-0">
                        <List sx={styles.list}>
                            <Typography sx={styles.label} variant="caption3">
                                SPECIAL USER
                            </Typography>
                            <Typography variant="body1Regular">
                                {frontlineUserData?.special_user === true ? "Yes" : "No" ?? "-"}
                            </Typography>
                        </List>
                    </Col> */}
                </Row>
                <Row>
                    <Col lg="12" className="d-flex justify-content-start ps-0 mt-4">
                        <AppButton onClick={toggleActivePopup} styles={
                            {
                                padding: "14px 70px",
                                backgroundColor: `${frontlineUserData?.status === "Activated" ? "transparent" : theme.palette.secondary.main}`,
                                borderColor: `${theme.palette.secondary.main}`
                            }} >
                            {`${getButtonStatus(frontlineUserData?.status ?? "-") ?? "-"} `}
                        </AppButton>
                    </Col>
                </Row>
            </Container>

        </>
    );
};

export default ViewFrontLineUser;
