import React from "react";
import LoaderAnimation from "assets/images/animation.json"
import Lottie from "lottie-react";
import { Box, Modal } from '@mui/material';
import { useSelector } from 'react-redux';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: 'transparent',
  borderRadius: "12px",
  color: "#ffffff",
  boxShadow: 24,
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  p: 4,
  
};


function AppLoader() {
  const { isLoading } = useSelector((state) => state?.app)

  return <>
    <Modal open={isLoading}>
      <>
        <Box sx={style}>
        <Lottie style={{width:'80%'}} loop={true} animationData={LoaderAnimation} />
        </Box>
      </>

    </Modal>


  </>;
}

export default AppLoader;
