import React, { Fragment, useState, useEffect } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { Container, Row, Col } from "react-bootstrap"
import Header from "components/Header/Header"
import FullCalendar from "components/TriviaCalendarComponents/FullCalendar"
// import SignUpSummary from "components/TriviaCalendarComponents/SignUpSummary/index";
// import UpgradeSummary from "components/TriviaCalendarComponents/upgradeSummary/index";
import { Box, Typography } from "@mui/material"
// import LeaderBoardTable from "components/TriviaCalendarComponents/LeaderBoardTable/LeaderBoardTable"
// import TriviaAnalyticsTable from "components/TriviaCalendarComponents/TriviaAnalyticsTable/TriviaAnalyticsTable"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { GMActions } from "redux/GM/slice/user"
import { appActions } from "redux/app/slice/app"
import { formatDate } from "helpers/functions"
import moment from "moment"
import { getGMCalendarDataAction, getGMInCorrectAnswersAction } from "redux/GM/actions"
import TriviaQuestionIcon from "assets/icons/TriviaQuestionIcon.svg"
import CutleryIcon from "assets/icons/CutleryIcon.svg"
import WineGlassIcon from "assets/icons/WineGlassIcon.svg"
import TicketIcon from "assets/icons/TicketIcon.svg"
import SuggestedQuestiontable from "components/suggestedQuestionTable"
import { getLeaderboardDataAction } from "redux/marketing/actions"

function GMTriviaCalendar() {
  const history = useHistory()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  // const [leaderBoardRowsData, setLeaderBoardRowsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  // const { incorrectAnswers } = useSelector((state) => state?.gm?.triviaAnalytics ?? {})
  // const { data } = useSelector((store) => store?.marketing?.leaderboard || {})
  const { calendarData } = useSelector((store) => store?.gm?.triviaAnalytics || {})

  // const { leaderBoardListGM } = useSelector((store) => store?.gm || [])
  const { date } = useSelector((state) => state?.app?.filters || "")

  const getIndexFromDate = (date) => parseInt(date?.split("-")?.[2]) - 1
  function getIndexFromDateWine(data) {
    let dateIndex = []
    for (let i = 0; i < data.length; i++) {
      dateIndex.push(parseInt(data[i]?.display_date?.split("-")?.[2]) - 1)
    }
    return dateIndex
  }

  const insertEvents = (formattedData, data) => {
    let date = new Date()
    if (data?.raffle_date) {
      let index = getIndexFromDate(data?.raffle_date)
      if (index >= formattedData.length) {
        for (let i = formattedData.length; i <= index; ++i) {
          date.setDate(i + 1)
          formattedData[i] = { start: formatDate(date), eventData: [] }
        }
      }
      formattedData[index]?.eventData.push({
        icon: TicketIcon,
        title: `Drawing Raffle for ${moment(formattedData[index]?.start).format("MMMM")} month`,
        type: "tickets"
      })
    }

    if (data?.wine_club_dinner_data?.length) {
      let dateIndex = getIndexFromDateWine(data?.wine_club_dinner_data)
      for (let i = 0; i <= formattedData.length; i++) {
        formattedData[dateIndex[i]]?.eventData?.push({
          icon: CutleryIcon,
          title: `Wine Club Member Dinner for ${data?.wine_club_dinner_data[i].prize_for_month}`,
          type: "dinner"
        })
      }
    }

    // data.wine_club_dinner = '2022-10-21'
    // if (data?.wine_club_dinner) {
    //   let index = getIndexFromDate(data?.wine_club_dinner);
    //   if (index >= formattedData.length) {
    //     for (let i = formattedData.length; i <= index; ++i) {
    //       date.setDate(i + 1);
    //       formattedData[i] = { start: formatDate(date), eventData: [] };
    //     }
    //   }
    //   formattedData[index].eventData.push({
    //     icon: CutleryIcon,
    //     title: "Wine Club Member Dinner",
    //     type: "dinner"
    //   });
    // }
  }

  const getCalendarDataFormatted = () => {
    let questionCount = 0
    let formattedData =
      calendarData?.map((data) => {
        let newData = {
          start: data?.start_date,
          activePlayers: data?.active_players || "0",
          showStats: true,
          checked: data?.checked || "0",
          eventData: [],
          dailyStats: {}
        }
        if (data?.wine_of_the_month) {
          newData.eventData.push({
            icon: WineGlassIcon,
            title: "Featured Item Of The Month",
            type: "wine",
            display: "background",
            backgroundColor: "rgba(164,127,81,0.2)"
          })
        }
        if (data?.stat) {
          let DataNew = {
            total_attempts: data?.stat?.total_attempts,
            total_correct: data?.stat?.total_correct,
            total_incorrect: data?.stat?.total_incorrect,
            percentage_correct: data?.stat?.percentage_correct.toFixed(2) ?? 0,
            percentage_incorrect: data?.stat?.percentage_incorrect.toFixed(2) ?? 0
          }
          DataNew.index = ++questionCount
          newData.dailyStats = DataNew
          if (data.question_published_on) {
            newData.eventData.push({
              icon: TriviaQuestionIcon,
              title: `Trivia Question published`,
              type: "trivia",
              stat: DataNew
            })
          }
          if (data?.note) {
            newData.notes = data?.note
          }
        }
        return newData
      }) || []
    if (formattedData?.length) {
      insertEvents(formattedData, calendarData[0])
    }
    return formattedData
  }

  const suggestQuestion = () => {
    dispatch(GMActions.setSuggestedQuestionUploaded(false))
    history.push("/gm/dashboard/calendar/suggest-a-question")
  }

  // function createLeaderBoardListData() {
  //   setLeaderBoardRowsData(
  //     data?.results?.map((res) => ({
  //       id: res.user.id,
  //       rank: res.rank,
  //       name: res.user.name,
  //       image: res.user.image_url,
  //       employeeClass: res.user.job_class ?? "-",
  //       correctAnswers: res.correct_answers ?? "-",
  //       signUp_Upgrades: "-",
  //       coins: res.coins_earned ?? "0",
  //       fairnessFactor: res.fairness_factor ?? "-",
  //       restaurantName: res.user.rest_name ?? "-"
  //     }))
  //   )
  // }

  // useEffect(() => {
  //   createLeaderBoardListData()
  // }, [leaderBoardListGM])

  useEffect(() => {
    dispatch(appActions.initFilters())
  }, [])

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Calendar"
  }, [])
  useEffect(() => {}, [])

  useEffect(() => {
    const dateObject = new Date(date)
    const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)
    dispatch(getLeaderboardDataAction({ date: date, page: currentPage }))
    dispatch(getGMCalendarDataAction({ date: formatDate(newDate) }))
    dispatch(getGMInCorrectAnswersAction(date))
  }, [date, currentPage])
  useEffect(() => {
    setCurrentPage(1)
  }, [date])

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header
              title={`Trivia Calendar & Leaderboard For ${moment(date).format("MMMM'YY")}`}
              breadCrumbs={breadCrumbs}>
              {/* <AppButton
                onClick={() =>
                  history.push("/gm/dashboard/performance-summary")
                }>
                Membership Sign-up Performance
              </AppButton> */}
            </Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="12" className="mb-4">
                {!calendarData.length ? (
                  <Typography variant="h2" className="mb-4">
                    No Calendar Data Found for this Month
                  </Typography>
                ) : (
                  ""
                )}
                <FullCalendar role="gm" date={date} events={getCalendarDataFormatted()} />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* IN next phase */}
        {/* <Row>
          <Col lg="12">
            <Row>
              <Col lg="6">
                <SignUpSummary />
              </Col>
              <Col lg="6">
                <UpgradeSummary />
              </Col>
            </Row>
          </Col>
        </Row> */}
      </Container>
      {/* <Container fluid className="p-0 mt-5 ">
        <Typography variant="h2" className="mb-2">
          Leaderboard
        </Typography>
        <Row>
          <Col lg="12">
            <LeaderBoardTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              rowsData={leaderBoardRowsData}
              totalPage={data?.pages}
              columns={LeaderBoardColumns}></LeaderBoardTable>
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0 mt-5">
        <Box className="d-flex justify-content-between">
          <Typography variant="h2" className="mb-2">
            Most Trivia Questions Answered Incorrectly
          </Typography>
        </Box>
        <Row>
          <Col lg="12">
            <TriviaAnalyticsTable
              columns={TriviaColumns}
              rowsData={incorrectAnswers ?? []}></TriviaAnalyticsTable>
          </Col>
        </Row>
      </Container> */}
      <Container fluid className="p-0 mt-5">
        <Box className="d-flex justify-content-between">
          <Typography variant="h2" className="mb-2">
            Suggested Questions
          </Typography>
          <Typography
            variant="body1Regular"
            onClick={suggestQuestion}
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              textDecoration: "underline",
              textUnderlineOffset: 2,
              cursor: "pointer"
            })}>
            Suggest A Question
          </Typography>
        </Box>
        <Row>
          <Col lg="12">
            <SuggestedQuestiontable></SuggestedQuestiontable>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default GMTriviaCalendar

// const TriviaColumns = [
//   { id: "Rank", label: "Rank", minWidth: 50 },
//   { id: "Questions", label: "Questions", minWidth: 170 },
//   {
//     id: "Trivia Category",
//     label: "Trivia Category",
//     align: "center",
//     minWidth: 170
//   },
//   {
//     id: "Difficulty Level",
//     label: "Difficulty Level",
//     align: "center",
//     minWidth: 170
//   },
//   {
//     id: "% Incorrect",
//     label: "% Incorrect",
//     align: "right",
//     minWidth: 170
//   }
// ]

// const LeaderBoardColumns = [
//   { id: "Rank", label: "Rank", minWidth: 50 },
//   { id: "Name", label: "Name", minWidth: 170 },
//   {
//     id: "Trivia Questions Answered Correctly",
//     label: "Trivia Questions Answered Correctly",
//     align: "center",
//     minWidth: 170
//   },
//   {
//     id: "Coins Earned",
//     label: "Coins Earned",
//     tooltip: "Trivia Questions Answered Correctly * 5 = Coins Earned",
//     icon: alert,
//     align: "center",
//     minWidth: 170
//   }
// ]
