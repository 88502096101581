import React, { useState, useEffect } from "react"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import DinnerDiningIcon from "@mui/icons-material/DinnerDining"
import "./imageDisplay.scss"

function ImageComponent({ image }) {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(image)
  // Effect to handle image changes
  useEffect(() => {
    setIsLoading(true)
    setCurrentImage(image)
  }, [image])

  return (
    <>
      {isLoading && (
        <div className="imageContainer">
          <DinnerDiningIcon
            className="mb-4 loadingImage"
            sx={{ color: theme.palette.secondary.main, fontSize: 48 }}
          />
          <Typography  variant="tagItalic">Image is loading...</Typography>
        </div>
      )}
      <img
        src={currentImage}
        onLoad={() => setIsLoading(false)} // Set loading to false when the image loads
        style={{ display: isLoading ? "none" : "block" }}
        alt="Dynamic"
      />
    </>
  )
}

export default ImageComponent
