import React, { useState, useEffect, useRef } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import EventContent from "./components/EventContent/EventContent"
import DateDisplay from "./components/DateDisplay/DateDisplay"
import "../trivia-calendar.scss"
import DailyStatsModal from "./components/DailyStatsModal/DailyStatsModal"
import { days } from "assets/json/DayNames"
import { months } from "assets/json/MonthNames"
import { useHistory } from "react-router-dom"
import TriviaStatsModal from "./components/TriviaStatsModal"
import CommentModal from "./components/CommentModal/index"

const FullCalendarWidget = ({ events, tableHeaders, role = "", date = new Date() }) => {
  const [negativeResultDays, setNegativeResultDays] = useState([])
  const [triviaStatsModalOpen, setTriviaStatsModalOpen] = useState(false)
  const [triviaStats, setTriviaStats] = useState([])
  const [dailyStatsModalOpen, setDailyStatsModalOpen] = useState(false)
  const [commentAddModal, setCommentAddModal] = useState(false)
  const [dailyStatsModalContent, setDailyStatsModalContent] = useState({})
  const [wineOfTheMonthDate, setWineOfTheMonthDate] = useState("")
  const [commentDat, setCommentData] = useState({})
  const history = useHistory()
  let calendarRef = useRef()
  useEffect(() => {
    const eventDays = events.filter((event) => event.display === "background")
    const negativeDaysArray = eventDays.filter(
      (item) => item.backgroundColor === "rgba(204, 75, 55, 0.15)"
    )
    const negativeDays = negativeDaysArray.map((item) => item.start.toString())

    const wineOfTheMonthDateFilter = eventDays.filter(
      (item) => item.backgroundColor === "rgba(164,127,81,0.2)"
    )

    setWineOfTheMonthDate(wineOfTheMonthDateFilter[0])
    setNegativeResultDays(negativeDays)
    let calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate(date)
  }, [events])

  const handleEventClick = (clickInfo) => {
    const { stats, type } = clickInfo.event._def.extendedProps
    if (type !== "dailyStats") {
      return
    }
    const dateObject = clickInfo.event._instance.range.start
    const day = days[dateObject.getDay()]
    const month = months[dateObject.getMonth()]
    const date = dateObject.getDate()

    setDailyStatsModalContent({
      type,
      stats,
      day,
      month,
      date
    })
    setDailyStatsModalOpen(true)
  }

  const handleLabelClick = (clickInfo, index) => {
    const { eventData } = clickInfo.event._def.extendedProps
    switch (eventData?.[index]?.type) {
      case "trivia":
        setTriviaStats(eventData?.[index]?.stat)
        setTriviaStatsModalOpen(false)
        break
      case "wine":
        if (role === "marketing") {
          history.push("/marketing/dashboard/featured-Items-of-the-Month")
        } else if (role === "gm") {
          history.push("/gm/dashboard/featured-Items-of-the-Month")
        } else {
          history.push("/learning_development/dashboard/featured-Items-of-the-Month")
        }
        break
    }
  }

  function handleModalData(data, state, type) {
    const { dailyStats, incorrectStats } = data.event._def.extendedProps

    events.map(() => {
      setTriviaStats(type === "incorrect" ? incorrectStats : dailyStats)
      setTriviaStatsModalOpen(state)
    })
  }

  function handlePencilData(data, state) {
    const { notes } = data.event._def.extendedProps
    const i = data.event._def.extendedProps.dailyStats.index
    setCommentData({ notes: notes, index: i })
    setCommentAddModal(state)
  }

  // Function used to render event content on each day of calendar
  function renderEventContent(eventInfo) {
    return (
      <EventContent
        setCommentModal={(state) => handlePencilData(eventInfo, state)}
        setModalState={(state, type) => handleModalData(eventInfo, state, type)}
        modalState={triviaStatsModalOpen}
        onTitleClick={(index) => handleLabelClick(eventInfo, index)}
        eventInfo={eventInfo}
      />
    )
  }

  return (
    <div className="table-container">
      <CommentModal
        data={commentDat}
        open={commentAddModal}
        onClose={setCommentAddModal}></CommentModal>
      <TriviaStatsModal
        open={triviaStatsModalOpen}
        setOpen={setTriviaStatsModalOpen}
        triviaStats={triviaStats}
      />
      <DailyStatsModal
        open={dailyStatsModalOpen}
        setOpen={setDailyStatsModalOpen}
        content={dailyStatsModalContent}
        tableHeaders={tableHeaders}
      />
      <FullCalendar
        plugins={[dayGridPlugin]}
        headerToolbar={{
          left: "",
          center: "",
          right: ""
        }}
        ref={calendarRef}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        showNonCurrentDates={true}
        stickyFooterScrollbar={true}
        fixedWeekCount={false}
        events={events}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        dayCellContent={(dayCell) => (
          // renders date in a formatted way
          <DateDisplay
            dayCell={dayCell}
            wineOfTheMonthDate={wineOfTheMonthDate}
            negativeResultDays={negativeResultDays}
          />
        )}
      />
    </div>
  )
}

export default FullCalendarWidget
