import React from "react";
import { Box, Typography } from "@mui/material";
import ToggleSwitch from "components/StyledComponents/ToggleSwitch";
import styles from "../../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss";
import { useDispatch } from "react-redux";
import { setQuestionStatusViewTriviaAction } from "redux/L&D/actions";

const SuggestedBy = ({ question, index }) => {
  const dispatch = useDispatch();
  const handleStatusChange = () => {
    dispatch(
      setQuestionStatusViewTriviaAction({
        questionIndex: index,
        questionId: question.id,
        status:
          question.status.toLowerCase() === "pending" ? "Approved" : "Pending"
      })
    );
  };

  const containerBoxStyles = { marginTop: "18px", marginBottom: "18px" };

  return (
    <Box
      sx={containerBoxStyles}
      className="d-flex flex-column justify-content-between">
      <Box className={styles.suggestedBy}>
        <Typography variant="caption3">SUGGESTED BY</Typography>
        {question?.suggested_by?.image ? (
          <img
            className={styles.avatar}
            src={question?.suggested_by?.image}
            alt=""
          />
        ) : null}
        <Typography variant="body2Regular">
          {question?.suggested_by?.name}
        </Typography>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            marginTop: "2px"
          })}
          variant="tag1">
          {question?.suggested_by?.role}
        </Typography>
      </Box>
      <Box className="d-flex">
        <ToggleSwitch
          checked={!(question.status.toLowerCase() === "pending")}
          onChange={handleStatusChange}
          className="me-3"
        />
        {question.status.toLowerCase() === "pending" ? (
          <Typography
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            variant="body1Regular">
            Approve
          </Typography>
        ) : (
          <Typography variant="body1Regular">Approved</Typography>
        )}
      </Box>
    </Box>
  );
};

export default SuggestedBy;
