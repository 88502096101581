import { List, ListItem, Typography } from "@mui/material";
import Card from "components/Card/Card";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomDivider from "components/StyledComponents/Divider";
import { useSelector } from "react-redux";

const TriviaCategoriesCard = () => {
  const history = useHistory();
  const data = useSelector((store) => store?.dmv?.triviaCategories?.list ?? []);
  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Trivia Categories</Typography>
          <span
            onClick={() =>
              history.push("/dmv/dashboard/trivia-categories")
            }>
            <IconButtonWithBorder islight={true}>
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>

        {data?.map((item, index) => (
          <Fragment key={`${item?.id}-${index}`}>
            <ListItem
              sx={{
                padding: "12px 0 12px 0"
              }}>
              <img
                src={item?.icon}
                alt=""
                className="me-3"
                style={{ width: 30 }}
              />
              <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
                {item?.name ?? "-"}
              </Typography>
            </ListItem>
            {data?.length - 1 !== index && <CustomDivider />}
          </Fragment>
        ))}
      </List>
    </Card>
  );
};

export default TriviaCategoriesCard;
