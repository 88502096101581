import React, { Fragment, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LeaderBoardList from "components/LeaderboardComponents/LeaderBoardList/LeaderBoardList";
import Header from "components/Header/Header";
import alert from "assets/icons/alert.svg";
import { useState } from "react";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getLeaderBoardGMListAction } from "redux/GM/actions";
import { getWinnerListMerchantAction } from "redux/L&D/actions";
import { appActions } from "redux/app/slice/app";
import moment from "moment";




function GMLeaderBoard() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const [leaderBoardRowsData, setLeaderBoardRowsData] = useState([]);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { leaderBoardListGM } = useSelector(
    (store) => store?.gm || []
  );

  // const merchantData = useSelector(
  //   (store) => store?.learningDevelopment?.leaderBoardMerchant
  // );
  const { date,employeeClass } = useSelector((state) => state?.app?.filters || "")
  function createLeaderBoardListData() {
    setLeaderBoardRowsData(
      leaderBoardListGM?.data?.map((res) => ({
        id: res.user.id,
        rank: res.rank ?? "-",
        name: res.user.name,
        employeeClass: res.user.job_class,
        image: res.user.image_url,
        correctAnswers: res.correct_answer ?? "-",
        signUp_Upgrades: "-",
        coins: res.cooper_coins ?? "0",
        fairnessFactor: res.fairness_factor ?? "-",
      }))
    );
  }

  useEffect(() => {
    createLeaderBoardListData();
  }, [leaderBoardListGM]);


  useEffect(() => {
    dispatch(appActions.initFilters());
  }, []);

  useEffect(() => {
    dispatch(getLeaderBoardGMListAction({ date: date, page: currentPage,employeeClass: employeeClass }));
    dispatch(getWinnerListMerchantAction({ date }));
  }, [date, currentPage,employeeClass])

  useEffect(() => {
    setCurrentPage(1)
  }, [date,employeeClass])


  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Top 5 Leaderboard";
  }, []);

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title={`Leaderboard For ${moment(date).format("MMMM'YY")}`} breadCrumbs={breadCrumbs} />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <LeaderBoardList
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={leaderBoardListGM.totalPages}
              rowsData={leaderBoardRowsData}
              columns={LeaderBoardColumns}
            ></LeaderBoardList>
          </Col>
        </Row>
      </Container>
      {/* Remove for Pilot */}
      {/* <Container fluid className="p-0 mt-4  ">
        <Row>
          <Col lg="12">
            <Card>
              <MonthlySwag merchantWinnerList={merchantData}></MonthlySwag>
            </Card>
          </Col>
        </Row>
      </Container> */}
    </Fragment>
  );
}

export default GMLeaderBoard;

const LeaderBoardColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 170
  },
  {
    id: "EmployeeClass",
    label: "Employee Class ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Trivia Questions Answered Correctly",
    label: "Trivia Questions Answered Correctly  ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Cooper Coins Earned",
    label: "Cooper Coins Earned",
    align: "center",
    tooltip:
    "Trivia Questions Answered Correctly * 5 = Cooper Coins Earned",
    icon: alert,
    minWidth: 170
  }
];