import React from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Divider, Grid, Typography } from "@mui/material"
import { useTheme } from "@mui/system"

import SpiritMenuListItems from "components/spiritsMenuListItems"

function SpiritsMenuListContainer({
  data,
  isEdit = false,
  setIsEditModalItem = () => {},
  setMenuItemsModal = () => {}
}) {
  const theme = useTheme()

  return (
    <>
      {data?.spirit_list?.length > 0 ? (
        <Card style={{ height: "fit-content" }}>
          <Row>
            <Col lg="12">
              <div
                className="d-flex justify-content-between align-item-center"
                style={{
                  backgroundColor: isEdit && theme.palette.background.default,
                  padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                  borderRadius: 6
                }}>
                <Typography variant="h2">{data?.spirit_category}</Typography>
              </div>
            </Col>
            {
              <Grid
                container
                xs={12}
                sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "48px" }}>
                {data?.spirit_list?.length > 0 ? (
                  data?.spirit_list?.map((items, idx) => {
                    return (
                      <Grid container key={idx}>
                        <SpiritMenuListItems
                          categoryIdData={data?.spirit_category_id}
                          setIsEditModalItem={setIsEditModalItem}
                          setMenuItemsModal={setMenuItemsModal}
                          isEdit={isEdit}
                          key={idx}
                          foodList={items}></SpiritMenuListItems>
                        {!isEdit && idx < data?.spirit_list.length - 1 && <Divider color="#fff" />}
                      </Grid>
                    )
                  })
                ) : (
                  <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                    No Items Found
                  </Typography>
                )}
              </Grid>
            }
          </Row>
        </Card>
      ) : (
        <Typography>No Items Found</Typography>
      )}
    </>
  )
}

export default React.memo(SpiritsMenuListContainer)
