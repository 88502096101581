import { Typography } from "@mui/material"
import AppTextField from "components/StyledComponents/AppTextField"
import { ErrorMessage, FieldArray } from "formik"
import React from "react"
import { Col, Row } from "react-bootstrap"
import ClearIcon from "@mui/icons-material/Clear"
import { useTheme } from "@mui/system"
import StyledLabel from "components/StyledComponents/StyledLabel"

const AddUniqueFact = ({ formik, values, name, buttonText, label }) => {
  const theme = useTheme()
  return (
    <Row className="w-100 m-0 mt-4">
      <StyledLabel>{label}</StyledLabel>
      <FieldArray
        name={name}
        render={(arrayHelper) => (
          <>
            {values?.map((fact, index) => (
              <Row
                className="mt-2"
                style={{ paddingRight: 0 }}
                id={`${name}-${index}`}
                key={`${name}-${index}`}>
                <Col lg="11">
                  <AppTextField
                    id="ingredient"
                    name="ingredient"
                    type="text"
                    fullWidth
                    placeholder={`Enter Unique Fact`}
                    value={fact?.fact}
                    onChange={(event) => {
                      formik.setFieldValue(`${name}[${index}].fact`, event.target.value)
                    }}
                  />
                  <ErrorMessage
                    style={{ color: "#A9402F", fontSize: "0.75rem" }}
                    name={`${name}[${index}].fact`}
                    component="div"
                    className="error"
                  />
                </Col>
                {index > 0 && (
                  <Col lg="1" style={{ display: "flex", justifyContent: "center" }}>
                    <span role="button" onClick={() => arrayHelper.remove(index)}>
                      <ClearIcon sx={{ width: "30px", height: "48px" }} />
                    </span>
                  </Col>
                )}
              </Row>
            ))}
            <Row className="w-100 m-0 mt-2 justify-content-end">
              <Col
                lg="12"
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}>
                <Typography
                  variant="body1Regular"
                  onClick={() =>
                    arrayHelper.push({
                      fact: ""
                    })
                  }
                  sx={{
                    color: theme.palette.secondary.main,
                    textDecoration: "underline",
                    cursor: "pointer",
                    textUnderlineOffset: "2px"
                  }}>
                  {buttonText}
                </Typography>
              </Col>
            </Row>
          </>
        )}
      />
    </Row>
  )
}

export default AddUniqueFact
