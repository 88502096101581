import React from "react";
import { PieChart, Pie, Cell, Label, LabelList } from "recharts";
import { ResponsiveContainer } from "recharts";
import { Typography } from "@mui/material";
import { ListItem } from "@mui/material";
import { List } from "@mui/material";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from "chart.js";
import { Col, Row } from "react-bootstrap";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

const COLORS = ["#274C9A", "#3C6BCE", "#4FACE9", "#A9D6F4", "#D1EBFA"];

export default function DMVPieChart(props) {
  // const RADIAN = Math.PI / 180;
  // const renderCustomizedLabel = ({
  //     cx,
  //     cy,
  //     midAngle,
  //     innerRadius,
  //     outerRadius,
  //     value
  // }) => {
  //     const radius = innerRadius + ((outerRadius - 10) - (innerRadius + 20)) * 0.5;
  //     const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //     const y = cy + radius * Math.sin(-midAngle * RADIAN);
  //     return (
  //         <>
  //             <text
  //                 x={x}
  //                 y={y}
  //                 fill="#0F1214"
  //                 fontWeight='bold'
  //                 fontSize={14}
  //                 textAnchor={x > cx ? "start" : "end"}
  //                 dominantBaseline="central"
  //             >
  //                 {`${value} `}
  //             </text>
  //             <text
  //                 x={x}
  //                 y={y + 20}
  //                 fontSize={14}
  //                 fill="#0F1214"
  //                 textAnchor={x > cx ? "start" : "end"}
  //                 dominantBaseline="central"
  //             >
  //                 {`users`}
  //             </text>
  //         </>
  //     );

  // };

  const totalSum = () => {
    return props.data.map((res) => {
      return res.value;
    });
  };
  const sum = totalSum().reduce((a, b) => a + b, 0);
  return (
    <>
      {props.isHeading && (
        <List>
          <ListItem
            sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
            <Typography variant="h2">{props.heading}</Typography>
          </ListItem>
        </List>
      )}
      <Row>
        <Col xl="6" lg="12">
          <ResponsiveContainer width="100%" height={props.height}>
            <PieChart>
              <Pie
                data={props.data}
                startAngle={-90}
                innerRadius={80}
                outerRadius={140}
                fill="#8884d8"
                dataKey="value"
                stroke="none"
                labelLine={false}
                // label={renderCustomizedLabel}
              >
                <LabelList
                  fill="#0F1214"
                  position="top"
                  fontWeight={500}></LabelList>
                <Label
                  value={sum}
                  fill="white"
                  fontWeight={500}
                  position="centerBottom"
                  className="label-top"
                  fontSize="24px"
                />

                <Label
                  value="Active Users"
                  dy={20}
                  fill="white"
                  fontSize="14px"
                  position="centerTop"
                  className="label"
                />
                <Legend />
                {props.data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Col>
        <Col
          xl="6"
          lg="12"
          md="4"
          sm="12"
          className="d-flex  flex-column justify-content-center">
          {props.data.map((res, index) => (
            <div className="d-flex align-items-center mb-3 ms-5" key={index}>
              <span
                style={{
                  background: `${COLORS[index]}`,
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  position: "relative  ",
                  marginRight: "10px"
                }}></span>
              <p
                className="d-flex align-items-center m-0"
                style={{
                  opacity: "0.6",
                  position: "relative",
                  fontSize: "12px"
                }}>
                {res.name}
              </p>
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
}
