import React from "react"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import DinnerDiningIcon from "@mui/icons-material/DinnerDining"
import "./imageDisplay.scss"
import ImageComponent from "components/ImageComponent"

function ImageDisplay({ data }) {
  const theme = useTheme()
  if (!data) {
    return (
      <div className="imageContainer">
        <DinnerDiningIcon
          className="mb-4 loadingImage"
          sx={{ color: theme.palette.secondary.main, fontSize: 48 }}
        />
        <Typography variant="tagItalic">No Image Found</Typography>
      </div>
    )
  }
  return <ImageComponent image={data} />
}

export default ImageDisplay
