import { Box, Grid, styled, Typography } from "@mui/material";
import AppDatePicker from "components/AppDatePicker";
import ImageUploadField from "components/FileUpload/ImageUploadField";
import VideoUploadField from "components/FileUpload/VideoUploadField";
import AppTextField from "components/StyledComponents/AppTextField";
import React from "react";
import { useDispatch } from "react-redux";
import {
  uploadGrandCruThumbnailAction,
  uploadGrandCruVideoAction
} from "redux/DMV/actions";
import { DMVActions } from "redux/DMV/slice/user";

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}));

const BasicDetailsForm = ({ formik }) => {
  const dispatch = useDispatch();

  const onThumbnailUpload = (image) => {
    let data = new FormData();
    data.append("media", image);
    data.append("media_type", "IMAGE");
    data.append("content_type", image.type);
    dispatch(uploadGrandCruThumbnailAction(data));
    formik.setFieldValue(`thumbnail`, image);
    formik.setFieldTouched(`thumbnail`);
  };

  const handleThumbnailDelete = () => {
    dispatch(DMVActions.setGrandCruThumbnail(null));
    formik.setFieldValue("thumbnail", null);
  };

  const handleVideoUpload = (video) => {
    let data = new FormData();
    data.append("media", video);
    data.append("media_type", "IMAGE");
    data.append("content_type", video.type);
    dispatch(uploadGrandCruVideoAction(data));
    formik.setFieldValue("video", video);
    formik.setFieldValue("videoUrl", "");
  };

  const handleVideoUrlUpload = (url) => {
    formik.setFieldValue("video", null);
    formik.setFieldValue("videoUrl", url);
  };

  const handleVideoDelete = () => {
    if (formik.values.video) {
      dispatch(DMVActions.setGrandCruVideo(null));
      formik.setFieldValue("video", null);
    } else {
      formik.setFieldValue("videoUrl", "");
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={6} lg={4}>
        <AppTextField
          label="Title"
          placeholder="Enter Title"
          fullWidth={true}
          isRequired={true}
          value={formik.values.title}
          onChange={formik.handleChange("title")}
          error={formik.touched.title && Boolean(formik.errors?.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <AppTextField
          label="Description"
          placeholder="Enter Description"
          fullWidth={true}
          isRequired={true}
          value={formik.values.description}
          onChange={formik.handleChange("description")}
          error={
            formik.touched.description && Boolean(formik.errors?.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4} className="datesContainer">
        <StyledLabel variant="body1Regular">
          {"Month and Year"}
          <span>*</span>
        </StyledLabel>
        <Box className="dateFieldContainer w-100 mt-2">
          <AppDatePicker
            inputFormat="MMM yyyy"
            views={["month", "year"]}
            value={formik.values.date}
            onChange={(val) => formik.setFieldValue("date", val)}
            error={formik.errors.date}
            touched={formik.touched.date}
          />
        </Box>
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <ImageUploadField
          label="Upload Video Thumbnail"
          isRequired={true}
          placeholder="Upload Thumbnail"
          value={formik.values.thumbnail}
          onChange={onThumbnailUpload}
          onDelete={handleThumbnailDelete}
          error={formik.errors.thumbnail}
          touched={formik.touched.thumbnail}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <VideoUploadField
          label="Upload Video"
          placeholder="Upload Video"
          isRequired={true}
          value={formik.values.video}
          onChange={handleVideoUpload}
          videoUrl={formik.values.videoUrl}
          onDelete={handleVideoDelete}
          onUrlUpload={handleVideoUrlUpload}
          error={formik.errors.video || formik.errors.videoUrl}
          touched={
            formik.errors.video ? formik.touched.video : formik.touched.videoUrl
          }
        />
      </Grid>
    </Grid>
  );
};

export default BasicDetailsForm;
