import { Grid, Typography, useTheme } from "@mui/material";
import Header from "components/Header/Header";
import AppButton from "components/StyledComponents/AppButton";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import FrontLineUserListTable from "./frontLineUserListTable/index";
import { useHistory } from "react-router-dom";
import { getFrontlineUsersListAction } from "redux/superAdmin/actions";
import { useDispatch, useSelector } from 'react-redux';
import UploadBulkUserModal from "./components/UploadBulkUserModal";
import { superAdminAction } from 'redux/superAdmin/slice/user';
import { appActions } from 'redux/app/slice/app';
import { toTitleCase } from 'helpers/functions';

const FrontlineUser = () => {
  const theme = useTheme();
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { frontlineUserList } = useSelector((store) => store?.superAdmin?.frontlineUser || [])
  const { searchField, employeeClass, userStatus, date, restaurant,orderBy } = useSelector((store) => store?.app?.filters || "")

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    dispatch(superAdminAction.setFrontlineUserData({}));
    dispatch(appActions.initFilters());
    dispatch(appActions.setInitDate(""));
    dispatch(appActions.setOrderBy("-hire_date"))
  }, []);


  useEffect(() => {
    dispatch(getFrontlineUsersListAction(
      {
        page: currentPage,
        search: searchField,
        job_class: employeeClass,
        status: toTitleCase(userStatus),
        restaurant: restaurant,
        hire_date: date,
        orderBy:orderBy
      }));
  }, [currentPage, searchField, employeeClass, userStatus, restaurant, date,orderBy])

  useEffect(() => {
    setCurrentPage(1)
  },[searchField, employeeClass, userStatus, restaurant, date,orderBy])

  useEffect(() => {
  },[frontlineUserList?.data])

  return (
    <>
      <UploadBulkUserModal
        open={modalOpen}
        onClose={handleClose}></UploadBulkUserModal>
      <Grid container>
        <Grid item xs={12}>
          <Header title="User List" breadCrumbs={breadCrumbs}>
            <Typography
              variant="body1Regular"
              onClick={() => setModalOpen(true)}
              sx={{
                color: theme.palette.secondary.main,
                textDecoration: "underline",
                margin: "13px 50px",
                cursor: "pointer",
                textUnderlineOffset: "2px"
              }}>
              + Add Bulk User
            </Typography>
            <AppButton
              onClick={() =>
                history.push("/super-admin/dashboard/frontline-user-list/add-frontline-user")
              }
              sx={{ padding: "13px 80px" }}>
              + Add New User
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col lg="12">
            <FrontLineUserListTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={frontlineUserList?.totalPages}
              rows={frontlineUserList?.data}
              columns={columns}></FrontLineUserListTable>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FrontlineUser;
const columns = [
  {
    id: "id",
    label: "S.No",
    minWidth: 100,
    align: "center"
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 200
  },
  {
    id: "User Type",
    label: "User Type",
    align: "center",
    minWidth: 170
  },
  {
    id: "Role",
    label: "Role ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Restaurant Name",
    label: "Restaurant Name",
    align: "center",
    minWidth: 170
  },
  {
    id: "Hire Date",
    label: "Hire Date",
    isSorting:true,
    align: "center",
    minWidth: 100
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 170
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 100
  }
];

