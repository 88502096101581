import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import { validationSchemaEvent } from "helpers/validationSchema"
import { useHistory } from "react-router-dom"
import moment from "moment"
import EventForm from "./EventForm"
import { useDispatch, useSelector } from "react-redux"
import { addEventAction } from "redux/superAdmin/actions"
import { convertDate, payloadDate } from "helpers/functions"
import { superAdminAction } from "redux/superAdmin/slice/user"

const initialValues = {
  name: "",
  startDate: "",
  startTime: "",
  endTime: "",
  rsvpDate: "",
  rsvpTime: "",
  eventType: "Online",
  eventLink: "",
  address: "",
  description: "",
  notificationDate: "",
  notificationTime: "",
  uploadThumbnail: "",
  notificationContent: ""
}

const AddEvent = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialFormValue] = useState(initialValues)
  const { notificationImageId } = useSelector((store) => store?.superAdmin?.notifications || [])
  const history = useHistory()
  const dispatch = useDispatch()
  const zone_name = moment.tz.guess()

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(superAdminAction?.setNotificationImageId(""))
    document.title = "Add New Event"
  }, [])

  function handleSubmit(values) {
    // Event Start Date and Time
    const EventStartTimeStamp = convertDate(values?.startDate, values?.startTime)
    const EventStartTimeSplit = EventStartTimeStamp.split(" ")
    const EventStartDate = payloadDate(EventStartTimeSplit)
    // Event End Date and Time
    const EventEndTimeStamp = convertDate(values?.startDate, values?.endTime)
    const EventEndTimeSplit = EventEndTimeStamp.split(" ")
    const EventEndDate = payloadDate(EventEndTimeSplit)
    // Event RSVP Date and Time
    const EventRSVPTimeStamp = convertDate(values?.rsvpDate, values?.rsvpTime)
    const EventRSVPTimeSplit = EventRSVPTimeStamp.split(" ")
    const EventRSVPDate = payloadDate(EventRSVPTimeSplit)
    // Event Notification Date and Time
    const EventNotificationTimeStamp = convertDate(
      values?.notificationDate,
      values?.notificationTime
    )
    const EventNotificationTimeSplit = EventNotificationTimeStamp.split(" ")
    const EventNotificationDate = payloadDate(EventNotificationTimeSplit)
    const payload = {
      name: values?.name || "",
      start_date: EventStartDate || "",
      start_time: EventStartTimeSplit[4] || "",
      end_date: EventEndDate || "",
      end_time: EventEndTimeSplit[4] || "",
      rsvp_deadline_date: EventRSVPDate || "",
      rsvp_deadline_time: EventRSVPTimeSplit[4] || "",
      event_type: values?.eventType,
      link: values?.eventLink || "",
      address: values?.address || "",
      description: values?.description || "",
      notification_data: {
        scheduled_date: EventNotificationDate || "",
        scheduled_time: EventNotificationTimeSplit[4] || "",
        ...(notificationImageId
          ? { image_id: notificationImageId }
          : values.uploadThumbnail
          ? {}
          : { image_id: "" }),
        content: values?.notificationContent || "",
        time_zone: zone_name,
        status: "scheduled"
      }
    }
    const onClose = () => {
      history.replace("/super-admin/dashboard/event-listing")
    }
    dispatch(addEventAction({ payload: payload, onClose: onClose }))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="New Event" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaEvent}
        initialValues={initialFormValue}>
        {(formik) => <EventForm formik={formik} btnText="Save Event"></EventForm>}
      </Formik>
    </>
  )
}

export default AddEvent
