import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from "@mui/material"
import CheckBox from "components/CheckBox"
import RenameModal from "components/Modal/RenameModal"
import AppPagination from "components/StyledComponents/AppPagination"
import TableNoRecords from "components/TableNoRecords"
import { dateDiffInDays } from "helpers/functions"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getIncorrectTriviaContestListAction } from "redux/L&D/actions"
import styles from "./ContestTable.module.scss"
import ContestRow from "./ContestRow"

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  "& .MuiTableCell-root": {
    padding: "14px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `2px solid ${theme.palette.background.default}`,
    boxSizing: "border-box",
    "&:first-of-type": {
      paddingLeft: 6,
      paddingRight: 27
    },
    "&:last-child": {
      paddingRight: 0
    }
  },

  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const IncorrectContest = ({
  isAllSelectedIncorrect,
  setIsAllSelectedIncorrect,
  selectedIncorrectContestCount,
  setSelectedIncorrectContestCount,
  selectedIncorrectContests,
  setSelectedIncorrectContests
}) => {
  const totalDeletableContestsRef = useRef(0)
  const [isModal, setModal] = useState(false)
  const [modalData, setModalData] = useState({ name: "" })
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [deletableContests, setDeletableContests] = useState([])
  const incorrectTriviaContestList = useSelector(
    (state) => state?.learningDevelopment?.trivia?.incorrectTriviaContestList
  )

  const handleSetModal = (data) => {
    setModal(data)
  }
  const handleCloseModal = (data) => {
    setModal(data)
  }

  const onRowClick = (index, id) => {
    let selectedCount = selectedIncorrectContestCount
    if (selectedIncorrectContests[index]) {
      setSelectedIncorrectContests((prev) => {
        prev[index] = false
        return [...prev]
      })
      --selectedCount
    } else {
      setSelectedIncorrectContests((prev) => {
        prev[index] = id
        return [...prev]
      })
      ++selectedCount
    }
    setSelectedIncorrectContestCount(selectedCount)
    selectedCount === totalDeletableContestsRef.current
      ? setIsAllSelectedIncorrect(true)
      : setIsAllSelectedIncorrect(false)
  }

  const hasDeletePermission = (contest) => {
    if (
      contest.status.toLowerCase() === "completed" ||
      contest.status.toLowerCase() === "published"
    ) {
      return false
    }
    const contestDateArr = contest?.start_date?.split("-")?.map(Number)
    if (contestDateArr?.length) {
      const contestDate = new Date(contestDateArr[0], contestDateArr[1] - 1, contestDateArr[2])
      let currentDate = new Date()
      if (
        contestDate.getMonth() === currentDate.getMonth() &&
        contestDate.getFullYear() === currentDate.getFullYear()
      ) {
        return false
      }
      if (dateDiffInDays(currentDate, contestDate) > 5) {
        return true
      }
    }
    return false
  }

  const onSelectAllClick = () => {
    if (isAllSelectedIncorrect) {
      setSelectedIncorrectContests(
        selectedIncorrectContests.map((contest) => (contest === "disabled" ? contest : false))
      )
      setSelectedIncorrectContestCount(0)
    } else {
      let selectedCount = 0
      setSelectedIncorrectContests(
        selectedIncorrectContests.map((contest, index) => {
          if (contest === "disabled") {
            return contest
          } else {
            ++selectedCount
            return incorrectTriviaContestList?.data?.[index]?.id
          }
        })
      )
      setSelectedIncorrectContestCount(selectedCount)
    }
    setIsAllSelectedIncorrect(!isAllSelectedIncorrect)
  }

  useEffect(() => {
    dispatch(getIncorrectTriviaContestListAction({ page: currentPage }))
  }, [currentPage])

  useEffect(() => {
    let selectedContest = []
    let totalDeletableContests = incorrectTriviaContestList?.data?.length || 0
    const deletableItems = incorrectTriviaContestList?.data?.map((contest, index) => {
      const isEnabled = hasDeletePermission(contest)
      selectedContest[index] = false
      if (!isEnabled) {
        selectedContest[index] = "disabled"
        --totalDeletableContests
      }
      return isEnabled
    })
    totalDeletableContestsRef.current = totalDeletableContests
    setDeletableContests(deletableItems || [])
    setSelectedIncorrectContests(selectedContest)
    setSelectedIncorrectContestCount(0)
  }, [incorrectTriviaContestList])

  return (
    <>
      <Paper className={styles.container}>
        {isModal ? (
          <RenameModal
            closeModal={handleCloseModal}
            page={currentPage}
            modalData={modalData}
            isIncorrect={true}></RenameModal>
        ) : null}
        <TableContainer sx={{ maxHeight: 600 }}>
          <StyledTable stickyHeader aria-label="contest table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ height: 24, width: 24 }}>
                  {deletableContests.includes(true) && (
                    <CheckBox
                      role="button"
                      checked={isAllSelectedIncorrect}
                      onClick={onSelectAllClick}
                    />
                  )}
                </TableCell>
                {ContestColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography className={styles.header} variant="body2Regular">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {incorrectTriviaContestList?.data?.length ? (
                <>
                  {incorrectTriviaContestList?.data?.map((row, index) => {
                    return (
                      <ContestRow
                        isOpenModal={handleSetModal}
                        setModalData={setModalData}
                        key={row.id}
                        row={row}
                        isSelected={selectedIncorrectContests[index]}
                        onChange={onRowClick}
                        index={index}
                        columns={ContestColumns}
                        isDeletable={deletableContests[index]}
                        isIncorrect={true}
                      />
                    )
                  })}
                </>
              ) : (
                <TableNoRecords colSpan={9} label="No Contests Found" />
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>
      <AppPagination
        onPageChange={setCurrentPage}
        totalPages={incorrectTriviaContestList?.totalPages}
        currentPage={currentPage}
      />
    </>
  )
}

export default IncorrectContest

const ContestColumns = [
  {
    id: "Name",
    label: "Name",
    align: "left",
    minWidth: 150
  },
  {
    id: "Trivia For",
    label: "Trivia For",
    align: "center",
    minWidth: 75
  },
  {
    id: "Created",
    label: "Created",
    align: "center",
    minWidth: 95
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    minWidth: 43
  },
  {
    id: "Region",
    label: "Region",
    align: "center",
    minWidth: 100
  },
  {
    id: "Trivia Analytics",
    label: "Trivia Analytics",
    align: "center",
    minWidth: 100
  },
  {
    id: "Actions",
    label: "Actions",
    align: "center",
    minWidth: 50
  }
]
