import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

function IconButtonWithBorder({ children, islight = false, style }) {
  const IconButtonWithBorder = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    border: `2px solid ${
      islight ? theme.palette.secondary.default : theme.palette.secondary.main
    }`,
    borderRadius: 8,
    padding: 12,
    marginRight: 0,
    ...style
  }));
  return <IconButtonWithBorder>{children}</IconButtonWithBorder>;
}

export default IconButtonWithBorder;
