import React from "react"
import { List, ListItem, Typography } from "@mui/material"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Card from "components/Card/Card"
import ListWithStatus from "components/List/ListBodyWithStatus"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

function TriviaContest({ style }) {
  const history = useHistory()
  const triviaContestList = useSelector(
    (state) => state?.learningDevelopment?.trivia?.triviaContestList
  )

  return (
    <Card style={{ paddingBottom: 12, ...style }}>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Trivia Contest</Typography>
          <span onClick={() => history.push("/learning_development/dashboard/trivia-contest")}>
            <IconButtonWithBorder aria-label="Go to Trivia Analytics">
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>
        {triviaContestList.length === 0 ? (
          <div className="text-center mt-3">
            <Typography variant="body2regular" sx={{ opacity: 0.6 }}>
              No Records Found
            </Typography>
          </div>
        ) : (
          <ListWithStatus data={triviaContestList?.data ?? []} />
        )}
      </List>
    </Card>
  )
}

export default TriviaContest
