import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Typography } from "@mui/material"
import MenuListItems from "../menuListItems/index"
import trashIcon from "assets/icons/trash.svg"
import { useTheme } from "@mui/system"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { DeleteMenuCategoryAction } from "redux/marketing/actions"
import AppButton from "components/StyledComponents/AppButton"

function MenuListContainer({
  data,
  isEdit = false,
  setCategoryModal = () => {},
  setIsEdit = () => {},
  setIsEditModalItem = () => {},
  setCurrentCategoryData = () => {},
  setMenuItemsModal = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()

  function EditCategoryModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setCategoryModal(true)
    setIsEdit(true)
    setCurrentCategoryData(data)
  }

  function handleMenuPeriodDelete(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    dispatch(DeleteMenuCategoryAction({ mealCategoryId: id }))
  }

  function handleMenuDeleteModal(id) {
    dispatch(marketingActions.setMenuCategoryId(id))
    setDeleteModal(true)
  }

  function handleAddItems(id) {
    setIsEditModalItem(false)
    dispatch(marketingActions.setMenuCategoryId(id))
    setMenuItemsModal(true)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }
  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to Delete “${data?.food_category ?? ""}”?`}
        description={`"${data?.food_category ?? ""}" has ${data?.food_list?.length ?? ""} ${
          data?.food_list?.length <= 1 ? "dish" : "dishes"
        }`}
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteModal(false)}
        onDelete={() => handleMenuPeriodDelete(data?.food_category_id)}
        open={modal}></DeleteConfirmationDialog>

      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <Typography variant="h2">{data?.food_category}</Typography>
              <div className="d-flex align-items-center">
                {isEdit && (
                  <img
                    className="me-4"
                    onClick={() => handleMenuDeleteModal(data?.food_category_id)}
                    style={{ cursor: "pointer" }}
                    src={trashIcon}
                    alt=""
                  />
                )}
                {isEdit && (
                  <ModeEditIcon
                    onClick={() => EditCategoryModal(data?.food_category_id)}
                    sx={{ color: theme.palette.secondary.main, cursor: "pointer" }}></ModeEditIcon>
                )}
              </div>
            </div>
          </Col>
          <Col lg="12">
            {data?.food_list?.length ? (
              data?.food_list?.map((items, idx) => {
                return (
                  <MenuListItems
                    categoryIdData={data?.food_category_id}
                    setIsEditModalItem={setIsEditModalItem}
                    setMenuItemsModal={setMenuItemsModal}
                    isEdit={isEdit}
                    key={idx}
                    foodList={items}></MenuListItems>
                )
              })
            ) : (
              <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                No Items Found
              </Typography>
            )}
          </Col>
          <Col lg="12">
            {isEdit && (
              <AppButton
                className="mt-4"
                onClick={() => handleAddItems(data?.food_category_id)}
                styles={{
                  backgroundColor: theme.palette.background.main,
                  padding: "10px 20px",
                  width: "100%"
                }}>
                + Add New Menu Item
              </AppButton>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default React.memo(MenuListContainer)
