import React, { useState } from "react"
import Chip from "@mui/material/Chip"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import Tooltip from "@mui/material/Tooltip"
import CloseIcon from "@mui/icons-material/Close"
import { Field } from "formik"

function tootTileString(renderString) {
  return <p dangerouslySetInnerHTML={{ __html: renderString }}></p>
}

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

const AppAutoComplete = styled(Autocomplete)(({ theme }) => ({
  marginTop: 8,
  "& .MuiAutocomplete-tag": {
    border: "1px solid",
    borderRadius: 6,
    backgroundColor: theme.palette.background.secondary,
    borderColor: theme.palette.secondary.main,
    marginRight: 12,
    marginBottom: 8
  },
  "& .MuiChip-label": {
    color: theme.palette.text.secondary,
    fontFamily: "Inter-Regular",
    fontSize: 14
  },
  "& .MuiOutlinedInput-root": {
    border: "1px solid",
    borderColor: theme.palette.text.secondary,
    borderRadius: 6,
    padding: "12px"
  },
  "& .MuiAutocomplete-clearIndicator": {
    color: theme.palette.text.secondary
  },
  "& .MuiChip-deleteIcon": {
    fontSize: 16,
    color: "white",
    opacity: 0.6
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0
  },
  '&[class*="Mui-error"] .MuiOutlinedInput-root': {
    // Default left padding is 6px
    backgroundColor: "red"
  }
}))

function AppAutocomplete({
  label,
  isDisabled,
  isToolTip = false,
  isRequired,
  renderToolTip,
  placeholder = "",
  formStyle = {},
  onChange = () => {},
  value = [],
  isUpperCase = false,
  ...props
}) {
  const [optionValue, setOptionValue] = useState("")
  const [editIndex, setEditindex] = useState(null)

  const handleEditTag = (index, value) => {
    setEditindex(index)
    setOptionValue(value)
  }

  const handleChangeEditValue = (event) => {
    if (editIndex !== null) {
      value[editIndex] = event.target.value
      setEditindex(null)
    }
  }

  return (
    <>
      {label ? (
        <StyledLabel variant="body1Regular">
          {!isUpperCase ? (label ?? "-").toUpperCase() : label ?? "-"}
          {isToolTip && (
            <Tooltip title={tootTileString(renderToolTip)}>
              <InfoIcon className="ms-1" fontSize="small"></InfoIcon>
            </Tooltip>
          )}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Field name={props.id}>
        {({ field }) => (
          <AppAutoComplete
            {...field}
            multiple={true}
            id={props.id}
            options={[]}
            sx={(theme) =>
              props.error && {
                "& .MuiOutlinedInput-root": { border: `1px solid ${theme.palette.error.main}` }
              }
            }
            inputValue={optionValue}
            onChange={editIndex !== null ? handleChangeEditValue : onChange}
            defaultValue={value}
            onInputChange={(event, newInputValue) => {
              setOptionValue(newInputValue)
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value?.map((option, index) => (
                <Chip
                  deleteIcon={<CloseIcon></CloseIcon>}
                  key={index}
                  variant="outlined"
                  label={option}
                  onClick={() => handleEditTag(index, option)}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                disabled={isDisabled}
                {...props}
                style={formStyle}
                {...params}
                placeholder={placeholder}
              />
            )}
            disabled={isDisabled}
          />
        )}
      </Field>
    </>
  )
}

export default AppAutocomplete
