import axios from "axios"
import { call, put, select } from "redux-saga/effects"
import { appActions } from "redux/app/slice/app"
import slice from "../slice/user"

const baseUrl = process.env.REACT_APP_API_MY_URL

export function* suggestQuestion(action) {
  try {
    const { data } = yield call(
      axios.patch,
      `${baseUrl}/trivia/${action.payload.triviaId}/questions/`,
      action.payload.data
    )
    if (data.success) {
      yield put(slice.actions.setSuggestedQuestionUploaded(true))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getTriviaCategories(action) {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/trivia/categories/?web=true`, action.payload)
    if (data.success) {
      yield put(slice.actions.setTriviaCategories(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getNextContestList(action) {
  try {
    const { data } = yield call(axios.get, `${baseUrl}/trivia/next-trivia/`, action.payload)
    if (data.success) {
      yield put(slice.actions.setNextContestList(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* uploadSuggestedQuestionImage(action) {
  try {
    const { data } = yield call(axios.post, `${baseUrl}/trivia/media/`, action.payload)
    if (data.success) {
      yield put(
        slice.actions.setSuggestedQuestionImage({
          id: data.data.id,
          url: URL.createObjectURL(action?.payload?.get("media"))
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getLeaderBoardGMList(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { date, page, employeeClass } = action.payload
    let params = `month=${date}&page=${page}`
    if (employeeClass && employeeClass !== "All") {
      params += `&job_class=${employeeClass}`
    }
    const { data } = yield call(
      axios.get,
      `${baseUrl}/general-manager/restaurant-leaderboard/?${params}`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(
        slice.actions.setLeaderBoardListGM({
          totalPages: data?.data?.pages,
          data: data?.data?.results || []
        })
      )
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getTriviaDashboardAnalytics() {
  try {
    yield put(appActions.setIsLoading(true))

    const { data } = yield call(axios.get, `${baseUrl}/general-manager/trivia-analytics/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setTriviaDashboardAnalytics(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getGMCalendarData(action) {
  const payload = action.payload
  const params = `date=${payload.date}`
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/general-manager/calendar/?${params}`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setGMCalendarData(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getGMCorrectAnswers(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/general-manager/trivia-analytics/correct-answers/?month=${action.payload}`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setGMCorrectAnswers(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getGMInCorrectAnswers(action) {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/general-manager/trivia-analytics/incorrect-answers/?month=${action.payload}`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setGMIncorrectAnswers(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getTop5Ranks() {
  const id = yield select((store) => store?.app?.filters?.date)

  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(
      axios.get,
      `${baseUrl}/general-manager/trivia-top-leaderboard?month=${id}`
    )
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      yield put(slice.actions.setTop5Ranks(data.data))
    }
  } catch (e) {
    console.log(e)
  }
}

export function* getSuggestedQuestionData() {
  try {
    yield put(appActions.setIsLoading(true))
    const { data } = yield call(axios.get, `${baseUrl}/general-manager/suggested-questions/`)
    if (data.success) {
      yield put(appActions.setIsLoading(false))
      let pendingQuestions = []
      data?.data?.forEach((contest) => {
        contest?.questions.forEach((question) => {
          pendingQuestions.push({ ...question, trivia: contest?.trivia })
        })
      })

      yield put(slice.actions.setSuggestedQuestionsTableData(pendingQuestions))
    }
  } catch (e) {
    console.log(e)
  }
}
