import * as React from "react"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import "./AppDatePicker.scss"
import { FormHelperText } from "@mui/material"

export default function AppDatePicker({
  value = new Date(),
  onChange = () => {},
  error,
  touched,
  placeholder = "",
  ...props
}) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="w-100 picker-button"
          value={value}
          disableMaskedInput
          onChange={onChange}
          PaperProps={{
            sx: (theme) => ({
              backgroundColor: theme.palette.background.default,
              "& .MuiCalendarPicker-root": {
                borderRadius: "12px"
              }
            })
          }}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              sx={(theme) =>
                error &&
                touched && {
                  "& .MuiOutlinedInput-root": {
                    border: `1px solid ${theme.palette.error.main} !important`
                  }
                }
              }
              {...params}
              inputProps={{
                ...inputProps,
                disabled: true,
                ...(placeholder ? { placeholder } : {})
              }}
              disabled
            />
          )}
          inputFormat="dd MMM yyyy"
          {...props}
        />
      </LocalizationProvider>
      {error && touched ? <FormHelperText error={true}>{error}</FormHelperText> : null}
    </>
  )
}
