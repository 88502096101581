import { styled } from "@mui/material";

const Card = styled("div")(({ theme }) => {
  return {
    padding: 24,
    margin: 12,
    borderRadius: 12,
    maxWidth: "100%",
    boxSizing: "border-box",
    marginRight: 0,
    marginLeft: 0,
    backgroundColor: theme.palette.drawer.main,
    color: theme.palette.text.primary,
    position: "relative"
  };
});

export default Card;
