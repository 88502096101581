import {
  Backdrop,
  Box,
  Menu,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import React, { useRef, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch } from "react-redux";
import { deleteBenefitAction } from "redux/DMV/actions";
import TableNoRecords from "components/TableNoRecords";
import { useHistory } from "react-router-dom";
import { DMVActions } from "redux/DMV/slice/user";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog";

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: "#15181a",
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const ImageContainer = styled(Box)(() => ({
  "& img": {
    width: 50,
    borderRadius: 10
  }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}));

const ExistingBenefitsTable = ({ data = [] }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(false);
  const theme = useTheme();
  const rowId = useRef(null);
  const dispatch = useDispatch();
  const openMenu = Boolean(anchorEl);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const onOptionsClick = (e, id) => {
    e.stopPropagation();
    rowId.current = id;
    setAnchorEl(e.currentTarget);
    dispatch(DMVActions.setWineBenefitId(id));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleDelete = () => {
    dispatch(deleteBenefitAction(rowId.current));
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        background: `${theme.palette.background.default}`
      }}>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Benefit"
        onDelete={handleDelete}
        onClose={toggleDeletePopup}
      />
      <TableContainer>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              <>
                {data.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        <ImageContainer>
                          <img src={row.image} alt="" />
                        </ImageContainer>
                      </TableCell>

                      <TableCell align="left">
                        <Typography variant="body2Regular">
                          {row.benefit}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row.detail}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row.has_tooltip ? row.tooltip : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {membershipList
                            .slice(row.available_with - 1)
                            .join(", ")}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        onClick={(e) => e.stopPropagation()}>
                        <span onClick={(e) => onOptionsClick(e, row.id)}>
                          <MoreHorizIcon
                            sx={() => ({
                              height: "24px",
                              width: "24px"
                            })}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableNoRecords colSpan={6} />
            )}
          </TableBody>
          <Backdrop
            sx={{ color: "#fff", zIndex: 1300 }}
            open={openMenu}
            onClick={handleMenuClose}>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              PaperProps={{
                sx: {
                  marginTop: "15px",
                  backgroundColor: theme.palette.background.default,
                  borderRadius: "12px",
                  "& .MuiList-root": {
                    backgroundColor: theme.palette.background.default,
                    width: "225px",
                    boxSizing: "border-box",
                    padding: "10px 0"
                  }
                }
              }}>
              <StyledMenuItem
                onClick={() =>
                  history.push(
                    "/dmv/dashboard/wine-club-memberships/list/edit"
                  )
                }>
                <Typography variant="body1Regular">Edit</Typography>
              </StyledMenuItem>
              <StyledMenuItem onClick={toggleDeletePopup}>
                <Typography
                  sx={{ color: theme.palette.red.main }}
                  variant="body1Regular">
                  Delete
                </Typography>
              </StyledMenuItem>
            </Menu>
          </Backdrop>
        </CustomTable>
      </TableContainer>
    </Paper>
  );
};

export default ExistingBenefitsTable;

const columns = [
  {
    id: "Icon",
    label: "Icon",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    align: "left",
    minWidth: 200
  },
  {
    id: "Detail",
    label: "Details",
    align: "center",
    minWidth: 200
  },
  {
    id: "Tooltip",
    label: "Tooltip",
    align: "center",
    minWidth: 200
  },
  {
    id: "Available With",
    label: "Available With",
    align: "center",
    minWidth: 200
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 70
  }
];

const membershipList = ["1 bottle", "2 bottle", "3 bottle"];
