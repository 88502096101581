import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "components/Header/Header";
import GaugeChart from "components/GaugeChart/gaugeChart.js";
// import { useHistory } from "react-router-dom";
import Card from "components/Card/Card";
import TitleCard from "components/TitleCard";
import WineOfTheMonth from "components/DashboardCards/WineOfTheMonth";
import InviteRateRankingsCard from "./components/InviteRateRankingsCard";
import TopRankersCard from "./components/TopRankersCard";
import CustomDivider from "components/StyledComponents/Divider";
import ExecutiveTriviaAnalyticsSnapshotUI from "./components/TriviaAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { getWineMonthAction } from "redux/marketing/actions";
import moment from "moment";

const ExecutiveDashboard = () => {
  //   const history = useHistory();
  const dispatch = useDispatch();
  const { listWineMonthData } = useSelector(
    (store) => store?.marketing?.wineOfTheMonth || {}
  );

  useEffect(() => {
    dispatch(getWineMonthAction(moment(new Date).format('YYYY-MM-DD')));
  }, []);

  useEffect(() => {
    document.title = "Dashboard - Executive";
  }, []);

  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header
            title="Executive Dashboard"
            back={false}
            notificationIcon="/executive/dashboard/notifications"
            isNotify={true}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12">
              <TitleCard>Statistics</TitleCard>
            </Col>
            <Col lg="12" style={{ marginTop: 10 }}>
              <InviteRateRankingsCard routeNext="/executive/dashboard/performance-summary" />
            </Col>
            <Col lg="12">
              <TopRankersCard
                type="Server"
                routeNext="/executive/dashboard/membership-sign-up-performance"
              />
            </Col>
            <Col lg="12">
              <TopRankersCard
                type="TR"
                routeNext="/executive/dashboard/membership-sign-up-performance"
              />
            </Col>
            <Col lg="12">
              <TopRankersCard
                type="Bartender"
                routeNext="/executive/dashboard/membership-sign-up-performance"
              />
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Col lg="12">
            <TitleCard>Performance Management</TitleCard>
          </Col>
          <Col lg="12">
            <Card
              style={{ marginTop: 24, marginBottom: 24, paddingBottom: 12 }}>
              <GaugeChart
                isIconHeading={true}
                routeNext="/executive/dashboard/percentage-of-goal"
                headingTitle="% of Goal"
                isHeading={true}></GaugeChart>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <GaugeChart
                isIconHeading={true}
                routeNext="/executive/dashboard/performance-management-progress"
                headingTitle="% of improved"
                isHeading={true}></GaugeChart>
            </Card>
          </Col>
          <Col lg="12" style={{ margin: "24px 0px" }}>
            <CustomDivider />
          </Col>
          <Col lg="12" style={{ marginBottom: 24 }}>
            <ExecutiveTriviaAnalyticsSnapshotUI routeNext="/executive/dashboard/trivia-analytics" />
          </Col>
          <Col lg="12">
            <WineOfTheMonth
            tableRowData={listWineMonthData}
              routeNext={"/executive/dashboard/featured-Items-of-the-Month"}
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ExecutiveDashboard;
