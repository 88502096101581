import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import CalendarIcon2 from "assets/icons/CalendarIcon2.svg";
import "./WineOfTheMonth.scss";
import YoutubeFrame from "components/YoutubeFrame/YoutubeFrame";
import Shimmer from "components/Shimmer/Shimmer";

function MonthlyVideoTab({ timePeriod, url }) {
  const theme = useTheme();
  const [videoLoaded, setVideoLoaded] = useState(false);

  const styles = {
    box: {
      borderTop: `1px solid ${theme.palette.background.default}`,
      borderBottom: `1px solid ${theme.palette.background.default}`,
      paddingTop: "8px",
      paddingBottom: "8px",
      marginLeft: "16px",
      marginRight: "16px",
      display: "flex",
      flexDirection: "column"
    },
    icon: {
      marginRight: "6px",
      position: "relative",
      bottom: "2px"
    },
    videoContainer: {
      height: "250px",
      marginTop: "8px",
      position: "relative"
    },
    loaderContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    },
    youtube: {
      width: "100%",
      marginTop: "10px"
    },
    placeholder: {
      height: "250px",
      width: "100%",
      borderRadius: "12px"
    },
    playButton: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  };

  return (
    <Box sx={styles.box}>
      <Box>
        <img src={CalendarIcon2} alt="Calendar Icon" style={styles.icon} />
        <Typography variant="tag1">{timePeriod}</Typography>
      </Box>

      {!videoLoaded && (
        <Shimmer
          type="rounded"
          height="250px"
          styles={{ marginLeft: 0, marginRight: 0 }}
        />
      )}
      <YoutubeFrame
        {...{ videoLoaded, setVideoLoaded, url }}
        style={styles.youtube}
      />
    </Box>
  );
}

export default React.memo(MonthlyVideoTab);
