import React from "react"
import Card from "components/Card/Card"
import { Grid, List, ListItem, Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import dinner from "assets/images/Dinner.png"
import Lunch from "assets/images/Lunch.png"
import Wine_by_glass from "assets/images/WineByGlass.png"
import Bar_Menu from "assets/images/Bar_Menu.png"
import { useDispatch } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import { useHistory } from "react-router-dom"

function MenuSnapShot({ path = "/marketing/dashboard/explore-menu" }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const mediaMatch = window.matchMedia("(max-width: 2000px) and (min-width: 1700px)")
  const classes = {
    featureItemContainer: {
      border: `1px solid `,
      borderColor: theme.palette.secondary.main,
      display: "flex",
      position: "relative",
      margin: 16,
      borderRadius: 12,
      height: 120,
      cursor: "pointer"
    },
    wineMonth: {
      textTransform: "uppercase",
      color: theme.palette.secondary.main
    },
    wineBottle: {
      height: "100%",
      width: `${mediaMatch.matches ? "calc(100% - 120px)" : "calc(100% - 80px)"}`
    },
    wineImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12
    },
    wineDetail: {
      padding: 16,
      display: "flex",
      alignItems: "end"
    }
  }

  function handleMenuItem(items) {
    const linkItem = {
      item: items,
      category: "Entrees",
      list: "Summary View"
    }
    dispatch(marketingActions.setMenuLink(linkItem))
    history.push(path)
  }

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem sx={{ justifyContent: "space-between", padding: "0 0 12px 0" }}>
          <Typography variant="h2">Menu</Typography>
        </ListItem>
      </List>
      <Grid
        spacing={{ xs: 2, md: 3, lg: 4 }}
        container
        style={classes.mainContainer}
        className="p-4 ">
        {menuSnapshotData?.map((items, idx) => {
          return (
            <Grid key={idx} item xs={12} sm={12} md={4} lg={3} xl={3} className="p-0">
              <div
                style={classes.featureItemContainer}
                onClick={() => handleMenuItem(items?.value)}>
                <div style={classes.wineBottle}>
                  <img style={classes.wineImage} src={items?.image} alt="" />
                </div>
                <div style={classes.wineDetail}>
                  <div style={classes.nameWine}>
                    <Typography variant="caption2" style={classes.wineMonth}>
                      {items?.name}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </Card>
  )
}

export default MenuSnapShot

const menuSnapshotData = [
  {
    image: dinner,
    name: "DINNER",
    link: "/marketing/dashboard/explore-menu",
    value: "dinner"
  },
  {
    image: Lunch,
    name: "LUNCH",
    link: "/marketing/dashboard/explore-menu",
    value: "lunch"
  },
  {
    image: Bar_Menu,
    name: "BAR MENU",
    link: "/marketing/dashboard/explore-menu",
    value: "bar menu"
  },
  {
    image: Wine_by_glass,
    name: "Wine By Glass",
    link: "/marketing/dashboard/explore-menu",
    value: "wine by glass"
  }
]
