import { Box, IconButton, Typography, useTheme } from "@mui/material"
import React, { useState } from "react"
import styles from "../../PendingQuestionTable/PendingQuestionTable.module.scss"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import EditIcon from "@mui/icons-material/Edit"
import EditSuggestedQuestion from "../../EditSuggestedQuestion"
import { useDispatch } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import { getEditTriviaQuestionsAction } from "redux/L&D/actions/user"

const Question = ({ row, index, isEdit, isIncorrect }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const dispatch = useDispatch()
  const theme = useTheme()

  const handleEditDialogOpen = () => {
    dispatch(learningDevelopmentActions.setSuggestedQuestionEdited(false))
    setOpenEditDialog(true)
  }

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  const onQuestionEdited = () => {
    dispatch(getEditTriviaQuestionsAction())
  }

  const isQuestionEditable = () => {
    if (!row?.suggested_by) {
      return true
    }
    return row?.status?.toLowerCase() !== "approved"
  }

  return (
    <Box className={styles.question}>
      {openEditDialog ? (
        <EditSuggestedQuestion
          isEdit={isEdit}
          onClose={handleEditDialogClose}
          data={row}
          onQuestionEdited={onQuestionEdited}
        />
      ) : null}
      <Typography
        sx={{ margin: "0px 0px 4px 52px" }}
        color={theme.palette.secondary.main}
        variant="caption3"
        style={{ opacity: 1 }}
        className={styles.questionHeader}>
        {`${row?.category ?? "-"} | ${row?.level}`}
        {!!(isIncorrect && (row?.incorrect_percentage > 0 || row?.incorrect_count)) && (
          <Typography
            variant="caption3"
            style={{ opacity: 1 }}
            className={styles.incorrect_analytics}>
            {`${
              row?.incorrect_percentage > 0 ? ` incorrect %: ${row?.incorrect_percentage}%` : ""
            }${row?.incorrect_count ? ` | count: ${row?.incorrect_count} ` : ""}`}
          </Typography>
        )}
      </Typography>
      <Box className={styles.questionTitle}>
        <Typography variant="body1Regular">{index + 1}</Typography>
        <ArrowForwardIcon sx={{ marginLeft: "14px" }} />
        <Typography variant="h4" sx={{ marginLeft: "6px" }}>
          {row.title}
        </Typography>
        {isQuestionEditable() ? (
          <span className="ms-1" onClick={handleEditDialogOpen}>
            <IconButton
              sx={(theme) => ({
                color: theme.palette.secondary.main
              })}>
              <EditIcon
                sx={{
                  height: 20,
                  width: 20
                }}
              />
            </IconButton>
          </span>
        ) : null}
      </Box>
      {row?.answer?.map((option, index) => (
        <Box
          key={option.id}
          className={`${styles.option} ${option.is_correct ? styles.optionCorrect : ""}`}>
          <div className={styles.optionNumber}>
            <Typography variant="tag1">{(index + 10).toString(36).toUpperCase()}</Typography>
          </div>
          <Typography
            className={styles.optionName}
            sx={{ marginLeft: "10px" }}
            variant="body2Regular">
            {option.answer}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default Question
