import React, { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import ContestDetails from "./components/ContestDetails"
import TriviaQuestions from "./components/TriviaQuestions"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import { useState } from "react"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useDispatch, useSelector } from "react-redux"
import { getViewTriviaContestDetailsAction, getViewTriviaQuestionsAction } from "redux/L&D/actions"
import { learningDevelopmentActions } from "redux/L&D/slice/user"

function ViewTriviaContest() {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    contestData = {},
    questions = [],
    triviaId
  } = useSelector((store) => store?.learningDevelopment?.trivia?.viewTrivia)
  const { triviaQuestionCount } = useSelector((store) => store?.learningDevelopment || [])

  const handleEditContest = () => {
    dispatch(learningDevelopmentActions.setEditTriviaId(triviaId))
    history.push("/learning_development/dashboard/trivia-contest/edit")
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Contest"
    dispatch(getViewTriviaContestDetailsAction())
    dispatch(getViewTriviaQuestionsAction())
  }, [])

  const styles = {
    btn: {
      paddingLeft: "67px",
      paddingRight: "67px"
    }
  }

  return (
    <Container fluid className="ps-4 pe-4">
      <Row>
        <Col lg="12" className="ps-0">
          <Header title={contestData?.name ?? "-"} breadCrumbs={breadCrumbs}>
            {contestData?.status?.toLowerCase() === "draft" ||
            contestData?.status?.toLowerCase() === "published" ? (
              <AppButton onClick={handleEditContest} styles={styles.btn}>
                Edit Contest
              </AppButton>
            ) : null}
          </Header>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Row>
            <ContestDetails contestDetails={contestData} />
          </Row>
          <Row>
            <TriviaQuestions
              questions={questions}
              triviaQuestionCount={triviaQuestionCount}
              isIncorrect={contestData?.trivia_type === "CORRECT" ? false : true}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ViewTriviaContest
