import React, { Fragment } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";

function DailyStatsTableBody({ content }) {
  const { stats } = content;

  const styles = {
    firstCell: {
      paddingLeft: "0",
      borderBottom: "2px solid rgba(255, 255, 255, 0.1)"
    },
    secondCell: {
      borderBottom: "2px solid rgba(255, 255, 255, 0.1)"
    },
    thirdCell: {
      borderBottom: "2px solid rgba(255, 255, 255, 0.1)"
    },
    fourthCell: {
      borderBottom: "2px solid rgba(255, 255, 255, 0.1)",
      paddingRight: "10px"
    }
  };
  return (
    <Fragment>
      {/* TR Attendants */}
      <TableRow>
        <TableCell align="left" sx={styles.firstCell}>
          <Typography variant="body1Regular">TR Attendants</Typography>
        </TableCell>
        <TableCell align="center" sx={styles.secondCell}>
          <Typography variant="body1Regular">
            {stats.trAttendants.invites}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={styles.thirdCell}>
          <Typography variant="body1Regular">
            {stats.trAttendants.upgrades}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={styles.fourthCell}>
          <Typography variant="body1Regular">
            {stats.trAttendants.total}
          </Typography>
        </TableCell>
      </TableRow>

      {/* Servers */}
      <TableRow>
        <TableCell align="left" sx={styles.firstCell}>
          <Typography variant="body1Regular">Servers</Typography>
        </TableCell>
        <TableCell align="center" sx={styles.secondCell}>
          <Typography variant="body1Regular">
            {stats.servers.invites}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={styles.thirdCell}>
          <Typography variant="body1Regular">
            {stats.servers.upgrades}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={styles.fourthCell}>
          <Typography variant="body1Regular">{stats.servers.total}</Typography>
        </TableCell>
      </TableRow>

      {/* Bartenders */}
      <TableRow>
        <TableCell
          align="left"
          sx={[
            styles.firstCell,
            { borderBottom: "2px solid rgba(255,255,255,0.4)" }
          ]}>
          <Typography variant="body1Regular">Bartenders</Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={[
            styles.secondCell,
            { borderBottom: "2px solid rgba(255,255,255,0.4)" }
          ]}>
          <Typography variant="body1Regular">
            {stats.bartenders.invites}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          sx={[
            styles.thirdCell,
            { borderBottom: "2px solid rgba(255,255,255,0.4)" }
          ]}>
          <Typography variant="body1Regular">
            {stats.bartenders.upgrades}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          sx={[
            styles.fourthCell,
            { borderBottom: "2px solid rgba(255,255,255,0.4)" }
          ]}>
          <Typography variant="body1Regular">
            {stats.bartenders.total}
          </Typography>
        </TableCell>
      </TableRow>

      {/* Totals */}
      <TableRow>
        <TableCell align="left" sx={styles.firstCell}>
          <Typography variant="body1Regular">Total</Typography>
        </TableCell>
        <TableCell align="center" sx={styles.secondCell}>
          <Typography variant="body1Regular">{stats.total.invites}</Typography>
        </TableCell>
        <TableCell align="center" sx={styles.thirdCell}>
          <Typography variant="body1Regular">{stats.total.upgrades}</Typography>
        </TableCell>
        <TableCell align="right" sx={styles.fourthCell}>
          <Typography variant="body1Regular">{stats.total.total}</Typography>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default DailyStatsTableBody;
