import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextArea from "components/StyledComponents/AppTextArea"
import { useDispatch } from "react-redux"
import { uploadGiftCardImageAction } from "redux/superAdmin/actions/user"
import { superAdminAction } from "redux/superAdmin/slice/user"
import AppSelect from "components/StyledComponents/AppSelect"
// import AppDatePicker from "components/AppDatePicker"
import moment from "moment"
import AppDateRange from "components/DateRange"
import AppTextField from "components/StyledComponents/AppTextField"

const buttonText = {
  EDIT: "Save Card",
  SAVE: "Add Card"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}))

function CreateGiftCard({ formik, open = false, onClose = () => {}, isEdit = false }) {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const [maxDate, setMaxDate] = useState(null)

  const handleDatesChange = (startDate, endDate) => {
    if (formik.values?.giftCardType !== "MONTHLY") {
      const MaxDate = moment(startDate).add(3, "months")
      setMaxDate(new Date(MaxDate?._d.getFullYear(), MaxDate?._d.getMonth() + 1, 1))
    } else {
      const MaxDate = moment(startDate).add(1, "months")
      setMaxDate(new Date(MaxDate?._d.getFullYear(), MaxDate?._d.getMonth() + 1, 1))
    }
    formik.setFieldValue(`month`, startDate)
    formik.setFieldValue(`end_date`, endDate)
  }

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      uploadThumbnail: "",
      giftCardType: "MONTHLY",
      header: "",
      month: new Date()
    })
    dispatch(superAdminAction?.setGiftCardImageId(""))
    dispatch(superAdminAction?.setGiftCardData({}))
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadGiftCardImageAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={
          uploadType === "uploadThumbnail" && formik.values?.giftCardType === "MONTHLY" ? 1 : 2 / 1
        }
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        {showCropperModal(imageObjectURL, fileDetails, formik)}
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2"> {isEdit ? "Edit" : "Create"} Prize</Typography>
        </Box>

        <Box flexDirection="column" className="mt-4">
          <React.Fragment>
            <Box className=" w-100 py-2">
              <Row className="w-100 m-0">
                <Col lg="6">
                  <AppSelect
                    id="giftCardType"
                    name="giftCardType"
                    menuItems={giftCardType}
                    label="Prize Type"
                    isRequired={true}
                    value={formik.values?.giftCardType}
                    onChange={formik.handleChange}
                    error={formik.errors.giftCardType}
                    touched={formik.touched.giftCardType}
                    disabled={isEdit}
                  />
                </Col>
                <Col lg="6">
                  <ImageUploadField
                    label="Upload Background"
                    isRequired={true}
                    isVariant={true}
                    placeholder="Upload Image"
                    value={formik.values.uploadThumbnail}
                    onChange={(e) => onImageUpload(e)}
                    onDelete={() => handleImageDelete(formik)}
                    error={formik.errors.uploadThumbnail}
                    touched={formik.touched.uploadThumbnail}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6" className="datesContainer">
                  <StyledLabel variant="body1Regular">
                    {"Date"}
                    <span>*</span>
                  </StyledLabel>
                  {/* {formik.values?.giftCardType === "MONTHLY" ? (
                    <Box className="dateFieldContainer w-100 mt-2">
                      <AppDatePicker
                        // views={["year", "month"]}
                        inputFormat="dd MMM yyyy"
                        key={"month"}
                        minDate={new Date()}
                        maxDate={moment().add(10, "y").toDate()}
                        value={formik.values.month}
                        onChange={(val) => formik.setFieldValue("month", val)}
                        error={formik.errors.month}
                        touched={formik.touched.month}
                      />
                    </Box>
                  ) : ( */}
                  <Box className="w-100 mt-2">
                    <AppDateRange
                      startDate={formik.values.month}
                      endDate={formik.values.end_date}
                      focusedInput={focusedInput}
                      handleDatesChange={handleDatesChange}
                      handleFocusChange={handleFocusChange}
                      minDate={new Date()}
                      maxDate={maxDate}
                      error={formik.errors.month}
                      touched={formik.touched.month}
                    />
                  </Box>
                  {/* )} */}
                </Col>
                <Col lg="6">
                  <AppTextField
                    type="time"
                    fullWidth
                    label="Time"
                    isRequired={true}
                    isUpperCase={true}
                    value={formik.values.time}
                    onChange={formik.handleChange("time")}
                    error={formik.touched.time && Boolean(formik.errors.time)}
                    helperText={formik.touched.time && formik.errors.time}
                  />
                </Col>
              </Row>

              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    label="Winning Prize"
                    minRows={5}
                    maxRows={15}
                    isRequired={true}
                    placeholder="Enter Header"
                    value={formik.values.header}
                    onChange={formik.handleChange("header")}
                    error={formik.errors?.header}
                    touched={formik.touched?.header}
                    charLimit={formik.values?.giftCardType === "MONTHLY" ? 125 : 75}
                  />
                </Col>
              </Row>
            </Box>
          </React.Fragment>
        </Box>
        <Box className="mt-2 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default CreateGiftCard

const giftCardType = [
  {
    label: "Monthly Prize",
    value: "MONTHLY"
  },
  {
    label: "Quarterly Prize",
    value: "QUARTERLY"
  }
]
