import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import TriviaCategoriesCard from "./components/triviaCategoriesCard"
// import TastingNotesConfigCard from "./components/tastingNotesConfigCard"
// import WineClubMembershipsCard from "./components/WineClubMembershipsCard"
import { useDispatch } from "react-redux"
import {
  getHolidaysAction,
  getRoundsListAction,
  getTriviaCategoriesAction
} from "redux/DMV/actions"
// import RoundsCard from "./components/RoundsCard"
import HolidayCalendar from "./components/HolidayCalendar"
import AppButton from "components/StyledComponents/AppButton"
import { Box, Typography } from "@mui/material"
import CalendarIcon2 from "assets/icons/CalendarIcon2.svg"
import { useTheme } from "@mui/system"
import UploadHolidaysModal from "./components/UploadHolidayModal"
import moment from "moment"
import { Link } from "react-router-dom"
import ExploreMenuCard from "./components/MenuCard"
import GamificationCard from "./components/GamificationCard"
import GenerateQuestionCard from "./components/GenerateQuestionCard"

const DMVSettings = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const theme = useTheme()
  const [calendarOpen, setCalendarOpen] = useState(false)
  // const { roundsList } = useSelector((store) => store?.dmv?.rounds ?? [])
  const [modalOpen, setModalOpen] = useState(false)

  const toggleCalendarPopup = () => {
    dispatch(getHolidaysAction(moment(new Date()).format("YYYY-MM-DD")))
    setCalendarOpen((prev) => !prev)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Dashboard - DMV"
    dispatch(getTriviaCategoriesAction())
    dispatch(getRoundsListAction())
  }, [])
  return (
    <Container fluid className="p-0">
      <UploadHolidaysModal open={modalOpen} onClose={handleClose}></UploadHolidaysModal>
      <HolidayCalendar open={calendarOpen} onClose={toggleCalendarPopup} />
      <Row>
        <Col lg="12">
          <Header title="Dashboard" breadCrumbs={breadCrumbs} back={true}>
            <Typography
              variant="body1Regular"
              onClick={() => setModalOpen(true)}
              sx={{
                color: theme.palette.aqua.main,
                textDecoration: "underline",
                margin: "13px 50px",
                cursor: "pointer",
                textUnderlineOffset: "2px"
              }}>
              Upload Holidays
            </Typography>
            <AppButton onClick={toggleCalendarPopup}>
              <Box className="d-flex align-items-center">
                <img src={CalendarIcon2} alt="" className="me-2" />
                <Typography variant="body1Regular" className="ms-1">
                  Holiday Calendar
                </Typography>
              </Box>
            </AppButton>
          </Header>
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12">
              <TriviaCategoriesCard />
            </Col>
            <Col lg="12" className="mt-3">
              <Link to="/dmv/dashboard/performance-management-content">
                <AppButton className="w-100" variant="outlined">
                  Video Gallery
                </AppButton>
              </Link>
            </Col>
            <Col lg="12">
              <GamificationCard />
            </Col>
            {/* For Pilot Launch */}
            {/* <Col lg="12">
              <GrandCruCard />
            </Col>
            <Col lg="12">
              <FairnessFactor />
            </Col> */}
          </Row>
        </Col>
        <Col lg="6" md="12">
          <Row>
            {/* <Col lg="12">
              <TastingNotesConfigCard />
            </Col> */}
            {/* <Col lg="12">
              <WineClubMembershipsCard />
            </Col> */}
            <Col lg="12">
              <ExploreMenuCard />
            </Col>
            <Col lg="12">
              <GenerateQuestionCard />
            </Col>
            {/* <Col lg="12">
              <RoundsCard rounds={roundsList} />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default DMVSettings
