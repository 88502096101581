import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Menu, MenuItem, Typography } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { deleteWineItemAction, getWineItemsAction } from "redux/marketing/actions"
import { useEffect } from "react"
import Paragraph from "components/ReadMoreParagraph"
import AddMenuItems from "pages/private/marketing/menu/AddMenuItems"
import { unarchiveWineItemsAction } from "redux/marketing/actions/user"
import { trackUnArchiveItem } from "helpers/google_event_trackers"

function ArchivedWineListItems({ foodList, isEdit, categoryIdData = () => {} }) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const [menuItemsModal, setMenuItemsModal] = useState(false)
  const [isEditModalItem, setIsEditModalItem] = useState(false)
  const [unarchiveModal, setUnarchiveItemModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const { menu_item_ID } = useSelector((store) => store?.marketing?.menu ?? "")
  const { full_name } = useSelector((store) => store.user?.userData)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(marketingActions.setMenuItemId(id))
    dispatch(marketingActions.setMenuCategoryId(categoryId))
    dispatch(getWineItemsAction())
  }

  function handleMenuItemDelete() {
    dispatch(deleteWineItemAction())
    dispatch(marketingActions.setisArchive(false))
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(marketingActions.setMenuItemId(""))
  }

  function handleCloseMenuItems() {
    setMenuItemsModal(false)
    setIsEditModalItem(false)
    dispatch(marketingActions.setFoodItemsDetail({}))
  }

  function handleArchiveItem() {
    setUnarchiveItemModal(true)
    setAnchorEl(null)
  }

  function handleCloseArchiveModal() {
    setUnarchiveItemModal(false)
  }

  function handleUnarchiveMenuItem() {
    const payload = {
      item_state: "available"
    }
    dispatch(unarchiveWineItemsAction(payload))
    trackUnArchiveItem({ id: menu_item_ID }, full_name)
  }

  useEffect(() => {}, [])

  function handleMenuDeleteModal() {
    handleClose()
    setDeleteModal(true)
    setAnchorEl(null)
    dispatch(marketingActions.setisArchive(true))
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
    dispatch(marketingActions.setisArchive(true))
  }

  return (
    <>
      <AddMenuItems
        isEdit={isEditModalItem}
        open={menuItemsModal}
        onClose={handleCloseMenuItems}></AddMenuItems>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Delete This Wine ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Unarchive This Wine?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseArchiveModal}
        onDelete={() => handleUnarchiveMenuItem()}
        open={unarchiveModal}
        btnText="Unarchive"
        type="warning"
      />
      <div
        className={classes.itemContainer}
        style={{
          border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
          borderBottom: `${
            !isEdit ? `1px solid #605e5e` : `.5px solid ${theme.palette.secondary.main}`
          }`,
          borderRadius: `${isEdit ? "6px" : "0px"}`,
          padding: `${isEdit ? "8px" : "5px"}`
        }}>
        <div className={classes.imageContainer} style={{ height: 250 }}>
          <ImageDisplay data={foodList?.image_url}></ImageDisplay>
        </div>
        <div
          style={{
            // display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: 375,
            overflowWrap: "break-word"
          }}>
          <div>
            <Typography variant="h4">{foodList?.name ?? "-"}</Typography>
          </div>

          <div>
            {(foodList?.region || isEdit) && (
              <div className="mt-2">
                <Typography variant="tag1">{"Region:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.region ?? "-"}
                </Typography>
              </div>
            )}
            {(foodList?.country || isEdit) && (
              <div className="mt-2">
                <Typography variant="tag1">{"Country:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.country ?? "-"}
                </Typography>
              </div>
            )}
            {(foodList?.varitals || isEdit) && (
              <div className="mt-2">
                <Typography variant="tag1">{"Varietals:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.varitals ?? "-"}
                </Typography>
              </div>
            )}
            {(foodList?.tasting_notes?.length > 0 || isEdit) && (
              <div className="mt-2">
                <Typography variant="tag1">{"Tasting Notes:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.tasting_notes?.length > 0
                    ? foodList?.tasting_notes?.map((item, index) => {
                        return foodList?.tasting_notes?.length - 1 > index
                          ? `${item?.name}, `
                          : `${item?.name}`
                      })
                    : ""}
                </Typography>
              </div>
            )}
            {(foodList?.menu_pairing || isEdit) && (
              <div className="mt-2">
                <Typography variant="tag1">{"Menu Pairing:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.menu_pairing ?? "-"}
                </Typography>
              </div>
            )}
            {(foodList?.sound_bite || isEdit) && (
              <div className="mt-2">
                <Typography variant="tag1">{"Sommelier’s Sound Bites:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {<Paragraph text={foodList?.sound_bite ?? ""} maxCharCount={70}></Paragraph>}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {isEdit && (
          <div>
            <MoreVertIcon
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, foodList?.id, categoryIdData)}
              sx={{ cursor: "pointer" }}></MoreVertIcon>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}>
              <MenuItem onClick={handleEditMenuItemModal} sx={{ width: "150px" }}>
                Edit
              </MenuItem>
              <MenuItem onClick={() => handleArchiveItem()} sx={{ width: "150px" }}>
                Unarchive
              </MenuItem>
              <MenuItem
                onClick={handleMenuDeleteModal}
                sx={{ width: "150px", color: theme.palette.red.main }}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </>
  )
}

export default ArchivedWineListItems
