import React from "react"
import { Box, styled } from "@mui/material"

const ButtonContainer = styled(Box)(({ theme }) => ({
  background: `linear-gradient(97.14deg, ${theme.palette.aqua.main} -118.91%, ${theme.palette.secondary.main} 64.39%)`,
  padding: "2px",
  boxSizing: "border-box",
  maxHeight: 48,
  height: "100%",
  borderRadius: 6,
  overflow: "hidden",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&.disabled": {
    cursor: "auto"
  },
  "& .button": {
    color: theme.palette.text.secondary,
    ...theme.typography.body1Regular,
    textTransform: "capitalize",
    padding: "0px 18px",
    borderRadius: 6,
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    minHeight: 44,
    alignItems: "center"
  },
  "& .filled": {
    backgroundColor: "transparent !important"
  },
  "& .outlined": {
    backgroundColor: `${theme.palette.background.main} !important`
  }
}))

const AppButtonGradient = ({
  onClick = () => {},
  variant = "filled",
  sx = {},
  disabled = false,
  ...props
}) => {
  const handleClick = (e) => {
    if (disabled) return
    onClick(e)
  }

  return (
    <ButtonContainer
      className={disabled ? "disabled" : ""}
      sx={sx}
      {...{ onClick: handleClick, ...props }}>
      <Box className={`${variant} button`}>{props.children}</Box>
    </ButtonContainer>
  )
}

export default AppButtonGradient
