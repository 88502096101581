import { createSlice } from "@reduxjs/toolkit"
import { revertAllMarketing } from "../actions/user"

const initialState = {
  wineOfTheMonth: {
    id: null,
    videoThumbnailImage: null,
    bottleimage: null,
    flavourImages: [],
    listWineMonthData: [],
    getSingleWineMonthData: {}
  },
  raffleContestData: {
    id: null,
    isFormSubmitted: false,
    raffleListData: [],
    getSingleRaffle: {}
  },
  menu: {
    linkCategory: {
      list: ""
    },
    archived: false,
    menuItemImageId: "",
    menu_Category_ID: "",
    menu_item_ID: "",
    foodItem: {},
    menuPeriod: [],
    barMenuPeriod: [],
    wineMenuPeriod: [],
    spiritData: [],
    isArchive: false,
    newSpiritCount: {},
    bar_category: null
  },
  leaderboard: {
    data: {},
    userId: "",
    singleUserData: {}
  },
  featureItemMonth: {
    featureItemId: ""
  },
  gamificationData: []
}

const marketing = createSlice({
  name: "marketing",
  initialState: initialState,
  extraReducers: (builder) => builder.addCase(revertAllMarketing, () => initialState),
  reducers: {
    setWineMonthVideoThumbnail: (state, action) => {
      state.wineOfTheMonth.videoThumbnailImage = action.payload
    },
    setWineMonthListData: (state, action) => {
      state.wineOfTheMonth.listWineMonthData = action.payload
    },
    setWineOfTheMonthID: (state, action) => {
      state.wineOfTheMonth.id = action.payload
    },
    setGetSingleWineMonthData: (state, action) => {
      state.wineOfTheMonth.getSingleWineMonthData = action.payload
    },
    setIsEditWineFormSubmitted: (state, action) => {
      state.raffleContestData.isFormSubmitted = action.payload
    },
    setRaffleListData: (state, action) => {
      state.raffleContestData.raffleListData = action.payload
    },
    setRaffleID: (state, action) => {
      state.raffleContestData.id = action.payload
    },
    setRaffleSingleData: (state, action) => {
      state.raffleContestData.getSingleRaffle = action.payload
    },
    setWineMonthPDFThumbnail: (state, action) => {
      state.wineOfTheMonth.bottleimage = action.payload
    },
    setMenuLink: (state, action) => {
      state.menu.linkCategory = action.payload
    },
    setArchivedMenu: (state, action) => {
      state.menu.archived = action.payload
    },
    setFilterListValue: (state, action) => {
      state.menu.linkCategory.list = action.payload
    },
    setMenuPeriodData: (state, action) => {
      state.menu.menuPeriod = action.payload
    },
    setMenuCategoryId: (state, action) => {
      state.menu.menu_Category_ID = action.payload
    },
    setBarCategory: (state, action) => {
      state.menu.bar_category = action.payload
    },
    setMenuImageId: (state, action) => {
      state.menu.menuItemImageId = action.payload
    },
    setMenuItemId: (state, action) => {
      state.menu.menu_item_ID = action.payload
    },
    setFoodItemsDetail: (state, action) => {
      state.menu.foodItem = action.payload
    },
    setFeatureItemId: (state, action) => {
      state.featureItemMonth.featureItemId = action.payload
    },
    setTastingNotesImages: (state, action) => {
      if (action.payload?.length === 0) state.wineOfTheMonth.flavourImages = action.payload
      state.wineOfTheMonth.flavourImages[action.payload.index] = action.payload.id
    },
    setLoaderBoardData: (state, action) => {
      state.leaderboard.data = action.payload
    },
    setUserId: (state, action) => {
      state.leaderboard.userId = action.payload
    },
    setSingleUserDataGraph: (state, action) => {
      state.leaderboard.singleUserData = action.payload
    },
    setBarMenuPeriodData: (state, action) => {
      state.menu.barMenuPeriod = action.payload
    },
    setWineMenuPeriodData: (state, action) => {
      state.menu.wineMenuPeriod = action.payload
    },
    setSpiritData: (state, action) => {
      state.menu.spiritData = action.payload
    },
    setisArchive: (state, action) => {
      state.menu.isArchive = action.payload
    },
    setNewSpiritCount: (state, action) => {
      state.menu.newSpiritCount = action.payload
    },
    setGamificationLoaderBoardData: (state, action) => {
      state.gamificationData = action.payload
    }
  }
})

export default marketing
export const marketingActions = marketing.actions
