import { Formik } from "formik"
import {
  validationSchemaBeverageItems,
  validationSchemaSpiritItems,
  validationSchemaWineRegion
} from "helpers/validationSchema"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import MenuItemsForm from "./components/WineMenuItemForm"
import {
  addDMVBeverageMenuItemsAction,
  addDMVSpiritMenuItemsAction,
  addDMVWineMenuItemsAction,
  updateDMVBeverageItemsAction,
  updateDMVSpiritItemsAction,
  updateDMVWineItemsAction
} from "redux/DMV/actions/user"
import BeverageItemForm from "./components/BeverageItemForm"
import SpiritItemForm from "./components/SpiritItemForm"
import { updateSpiritItemsAction } from "redux/marketing/actions/user"

const menuText = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

function AddMenuItems({ open = false, onClose = () => {}, isEdit = false, isCMS = false }) {
  const { menuItemImageId, linkCategory } = useSelector((store) => store?.marketing?.menu ?? {})
  const { dmvMenuLink, DMVmenu_Category_ID, wineMenuItem, beverageMenuItem, spiritMenuItem } =
    useSelector((state) => state.dmv)
  const dispatch = useDispatch()

  const initialValues = {
    region: "",
    uploadThumbnail: "",
    Place: "",
    terroir: "",
    famous_vineyards: "",
    unique_fact: "",
    famous_wines: "",
    white_grape_varietals: "",
    red_grape_varietals: ""
  }

  const menuInitialValue = {
    cocktailName: "",
    uploadThumbnail: "",
    description: "",
    ingrediants: [{ name: "", measurement: "" }],
    methodology: [{ name: "", step: "" }],
    taste: "",
    // unique_fact: "",
    unique_fact: [{ fact: "" }],
    origin_name: "",
    how_its_made: ""
  }

  const spiritMenuInitialValue = {
    spiritName: "",
    uploadThumbnail: "",
    description: "",
    ingrediants: [{ name: "", measurement: "" }],
    unique_fact: [{ fact: "" }],
    origin_name: "",
    nose: "",
    palate: "",
    finish: "",
    distillation: "",
    aging: "",
    bottling: ""
  }

  function setInitialMenuFormValue() {
    if (isEdit === false) {
      return menuInitialValue
    } else {
      return {
        cocktailName: beverageMenuItem?.name ?? "",
        uploadThumbnail: beverageMenuItem?.image_url
          ? {
              id: "",
              url: beverageMenuItem?.image_url ?? ""
            }
          : "",
        description: beverageMenuItem?.description ?? "",
        ingrediants: beverageMenuItem?.ingredient ?? [{ name: "", measurement: "" }],
        methodology: beverageMenuItem?.methodology ?? [{ name: "", step: "" }],
        preparationMethod: beverageMenuItem?.preparation_method ?? "",
        taste: beverageMenuItem?.taste_profile ?? "",
        // unique_fact: beverageMenuItem?.unique_fact ?? "",
        unique_fact:
          beverageMenuItem?.unique_facts?.length > 0
            ? beverageMenuItem?.unique_facts
            : [{ fact: "" }],
        origin_name: beverageMenuItem?.origin_name ?? "",
        how_its_made: beverageMenuItem?.how_its_made ?? ""
      }
    }
  }

  function setInitialSpiritMenuFormValue() {
    if (isEdit === false) {
      return spiritMenuInitialValue
    } else {
      return {
        spiritName: spiritMenuItem?.name ?? "",
        uploadThumbnail: spiritMenuItem?.image_url
          ? {
              id: "",
              url: spiritMenuItem?.image_url ?? ""
            }
          : "",
        description: spiritMenuItem?.description ?? "",
        ingrediants: spiritMenuItem?.ingredient ?? [{ name: "", measurement: "" }],
        unique_fact:
          spiritMenuItem?.unique_facts?.length > 0 ? spiritMenuItem?.unique_facts : [{ fact: "" }],
        origin_name: spiritMenuItem?.origin ?? "",
        nose: spiritMenuItem?.nose ?? "",
        palate: spiritMenuItem?.palate,
        finish: spiritMenuItem?.finish,
        distillation: spiritMenuItem?.distillation ?? "",
        aging: spiritMenuItem?.aging ?? "",
        bottling: spiritMenuItem?.bottling ?? "",
        itemPrice: spiritMenuItem?.item_price ?? null
      }
    }
  }

  function setInitialFormValue() {
    if (isEdit === false) {
      return initialValues
    } else {
      return {
        region: wineMenuItem?.name ?? "",
        uploadThumbnail: wineMenuItem?.image
          ? {
              id: "",
              url: wineMenuItem?.image ?? ""
            }
          : "",
        Place: wineMenuItem?.place ?? "",
        famous_wines: wineMenuItem?.most_famous_wines ?? "",
        white_grape_varietals: wineMenuItem?.white_grape_varietals ?? "",
        red_grape_varietals: wineMenuItem?.red_grape_varietals ?? "",
        terroir: wineMenuItem?.terroir ?? "",
        famous_vineyards: wineMenuItem?.famous_vineyards ?? "",
        unique_fact: wineMenuItem?.unique_facts ?? ""
      }
    }
  }

  useEffect(() => {
    dispatch(marketingActions.setMenuImageId(""))
  }, [])

  function handleSubmit(e) {
    const payload = {
      name: e.region,
      place: e.Place,
      wine_category: DMVmenu_Category_ID,
      most_famous_wines: e.famous_wines,
      white_grape_varietals: e.white_grape_varietals,
      red_grape_varietals: e.red_grape_varietals,
      terroir: e?.terroir,
      famous_vineyards: e?.famous_vineyards,
      unique_facts: e?.unique_fact,
      ...(menuItemImageId ? { image: menuItemImageId } : e.uploadThumbnail ? {} : { image: "" })
    }

    if (isEdit === false) {
      dispatch(addDMVWineMenuItemsAction(payload))
    } else {
      dispatch(updateDMVWineItemsAction(payload))
    }

    onClose()
  }

  function handleSubmitBeverageItem(e) {
    const payload = {
      name: e.cocktailName,
      description: e.description,
      beverage_category: DMVmenu_Category_ID,
      methodology: e.methodology,
      taste_profile: e.taste,
      ingredient: e.ingrediants,
      unique_facts: e?.unique_fact,
      origin_name: e?.origin_name,
      how_its_made: e?.how_its_made,
      ...(menuItemImageId ? { image: menuItemImageId } : e.uploadThumbnail ? {} : { image: "" })
    }

    if (isEdit === false) {
      dispatch(addDMVBeverageMenuItemsAction(payload))
    } else {
      dispatch(updateDMVBeverageItemsAction(payload))
    }

    onClose()
  }

  function handleSubmitSpiritItem(e) {
    const payload = {
      name: e.spiritName,
      description: e.description,
      spirit_category: DMVmenu_Category_ID,
      ingredient: e.ingrediants,
      unique_facts: e?.unique_fact,
      origin: e?.origin_name,
      nose: e.nose,
      palate: e.palate,
      finish: e.finish,
      aging: e.aging,
      distillation: e.distillation,
      bottling: e.bottling,
      ...(menuItemImageId ? { image: menuItemImageId } : e.uploadThumbnail ? {} : { image: "" }),
      item_price: e.itemPrice !== "" ? e.itemPrice : null
    }

    if (isEdit === false) {
      dispatch(addDMVSpiritMenuItemsAction(payload))
    } else {
      if (isCMS) {
        dispatch(updateSpiritItemsAction(payload))
      } else dispatch(updateDMVSpiritItemsAction(payload))
    }

    onClose()
  }

  return (
    <>
      {dmvMenuLink === menuText.WINE_REGION && (
        <Formik
          initialValues={setInitialFormValue()}
          enableReinitialize={true}
          validationSchema={validationSchemaWineRegion}
          onSubmit={handleSubmit}>
          {(formik) => (
            <MenuItemsForm
              isEdit={isEdit}
              onClose={onClose}
              formik={formik}
              open={open}></MenuItemsForm>
          )}
        </Formik>
      )}

      {dmvMenuLink === menuText.BEVERAGE && (
        <Formik
          initialValues={setInitialMenuFormValue()}
          enableReinitialize={true}
          validationSchema={validationSchemaBeverageItems}
          onSubmit={handleSubmitBeverageItem}>
          {(formik) => (
            <>
              <BeverageItemForm
                isEdit={isEdit}
                onClose={onClose}
                formik={formik}
                open={open}
                title="Menu Item"></BeverageItemForm>
            </>
          )}
        </Formik>
      )}
      {(dmvMenuLink === menuText.SPIRITS || linkCategory?.item === menuText.SPIRITS) && (
        <Formik
          initialValues={setInitialSpiritMenuFormValue()}
          enableReinitialize={true}
          validationSchema={validationSchemaSpiritItems}
          onSubmit={handleSubmitSpiritItem}>
          {(formik) => (
            <>
              <SpiritItemForm
                isEdit={isEdit}
                onClose={onClose}
                formik={formik}
                open={open}
                title="Menu Item"
                isCMS={isCMS}></SpiritItemForm>
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default AddMenuItems
