import React, { Fragment } from "react"
import { ListItem, Typography } from "@mui/material"
import Divider from "components/StyledComponents/Divider"

function ListWith2Items({ tableData, marginRight = 0, percentage = false }) {
  return (
    <Fragment>
      {tableData.map((item, index) => (
        <Fragment key={`${item.id} ${index}`}>
          <ListItem sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
            <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
              {`${item.key}  `}
            </Typography>
            {percentage ? (
              <Typography variant="body1Regular" sx={{ marginRight }}>
                {Math.round(item?.field * 10) / 10 + "%"}
              </Typography>
            ) : (
              <Typography variant="body1Regular" sx={{ marginRight }}>
                {item.field ?? "-"}
              </Typography>
            )}
          </ListItem>
          {index !== tableData.length - 1 ? <Divider /> : null}
        </Fragment>
      ))}
    </Fragment>
  )
}

export default ListWith2Items
