import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import QuestionTable from "components/QuestionTable";
// import Questions from "assets/json/triviaAnalytics.json";
import { useTheme } from "@mui/system";
import Header from "components/Header/Header";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import { useState } from "react";
// import QuestionsForReview from "./components/QuestionsForReview";
import { useDispatch, useSelector } from 'react-redux';
import { getGMCorrectAnswersAction, getGMInCorrectAnswersAction } from "redux/GM/actions";
import CorrectAnswerModal from "components/CorrectAnswerModal/CorrectAnswerModal";
import Modal from "components/Modal/Modal";
import { getMonthYearFromDate } from "helpers/functions";
const GMTriviaAnalytics = () => {
  const theme = useTheme();
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const dispatch = useDispatch();
  const [modalContent, setModalContent] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const { correctAnswers, incorrectAnswers } = useSelector((state) => state?.gm?.triviaAnalytics ?? {})
  const { date } = useSelector((state) => state?.app?.filters || "")
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Trivia Analytics";
  }, []);

  useEffect(() => {
    dispatch(getGMCorrectAnswersAction(date))
    dispatch(getGMInCorrectAnswersAction(date))
  }, [date]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const styles = {
    gridContainer: {
      "&.MuiGrid-root": {
        paddingTop: "0"
      }
    },
    heading: {
      color: theme.palette.text.primary
    }
  };

  return (
    <>
      <Modal open={modalOpen} onClose={handleClose}>
        <CorrectAnswerModal modalContent={modalContent} />
      </Modal>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header title={`Trivia Analytics For ${getMonthYearFromDate()}`} breadCrumbs={breadCrumbs} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.heading} variant="h2">
            Trivia Questions Answered Most Correctly
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <QuestionTable
            isGM={true}
            isCorrectAnswer={true}
            rows={correctAnswers ?? []}
            setModalOpen={setModalOpen}
            setModalContent={setModalContent}
            columns={correctColumns}
          />
        </Grid>
        <Grid sx={styles.gridContainer} item xs={12}>
          <Box height={23} />
          <Typography sx={styles.heading} variant="h2">
            Trivia Questions Answered Most Incorrectly
          </Typography>
        </Grid>
        <Grid sx={styles.gridContainer} item xs={12}>
          <QuestionTable
            isGM={true}
            isCorrectAnswer={false}
            setModalOpen={setModalOpen}
            setModalContent={setModalContent}
            rows={incorrectAnswers ?? []}
            columns={IncorrectColumns}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Box height={23} />
          <Typography sx={styles.heading} variant="h2">
            Trivia Questions Sent for Review
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <QuestionsForReview

            columns={Questions.ReviewQuestionsColumns}
            rows={Questions.ReviewQuestionsRows}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default GMTriviaAnalytics;
const correctColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Questions",
    label: "Questions",
    minWidth: 170
  },
  {
    id: "Correct Answer",
    label: "Correct Answer",
    align: "center",
    minWidth: 100
  },
  {
    id: "Correct Answers",
    label: "Correct Answers",
    align: "center",
    minWidth: 100
  },
  {
    id: "Players Answered",
    label: "Players Answered",
    align: "center",
    minWidth: 100
  },
  {
    id: "% Correct Answered",
    label: "% Correct Answered",
    align: "center",
    minWidth: 50
  }
];

const IncorrectColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Questions",
    label: "Questions",
    minWidth: 170
  },
  {
    id: "Correct Answer",
    label: "Correct Answer",
    align: "center",
    minWidth: 100
  },
  {
    id: "Incorrect Answers",
    label: "Incorrect Answers",
    align: "center",
    minWidth: 100
  },
  {
    id: "Players Answered",
    label: "Players Answered",
    align: "center",
    minWidth: 100
  },
  {
    id: "% Incorrect Answered",
    label: "% Incorrect Answered",
    align: "center",
    minWidth: 50
  }
];