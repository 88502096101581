import React, { useEffect } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import AppSelect from "components/StyledComponents/AppSelect"
import CheckBox from "components/CheckBox"

const buttonText = {
  EDIT: "Update Release",
  SAVE: "Save Release"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

function AppReleaseForm({ formik, open = false, onClose = () => {}, isEdit = false }) {
  const theme = useTheme()


  useEffect(() => {
    formik.resetForm({
      dishName: "",
      uploadThumbnail: "",
      menuDescription: [],
      allergens: [],
      chefDescription: ""
    })
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  return (
    <StyledDialog open={open} onClose={() => onClose()}>
      <DialogContent>
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2"> {isEdit ? "Edit" : "Add New"} App Release</Typography>
        </Box>

        <Box flexDirection="column" className="mt-4">
          <React.Fragment>
            <Box className=" w-100 py-2">
              <Row className="w-100 m-0">
                <Col lg="6">
                  <AppSelect
                    id="platform"
                    name="platform"
                    label="Select platform"
                    menuItems={PLATFORM}
                    value={formik.values.platform}
                    onChange={formik.handleChange}
                    error={formik.errors.platform}
                    isRequired={true}
                    touched={formik.touched.platform}
                  />
                </Col>
                <Col lg="6">
                  <AppTextField
                    id="title"
                    name="title"
                    fullWidth
                    type="text"
                    label="App Name"
                    isRequired={true}
                    placeholder="Enter App Name"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.errors.title}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6">
                  <AppTextField
                    id="appVersion"
                    name="appVersion"
                    fullWidth
                    type="text"
                    label="App Version"
                    isRequired={true}
                    placeholder="Enter App Version"
                    value={formik.values.appVersion}
                    onChange={formik.handleChange}
                    error={formik.touched.appVersion && Boolean(formik.errors.appVersion)}
                    helperText={formik.errors.appVersion}
                  />
                </Col>
                <Col lg="6">
                  <AppSelect
                    id="status"
                    name="status"
                    label="Select Status"
                    menuItems={STATUS}
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    error={formik.errors.status}
                    isRequired={true}
                    touched={formik.touched.status}
                  />
                </Col>
              </Row>

              <Row className="w-100 m-0 mt-4">
                <Col lg="1">
                  <CheckBox
                    role="button"
                    checked={formik.values.isMandatory}
                    onClick={() => formik.setFieldValue("isMandatory", !formik.values.isMandatory)}
                  />
                </Col>
                <Col lg="11" style={{ marginLeft: -40, marginTop: 2 }}>
                  <Typography>is Mandatory</Typography>
                </Col>
              </Row>
            </Box>
          </React.Fragment>
        </Box>
        <Box className="mt-5 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default AppReleaseForm

const STATUS = [
  {
    label: "Draft",
    value: "DRAFT"
  },
  {
    label: "Release",
    value: "RELEASE"
  }
]

const PLATFORM = [
  {
    label: "Android",
    value: "ANDROID"
  },
  {
    label: "iOS",
    value: "IOS"
  }
]
