import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import MonthlyCardTitle from "./MonthlyCardTitle";
import MonthlyVideoTab from "./MonthlyVideoTab";
import MonthlyTastingNote from "./MonthlyTastingNote";
import moment from "moment";

function MonthlyCard({ details, editable = false }) {
  const theme = useTheme();
  const { id, month, video_url, name } = details;

  function createTitle(date) {
    return `${moment(date).format("MMMM YYYY")} Wine Of The Month`;
  }

  function getMonthRange(month) {
    var date = new Date(month);
    var firstDay = moment(
      new Date(date.getFullYear(), date.getMonth(), 1).toUTCString()
    ).format("MMMM DD");
    var lastDay = moment(
      new Date(date.getFullYear(), date.getMonth() + 1, 0).toUTCString()
    ).format("MMMM DD");
    return `${firstDay} - ${lastDay}`;
  }

  function convertVideoUrl(videoURL) {
    let NewVideoURL = videoURL.replace("watch?v=", "embed/");
    NewVideoURL =
      NewVideoURL.indexOf("&") > -1
        ? NewVideoURL.slice(0, NewVideoURL.indexOf("&"))
        : NewVideoURL;
    return NewVideoURL;
  }

  const styles = {
    box: {
      width: "100%",
      height: "100%",
      border: `2px solid ${theme.palette.background.default}`,
      borderRadius: "12px"
    }
  };

  return (
    <Box sx={styles.box}>
      <MonthlyCardTitle
        idMonth={id}
        title={createTitle(month)}
        wineName={name}
        editable={editable}
      />
      <MonthlyVideoTab
        id={id}
        timePeriod={getMonthRange(month)}
        url={convertVideoUrl(video_url)}
      />
      <MonthlyTastingNote
        editable={true}
        month={moment(month).format("MMMM")}
        pdfUrl={details?.pdf_url}
        pdfThumbnail={details?.pdf_thumbnail_url}
      />
    </Box>
  );
}

export default MonthlyCard;
