import React, { useEffect, useState } from "react"
import Header from "components/Header/Header"
import { Col, Container, Row } from "react-bootstrap"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import Card from "components/Card/Card"
import {
  Backdrop,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { deleteTastingNoteAction, getTastingNotesDataAction, getTastingNotesWeekListAction } from "redux/DMV/actions"
import TableNoRecords from "components/TableNoRecords"
import { getFormattedDate } from "helpers/functions"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { useHistory } from "react-router-dom"
import { DMVActions } from "redux/DMV/slice/user"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: "#15181a",
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    background: "#15181a"
  },
  "& .MuiTableCell-head": {
    background: "#15181a",
    verticalAlign: "bottom",
    position: "relative",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))

const TastingNotesWeek = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const weekList = useSelector((store) => store?.dmv?.tastingNotes?.weekList || [])
  const [anchorEl, setAnchorEl] = useState(false)
  const theme = useTheme()
  const dispatch = useDispatch()
  const openMenu = Boolean(anchorEl)
  const history = useHistory()
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)

  const onOptionsClick = (e, id) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    dispatch(DMVActions.setTastingNotesId(id))
    dispatch(getTastingNotesDataAction(id))
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleViewTastingNote = () => {
    history.push("/super-admin/dashboard/tasting-notes-config/list/view")
  }

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen)
  }

  useEffect(() => {}, [weekList])

  const handleDeleteTastingNote = () => {
    dispatch(deleteTastingNoteAction())
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Tasting Notes Config - DMV"
    dispatch(getTastingNotesWeekListAction())
  }, [])
  return (
    <Container fluid className="p-0  ">
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Tasting Notes"
        onDelete={handleDeleteTastingNote}
        onClose={toggleDeletePopup}
      />
      <Row>
        <Col lg="12">
          <Header title="Tasting Notes" breadCrumbs={breadCrumbs} back={true} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="mt-5 mb-5">
            <Typography className="mb-3" variant="h2">
              Tasting Notes
            </Typography>
            <TableContainer>
              <CustomTable stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={
                          index === 0
                            ? { minWidth: column.minWidth, paddingLeft: "24px" }
                            : { minWidth: column.minWidth }
                        }>
                        <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weekList?.length ? (
                    <>
                      {weekList?.map((row) => (
                        <TableRow key={row?.id}>
                          <TableCell align="left" style={{ paddingLeft: "24px" }}>
                            <Typography variant="body2Regular">{row?.title ?? "-"}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body2Regular">
                              {row?.start_date ? getFormattedDate(row.start_date) : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body2Regular">
                              {row?.end_date ? getFormattedDate(row.end_date) : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                            <span role="button" onClick={(e) => onOptionsClick(e, row.id)}>
                              <MoreHorizIcon
                                sx={() => ({
                                  height: "24px",
                                  width: "24px"
                                })}
                              />
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableNoRecords colSpan={4} />
                  )}
                </TableBody>
              </CustomTable>
            </TableContainer>
            <Backdrop
              sx={{ color: "#fff", zIndex: 1300 }}
              open={openMenu}
              onClick={handleMenuClose}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                PaperProps={{
                  sx: {
                    marginTop: "15px",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "12px",
                    "& .MuiList-root": {
                      backgroundColor: theme.palette.background.default,
                      width: "225px",
                      boxSizing: "border-box",
                      padding: "10px 0"
                    }
                  }
                }}>
                <StyledMenuItem onClick={() => handleViewTastingNote()}>
                  <Typography variant="body1Regular">View</Typography>
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() =>
                    history.push("/super-admin/dashboard/tasting-notes-config/list/edit")
                  }>
                  <Typography variant="body1Regular">Edit</Typography>
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => toggleDeletePopup()}
                  sx={{
                    borderTop: `2px solid ${theme.palette.background.secondary}`
                  }}>
                  <Typography sx={{ color: theme.palette.red.main }} variant="body1Regular">
                    Delete
                  </Typography>
                </StyledMenuItem>
              </Menu>
            </Backdrop>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default TastingNotesWeek

const columns = [
  {
    id: "Month",
    label: "Month",
    align: "left",
    minWidth: 50
  },
  {
    id: "Start Date",
    label: "Start Date",
    align: "center",
    minWidth: 200
  },
  {
    id: "End Date",
    label: "End Date",
    align: "center",
    minWidth: 200
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 70
  }
]
