import { Divider, Typography, List, Box, Tooltip } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import { Formik } from "formik"
import {
  validationSchemaAddContest,
  validationSchemaAddIncorrectContest
} from "helpers/validationSchema"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTheme } from "@mui/system"
import Header from "components/Header/Header"
import { Container, Row, Col } from "react-bootstrap"
import AppDatePicker from "components/AppDatePicker"
import { Prompt, useHistory } from "react-router-dom"
import AppSelect from "components/StyledComponents/AppSelect"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import { useDispatch, useSelector } from "react-redux"
import { createTriviaAction, getIncorrectTriviaDurationAction } from "redux/L&D/actions"
import { formatDate, getFormattedDate } from "helpers/functions"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import moment from "moment"
import InfoIcon from "@mui/icons-material/Info"

const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

function NewContest() {
  const theme = useTheme()
  const isDraft = useRef(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const isIncorrectQuestionTrivia = history?.location?.state?.isIncorrect || false
  const { triviaId } = useSelector(
    (store) => store?.learningDevelopment?.trivia?.triviaContest?.newContest
  )
  const { id: user_id, full_name: user_name } = useSelector((store) => store?.user?.userData)
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [weekDaysChoices, setWeekDaysChoices] = useState([])
  const { incorrectTriviaDuration } = useSelector((state) => state?.learningDevelopment)

  const styles = {
    heading: {
      color: theme.palette.text.secondary
    },
    required: {
      color: theme.palette.error.main
    },
    btn: {
      paddingLeft: "67px",
      paddingRight: "67px"
    }
  }

  const handleSubmit = (values) => {
    setIsFormSubmitting(true)
    const { start_date } = values
    // const MonthName = new Date(start_date).toLocaleString("en-US", { month: "long" }) ${MonthName}
    const payload = {
      ...values,
      name: isIncorrectQuestionTrivia ? `Incorrect Trivia` : values?.name,
      start_date: isIncorrectQuestionTrivia
        ? new Date(incorrectTriviaDuration.start_date).toISOString().slice(0, 10)
        : formatDate(new Date(start_date.getFullYear(), start_date.getMonth(), 1)),
      end_date: isIncorrectQuestionTrivia
        ? incorrectTriviaDuration.end_date
        : formatDate(new Date(start_date.getFullYear(), start_date.getMonth() + 1, 0)),
      created_by: user_id,
      status: "Draft",
      trivia_type: isIncorrectQuestionTrivia ? "INCORRECT" : "CORRECT",
      start_time: incorrectTriviaDuration.start_time
    }
    dispatch(createTriviaAction(payload))
    // dispatch(learningDevelopmentActions.setIncorrectTriviaDuration({}))
  }

  const handleSave = (formik) => {
    isDraft.current = false
    formik.handleSubmit()
  }

  const handleSaveAsDraft = (formik) => {
    isDraft.current = true
    formik.handleSubmit()
  }

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ""
  }

  useEffect(() => {
    if (triviaId) {
      if (isDraft.current) {
        history.replace("/learning_development/dashboard/trivia-contest")
      } else {
        history.replace({
          pathname: "/learning_development/dashboard/question-bank",
          state: { isIncorrect: isIncorrectQuestionTrivia }
        })
      }
    }
  }, [triviaId])

  function convertDate(date, time) {
    const forDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().format("DD MMM YYYY")
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Trivia Contest"
    dispatch(learningDevelopmentActions.setUploadQuestionsData([]))
    setWeekDaysChoices(
      weekDays.map((weekName) => ({
        label: `Every ${weekName}`,
        value: weekName.toUpperCase()
      }))
    )
  }, [])

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser)
    return () => {
      window.removeEventListener("beforeunload", alertUser)
    }
  }, [])

  useLayoutEffect(() => {
    dispatch(learningDevelopmentActions.initTriviaContest())
  }, [])

  const handleTriviaDuration = (formik, val) => {
    formik.setFieldValue("start_date", val)
    if (isIncorrectQuestionTrivia) {
      const payload = {
        start_date: formatDate(new Date(val.getFullYear(), val.getMonth(), 1)),
        end_date: formatDate(new Date(val.getFullYear(), val.getMonth() + 1, 0))
      }
      dispatch(getIncorrectTriviaDurationAction(payload))
    }
  }

  useEffect(() => {
    if (isIncorrectQuestionTrivia) {
      const start_date = new Date()
      const payload = {
        start_date: formatDate(new Date(start_date.getFullYear(), start_date.getMonth(), 1)),
        end_date: formatDate(new Date(start_date.getFullYear(), start_date.getMonth() + 1, 0))
      }
      dispatch(getIncorrectTriviaDurationAction(payload))
    }
  }, [])

  const formatTime = (date, time) => {
    if (!date || !time) return ""
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().format("hh:mm A")
  }

  function tootTileString(renderString) {
    return <p dangerouslySetInnerHTML={{ __html: renderString }}></p>
  }

  return (
    <>
      <Prompt
        when={!isFormSubmitting}
        message={"Changes you made may not be saved. Are you sure you want to leave this page ?"}
      />
      <Formik
        initialValues={{
          name: "",
          scope: "All Restaurant",
          created_by: user_name,
          published_week_day: "MONDAY",
          start_date: new Date(),
          creation_date: new Date()
        }}
        // enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={
          !isIncorrectQuestionTrivia
            ? validationSchemaAddContest
            : validationSchemaAddIncorrectContest
        }>
        {(formik) => (
          <React.Fragment>
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Header title="Trivia Contest & Questions" breadCrumbs={breadCrumbs}>
                    <Typography
                      variant="body1Regular"
                      onClick={() => handleSaveAsDraft(formik)}
                      sx={{
                        color: theme.palette.secondary.main,
                        textDecoration: "underline",
                        margin: "13px 50px",
                        cursor: "pointer",
                        textUnderlineOffset: "2px",
                        marginRight: "0"
                      }}>
                      Save Draft
                    </Typography>
                  </Header>
                </Col>
              </Row>
            </Container>
            {!isIncorrectQuestionTrivia ? (
              <>
                <Container fluid>
                  <Row>
                    <Col lg="4">
                      <AppTextField
                        id="name"
                        name="name"
                        fullWidth
                        label="Contest Name"
                        isRequired={true}
                        placeholder="Enter Contest Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                    </Col>
                    <Col lg="4">
                      <AppTextField
                        id="scope"
                        name="scope"
                        isDisabled={true}
                        fullWidth
                        label="Scope"
                        isRequired={false}
                        placeholder="Enter Scope"
                        value={formik.values.scope}
                        onChange={formik.handleChange}
                        error={formik.touched.scope && Boolean(formik.errors.scope)}
                        helperText={formik.touched.scope && formik.errors.scope}
                      />
                    </Col>

                    <Col lg="4">
                      <List sx={{ padding: 0 }}>
                        <Box>
                          <Typography variant="body1Regular" sx={styles.heading}>
                            DURATION
                          </Typography>
                          <span style={styles.required}>*</span>
                        </Box>
                        <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
                          <Col lg="6" className="me-2 dateFieldContainer">
                            <AppDatePicker
                              views={["year", "month"]}
                              inputFormat="MMM yyyy"
                              maxDate={new Date().setFullYear(new Date().getFullYear() + 2)}
                              key={"start_date"}
                              value={formik.values.start_date}
                              minDate={new Date()}
                              onChange={(val) => formik.setFieldValue("start_date", val)}
                              error={formik.errors.start_date}
                              touched={formik.touched.start_date}
                            />
                          </Col>
                        </Box>
                      </List>
                    </Col>
                  </Row>
                  <Divider className="mt-4"></Divider>
                </Container>
                <Container fluid className="mt-4 ">
                  <Typography className="my-4">Publisher Information</Typography>
                  <Row>
                    <Col lg="4">
                      <List sx={{ padding: 0 }}>
                        <Box>
                          <Typography variant="body1Regular" sx={styles.heading}>
                            {"Creation Date".toUpperCase()}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
                          <Col lg="12" className="dateFieldContainer">
                            <AppDatePicker
                              key="creation_date"
                              disabled
                              value={formik.values.creation_date}
                              onChange={(val) => formik.setFieldValue("creation_date", val)}
                              error={formik.errors.creation_date}
                              touched={formik.touched.creation_date}
                            />
                          </Col>
                        </Box>
                      </List>
                    </Col>
                    <Col lg="4">
                      <AppTextField
                        id="created_by"
                        name="created_by"
                        fullWidth
                        label="Created By"
                        isDisabled={true}
                        placeholder="Enter Created By"
                        value={formik.values.created_by}
                        onChange={formik.handleChange}
                        error={formik.touched.created_by && Boolean(formik.errors.created_by)}
                        helperText={formik.touched.created_by && formik.errors.created_by}
                      />
                    </Col>
                    {!isIncorrectQuestionTrivia ? (
                      <Col lg="4">
                        <AppSelect
                          id="published_week_day"
                          name="published_week_day"
                          menuItems={weekDaysChoices}
                          label="Publish Weekly On"
                          disabled={true}
                          value={formik.values.published_week_day}
                          onChange={formik.handleChange}
                          error={formik.errors.published_week_day}
                          touched={formik.touched.published_week_day}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <Divider className="mt-4"></Divider>
                </Container>
              </>
            ) : (
              <Container fluid>
                <Row>
                  <Col lg="4">
                    <List sx={{ padding: 0 }}>
                      <Box>
                        <Typography variant="body1Regular" sx={styles.heading}>
                          MONTH
                        </Typography>
                        <span style={styles.required}>*</span>
                      </Box>
                      <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
                        <Col lg="6" className="me-2 dateFieldContainer">
                          <AppDatePicker
                            views={["year", "month"]}
                            inputFormat="MMM yyyy"
                            maxDate={new Date().setFullYear(new Date().getFullYear() + 2)}
                            key={"start_date"}
                            value={formik.values.start_date}
                            minDate={new Date()}
                            onChange={(val) => handleTriviaDuration(formik, val)}
                            error={formik.errors.start_date}
                            touched={formik.touched.start_date}
                          />
                        </Col>
                      </Box>
                    </List>
                  </Col>
                </Row>
                <Row>
                  {formik.values.start_date && incorrectTriviaDuration && (
                    <>
                      <Typography className="my-4">Publisher Information</Typography>
                      <Col lg="4">
                        <List sx={{ padding: 0 }}>
                          <Box>
                            <Typography variant="body1Regular" sx={styles.heading}>
                              {"Start Date".toUpperCase()}
                              <Tooltip
                                title={tootTileString(
                                  "The start date for incorrect trivia is the date when the raffle ends."
                                )}>
                                <InfoIcon className="ms-1" fontSize="small"></InfoIcon>
                              </Tooltip>
                            </Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", marginTop: "8px" }}
                            className="datesContainer">
                            <Col lg="12" className="dateFieldContainer">
                              <Typography variant="body1Regular">
                                {convertDate(
                                  incorrectTriviaDuration.start_date,
                                  incorrectTriviaDuration.start_time
                                )}
                              </Typography>
                            </Col>
                          </Box>
                        </List>
                      </Col>
                      <Col lg="4">
                        <List sx={{ padding: 0 }}>
                          <Box>
                            <Typography variant="body1Regular" sx={styles.heading}>
                              {"Start Time".toUpperCase()}
                              <Tooltip
                                title={tootTileString(
                                  "The start time for incorrect trivia is the time when the raffle ends."
                                )}>
                                <InfoIcon className="ms-1" fontSize="small"></InfoIcon>
                              </Tooltip>
                            </Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", marginTop: "8px" }}
                            className="datesContainer">
                            <Col lg="12" className="dateFieldContainer">
                              <Typography variant="body1Regular">
                                {formatTime(
                                  incorrectTriviaDuration.start_date,
                                  incorrectTriviaDuration.start_time
                                )}
                              </Typography>
                            </Col>
                          </Box>
                        </List>
                      </Col>
                      <Col lg="4">
                        <List sx={{ padding: 0 }}>
                          <Box>
                            <Typography variant="body1Regular" sx={styles.heading}>
                              {"End Date".toUpperCase()}
                              <Tooltip
                                title={tootTileString(
                                  "The end date of this trivia would be the first monday of the month when new trivia starts."
                                )}>
                                <InfoIcon className="ms-1" fontSize="small"></InfoIcon>
                              </Tooltip>
                            </Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", marginTop: "8px" }}
                            className="datesContainer">
                            <Col lg="12" className="dateFieldContainer">
                              <Typography variant="body1Regular">
                                {getFormattedDate(incorrectTriviaDuration.end_date)}
                              </Typography>
                            </Col>
                          </Box>
                        </List>
                      </Col>
                    </>
                  )}
                </Row>
                <Divider className="mt-4"></Divider>
              </Container>
            )}
            <Container fluid className="mt-4">
              <Row>
                <Col lg="12">
                  <AppButton onClick={() => handleSave(formik)} styles={styles.btn}>
                    Next
                  </AppButton>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        )}
      </Formik>
    </>
  )
}

export default NewContest
