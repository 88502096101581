import React from "react";
import { Typography } from "@mui/material";
function DateDisplay({ wineOfTheMonthDate, negativeResultDays, dayCell }) {
  const date =
    dayCell?.date?.getFullYear() +
    "-" +
    `${dayCell?.date?.getMonth() < 10 ? "0" : ""}` +
    (dayCell?.date?.getMonth() + 1) +
    "-" +
    `${dayCell?.date?.getDate() < 10 ? "0" : ""}` +
    dayCell?.date?.getDate();

  const styles = {
    date: {
      position: "relative",
      top: "1px"
    }
  };

  return (
    <div
      className={`date ${
        negativeResultDays?.includes(date)
          ? "negative-date"
          : wineOfTheMonthDate?.start === date
          ? "wine-date"
          : "positive-date"
      }`}>
      <Typography sx={styles.date} variant="tag1">
        {dayCell?.date?.getDate()}
      </Typography>
    </div>
  );
}

export default DateDisplay;
