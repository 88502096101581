import React, { useState } from "react"
import ClearIcon from "@mui/icons-material/Clear"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import { Col, Row } from "react-bootstrap"
import { Grid, Typography } from "@mui/material"
import styles from "../menu.module.scss"

const AddNewFields = ({ handleNewFields }) => {
  const [fields, setFields] = useState([])

  const handleAddField = () => {
    setFields([...fields, { fieldName: "" }])
  }

  const handleRemoveField = (index) => {
    const newFields = fields.filter((_, i) => i !== index)
    setFields(newFields)
  }

  const handleChange = (index, event) => {
    const newFields = fields.map((field, i) =>
      i === index ? { ...field, fieldName: event.target.value } : field
    )
    setFields(newFields)
  }

  const saveFields = (fields) => {
    handleNewFields(fields)
    setFields([])
  }

  return (
    <Grid item container mt={fields?.length ? 4 : 0}>
      {fields?.map((field, index) => (
        <Grid item container xs={12} key={index} mb={2}>
          <Grid item xs={10} alignItems="center">
            <Row>
              <Col lg="11">
                <AppTextField
                  fullWidth
                  type="text"
                  value={field.fieldName}
                  onChange={(e) => handleChange(index, e)}
                  placeholder="Enter Field Name"
                  variant="outlined"
                />
              </Col>
              <Col lg="1" style={{ display: "flex", justifyContent: "center" }}>
                <span role="button" onClick={() => handleRemoveField(index)}>
                  <ClearIcon sx={{ width: "30px", height: "48px" }} />
                </span>
              </Col>
            </Row>
          </Grid>
          {index === fields?.length - 1 && (
            <Grid item container xs={2} justifyContent="flex-end">
              <AppButton onClick={() => saveFields(fields)}>Save</AppButton>
            </Grid>
          )}
        </Grid>
      ))}
      <Grid item xs={12} mt={fields?.length ? 2 : 4}>
        <Typography variant="largeText" onClick={handleAddField} className={styles.addNewField}>
          Add New Field
        </Typography>
      </Grid>
    </Grid>
  )
}

export default AddNewFields
