import React, { Fragment } from "react";
import { TableCell, Typography } from "@mui/material";

function DailyStatsTableHeader({ tableHeaders }) {
  return (
    <Fragment>
      {tableHeaders.map((header) => (
        <TableCell
          key={header.id}
          align={header.align}
          style={{
            minWidth: header.minWidth,
            paddingLeft: header.paddingLeft,
            paddingRight: header.paddingRight,
            paddingTop: "12px",
            paddingBottom: "12px",
            borderBottom: "2px solid rgba(255, 255, 255, 0.1)",
            borderTop: "2px solid rgba(255, 255, 255, 0.1)"
          }}>
          <Typography variant="body2Regular" sx={{ opacity: "0.6" }}>
            {header.label}
          </Typography>
        </TableCell>
      ))}
    </Fragment>
  );
}

export default DailyStatsTableHeader;
