import { Box, Grid, Typography } from "@mui/material";
import Card from "components/Card/Card";
import ImageUploadField from "components/FileUpload/ImageUploadField";
import ImageCropper from "components/ImageCropper";
import AppSelect from "components/StyledComponents/AppSelect";
import AppTextArea from "components/StyledComponents/AppTextArea";
import AppTextField from "components/StyledComponents/AppTextField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadBenefitsImageAction } from "redux/DMV/actions/user";
import { DMVActions } from "redux/DMV/slice/user";

const BenefitsForm = ({ index, formik, arrayHelper }) => {
  const [selectedMemberShips, setSelectedMemberships] = React.useState("");
  const dispatch = useDispatch();
  const [openCrop, setCrop] = useState(false);

  const [fileDetails, setFileDetails] = useState(null);
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const onMembershipSelected = (e) => {
    setSelectedMemberships(e.target.value);
    formik.setFieldValue(`benefitsSet[${index}].memberships`, e.target.value);
  };

  const handleImageUpload = (image) => {

    if (image) {
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image));
    }

  };

  const handleImageDelete = () => {
    dispatch(DMVActions.setbenefitImage({ index, id: null }));
    formik.setFieldValue(`benefitsSet[${index}].icon`, null);
  };

  const deleteBenefit = () => {
    arrayHelper.remove(index);
    dispatch(DMVActions.setbenefitImage({ index, id: null }));
  };

  function handleCroppedImage(image,formik) {

    let data = new FormData();
    data.append("media", image);
    data.append("media_type", "IMAGE");
    data.append("content_type", image.type);
    dispatch(
      uploadBenefitsImageAction({
        index,
        data
      })
    );
    formik.setFieldValue(`benefitsSet[${index}].icon`, image);
  }


  function showCropperModal(imageObjectURL, fileDetails) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image,formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={0}
        onClose={setCrop}
        openCrop={openCrop}
      >
      </ImageCropper>
    )
  }

  return (
    <Card className="mb-4">
      {showCropperModal(imageObjectURL,fileDetails)}
      <Box className="d-flex justify-content-between">
        <Typography variant="h2">{`Benefit ${index + 1}`}</Typography>
        {formik?.values?.benefitsSet?.length > 1 ? (
          <Typography
            role="button"
            onClick={deleteBenefit}
            color="error"
            className="body1Regular">
            Delete
          </Typography>
        ) : null}
      </Box>
      <Grid container className="mt-0" spacing={4}>
        <Grid item sm={12} md={6} lg={3}>
          <AppTextField
            placeholder="Enter Benefit Name"
            label="Benefit Name"
            isRequired={true}
            fullWidth
            value={formik.values?.benefitsSet?.[index]?.name}
            onChange={formik.handleChange(`benefitsSet[${index}].name`)}
            error={
              formik.touched.benefitsSet?.[index]?.name &&
              Boolean(formik.errors?.benefitsSet?.[index]?.name)
            }
            helperText={
              formik.touched.benefitsSet?.[index]?.name &&
              formik.errors.benefitsSet?.[index]?.name
            }
          />
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <AppTextField
            placeholder="Enter Benefit Details"
            label="Benefit Details"
            isRequired={true}
            fullWidth
            value={formik.values?.benefitsSet?.[index]?.description}
            onChange={formik.handleChange(`benefitsSet[${index}].description`)}
            error={
              formik.touched.benefitsSet?.[index]?.description &&
              Boolean(formik.errors?.benefitsSet?.[index]?.description)
            }
            helperText={
              formik.touched.benefitsSet?.[index]?.description &&
              formik.errors.benefitsSet?.[index]?.description
            }
          />
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <ImageUploadField
            label="Benefit Icon"
            isRequired={true}
            placeholder="Upload Icon"
            value={formik.values?.benefitsSet?.[index]?.icon}
            onChange={handleImageUpload}
            onDelete={handleImageDelete}
            error={formik.errors.benefitsSet?.[index]?.icon}
            touched={formik.touched.benefitsSet?.[index]?.icon}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <AppSelect
            label="Memberships"
            isRequired={true}
            menuItems={membershipList}
            value={selectedMemberShips}
            onChange={onMembershipSelected}
            error={formik.errors?.benefitsSet?.[index]?.memberships}
            touched={formik.touched?.benefitsSet?.[index]?.memberships}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <AppTextArea
            label="Tooltip Details"
            placeholder="Enter Benefit Tooltip"
            value={formik.values?.benefitsSet?.[index]?.tooltip}
            onChange={formik.handleChange(`benefitsSet[${index}].tooltip`)}
            error={formik.errors?.benefitsSet?.[index]?.tooltip}
            touched={formik.touched?.benefitsSet?.[index]?.tooltip}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default BenefitsForm;

const membershipList = [
  {
    label: "1 bottle",
    value: 1
  },
  {
    label: "2 bottle",
    value: 2
  },
  {
    label: "3 bottle",
    value: 3
  }
];
