import SubButtonNav from "components/SubMenuNavButton"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "react-bootstrap"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import { DMVActions } from "redux/DMV/slice/user"
import { getDMVSpiritSubMenuistAction, getSpiritAction } from "redux/DMV/actions/user"
import { getArchivedSpiritCMSAction, getSpiritCMSAction } from "redux/marketing/actions/user"
import classes from "pages/private/DVM_Console/DMV_Menu/dmvmenu.module.scss"
import cms_classes from "pages/private/marketing/menu/menu.module.scss"

function SpiritsSubMenuBar(props) {
  const [menu, setMenu] = useState([])
  const dispatch = useDispatch()

  const { menuCategory, SpiritSubMenu } = useSelector((state) => state.dmv)

  const handleChange = (event) => {
    dispatch(DMVActions.setSubFilterValueView(event.target.value))
  }

  useEffect(() => {
    const param = props?.isCMS ? "marketing" : "dmv"
    dispatch(getDMVSpiritSubMenuistAction(param))
  }, [])

  useEffect(() => {
    let menuNav = []
    if (SpiritSubMenu?.length > 0) {
      menuNav = SpiritSubMenu?.map((item, index) => ({
        isActive: index === 0 ? true : false,
        name: item?.name,
        value: item?.id,
        spirit_count: props?.isCMS ? item?.marketing_spirit_count : item?.dmv_spirit_count
      }))
    }
    setMenu(menuNav)
  }, [SpiritSubMenu])

  useEffect(() => {
    if (!props?.isArchived) {
      if (props?.isCMS) {
        dispatch(getSpiritCMSAction())
      } else {
        dispatch(getSpiritAction())
      }
    }

    if (props?.isArchived) {
      const payload = {
        archive: true
      }
      dispatch(getArchivedSpiritCMSAction(payload))
    }
  }, [menuCategory])

  function handleButtonNav(name) {
    let itemsLink = {}
    if (props?.isEdit) {
      itemsLink = {
        name: name,
        list: "Detailed View"
      }
    } else {
      itemsLink = {
        name: name,
        list: "Summary View"
      }
    }
    dispatch(DMVActions.setSubFilterValue(itemsLink))
  }

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <SubButtonNav
            style={{ marginRight: 12, position: "relative" }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
            {props.activeTab === item.value && !props?.isArchived ? (
              <div className={props?.isCMS ? cms_classes?.spirit_count : classes?.spirit_count}>
                {item?.spirit_count > 100 ? "+99" : item?.spirit_count}
              </div>
            ) : null}
          </SubButtonNav>
        )
      })}
      <Row className="mt-4">
        <Col lg="12" style={{ display: "flex", alignItems: "center" }}>
          {props?.isEdit ? null : (
            <>
              <div className={props?.isCMS ? cms_classes?.total_count : classes?.total_count}>
                Total Spirits:{" "}
                {props?.isCMS
                  ? SpiritSubMenu?.[0]?.marketing_total_count
                  : SpiritSubMenu?.[0]?.dmv_total_count}{" "}
              </div>
              <FormControl variant="filled">
                <RadioGroup
                  className="d-flex flex-row"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={menuCategory?.list}
                  onChange={(e) => {
                    handleChange(e)
                  }}>
                  <FormControlLabel
                    value="Summary View"
                    control={<Radio color="secondary" />}
                    label="Summary View"
                  />
                  <FormControlLabel
                    value="Detailed View"
                    control={<Radio color="secondary" />}
                    label="Detailed View"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

export default SpiritsSubMenuBar
