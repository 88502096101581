import React, { useEffect, useState } from "react"
import { Typography, Grid, IconButton } from "@mui/material"
import { Formik } from "formik"
import styles from "./login.module.scss"
import AppTextField from "components/StyledComponents/AppTextField"

import AppButton from "components/StyledComponents/AppButton"
import Visibility from "assets/icons/VisibilityIcon.svg"
import VisibilityOff from "assets/icons/VisibilityOffIcon.svg"
// import DMVLogo from "assets/icons/DMVLogo.svg"
import VantaLogo from "assets/icons/VantaLogo.svg"
import { validationSchemaLogin } from "helpers/validationSchema"
import CheckBox from "components/CheckBox"
import { useDispatch, useSelector } from "react-redux"
import { loginAction } from "redux/L&D/actions/index"
import { Redirect, useLocation } from "react-router-dom"
import { appActions } from "redux/app/slice/app"
import moment from "moment"
import CryptoJS from "crypto-js"

const secretPass = "XkhZG4fW2t2W"
const encryptData = (stringData) => {
  const data = CryptoJS.AES.encrypt(JSON.stringify(stringData), secretPass).toString()

  return data
}

const decryptData = (stringData) => {
  const bytes = CryptoJS.AES.decrypt(stringData, secretPass)
  const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return data
}

const Login = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [initForm, setInitForm] = useState({})
  const [isVisible, setVisible] = useState(false)
  const isLoggState = useSelector((res) => res?.app?.isLoggedIn)
  const cookieValues = getCookie("username") ? JSON.parse(getCookie("username")) : {}

  const userLogin = (e) => {
    const date = moment().add(10, "days").toDate()

    if (e.rememberMe) {
      document.cookie = `username=${JSON.stringify({
        email: e.email,
        password: encryptData(e.password),
        rememberMe: e.rememberMe
      })}; path=/; expires=${date.toUTCString()}`
    } else {
      document.cookie = `username=${JSON.stringify({
        rememberMe: false
      })}; path=/;expires=${date.toUTCString()}`
    }

    dispatch(
      loginAction({
        email: e.email,
        password: e.password
      })
    )
    dispatch(appActions.setIsLoading(false))
  }

  function initFormValues() {
    setInitForm({
      email: cookieValues.email,
      password: decryptData(cookieValues.password),
      rememberMe: cookieValues.rememberMe
    })
  }

  useEffect(() => {
    if (cookieValues.rememberMe) {
      initFormValues()
    }
  }, [])

  useEffect(() => {}, [cookieValues])

  function getCookie(cname) {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  if (isLoggState) {
    return <Redirect to={location?.state?.from} />
  }

  return (
    <section className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <Typography className={styles.heading} variant="h2">
          Login to your account
        </Typography>
        <Formik
          initialValues={initForm}
          enableReinitialize={true}
          validationSchema={validationSchemaLogin}
          onSubmit={userLogin}>
          {(formik) => (
            <React.Fragment>
              <Grid item xs={12}>
                <AppTextField
                  id="email"
                  isUpperCase={true}
                  fullWidth
                  label="Email"
                  isRequired={true}
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextField
                  id="password"
                  fullWidth
                  isUpperCase={true}
                  type="password"
                  label="Password"
                  style={{ marginTop: 35 }}
                  isRequired={true}
                  placeholder="Enter Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    type: isVisible ? "text" : "password",
                    disableUnderline: true,
                    endAdornment: (
                      <IconButton
                        style={{ margin: 0 }}
                        aria-label="toggle password visibility"
                        onClick={() => setVisible(!isVisible)}
                        edge="end"
                        className={styles.imageSrc}>
                        {isVisible ? (
                          <img src={Visibility} alt="alt" />
                        ) : (
                          <img src={VisibilityOff} alt="alt" />
                        )}
                      </IconButton>
                    )
                  }}
                />
              </Grid>
              <Grid
                container
                className={styles.extrabuttons}
                justifyContent="space-between"
                alignItems="center">
                <button
                  className={styles.rememberMe}
                  onClick={() => formik.setFieldValue("rememberMe", !formik.values.rememberMe)}>
                  <CheckBox checked={formik.values.rememberMe} />
                  <Typography variant="body2Regular">Remember Me</Typography>
                </button>
                {/* <button className={styles.forgotPassword}>
                  <Typography
                    sx={(theme) => ({ color: theme.palette.secondary.main })}
                    variant="body2Regular">
                    Forgot your password?
                  </Typography>
                </button> */}
              </Grid>

              <Grid item xs={12}>
                <AppButton
                  className={styles.btnSubmit}
                  variant="outlined"
                  onClick={formik.handleSubmit}>
                  Log In
                </AppButton>
              </Grid>
            </React.Fragment>
          )}
        </Formik>
      </div>
      <footer className={styles.footer}>
        <img src={VantaLogo} alt="" />
        <Typography variant="body1Regular">Gamification Platform Powered by DM Ventures</Typography>
      </footer>
    </section>
  )
}

export default Login
