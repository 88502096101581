import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import EventListTable from "./EventListTable"
import { useDispatch, useSelector } from "react-redux"
import { getEventDataAction } from "redux/superAdmin/actions"

const EventManagement = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const { eventList } = useSelector((store) => store?.superAdmin || [])
  const { orderBy } = useSelector((store) => store?.app?.filters || "")
  const history = useHistory()
  const dispatch = useDispatch()

  const itemsPerPage = 12
  const totalPages = Math.ceil(eventList?.length / itemsPerPage)

  const paginatedList = eventList?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Event Management"
    dispatch(getEventDataAction({ orderBy: orderBy }))
  }, [orderBy])

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Event Listing" breadCrumbs={breadCrumbs}>
            <AppButton
              onClick={() => history.push("/super-admin/dashboard/event-listing/add-new-event")}
              sx={{ padding: "13px 80px" }}>
              + Add New Event
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col lg="12">
            <EventListTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={totalPages}
              rows={paginatedList}
              columns={columns}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default EventManagement
const columns = [
  {
    id: "id",
    label: "Sq.no",
    minWidth: 100,
    align: "center"
  },
  {
    id: "Event Name",
    label: "Event Name",
    minWidth: 200
  },
  {
    id: "Event Date and Time",
    label: "Event Date and Time",
    align: "center",
    isSorting: true,
    minWidth: 200
  },
  {
    id: "Event End Time",
    label: "Event End Time",
    align: "center",
    minWidth: 145
  },
  {
    id: "Event type",
    label: "Event type",
    align: "center",
    minWidth: 130
  },
  {
    id: "Deadline for RSVP (Date and Time)",
    label: "Deadline for RSVP (Date and Time)",
    align: "center",
    minWidth: 260
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 100
  }
]
