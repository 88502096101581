import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import styles from "./attendantsTable.module.scss";
import CheckBox from "components/CheckBox";

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:nth-of-type(1)": {
      position: "sticky",
      background: theme.palette.background.main,
      left: 0
    },
    "&:nth-of-type(2)": {
      position: "sticky",
      background: theme.palette.background.main,
      left: 48
    },
    "&:nth-of-type(3)": {
      background:
        "linear-gradient(to left, rgb(15, 18, 20,0), rgb(15, 18, 20,1) 30%)",
      position: "sticky",
      left: 100
    },
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    },
    "&:nth-last-of-type(2)": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:nth-of-type(1)": {
      position: "sticky",
      background: theme.palette.background.main,
      left: 0,
      zIndex: 10
    },
    "&:nth-of-type(2)": {
      position: "sticky",
      background: theme.palette.background.main,
      left: 48,
      zIndex: 10
    },
    "&:nth-of-type(3)": {
      background: theme.palette.background.main,
      position: "sticky",
      left: 100,
      zIndex: 10
    },
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const TableFooter = styled(TableRow)(() => ({
  backgroundColor: "#1c1f21",
  borderRadius: 6,
  zIndex: 20,
  position: "sticky",
  bottom: 0,
  marginBottom: 10,
  "& .MuiTableCell-root": {
    backgroundColor: "#1c1f21",
    "&:first-of-type": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "&:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6
    }
  }
}));

const AttendantsTable = ({ columns = [], rows = [] }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ height: 24, width: 24 }}>
                <CheckBox role="button" />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                    {column.icon && (
                      <img
                        className={styles.columnIcon}
                        src={column.icon}
                        alt=""
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    <CheckBox role="button" />
                  </TableCell>
                  <TableCell>
                    <div
                      className={
                        row.rank < 5
                          ? styles.rank
                          : row.rank >= 5 && row.rank <= 7
                          ? styles.rankMid
                          : row.rank > 7
                          ? styles.rankLow
                          : "-"
                      }>
                      {row.rank}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={`${styles.tableAvatar}`}>
                      <div
                        className={`${styles.tableAvatar__img} ${
                          row.rank === 1 && styles.borderImg
                        }`}>
                        <img src={row.image} />
                      </div>
                      <div className="d-flex flex-column">
                        <Typography variant="body2Regular">
                          {row.name}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.lostOpportunity}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.hasApp}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.bottles}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.percentOfTotal + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.nonMembers}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.inviteRate + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.invites}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.wineClubMembers}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.nonWCM}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.upgradeRate + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.performanceGap}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.membershipOpp != 0 ? row.membershipOpp : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.membershipAnnualized != 0
                        ? row.membershipAnnualized
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.tenure}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.beverageAverageCheck}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.wineAverageCheck}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.totalAverageCheck}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableFooter>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.inviteRate, 0)}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.wineClubMembers, 0)}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.membershipOpp, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.membershipAnnualized, 0)}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableFooter>
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  );
};

export default AttendantsTable;
