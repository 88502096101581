import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import styles from "./excluded.module.scss";

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    borderBottom: `2px solid #272a2c`,
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const ExcludedTable = ({ columns = [], rows = [] }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                    {column.icon && (
                      <img
                        className={styles.columnIcon}
                        src={column.icon}
                        alt=""
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <div
                      className={row.rank < 3 ? styles.rank : styles.rankDown}>
                      {row.rank}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={`${styles.tableAvatar}`}>
                      <div
                        className={`${styles.tableAvatar__img} ${
                          row.rank === 1 && styles.borderImg
                        }`}>
                        <img src={row.image} />
                      </div>
                      <div className="d-flex flex-column">
                        <Typography variant="body2Regular">
                          {row.name}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Typography align="center" variant="body2Regular">
                      {row.inviteRate + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.invites}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">{row.nonWCM}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  );
};

export default ExcludedTable;
