import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton, Grid } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import { useDispatch } from "react-redux"
import { uploadMenuItemAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"
import StyledLabel from "components/StyledComponents/StyledLabel"
import AppDatePicker from "components/AppDatePicker"
import AppAutocomplete from "components/AppAutocomplete"

const buttonText = {
  EDIT: "Update Item",
  SAVE: "Save Item"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

const BeerMenuItemForm = ({ formik, open = false, onClose = () => {}, isEdit = false, title }) => {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      beerName: "",
      uploadThumbnail: "",
      style: "",
      abv: null,
      color: "",
      brewery: "",
      region: "",
      testingNotes: [],
      itemPrice: null
    })
    dispatch(marketingActions.setMenuImageId(""))
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadMenuItemAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        {showCropperModal(imageObjectURL, fileDetails, formik)}
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2">
            {isEdit ? "Edit" : "Add New"} {title}
          </Typography>
        </Box>
        <Box flexDirection="column" className="mt-4">
          <React.Fragment>
            <Box className=" w-100 py-2">
              <Row className="w-100 m-0">
                <Col lg="6">
                  <AppTextField
                    id="beerName"
                    name="beerName"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Beer Name"
                    isRequired={true}
                    placeholder="Enter Beer Name"
                    value={formik.values.beerName}
                    onChange={formik.handleChange}
                    error={formik.touched.beerName && Boolean(formik.errors.beerName)}
                    helperText={formik.touched.beerName && formik.errors.beerName}
                  />
                </Col>
                <Col lg="6">
                  <ImageUploadField
                    label="Upload Thumbnail"
                    isVariant={true}
                    placeholder="Upload Image"
                    value={formik.values.uploadThumbnail}
                    onChange={(e) => onImageUpload(e)}
                    onDelete={() => handleImageDelete(formik)}
                  />
                </Col>
              </Row>

              <Row className="w-100 m-0 mt-4">
                <Col lg="4">
                  <AppTextField
                    id="style"
                    name="style"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Style"
                    placeholder="Enter Style"
                    value={formik.values.style}
                    onChange={formik.handleChange}
                    error={formik.touched.style && Boolean(formik.errors.style)}
                    helperText={formik.errors.style}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="abv"
                    name="abv"
                    isUpperCase={!false}
                    type="number"
                    fullWidth
                    label="ABV"
                    placeholder="Enter ABV"
                    value={formik.values.abv}
                    onChange={formik.handleChange}
                    error={formik.touched.abv && Boolean(formik.errors.abv)}
                    helperText={formik.errors.abv}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="color"
                    name="color"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Color"
                    placeholder="Enter Color"
                    value={formik.values.color}
                    onChange={formik.handleChange}
                    error={formik.touched.color && Boolean(formik.errors.color)}
                    helperText={formik.errors.color}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="4">
                  <AppTextField
                    id="region"
                    name="region"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Region"
                    placeholder="Enter region"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    error={formik.touched.region && Boolean(formik.errors.region)}
                    helperText={formik.errors.region}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="brewery"
                    name="brewery"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Brewery"
                    placeholder="Enter Brewery"
                    value={formik.values.brewery}
                    onChange={formik.handleChange}
                    error={formik.touched.brewery && Boolean(formik.errors.brewery)}
                    helperText={formik.errors.brewery}
                  />
                </Col>
                <Col lg="4">
                  <AppTextField
                    id="itemPrice"
                    name="itemPrice"
                    isUpperCase={!false}
                    type="number"
                    fullWidth
                    label="Price"
                    placeholder="Enter Price"
                    value={formik.values.itemPrice}
                    onChange={formik.handleChange}
                    isNumber={true}
                    error={formik.touched.itemPrice && Boolean(formik.errors.itemPrice)}
                    helperText={formik.errors.itemPrice}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppAutocomplete
                    id="testingNotes"
                    placeholder="Enter Tasting Notes"
                    label={"Tasting Notes"}
                    isUpperCase={true}
                    value={formik.values.testingNotes}
                    onChange={(event, newValue) =>
                      event.target.value
                        ? event.target.value.trim() !== ""
                          ? formik.setFieldValue("testingNotes", newValue)
                          : ""
                        : formik.setFieldValue("testingNotes", newValue)
                    }></AppAutocomplete>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6">
                  <Grid item sm={12} lg={6} className="datesContainer">
                    <StyledLabel variant="body1Regular">
                      {"Schedule Notification Date"}
                      <span>*</span>
                    </StyledLabel>
                    <Box className="dateFieldContainer w-100 mt-2">
                      <AppDatePicker
                        minDate={new Date()}
                        maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                        inputFormat="dd MMM yyyy"
                        key={"scheduleNotificationDate"}
                        value={formik.values.scheduleNotificationDate}
                        onChange={(val) => formik.setFieldValue("scheduleNotificationDate", val)}
                        error={formik.errors.scheduleNotificationDate}
                        touched={formik.touched.scheduleNotificationDate}
                      />
                    </Box>
                  </Grid>
                </Col>
                <Col lg="6">
                  <AppTextField
                    type="time"
                    fullWidth
                    label="Schedule Notification Time"
                    isRequired={true}
                    isUpperCase={true}
                    value={formik.values.scheduleNotificationTime}
                    onChange={formik.handleChange("scheduleNotificationTime")}
                    error={
                      formik.touched.scheduleNotificationTime &&
                      Boolean(formik.errors.scheduleNotificationTime)
                    }
                    helperText={
                      formik.touched.scheduleNotificationTime &&
                      formik.errors.scheduleNotificationTime
                    }
                  />
                </Col>
              </Row>
            </Box>
          </React.Fragment>
        </Box>
        <Box className="mt-5 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default BeerMenuItemForm
