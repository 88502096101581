import React, { useState } from "react";
import { FormControl, Grid } from "@mui/material";
import { useFormik } from "formik";
import { validationSchemaCSV } from "helpers/validationSchema";
import AppButton from "components/StyledComponents/AppButton";
import DragAndDropUser from "./DragAndDropUser";
import { useDispatch } from 'react-redux';
import { uploadHolidayListAction } from "redux/DMV/actions";

function UploadTabUser({ onClose }) {
  const [allFilesSelected, setAllFilesSelected] = useState({
    csvUploaded: false
  });
  const dispatch = useDispatch();
  const [isDisabled, setDisable] = useState(false);

  const styles = {
    container: {
      marginTop: "5px"
    },
    uploadBtnContainer: {
      padding: "12px 79px"
    }
  };



  const formik = useFormik({
    initialValues: {
      csvUrl: ""
    },
    validationSchema: validationSchemaCSV,

    onSubmit: values => {
      dispatch(uploadHolidayListAction(values.csvUrl))
      onClose()
    }
  });


  return (
    <FormControl className="mt-4" style={{ width: "100%" }}>
      <Grid container sx={styles.container}>

        {/* Drag and Drop PDF */}
        <Grid item sm={12}>
          <DragAndDropUser
                  setDisable={setDisable}
            type="CSV"
            setAllFilesSelected={setAllFilesSelected}
            onChange={(url) => formik.setFieldValue("csvUrl", url)}
          />
        </Grid>

        {/* Upload Button */}
        {allFilesSelected.csvUploaded && (
          <Grid
            item
            sm={12}
            className="d-flex justify-content-center align-items-center"
            style={{ paddingTop: "0px" }}>
            {isDisabled &&
              <AppButton
                onClick={formik.handleSubmit}
                className={styles.uploadBtn}>
                Upload
              </AppButton>
            }
          </Grid>
        )}
      </Grid>
    </FormControl>
  );
}

export default UploadTabUser;
