import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import { useDispatch, useSelector } from "react-redux"
import { uploadMenuItemAction } from "redux/marketing/actions"
import { Formik } from "formik"
import AppSelect from "components/StyledComponents/AppSelect"
import { marketingActions } from "redux/marketing/slice/user"
import { addDMVSpiritSubMenuistAction, getDMVSpiritSummaryAction } from "redux/DMV/actions/user"
import { validationSchemaSpiritSummary } from "helpers/validationSchema"
import AppTextField from "components/StyledComponents/AppTextField"
import AddUniqueFact from "./components/AddUniqueFact"
import AddIngrediant from "./components/AddIngrediant"
import { DMVActions } from "redux/DMV/slice/user"

const buttonText = {
  EDIT: "Update Item",
  SAVE: "Save Item"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

function AddSummary({ open = false, onClose = () => {}, title, isEdit, categoryList }) {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)
  const { menuItemImageId } = useSelector((store) => store?.marketing?.menu ?? {})
  const { spiritSummary } = useSelector((state) => state.dmv)

  const dispatch = useDispatch()

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const setInitialValue = {
    category: spiritSummary?.id || "",
    description: spiritSummary?.description || "",
    uploadThumbnail: spiritSummary?.image_url
      ? {
          id: "",
          url: spiritSummary?.image_url ?? ""
        }
      : "",
    ingrediants:
      spiritSummary?.ingredient?.length > 0
        ? spiritSummary?.ingredient
        : [{ name: "", measurement: "" }],
    unique_fact:
      spiritSummary?.unique_facts?.length > 0 ? spiritSummary?.unique_facts : [{ fact: "" }],
    origin_name: spiritSummary?.origin || "",
    nose: spiritSummary?.nose || "",
    palate: spiritSummary?.palate || "",
    finish: spiritSummary?.finish || "",
    distillation: spiritSummary?.distillation || "",
    aging: spiritSummary?.aging || "",
    bottling: spiritSummary?.bottling || ""
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadMenuItemAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  const handleSubmitSummary = (values) => {
    const payload = {
      category: values?.category,
      description: values?.description,
      ingredient: values?.ingrediants,
      unique_facts: values?.unique_fact,
      origin: values?.origin_name,
      nose: values?.nose,
      palate: values?.palate,
      finish: values?.finish,
      aging: values?.aging,
      distillation: values?.distillation,
      bottling: values?.bottling,
      ...(menuItemImageId
        ? { image: menuItemImageId }
        : values.uploadThumbnail
        ? {}
        : { image: "" })
    }
    dispatch(addDMVSpiritSubMenuistAction(payload))
    dispatch(marketingActions.setMenuImageId(""))
    dispatch(DMVActions.setSpiritSummary({}))
    onClose()
  }

  useEffect(() => {
    dispatch(marketingActions.setMenuImageId(""))
    dispatch(DMVActions.setSpiritSummary({}))
  }, [open])

  const handleSpiritData = (formik) => (event) => {
    formik.handleChange(event) // Pass the event to handleChange
    const category = event?.target?.value
    dispatch(getDMVSpiritSummaryAction(category))
  }

  return (
    <StyledDialog open={open} maxWidth="md">
      <Formik
        initialValues={setInitialValue}
        enableReinitialize={true}
        validationSchema={validationSchemaSpiritSummary}
        onSubmit={handleSubmitSummary}>
        {(formik) => (
          <DialogContent>
            {showCropperModal(imageObjectURL, fileDetails, formik)}
            <Box className="d-flex justify-content-end">
              <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
                <CloseIcon sx={{ height: "14px", width: "14px" }} />
              </IconButton>
            </Box>
            <Box className="w-100 d-flex justify-content-center mb-2">
              <Typography variant="h2">
                {" "}
                {/* {isEdit ? "Edit" : "Add New"} */}
                {title}
              </Typography>
            </Box>
            <Box flexDirection="column" className="mt-4">
              <Box className=" w-100 py-2">
                <Row className="w-100 m-0">
                  <Col lg="6">
                    <AppSelect
                      id="category"
                      name="category"
                      label="Category"
                      menuItems={categoryList}
                      value={formik.values.category}
                      onChange={handleSpiritData(formik)}
                      isRequired={true}
                      error={formik.errors.category}
                      touched={formik.touched.category}
                    />
                  </Col>
                  <Col lg="6">
                    <ImageUploadField
                      label="Upload Thumbnail"
                      isVariant={true}
                      placeholder="Upload Image"
                      value={formik.values.uploadThumbnail}
                      onChange={(e) => onImageUpload(e)}
                      onDelete={() => handleImageDelete(formik)}
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="description"
                      name="description"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="description"
                      placeholder="Enter description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                    />
                  </Col>
                </Row>
                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="origin_name"
                      name="origin_name"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Region Name"
                      placeholder="Enter Region Name"
                      value={formik.values.origin_name}
                      onChange={formik.handleChange}
                      error={formik.touched.origin_name && Boolean(formik.errors.origin_name)}
                      helperText={formik.errors.origin_name}
                    />
                  </Col>
                </Row>
                <AddUniqueFact
                  formik={formik}
                  values={formik.values.unique_fact}
                  name="unique_fact"
                  buttonText="+ Add Unique Fact"
                  label="Unique Fact"
                />
                <AddIngrediant
                  formik={formik}
                  values={formik.values.ingrediants}
                  name="ingrediants"
                  buttonText="+ Add Ingredient"
                  label="Ingredients"
                />
                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="nose"
                      name="nose"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Nose"
                      placeholder="Enter Nose"
                      value={formik.values.nose}
                      onChange={formik.handleChange}
                      error={formik.touched.nose && Boolean(formik.errors.nose)}
                      helperText={formik.errors.nose}
                    />
                  </Col>
                </Row>

                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="palate"
                      name="palate"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Palate"
                      placeholder="Enter Palate"
                      value={formik.values.palate}
                      onChange={formik.handleChange}
                      error={formik.touched.palate && Boolean(formik.errors.palate)}
                      helperText={formik.errors.palate}
                    />
                  </Col>
                </Row>

                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="finish"
                      name="finish"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Finish"
                      placeholder="Enter Finish"
                      value={formik.values.finish}
                      onChange={formik.handleChange}
                      error={formik.touched.finish && Boolean(formik.errors.finish)}
                      helperText={formik.errors.finish}
                    />
                  </Col>
                </Row>

                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="distillation"
                      name="distillation"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Distillation"
                      placeholder="Enter Distillation"
                      value={formik.values.distillation}
                      onChange={formik.handleChange}
                      error={formik.touched.distillation && Boolean(formik.errors.distillation)}
                      helperText={formik.errors.distillation}
                    />
                  </Col>
                </Row>

                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="aging"
                      name="aging"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Aging"
                      placeholder="Enter Aging"
                      value={formik.values.aging}
                      onChange={formik.handleChange}
                      error={formik.touched.aging && Boolean(formik.errors.aging)}
                      helperText={formik.errors.aging}
                    />
                  </Col>
                </Row>

                <Row className="w-100 m-0 mt-4">
                  <Col lg="12">
                    <AppTextField
                      id="bottling"
                      name="bottling"
                      isUpperCase={!false}
                      type="text"
                      fullWidth
                      label="Bottling"
                      placeholder="Enter Bottling"
                      value={formik.values.bottling}
                      onChange={formik.handleChange}
                      error={formik.touched.bottling && Boolean(formik.errors.bottling)}
                      helperText={formik.errors.bottling}
                    />
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box className="mt-5 d-flex justify-content-around">
              <AppButton
                onClick={() => onClose()}
                sx={() => ({
                  height: "48px",
                  padding: "10px 50px"
                })}>
                Cancel
              </AppButton>
              <AppButton
                onClick={formik.handleSubmit}
                sx={() => ({
                  height: "48px",
                  padding: "10px 50px"
                })}>
                {isEdit ? buttonText.EDIT : buttonText.SAVE}
              </AppButton>
            </Box>
          </DialogContent>
        )}
      </Formik>
    </StyledDialog>
  )
}

export default AddSummary
