import { styled, Typography } from "@mui/material";
import Card from "components/Card/Card";
import React from "react";

const StyledCard = styled(Card)(() => ({
  textAlign: "center",
  paddingTop: 9,
  paddingBottom: 9,
  marginBottom: 4
}));

const TitleCard = ({ children }) => {
  return (
    <StyledCard className="text-align-center">
      <Typography variant="h2">{children}</Typography>
    </StyledCard>
  );
};

export default TitleCard;
