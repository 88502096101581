import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AppTextField from "components/StyledComponents/AppTextField";
import AppDatePicker from "components/AppDatePicker";
import moment from "moment";
import AppButton from "components/StyledComponents/AppButton";
import ImageUploadField from "components/FileUpload/ImageUploadField";
import { Formik } from "formik";
import { validationSchemaPerformanceManagementVideo } from "helpers/validationSchema";
import StyledLabel from "components/StyledComponents/StyledLabel";
import {
  uploadVideoGalleryThumbnailAction,
  uploadVideoGalleryVideoAction
} from "redux/DMV/actions";
import { useDispatch, useSelector } from "react-redux";
import { DMVActions } from "redux/DMV/slice/user";
import { formatDate } from "helpers/functions";
import ImageCropper from "components/ImageCropper";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 12,
    border: "none",
    width: 740,
    padding: 35,
    boxSizing: "border-box"
  },
  "& .closeButton": {
    height: 32,
    width: 32,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.main,
    position: "absolute",
    top: 35,
    right: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .clearDate": {
    cursor: "pointer",
    color: theme.palette.secondary.default,
    textDecoration: "underline",
    textUnderlineOffset: 2
  },
  "& .datePickerTextField": {
    "& .MuiInputBase-input": {
      "-webkit-text-fill-color": `${theme.palette.text.primary} !important`
    }
  }
}));

const UploadVideo = ({ onClose, editModeData }) => {
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.app.loading);
  const isEditMode = useRef(false);
  const { thumbnail, isSubmitted } = useSelector(
    (store) => store?.dmv?.videoGallery || {}
  );
  const [initialData, setInitialData] = useState({
    title: "",
    description: "",
    videoUrl: "",
    thumbnail: null,
    week: ""
  });
  const [openCrop, setCrop] = useState(false);

  const [fileDetails, setFileDetails] = useState(null);
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const getCustomText = (date) =>
    date
      ? `${moment(date).format("DD MMM YYYY")} - ${moment(date)
        .add(7, "d")
        .format("DD MMM YYYY")}`
      : "";

  const handleSubmit = (values) => {
    const payload = {
      title: values.title,
      description: values.description,
      video_url: values.videoUrl,
      ...(thumbnail ? { thumbnail } : {})
    };
    if (values?.week) {
      payload.start_date = formatDate(values.week);
      payload.end_date = formatDate(moment(values.week).add(7, "d"));
    } else {
      payload.start_date = null;
      payload.end_date = null;
    }
    dispatch(
      uploadVideoGalleryVideoAction({
        isEditMode: isEditMode.current,
        payload
      })
    );
  };

  const onThumbnailUpload = (image) => {
    if (image) {
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image));
    }

  };

  const handleThumbnailDelete = (formik) => {
    dispatch(DMVActions.setVideoGalleryThumbnail(null));
    formik.setFieldValue("thumbnail", null);
  };

  useEffect(() => {
    dispatch(DMVActions.initVideoGallery());
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      dispatch(DMVActions.setVideoGalleryVideoSubmitted(false));
      onClose();
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (editModeData) {
      isEditMode.current = true;
      setInitialData({
        title: editModeData?.title || "",
        description: editModeData?.description || "",
        videoUrl: editModeData?.video_url || "",
        thumbnail: { id: "", url: editModeData?.thumbnail_url },
        week: editModeData?.start_date ? new Date(editModeData?.start_date) : ""
      });
    }
  }, [editModeData]);

  function handleCroppedImage(image, formik) {
    let data = new FormData();
    data.append("media", image);
    data.append("media_type", "IMAGE");
    data.append("content_type", image.type);
    dispatch(uploadVideoGalleryThumbnailAction(data));
    formik.setFieldValue(`thumbnail`, image);
    formik.setFieldTouched(`thumbnail`);
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={4 / 3}
        onClose={setCrop}
        openCrop={openCrop}
      >
      </ImageCropper>
    )
  }


  return (
    <StyledDialog open={true} maxWidth="">

      <DialogContent>
        <Box className="closeButton">
          <span role="button" onClick={onClose}>
            <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
              <CloseIcon sx={{ height: "14px", width: "14px" }} />
            </IconButton>
          </span>
        </Box>
        <Formik
          initialValues={initialData}
          enableReinitialize={true}
          validationSchema={validationSchemaPerformanceManagementVideo}
          onSubmit={handleSubmit}>
          {(formik) => (
            <Grid container className="mt-2" spacing={4}>
              {showCropperModal(imageObjectURL, fileDetails,formik)}
              <Grid item xs={6}>
                <AppTextField
                  label="Title"
                  isRequired={true}
                  fullWidth={true}
                  placeholder="Enter Title"
                  value={formik.values.title}
                  onChange={formik.handleChange("title")}
                  error={formik.touched.title && Boolean(formik.errors?.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={6}>
                <AppTextField
                  label="Description"
                  isRequired={true}
                  fullWidth={true}
                  placeholder="Enter Description"
                  value={formik.values.description}
                  onChange={formik.handleChange("description")}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors?.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <AppTextField
                  label="Video URL"
                  isRequired={true}
                  fullWidth={true}
                  placeholder="Enter Video URL"
                  value={formik.values.videoUrl}
                  onChange={formik.handleChange("videoUrl")}
                  error={
                    formik.touched.videoUrl && Boolean(formik.errors?.videoUrl)
                  }
                  helperText={formik.touched.videoUrl && formik.errors.videoUrl}
                />
              </Grid>
              <Grid item xs={6}>
                <ImageUploadField
                  label="Video Thumbnail"
                  placeholder="Upload Thumbnail"
                  isRequired={true}
                  value={formik.values.thumbnail}
                  onChange={(img) => onThumbnailUpload(img)}
                  onDelete={() => handleThumbnailDelete(formik)}
                  error={formik.errors.thumbnail}
                  touched={formik.touched.thumbnail}
                />
              </Grid>
              <Grid item xs={6} className="datesContainer">
                <StyledLabel variant="body1Regular">
                  Week (You can assign the week later)
                </StyledLabel>
                <Box className="dateFieldContainer w-100 mt-2">
                  <AppDatePicker
                    key={"week"}
                    minDate={new Date()}
                    value={formik.values.week}
                    onChange={(week) => formik.setFieldValue("week", week)}
                    renderInput={({ inputProps, InputProps, ...params }) => (
                      <TextField
                        InputProps={InputProps}
                        className="datePickerTextField"
                        inputProps={{ type: inputProps.type }}
                        value={getCustomText(formik.values.week)}
                        {...params}
                        disabled={true}
                        placeholder="Select Week"
                      />
                    )}
                    error={formik.errors.week}
                    touched={formik.touched.week}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="h-100 d-flex align-items-end pb-2 mb-1">
                  <Typography
                    variant="body1Regular"
                    className="clearDate"
                    onClick={() => formik.setFieldValue("week", "")}>
                    Clear Date
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="w-100 d-flex justify-content-center mt-4">
                  <AppButton
                    onClick={formik.handleSubmit}
                    disabled={loading}
                    styles={{ borderColor: "#4FACE9", padding: "12px 69px" }}>
                    {loading ? (
                      <Box className="d-flex">
                        <CircularProgress
                          size={20}
                          sx={{ color: "#FFFFFF", marginRight: "5px" }}
                        />
                        <span>Please Wait...</span>
                      </Box>
                    ) : (
                      "Upload"
                    )}
                  </AppButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  );
};

export default UploadVideo;
