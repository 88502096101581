export function trackEvent(event, params = {}) {
  let eventParams = { event: event, ...params, user_identifier: "anonymous" }
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", event, eventParams)
  }
}

export function trackLogin(user) {
  trackEvent("successful_login", {
    user_name: user?.full_name || "",
    restaurant: user?.restaurant || ""
  })
}

export function trackLogOut(full_name, rest_name) {
  trackEvent("log_out", {
    user_name: full_name || "",
    restaurant: rest_name || ""
  })
}

export function trackTriviaQuestionBank(full_name) {
  trackEvent("trivia_question_bank", { full_name })
}

export function trackTriviaAddQuestionClick(full_name) {
  trackEvent("trivia_add_question_click", {
    page_url: window.location.pathname,
    user_name: full_name
  })
}

export function trackTriviaAddNewQuestion(full_name) {
  trackEvent("trivia_add_new_question", { user_name: full_name })
}

export function trackTriviaGenerateQuestionClick(full_name) {
  trackEvent("trivia_generate_question_click", { user_name: full_name })
}

export function trackTriviaGenerateQuestion(payload, full_name) {
  trackEvent("trivia_generate_question", {
    menuItems: payload?.menu_ids,
    question_number: payload?.noOfQuestion,
    platform: payload?.platform,
    user_name: full_name
  })
}

export function trackTriviaAutoSuggestionQuestion(full_name) {
  trackEvent("trivia_auto_suggestion_question", { user_name: full_name })
}

// To Do
export function trackTriviaAddQuestionToTrivia(full_name) {
  trackEvent("trivia_add_question_to_trivia", { user_name: full_name })
}

export function trackTriviaAddQuestionToBank(que, full_name) {
  trackEvent("trivia_add_question_to_question_bank", { question_added: que, user_name: full_name })
}

export function trackTriviaSelectMenu(full_name) {
  trackEvent("trivia_select_menu", { user_name: full_name })
}

export function trackTriviaPreviewMenu(ids, full_name) {
  trackEvent("trivia_preview_menu", { selected_menu: ids, user_name: full_name })
}

export function trackEditMenu(full_name) {
  trackEvent("edit_menu", { user_name: full_name })
}

export function trackImportSpiritClick(full_name) {
  trackEvent("import_spirit_click", { user_name: full_name })
}

export function trackImportSpirit(spirit, full_name) {
  trackEvent("import_spirit", { imported_sprit: spirit, user_name: full_name })
}

export function trackAddCategoryClick(full_name) {
  trackEvent("add_menu_cateory_click", { user_name: full_name })
}

export function trackSaveAddCategory(payload, full_name) {
  trackEvent("save_and_add_category", {
    meal_period: payload?.meal_period || "",
    food_category: payload?.food_category || "",
    name: payload?.name || "",
    user_name: full_name
  })
}

export function trackSaveCategory(payload, full_name) {
  trackEvent("save_category", {
    meal_period: payload?.meal_period || "",
    food_category: payload?.food_category || "",
    name: payload?.name || "",
    mealCategoryId: payload?.mealCategoryId,
    user_name: full_name
  })
}

export function trackSaveItem(payload, full_name) {
  trackEvent("save_item", { new_item: payload, user_name: full_name })
}

export function trackEditItem(payload, full_name) {
  trackEvent("edit_item", { new_item: payload, user_name: full_name })
}

export function trackArchiveItem(id, full_name) {
  trackEvent("archive_item", { item_id: id, state: "archive", user_name: full_name })
}

export function trackArchiveItemClick(full_name) {
  trackEvent("archive_item_click", { user_name: full_name })
}

export function trackUnArchiveItem(payload, full_name) {
  trackEvent("unarchive_item", {
    id: payload.id,
    food_type: payload?.food_type || "",
    state: "unarchive",
    user_name: full_name
  })
}

// export function trackUnarchiveCategory() {
//   trackEvent("unarchive_category")
// }

export function trackAddFeatureItemPageView(full_name) {
  trackEvent("add_feature_item_Page_view", { user_name: full_name })
}

export function trackAddFeatureItemClick(full_name) {
  trackEvent("add_feature_item_click", { user_name: full_name })
}

export function trackAddFeatureItem(payload, full_name) {
  trackEvent("add_feature_item", {
    month: payload?.month,
    name: payload?.nameOfTheWine,
    thumbnail: payload?.thumbnail,
    video_url: payload?.videoURL,
    image: payload?.image,
    wine_region: payload?.wineRegion,
    gape_variatals: payload?.grapeVarietals,
    description: payload?.description,
    menu_tasting_notes: payload.tastingNotes,
    menu_pairing: payload?.menuPairing,
    user_name: full_name
  })
}

export function trackLeaderBoardPageView(full_name) {
  trackEvent("leader_board_page_view", { user_name: full_name })
}

export function trackMenuPageView(full_name) {
  trackEvent("menu_page_view", { user_name: full_name })
}

export function trackSubmitTastingForm(values, full_name) {
  trackEvent("tasting_form_submit", {
    name: values.wine,
    start_date: values?.start_date,
    end_date: values?.end_date,
    grape_varietal: values.grapeVarietals,
    origin: values.origin,
    personality: values.personality,
    video_url: values.videoUrl || null,
    wine_type: values?.wineType,
    wine_color: values?.week_answer,
    thumbnail: values.videoUrl ? values?.videoThumbnail : null,
    tasting_notes: values?.tasting_notes,
    characteristics: values?.characteristics,
    user_name: full_name
  })
}
