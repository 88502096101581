import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import CreateIcon from "@mui/icons-material/Create"
import DMVNavBar from "components/DMVNavBar"
import { useDispatch, useSelector } from "react-redux"
import { Grid, Typography } from "@mui/material"
import DMVMenuListContainer from "components/DMVMenuListContainer"
import classes from "./dmvmenu.module.scss"
import { useHistory } from "react-router-dom"
import {
  getBeverageAction,
  getDMVWineRegionListAction,
  getSpiritAction
} from "redux/DMV/actions/user"
import SpiritsSubMenuBar from "components/SpiritsSubMenuBar"
import DMVSpiritMenuListContainer from "components/DMVSpiritMenuListContainer"
import DMV_SpiritSummary from "components/DMVSpiritSummary"

const menuText = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

const DMVMenu = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const { dmvMenuLink, wineRegionData, beverageData, menuCategory, spiritData, SpiritSubMenu } =
    useSelector((state) => state.dmv)

  const handleRouting = () => {
    history.push("/dmv/dashboard/explore-menu/edit")
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Explore Menu"
  }, [])

  useEffect(() => {
    if (dmvMenuLink === menuText.WINE_REGION) {
      dispatch(getDMVWineRegionListAction())
    }
    if (dmvMenuLink === menuText.BEVERAGE) {
      dispatch(getBeverageAction())
    }
    if (dmvMenuLink === menuText.SPIRITS) {
      dispatch(getSpiritAction())
    }
  }, [dmvMenuLink])


  return (
    <>
      <Row>
        <Col lg="12">
          <Header title="Menu" breadCrumbs={breadCrumbs} back={true}>
            <AppButton onClick={handleRouting}>
              <CreateIcon
                sx={(theme) => ({ color: theme.palette.secondary.main })}
                className="me-2"></CreateIcon>
              Edit Menu
            </AppButton>
          </Header>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="12">
          <DMVNavBar activeTab={dmvMenuLink}></DMVNavBar>
        </Col>
      </Row>
      {dmvMenuLink === menuText.SPIRITS && (
        <Row className="mt-4">
          <Col lg={12}>
            <SpiritsSubMenuBar activeTab={menuCategory?.name} />
          </Col>
        </Row>
      )}

      {dmvMenuLink === menuText.WINE_REGION && (
        <Container fluid className="p-0 mt-4">
          <div className={classes.menuContainer}>
            {wineRegionData?.length ? (
              wineRegionData?.map((menuItem, idx) => {
                return <DMVMenuListContainer data={menuItem} key={idx}></DMVMenuListContainer>
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </div>
        </Container>
      )}
      {dmvMenuLink === menuText.BEVERAGE && (
        <Container fluid className="p-0 mt-4">
          <div className={classes.menuContainer}>
            {beverageData?.length > 0 ? (
              beverageData?.map((menuItem, idx) => {
                return <DMVMenuListContainer data={menuItem} key={idx}></DMVMenuListContainer>
              })
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </div>
        </Container>
      )}
      {dmvMenuLink === menuText.SPIRITS && (
        <>
          <Grid item sx={{ maxWidth: "48%" }}>
            {SpiritSubMenu?.map(
              (foodList, i) =>
                foodList?.id === menuCategory?.name &&
                (foodList?.description ||
                  foodList?.image_url ||
                  foodList?.unique_facts?.length > 0) && (
                  <DMV_SpiritSummary key={i} isEdit={false} foodList={foodList} />
                )
            )}
          </Grid>
          <Container fluid className="p-0 mt-4">
            <div className={classes.menuContainer}>
              {spiritData?.length > 0 ? (
                spiritData?.map((menuItem, idx) => {
                  return (
                    <DMVSpiritMenuListContainer
                      data={menuItem}
                      key={idx}></DMVSpiritMenuListContainer>
                  )
                })
              ) : (
                <Typography>No Data Found</Typography>
              )}
            </div>
          </Container>
        </>
      )}
    </>
  )
}

export default DMVMenu
