import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import crown from "assets/icons/crown.svg";
import styles from "./InviteRateTable.module.scss";
import { useTheme } from "@mui/system";

const InviteRateTable = ({ columns, rows }) => {
  const themes = useTheme();
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: `${themes.palette.background.main}`,
      "& .MuiPaper-root": {
        backgroundColor: "red"
      },
      "& .MuiTableRow-hover": {
        backgroundColor: "red"
      },
      "& .MuiTableCell-root": {
        padding: "16px 16px",
        backgroundColor: `${themes.palette.background.main}`,
        borderBottom: "1px solid #353535"
      }
    }
  }));
  const classes = useStyles();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table className={classes.root} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                    {column.icon && (
                      <img
                        className={styles.columnIcon}
                        src={column.icon}
                        alt=""
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <span
                      style={{
                        border: "2px solid #3ADB76",
                        borderRadius: 6,
                        padding: "12px 10px"
                      }}>
                      {row.rank}
                    </span>
                  </TableCell>
                  <TableCell>
                    <div className={`${styles.tableAvatar}`}>
                      <div
                        className={`${styles.tableAvatar__img} ${
                          row.rank === 1 && styles.borderImg
                        }`}>
                        <img src={row.image} />
                      </div>
                      <div className="d-flex flex-column">
                        <Typography variant="body2Regular">
                          {row.name ? row.name : "-"}
                        </Typography>
                        {row.rank === 1 && (
                          <div
                            className="mt-1 d-flex"
                            style={{ color: "#E67C1B" }}>
                            <img
                              width={12}
                              className="me-2"
                              height={12}
                              src={crown}
                              alt=""
                            />
                            <Typography variant="tag1">
                              Highest Ranked
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {row.restaurantName ? row.restaurantName : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.nonMember ? row.nonMember : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.inviteRate ? row.inviteRate : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.membership ? row.membership : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.upgradeRate ? row.upgradeRate : "-"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default InviteRateTable;
