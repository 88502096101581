import {
  Box,
  Grid,
  Menu,
  MenuItem,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import Card from "components/Card/Card";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TabSwitchContainer from "./TabSwitchContainer";
import moment from "moment";
import VideoPlayer from "./VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { DMVActions } from "redux/DMV/slice/user";
import { deleteVideoGalleryVideoAction } from "redux/DMV/actions";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  border: `2px solid ${theme.palette.background.secondary}`,
  padding: 16
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}));

const StyledHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  paddingBottom: 11,
  borderBottom: "1px solid #212121"
}));

const PerformanceMgmtVideos = ({
  toggleUploadVideoPopup,
  setEditModeData,
  selectedTab,
  onTabChange
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const dispatch = useDispatch();
  const { allVideos, assignedVideos, unassignedVideos } = useSelector(
    (store) => store?.dmv?.videoGallery || {}
  );

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen((prev) => !prev);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const showMoreOptions = (e, video) => {
    e.stopPropagation();
    dispatch(DMVActions.setVideoGalleryId(video.id));
    setEditModeData(video);
    setAnchorEl(e.currentTarget);
  };

  const onEditClick = () => {
    handleMenuClose();
    toggleUploadVideoPopup();
  };

  const onDeleteClick = () => {
    handleMenuClose();
    toggleDeletePopup();
  };

  const handleDelete = () => {
    dispatch(deleteVideoGalleryVideoAction());
  };

  const getDate = ({ start_date, end_date }) =>
    !start_date || !end_date
      ? "-"
      : `${moment(start_date).format("DD MMM YYYY")} - ${moment(
          end_date
        ).format("DD MMM YYYY")}`;

  const getVideos = () => {
    switch (selectedTab?.value) {
      case "assigned":
        return assignedVideos;
      case "unassigned":
        return unassignedVideos;
      default:
        return allVideos;
    }
  };

  return (
    <TabSwitchContainer value={selectedTab} onChange={onTabChange}>
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        onClose={toggleDeletePopup}
        title="Delete Video"
        onDelete={handleDelete}
      />
      <Typography variant="h3" className="mt-2 mb-4">
        {selectedTab?.titleLabel ?? ""}
      </Typography>
      <Grid container spacing={4}>
        {!getVideos()?.length ? (
          <Grid item xs={12}>
            <Box className="d-flex justify-content-center">
              <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
                No Videos Found
              </Typography>
            </Box>
          </Grid>
        ) : null}
        {getVideos()?.map((video) => (
          <Grid key={video.id} item lg={4} md={6} sm={12}>
            <StyledCard>
              <StyledHeader>
                <Box
                  className="d-flex flex-column"
                  sx={{ width: "calc(100% - 24px)" }}>
                  <Typography
                    variant="tag1"
                    className="text-ellipsis flex-shrink-1">
                    {video?.description ?? "-"}
                  </Typography>
                  <Typography className="mt-1 text-ellipsis" variant="caption1">
                    {video?.title ?? "-"}
                  </Typography>
                </Box>
                <span role="button" onClick={(e) => showMoreOptions(e, video)}>
                  <MoreVertIcon />
                </span>
              </StyledHeader>
              {video?.start_date ? (
                <Typography variant="tag1" className="mt-2 text-capitalize">
                  {video?.week ?? ""}
                </Typography>
              ) : (
                <Box height={20} />
              )}
              <Box className="mb-3">
                <CalendarTodayIcon sx={{ height: "16px", width: "16px" }} />
                <Typography variant="tag1" className="ms-1">
                  {getDate(video)}
                </Typography>
              </Box>
              <VideoPlayer video={video} />
            </StyledCard>
          </Grid>
        ))}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              marginTop: "15px",
              backgroundColor: theme.palette.background.default,
              borderRadius: "12px",
              "& .MuiList-root": {
                backgroundColor: theme.palette.background.default,
                width: "225px",
                boxSizing: "border-box",
                padding: "10px 0"
              }
            }
          }}>
          <StyledMenuItem onClick={onEditClick}>
            <Typography variant="body1Regular">Edit</Typography>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={onDeleteClick}
            sx={{
              borderTop: `2px solid #2c2c2c`
            }}>
            <Typography
              sx={{ color: theme.palette.red.main }}
              variant="body1Regular">
              Delete
            </Typography>
          </StyledMenuItem>
        </Menu>
      </Grid>
    </TabSwitchContainer>
  );
};

export default PerformanceMgmtVideos;
