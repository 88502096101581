import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton, Grid } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextArea from "components/StyledComponents/AppTextArea"
import AppAutocomplete from "components/AppAutocomplete"
import { useDispatch } from "react-redux"
import { uploadMenuItemAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"
import StyledLabel from "components/StyledComponents/StyledLabel"
import AppDatePicker from "components/AppDatePicker"

const buttonText = {
  EDIT: "Update Item",
  SAVE: "Save Item"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

function WineMenuItemForm({ formik, open = false, onClose = () => {}, isEdit = false }) {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      wineName: "",
      producer: "",
      uploadThumbnail: "",
      region: "",
      country: "",
      testingNotes: [],
      varietal: "",
      menuPairing: "",
      soundBites: "",
      itemPrice: null
    })
    dispatch(marketingActions.setMenuImageId(""))
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadMenuItemAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        {showCropperModal(imageObjectURL, fileDetails, formik)}
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2"> {isEdit ? "Edit" : "Add New"} Wine Item</Typography>
        </Box>

        <Box flexDirection="column" className="mt-4">
          <React.Fragment>
            <Box className=" w-100 py-2">
              <Row className="w-100 m-0">
                <Col lg="6">
                  <AppTextField
                    id="wineName"
                    name="wineName"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Wine Name"
                    isRequired={true}
                    placeholder="Enter Wine Name"
                    value={formik.values.wineName}
                    onChange={formik.handleChange}
                    error={formik.touched.wineName && Boolean(formik.errors.wineName)}
                    helperText={formik.errors.wineName}
                  />
                </Col>
                <Col lg="6">
                  <AppTextField
                    id="producer"
                    name="producer"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Producer"
                    placeholder="Enter Producer Name"
                    value={formik.values.producer}
                    onChange={formik.handleChange}
                    error={formik.touched.producer && Boolean(formik.errors.producer)}
                    helperText={formik.errors.producer}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6">
                  <ImageUploadField
                    label="Upload Thumbnail"
                    isVariant={true}
                    placeholder="Upload Image"
                    value={formik.values.uploadThumbnail}
                    onChange={(e) => onImageUpload(e)}
                    onDelete={() => handleImageDelete(formik)}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6">
                  <AppTextField
                    id="region"
                    name="region"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Region"
                    placeholder="Enter Region"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    error={formik.touched.region && Boolean(formik.errors.region)}
                    helperText={formik.errors.region}
                  />
                </Col>
                <Col lg="6">
                  <AppTextField
                    id="country"
                    name="country"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Country"
                    placeholder="Enter Country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.errors.country}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppAutocomplete
                    id="testingNotes"
                    placeholder="Enter Tasting Notes"
                    label={"Tasting Notes"}
                    isUpperCase={true}
                    value={formik.values.testingNotes}
                    onChange={(event, newValue) =>
                      event.target.value
                        ? event.target.value.trim() !== ""
                          ? formik.setFieldValue("testingNotes", newValue)
                          : ""
                        : formik.setFieldValue("testingNotes", newValue)
                    }></AppAutocomplete>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="varietal"
                    name="varietal"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Varietal(s)"
                    placeholder="Enter Varietal(s)"
                    value={formik.values.varietal}
                    onChange={formik.handleChange}
                    error={formik.touched.varietal && Boolean(formik.errors.varietal)}
                    helperText={formik.errors.varietal}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="menuPairing"
                    name="menuPairing"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Menu Pairing"
                    placeholder="Enter Menu Pairing"
                    value={formik.values.menuPairing}
                    onChange={formik.handleChange}
                    error={formik.touched.menuPairing && Boolean(formik.errors.menuPairing)}
                    helperText={formik.errors.menuPairing}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="itemPrice"
                    name="itemPrice"
                    isUpperCase={!false}
                    type="number"
                    fullWidth
                    label="Price"
                    placeholder="Enter Price"
                    value={formik.values.itemPrice}
                    onChange={formik.handleChange}
                    error={formik.touched.itemPrice && Boolean(formik.errors.itemPrice)}
                    helperText={formik.errors.itemPrice}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    label="Sommelier’s Sound Bites"
                    minRows={5}
                    maxRows={15}
                    placeholder="Enter Sommelier’s Sound Bites"
                    value={formik.values.soundBites}
                    onChange={formik.handleChange("soundBites")}
                    error={formik.errors.soundBites}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="6">
                  <Grid item sm={12} lg={6} className="datesContainer">
                    <StyledLabel variant="body1Regular">
                      {"Schedule Notification Date"}
                      <span>*</span>
                    </StyledLabel>
                    <Box className="dateFieldContainer w-100 mt-2">
                      <AppDatePicker
                        minDate={new Date()}
                        maxDate={new Date().setMonth(new Date().getMonth() + 5)}
                        inputFormat="dd MMM yyyy"
                        key={"scheduleNotificationDate"}
                        value={formik.values.scheduleNotificationDate}
                        onChange={(val) => formik.setFieldValue("scheduleNotificationDate", val)}
                        error={formik.errors.scheduleNotificationDate}
                        touched={formik.touched.scheduleNotificationDate}
                      />
                    </Box>
                  </Grid>
                </Col>
                <Col lg="6">
                  <AppTextField
                    type="time"
                    fullWidth
                    label="Schedule Notification Time"
                    isRequired={true}
                    isUpperCase={true}
                    value={formik.values.scheduleNotificationTime}
                    onChange={formik.handleChange("scheduleNotificationTime")}
                    error={
                      formik.touched.scheduleNotificationTime &&
                      Boolean(formik.errors.scheduleNotificationTime)
                    }
                    helperText={
                      formik.touched.scheduleNotificationTime &&
                      formik.errors.scheduleNotificationTime
                    }
                  />
                </Col>
              </Row>
            </Box>
          </React.Fragment>
        </Box>
        <Box className="mt-5 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default WineMenuItemForm
