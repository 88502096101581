import moment from "moment"
import { window } from "global"

// Get Mobile device operating system
export function getMobileWebOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return "android"
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios"
  }

  return ""
}

// returns a negative number for furtureDates that have already passed
export const daysUntil = (futureDate, currentDate) => {
  if (currentDate) {
    return moment(futureDate).diff(moment(currentDate, "days")) + 1
  }
  return moment(futureDate).diff(moment(), "days") + 1
}

// Get browser operating system
export const getWebOS = () => {
  if (navigator?.platform && navigator?.platform?.indexOf("iPhone") !== -1) return "ios"
  return "android"
}

const monthsArray = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

export const getMonthName = (dateObj) => {
  return monthsArray[dateObj.getMonth()]
}

export const getMonthNameFromString = (string) => {
  return monthsArray[string]
}

export const getMonthYearString = (string) => {
  const dateArr = string ? string.split("-") : ""
  return `${monthsArray[parseInt(dateArr[1]) - 1]} ${dateArr[0]}`
}

export const getMonthYearFromDate = (dateObj) => {
  return moment(dateObj).format("MMM YYYY")
}

export const getFormattedDate = (dateString = "") => {
  let answer = dateString.split("-").reverse()
  if (answer.length < 3) {
    return "-"
  }
  const months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
  }

  answer[1] = months[answer[1]]

  return answer.join(" ")
}

export const formatDate = (dateObj) => {
  return moment(dateObj).format("YYYY-MM-DD")
}

export const dateDiffInDays = (date1, date2) => {
  const diffTime = date2 - date1
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes == 0) return "0 Byte"
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
}

export const removeDecimalUpto1 = (value) => {
  value *= 10
  let newValue = parseInt(value)
  return newValue / 10
}

export const dateToUTC = (date) => moment(date).utc()

export const getYoutubeIdByUrl = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url?.match(regExp)

  return match && match[2].length === 11 ? match[2] : null
}

export function toTitleCase(str) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function getLastDayOfMonth(date) {
  const dateObject = new Date(date)
  const newDate = new Date(dateObject.getFullYear(), dateObject.getMonth() + 1, 0)

  return formatDate(newDate)
}

export function splitAndMergeString(data) {
  const newString = data?.split("_")
  return toTitleCase(newString?.join(" "))
}

export const isEmptyObject = (obj) => {
  if (JSON.stringify(obj) === "{}") {
    return true
  } else {
    return false
  }
}

export const initDateConvert = (date, time) => {
  const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
  const temp = new Date(initDate)
  const convertedUTCDate = new Date(
    Date.UTC(
      temp.getFullYear(),
      temp.getMonth(),
      temp.getDate(),
      temp.getHours(),
      temp.getMinutes(),
      temp.getSeconds()
    )
  )
  return moment(convertedUTCDate).local().toDate()
}

export const convertDate = (date, time) => {
  const forDate = moment(new Date(date)).format("ddd MMM DD YYYY") + " " + time
  const newDate = new Date(forDate)
  return newDate.toUTCString()
}

const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12"
}

export const payloadDate = (dateArray = []) => {
  return `${dateArray[3]}-${months[dateArray[2]]}-${dateArray[1]}`
}
