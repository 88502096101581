import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  maxWidth: "100%",
  "& .label": {
    color: "#C4C4C4",
    marginBottom: 8
  }
}));

const DetailField = ({ label = "", value = "" }) => {
  return (
    <StyledContainer>
      <Typography className="label" variant="body2Regular">
        {label?.toUpperCase()}
      </Typography>
      <Typography className="data" variant="body1Regular">
        {value}
      </Typography>
    </StyledContainer>
  );
};

export default DetailField;
