import { Grid } from "@mui/material";
import Header from "components/Header/Header";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import FrontLineUserForm from "./frontLineUserForm";
import { validationSchemaFrontLineUser } from "helpers/validationSchema";
import { formatDate } from "helpers/functions";
import { useDispatch } from 'react-redux';
import { uploadFrontlineUserAction } from "redux/superAdmin/actions";
import { useHistory } from 'react-router-dom';




// const isSpecial = {
//   true:"true",
//   false:"false"
// }

const initialValues = {
  userType: "",
  role: "",
  fullName: "",
  restaurant: "",
  email: "",
  contactNumber: "",
  address: "",
  hireDate: "",
  reportingManager: "",
  // specialUser:isSpecial.false.toString()
}



const AddFrontLineUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const [initialFormValue] = useState(initialValues);
  const dispatch =  useDispatch();
  const history = useHistory()
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
  }, []);
  

  function handleSubmit(values) {
    const payload = {
      full_name: values?.fullName,
      email: values?.email,
      mobile: values?.contactNumber,
      job_class: values?.role,
      role: values?.userType,
      rest_name: values?.restaurant,
      address: values?.address,
      hire_date: formatDate(new Date(values?.hireDate)),
      reporting_manager: values?.reportingManager,
      // special_user:values?.specialUser
    }
    dispatch(uploadFrontlineUserAction(payload));
    history.replace("/super-admin/dashboard/frontline-user-list")
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Add Frontline User" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaFrontLineUser}
        initialValues={initialFormValue}
      >
        {(formik) => (
          <FrontLineUserForm formik={formik}></FrontLineUserForm>
        )}
      </Formik>
    </>
  );
};

export default AddFrontLineUser;
