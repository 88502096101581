// import AppButton from "components/StyledComponents/AppButton"
import Header from "components/Header/Header"
import QuestionsBanks from "pages/private/learning_development/QuestionBank/components/QuestionBanks"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDMVAutoSuggestQuestionsAction } from "redux/DMV/actions/user"

const DMV_AutoSuggest = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const { DMVAutoSuggestQuestions } = useSelector((state) => state.dmv)

  useEffect(() => {
    dispatch(getDMVAutoSuggestQuestionsAction({ page: currentPage }))
  }, [currentPage])

  return (
    <>
      <Header title="Auto Suggestion Questionnaire" />
      <QuestionsBanks
        title=""
        questions={DMVAutoSuggestQuestions?.results}
        pages={DMVAutoSuggestQuestions?.pages}
        isEditable={true}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isTiriva={false}
        isGamification={true}
        isAutoSuggest={true}
      />
    </>
  )
}

export default DMV_AutoSuggest
