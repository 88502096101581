import { Box } from "@mui/material";
import Header from "components/Header/Header";
import AppButton from "components/StyledComponents/AppButton";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InfoCard from "components/PerformanceSummary/InfoCard/infoCard";
import PerformanceSummaryTable from "components/PerformanceSummary/PerformanceSummaryTable";
import DataCard from "./components/DataCard";

const MembershipSignupPerformance = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([]);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Performance Summary";
  }, []);

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header
              title="Frontline Wine Club Membership Sign-up Performance"
              breadCrumbs={breadCrumbs}></Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Box className="d-flex w-100 flex-wrap">
              <InfoCard color="#3ADB76" style={{ paddingLeft: 0 }}>
                Top Servers
              </InfoCard>
              <InfoCard color="#9CC43B">Doing Great</InfoCard>
              <InfoCard color="#FFAE00">Average</InfoCard>
              <InfoCard color="#E67C1B">Underperforming</InfoCard>
              <InfoCard color="#CC4B37" style={{ paddingRight: 0 }}>
                Poor
              </InfoCard>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <PerformanceSummaryTable rows={rows} columns={columns} />
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <DataCard data={inviteMetricsData} />
          </Col>
          <Col lg="4">
            <DataCard data={upgradeMetricsData} />
          </Col>
          <Col lg="4">
            <DataCard data={appUsageData} />
            <AppButton
              onClick={goToTop}
              styles={{ width: "100%", marginTop: 16 }}>
              Back to the top
            </AppButton>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MembershipSignupPerformance;

const inviteMetricsData = {
  heading: "Invite Metrics",
  list: [
    { key: "Total Signups", field: "203" },
    { key: "Total Non-Member Guests", field: "5814" },
    { key: "Invite Rate", field: "3.5%" }
  ]
};

const upgradeMetricsData = {
  heading: "Upgrade Metrics",
  list: [
    { key: "Total Upgrades", field: "37" },
    { key: "Total Member Guests", field: "3616" },
    { key: "Upgrade Rate", field: "1.0%" }
  ]
};

const appUsageData = {
  heading: "App Usage",
  list: [
    { key: "Team", field: "79%" },
    { key: "Servers", field: "74%" },
    { key: "Bartenders", field: "80%" },
    { key: "Tasting Room Attendants", field: "90%" },
    { key: "Managers", field: "100%" }
  ]
};

const columns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 220
  },
  {
    id: "Has App",
    label: "Has App",
    align: "center",
    minWidth: 90
  },
  {
    id: "Bottles",
    label: "Bottles",
    align: "center",
    minWidth: 90
  },
  {
    id: "% of Total",
    label: "% of Total",
    align: "center",
    minWidth: 100
  },
  {
    id: "Non Members",
    label: "Non Members",
    align: "center",
    minWidth: 100
  },
  {
    id: "Invite Rate",
    label: "Invite Rate",
    align: "center",
    minWidth: 100
  },
  {
    id: "Wine club Members",
    label: "Wine club Members",
    align: "center",
    minWidth: 80
  },
  {
    id: "Upgrade Rate",
    label: "Upgrade Rate",
    align: "center",
    minWidth: 80
  },
  {
    id: "Lost Opportunity",
    label: "Lost Opportunity",
    align: "center",
    minWidth: 80
  }
];

const rows = [
  {
    id: 1,
    rank: 1,
    name: "Carlos Pena",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    hasApp: true,
    bottles: 36,
    percentTotal: 15,
    nonMembers: 300,
    inviteRate: 10.0,
    wineClubMembers: 150,
    upgradeRate: 6.7,
    lostOpportunity: 0
  },
  {
    id: 2,
    rank: 2,
    name: "Josephine Flores",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    hasApp: true,
    bottles: 24,
    percentTotal: 10.0,
    nonMembers: 250,
    inviteRate: 8.0,
    wineClubMembers: 125,
    upgradeRate: 4.8,
    lostOpportunity: 0
  },
  {
    id: 3,
    rank: 3,
    name: "Robert Fox",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    hasApp: true,
    bottles: 19,
    percentTotal: 18.0,
    nonMembers: 260,
    inviteRate: 6.2,
    wineClubMembers: 130,
    upgradeRate: 3.1,
    lostOpportunity: 0
  },
  {
    id: 4,
    rank: 4,
    name: "Arlene McCoy",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    hasApp: true,
    bottles: 17,
    percentTotal: 7.0,
    nonMembers: 280,
    inviteRate: 5.0,
    wineClubMembers: 140,
    upgradeRate: 2.9,
    lostOpportunity: 0
  },
  {
    id: 5,
    rank: 5,
    name: "Marvin McKinney",
    image: "https://randomuser.me/api/portraits/men/31.jpg",
    hasApp: true,
    bottles: 16,
    percentTotal: 6.0,
    nonMembers: 290,
    inviteRate: 4.5,
    wineClubMembers: 145,
    upgradeRate: 1.4,
    lostOpportunity: 0
  },
  {
    id: 6,
    rank: 6,
    name: "Jade Cooper",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    hasApp: true,
    bottles: 14,
    percentTotal: 6.0,
    nonMembers: 270,
    inviteRate: 4.4,
    wineClubMembers: 135,
    upgradeRate: 1.5,
    lostOpportunity: 0,
    isSystemRisk: true
  },
  {
    id: 7,
    rank: 7,
    name: "Kristin Watson",
    image: "https://randomuser.me/api/portraits/women/40.jpg",
    hasApp: true,
    bottles: 13,
    percentTotal: 50,
    nonMembers: 222,
    inviteRate: 50,
    wineClubMembers: 111,
    upgradeRate: 1.8,
    lostOpportunity: 0
  },
  {
    id: 8,
    rank: 8,
    name: "Esther Howard",
    image: "https://randomuser.me/api/portraits/men/51.jpg",
    hasApp: true,
    bottles: 12,
    percentTotal: 5.0,
    nonMembers: 220,
    inviteRate: 4.5,
    wineClubMembers: 101,
    upgradeRate: 1.0,
    lostOpportunity: 0
  },
  {
    id: 9,
    rank: 9,
    name: "Dianne Russell",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
    hasApp: true,
    bottles: 10,
    percentTotal: 4.0,
    nonMembers: 220,
    inviteRate: 4.5,
    wineClubMembers: 1.0,
    upgradeRate: 1.0,
    lostOpportunity: 0,
    isBiggestOpportunityServer: true
  },
  {
    id: 10,
    rank: 10,
    name: "Ralph Edwards",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    hasApp: true,
    bottles: 10,
    percentTotal: 4.0,
    nonMembers: 220,
    inviteRate: 3.6,
    wineClubMembers: 100,
    upgradeRate: 1.0,
    lostOpportunity: 0
  },
  {
    id: 11,
    rank: 11,
    name: "Guy Hawkins",
    image: "https://randomuser.me/api/portraits/men/34.jpg",
    hasApp: true,
    bottles: 10,
    percentTotal: 4.0,
    nonMembers: 220,
    inviteRate: 3.6,
    wineClubMembers: 100,
    upgradeRate: 1.0,
    lostOpportunity: 0
  },
  {
    id: 12,
    rank: 12,
    name: "Leslie Alexander",
    image: "https://randomuser.me/api/portraits/women/39.jpg",
    hasApp: true,
    bottles: 10,
    percentTotal: 4.0,
    nonMembers: 220,
    inviteRate: 3.6,
    wineClubMembers: 100,
    upgradeRate: 1.0,
    lostOpportunity: 0
  },
  {
    id: 13,
    rank: 13,
    name: "Taylor Mulder",
    image: "https://randomuser.me/api/portraits/men/35.jpg",
    hasApp: false,
    bottles: 0,
    percentTotal: 0.0,
    nonMembers: 120,
    inviteRate: 0.0,
    wineClubMembers: 103,
    upgradeRate: 0.0,
    lostOpportunity: 1
  },
  {
    id: 14,
    rank: 14,
    name: "Kate Harang",
    image: "https://randomuser.me/api/portraits/women/41.jpg",
    hasApp: false,
    bottles: 0,
    percentTotal: 0.0,
    nonMembers: 120,
    inviteRate: 0.0,
    wineClubMembers: 103,
    upgradeRate: 0.0,
    lostOpportunity: 1
  },
  {
    id: 15,
    rank: 15,
    name: "Mara Lidle",
    image: "https://randomuser.me/api/portraits/women/42.jpg",
    hasApp: false,
    bottles: 0,
    percentTotal: 0.0,
    nonMembers: 120,
    inviteRate: 0.0,
    wineClubMembers: 103,
    upgradeRate: 0.0,
    lostOpportunity: 1
  }
];
