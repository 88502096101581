import { Grid, Typography } from "@mui/material"
import Card from "components/Card/Card"
import DetailField from "components/DetailField"
import React from "react"

// const ImageContainer = styled(Box)(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   height: 52,
//   width: 52,
//   borderRadius: 4,
//   backgroundColor: theme.palette.primary.main,
//   margin: "24px 0 0 24px",
//   "& img": {
//     maxHeight: 52,
//     maxWidth: 52,
//     backgroundColor: "#15181a"
//   }
// }));

const TastingNotes = ({ data }) => {
  return (
    <>
      <Typography variant="body1Medium">Tasting Notes</Typography>
      {data.map((tastingData, index) => (
        <Card key={tastingData.id} className="mb-4">
          <Grid container spacing={3}>
            {/* <Grid xs={2}>
              {tastingData.image ? (
                <ImageContainer>
                  <img src={tastingData.image} alt="" />
                </ImageContainer>
              ) : null}
            </Grid> */}
            <Grid item md={3} sm={4}>
              <DetailField label={`Flavour ${index + 1}`} value={tastingData?.flavor ?? "-"} />
            </Grid>
            {tastingData?.has_tooltip ? (
              <>
                <Grid item md={3} sm={4}>
                  <DetailField
                    label="Tooltip Heading"
                    value={tastingData?.tooltip_heading ?? "-"}
                  />
                </Grid>
                <Grid item md={3} sm={4}>
                  <DetailField
                    label="Tooltip Details"
                    value={tastingData?.tooltip_details ?? "-"}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Card>
      ))}
    </>
  )
}

export default TastingNotes
