import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Formik } from "formik"

import { validationSchemaFrontLineUser } from "helpers/validationSchema"
import { formatDate } from "helpers/functions"
import { useDispatch } from "react-redux"
import { EditAdminUsersDataAction, getAdminUsersDataAction } from "redux/superAdmin/actions"
import { useSelector } from "react-redux"
import AdminUserForm from "../adminUserForm/index"
import { useHistory } from "react-router-dom"

const isSpecial = {
  true: "true",
  false: "false"
}

const initData = {
  fullName: "",
  email: "",
  contactNumber: "",
  userType: "",
  role: "",
  restaurant: "",
  address: "",
  hire_date: "",
  specialUser: "",
  reportingManager: ""
}

const EditAdminUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [initialFormValue, setInitialFormValue] = useState(initData)

  const { adminUserData } = useSelector((store) => store?.superAdmin?.adminUsers ?? {})
  const history = useHistory()
  const dispatch = useDispatch()

  function initFormData() {
    const initData = {
      fullName: adminUserData?.full_name,
      email: adminUserData?.email,
      contactNumber: adminUserData?.phone_number || "",
      userType: adminUserData?.user_type?.id,
      role: adminUserData?.role?.id,
      restaurant: adminUserData?.restaurant?.id,
      address: adminUserData?.address || "",
      hireDate: new Date(adminUserData?.hire_date),
      specialUser:
        adminUserData?.is_special_user === true
          ? isSpecial.true.toString()
          : isSpecial.false.toString(),
      reportingManager: adminUserData?.reporting_manager ?? "-"
    }
    setInitialFormValue(initData)
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    initFormData()
  }, [adminUserData])

  function handleSubmit(values) {
    const payload = {
      full_name: values?.fullName,
      email: values?.email,
      phone_number: values?.contactNumber,
      role: values?.role,
      user_type: values?.userType,
      restaurant: values?.restaurant,
      address: values?.address,
      hire_date: formatDate(new Date(values?.hireDate)),
      reporting_manager: values?.reportingManager,
      is_special_user: values?.specialUser
    }
    dispatch(EditAdminUsersDataAction(payload))
    history.replace("/super-admin/dashboard/admin-user-list")
  }

  useEffect(() => {
    dispatch(getAdminUsersDataAction())
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Edit Admin User" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaFrontLineUser}
        initialValues={initialFormValue}>
        {(formik) => <AdminUserForm isEditContent={true} formik={formik}></AdminUserForm>}
      </Formik>
    </>
  )
}

export default EditAdminUser
