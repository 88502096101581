import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "../PendingQuestionTable.module.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import EditSuggestedQuestion from "../../EditSuggestedQuestion";
import { useDispatch } from "react-redux";
import { learningDevelopmentActions } from "redux/L&D/slice/user";
import { getPendingQuestionsAction } from "redux/L&D/actions";

const Question = ({ row, questionIndex }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const dispatch = useDispatch();
  const handleEditDialogOpen = () => {
    dispatch(learningDevelopmentActions.setSuggestedQuestionEdited(false));
    setOpenEditDialog(true);
  };

  const onQuestionEdited = () => {
    dispatch(getPendingQuestionsAction());
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
  };

  return (
    <Box className={styles.question}>
      {openEditDialog ? (
        <EditSuggestedQuestion
          data={row}
          onClose={handleEditDialogClose}
          onQuestionEdited={onQuestionEdited}
        />
      ) : null}
      <Typography
        sx={{ margin: "0px 0px 4px 52px" }}
        variant="caption3"
        className={styles.questionHeader}>
        {`${row.category} | ${row.level}`}
      </Typography>
      <Box className={styles.questionTitle}>
        <Typography variant="body1Regular">{questionIndex + 1}</Typography>
        <ArrowForwardIcon sx={{ marginLeft: "14px" }} />
        <Typography variant="h4" sx={{ marginLeft: "6px" }}>
          {row.title}
        </Typography>
        {row?.status?.toLowerCase() === "approved" ? null : (
          <span role="button" className="ms-1" onClick={handleEditDialogOpen}>
            <EditIcon
              sx={(theme) => ({
                color: theme.palette.secondary.main,
                height: 20,
                width: 20
              })}
            />
          </span>
        )}
      </Box>
      {row?.answer?.map((option, index) => (
        <Box
          key={option.id}
          className={`${styles.option} ${
            option.is_correct ? styles.optionCorrect : ""
          }`}>
          <div className={styles.optionNumber}>
            <Typography variant="tag1">
              {(index + 10).toString(36).toUpperCase()}
            </Typography>
          </div>
          <Typography
            className={styles.optionName}
            sx={{ marginLeft: "10px" }}
            variant="body2Regular">
            {option.answer}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Question;
