import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import styles from "./userPerformanceTable.module.scss";

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    },
    "&:nth-last-of-type(2)": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const TableFooter = styled(TableRow)(() => ({
  backgroundColor: "#272a2c",
  borderRadius: 6,
  marginBottom: 10,
  "& .MuiTableCell-root": {
    backgroundColor: "transparent",
    "&:first-of-type": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "&:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6
    }
  }
}));

const UserPerformanceTable = ({ columns = [], rows = [] }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                    {column.icon && (
                      <img
                        className={styles.columnIcon}
                        src={column.icon}
                        alt=""
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <div className={styles.rank}>{row.rank}</div>
                  </TableCell>
                  <TableCell>
                    <div className={`${styles.tableAvatar}`}>
                      <div
                        className={`${styles.tableAvatar__img} ${
                          row.rank === 1 && styles.borderImg
                        }`}>
                        <img src={row.image} />
                      </div>
                      <div className="d-flex flex-column">
                        <Typography variant="body2Regular">
                          {row.name}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.percentGoal + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.percentImproved + "%"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.improvedTeam}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.totalTeam}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.incrementalMembers}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body2Regular">
                      {row.annualized}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableFooter>
              <TableCell />
              <TableCell />
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {(
                    rows.reduce((prev, el) => prev + el.percentGoal, 0) /
                    rows.length
                  ).toFixed(1) + "%"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {(
                    rows.reduce((prev, el) => prev + el.percentImproved, 0) /
                    rows.length
                  ).toFixed(1) + "%"}
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.improvedTeam, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.totalTeam, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.incrementalMembers, 0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2Regular">
                  {rows.reduce((prev, el) => prev + el.annualized, 0)}
                </Typography>
              </TableCell>
            </TableFooter>
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  );
};

export default UserPerformanceTable;
