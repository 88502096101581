import { Grid } from "@mui/material"
import Header from "components/Header/Header"
import AppButton from "components/StyledComponents/AppButton"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { getVersionListAction } from "redux/superAdmin/actions"
import { useDispatch, useSelector } from "react-redux"
import { superAdminAction } from "redux/superAdmin/slice/user"
import { appActions } from "redux/app/slice/app"
import AppReleaseListTable from "./appReleaseListTable"
import ReleaseForm from "../addRelease"

const ManageAppRelease = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [openForm, setOpenForm] = useState(false)

  const { versionListData } = useSelector((store) => store?.superAdmin || [])
  const { searchField } = useSelector((store) => store?.app?.filters || "")

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    dispatch(superAdminAction.setFrontlineUserData({}))
    dispatch(appActions.initFilters())
    dispatch(appActions.setInitDate(""))
    dispatch(appActions.setOrderBy("-hire_date"))
  }, [])

  const handleCloseForm = () => {
    setOpenForm(false)
  }

  const handleOpenForm = () => {
    setOpenForm(true)
  }

  useEffect(() => {
    dispatch(
      getVersionListAction({
        page: currentPage,
        search: searchField
      })
    )
  }, [currentPage, searchField])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchField])

  useEffect(() => {}, [versionListData])

  return (
    <>
      <ReleaseForm open={openForm} onClose={handleCloseForm}></ReleaseForm>
      <Grid container>
        <Grid item xs={12}>
          <Header title="App Release" breadCrumbs={breadCrumbs}>
            <AppButton onClick={handleOpenForm} sx={{ padding: "13px 30px" }}>
              + Add New Release
            </AppButton>
          </Header>
        </Grid>
      </Grid>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col lg="12">
            <AppReleaseListTable
              setPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={versionListData?.totalPages}
              rows={versionListData?.data}
              columns={columns}></AppReleaseListTable>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ManageAppRelease
const columns = [
  {
    id: "Platform",
    label: "Platform",
    minWidth: 100,
    align: "center"
  },
  {
    id: "App Name",
    label: "App Name",
    minWidth: 200,
    align: "center"
  },
  {
    id: "App Version",
    label: "App Version",
    align: "center",
    minWidth: 170
  },
  {
    id: "App Status",
    label: "App Status",
    align: "center",
    minWidth: 100
  },
  {
    id: "Mandatory",
    label: "Mandatory",
    align: "center",
    minWidth: 170
  },
  {
    id: "published",
    label: "Published By",
    align: "center",
    minWidth: 170
  },
  {
    id: "date",
    label: "Date created",
    align: "center",
    minWidth: 170
  },
  {
    id: "Action",
    label: "Action",
    align: "center",
    minWidth: 100
  }
]
