import React, { useState } from "react"
import Card from "components/Card/Card"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import { Divider, Typography } from "@mui/material"
import trashIcon from "assets/icons/trash.svg"
import { useTheme } from "@mui/system"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import {
  useDispatch
  // , useSelector
} from "react-redux"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import AppButton from "components/StyledComponents/AppButton"
import { DMVActions } from "redux/DMV/slice/user"
import { deleteSpiritCategoryAction } from "redux/DMV/actions/user"
import DMV_SpiritMenuListItems from "components/DMVSpirit_list_items"

function DMVSpiritMenuListContainer({
  data,
  isEdit = false,
  setCategoryModal = () => {},
  setIsEdit = () => {},
  setIsEditModalItem = () => {},
  setCurrentCategoryData = () => {},
  setMenuItemsModal = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  // const { dmvMenuLink } = useSelector((state) => state.dmv)

  function EditCategoryModal(id) {
    dispatch(DMVActions.setDMVMenuCategoryId(id))
    setCategoryModal(true)
    setIsEdit(true)
    setCurrentCategoryData(data)
  }

  function handleMenuPeriodDelete(id) {
    dispatch(deleteSpiritCategoryAction({ categoryID: id }))
  }

  function handleMenuDeleteModal(id) {
    dispatch(DMVActions.setDMVMenuCategoryId(id))
    setDeleteModal(true)
  }

  function handleAddItems(id) {
    setIsEditModalItem(false)
    dispatch(DMVActions.setDMVMenuCategoryId(id))
    setMenuItemsModal(true)
    dispatch(DMVActions.setWineMenuItem({}))
    dispatch(DMVActions.setBeverageMenuItem({}))
  }
  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to Delete “${data?.spirit_category ?? ""}”?`}
        description={`"${data?.spirit_category ?? ""}" has ${data?.spirit_list?.length ?? ""} ${
          data?.spirit_list?.length <= 1 ? `Spirit` : "Spirits"
        }`}
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteModal(false)}
        onDelete={() => handleMenuPeriodDelete(data?.spirit_category_id)}
        open={modal}></DeleteConfirmationDialog>

      <Card style={{ height: "fit-content" }}>
        <Row>
          <Col lg="12">
            <div
              className="d-flex justify-content-between align-item-center"
              style={{
                backgroundColor: isEdit && theme.palette.background.default,
                padding: ` ${isEdit ? "12px 16px" : "0px"}`,
                borderRadius: 6
              }}>
              <Typography variant="h2">{data?.spirit_category}</Typography>
              <div className="d-flex align-items-center">
                {isEdit && (
                  <img
                    className="me-4"
                    onClick={() => handleMenuDeleteModal(data?.spirit_category_id)}
                    style={{ cursor: "pointer" }}
                    src={trashIcon}
                    alt=""
                  />
                )}
                {isEdit && (
                  <ModeEditIcon
                    onClick={() => EditCategoryModal(data?.spirit_category_id)}
                    sx={{ color: theme.palette.secondary.main, cursor: "pointer" }}></ModeEditIcon>
                )}
              </div>
            </div>
          </Col>
          {
            <Col lg="12">
              {data?.spirit_list?.length ? (
                data?.spirit_list?.map((items, idx) => {
                  return (
                    <>
                      <DMV_SpiritMenuListItems
                        categoryIdData={data?.spirit_category_id}
                        setIsEditModalItem={setIsEditModalItem}
                        setMenuItemsModal={setMenuItemsModal}
                        isEdit={isEdit}
                        key={idx}
                        foodList={items}></DMV_SpiritMenuListItems>
                      {!isEdit && idx < data?.spirit_list.length - 1 && <Divider color="#fff" />}
                    </>
                  )
                })
              ) : (
                <Typography className="mt-4" style={{ opacity: 0.6, textAlign: "center" }}>
                  No Items Found
                </Typography>
              )}
            </Col>
          }

          <Col lg="12">
            {isEdit && (
              <AppButton
                className="mt-4"
                onClick={() => handleAddItems(data?.spirit_category_id)}
                styles={{
                  backgroundColor: theme.palette.background.main,
                  padding: "10px 20px",
                  width: "100%"
                }}>
                + Add New Menu Item
              </AppButton>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default React.memo(DMVSpiritMenuListContainer)
