import React from "react"
import { Typography } from "@mui/material"

function EventName({ title, lowRes, icon, onTitleClick = { onTitleClick } }) {
  const styles = {
    typography: {
      margin: "4px auto 4px 0",
      height: "10px",
      marginRight: lowRes ? "5px" : 0,
      opacity: 1,
      overflow: "hidden"
    },
    titleBox: {
      whiteSpace: "break-spaces",
      marginTop: "-3px"
    }
  }
  return (
    <div>
      {title ? (
        <div
          style={{
            padding: icon ? "0px" : "4px",
            marginTop: icon ? "0px" : "8px"
          }}
          onClick={() => onTitleClick()}
          className={`${
            title.includes("Wine Club Member Dinner") ? "greenBg" : title !== "" ? "orangeBg" : null
          } event-itself`}>
          <img src={icon} className="event-icon" />

          <div style={styles.titleBox}>
            <Typography variant="tag2" sx={styles.typography}>
              {title}
            </Typography>
          </div>
        </div>
      ) : (
        <div style={{ height: "24px" }}></div>
      )}
    </div>
  )
}

export default EventName
