import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import { Row } from "react-bootstrap"
import AppTextField from "components/StyledComponents/AppTextField"
import { Col } from "react-bootstrap"
import AppSelect from "components/StyledComponents/AppSelect"
import AppDatePicker from "components/AppDatePicker"
import AppButton from "components/StyledComponents/AppButton"
import {
  Typography,
  List,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  Tooltip
} from "@mui/material"
import PhoneNumberField from "components/PhoneNumberField"
import { useDispatch, useSelector } from "react-redux"
import { Form } from "formik"
import {
  getNewRestaurantListAction,
  getRoleUsersListAction,
  getUserMainListAction
} from "redux/superAdmin/actions"
import InfoIcon from "@mui/icons-material/Info"
import { useTheme } from "@mui/system"

const options = ["true", "false"]

const AdminUserForm = ({ formik }) => {
  const theme = useTheme()

  const dispatch = useDispatch()

  const { newRestaurantList, adminUsersList, roleUsers } = useSelector((store) => store?.app || [])

  useEffect(() => {
    dispatch(getUserMainListAction())
    dispatch(getRoleUsersListAction())
    dispatch(getNewRestaurantListAction())
  }, [])

  function getRestaurantList() {
    let restaurantList = []
    newRestaurantList?.forEach((data) => {
      restaurantList?.push({
        label: `${data.name}`,
        value: `${data.id}`
      })
    })
    return restaurantList
  }

  function getUserType() {
    const filterData = adminUsersList?.filter((role) => {
      return role?.name !== "FRONTLINE"
    })
    let userTypeList = []
    filterData?.forEach((data) => {
      userTypeList.push({
        label: `${data?.name.toUpperCase()}`,
        value: `${data?.id}`
      })
    })
    return userTypeList
  }

  function getUserRole() {
    const filterRole = roleUsers?.filter((role) => {
      return role?.name !== "FRONTLINE" && role?.name !== "ADMIN" && role?.name !== "DMV"
    })
    let userRole = []
    filterRole?.forEach((data) => {
      userRole?.push({
        label: `${data?.name.toUpperCase()}`,
        value: `${data?.id}`
      })
    })
    return userRole
  }

  useEffect(() => {}, [])

  return (
    <>
      <Container fluid className="mt-2">
        <Row>
          <Col lg="4">
            <AppSelect
              id="userType"
              name="userType"
              label="User Type"
              menuItems={getUserType()}
              value={formik.values.userType}
              onChange={formik.handleChange}
              error={formik.errors.userType}
              isRequired={true}
              touched={formik.touched.userType}
            />
          </Col>
          <Col lg="4">
            <AppSelect
              id="role"
              name="role"
              menuItems={getUserRole()}
              label="Role"
              fullWidth
              isRequired={true}
              value={formik.values?.role}
              onChange={formik.handleChange}
              error={formik.errors.role}
              touched={formik.touched.role}
            />
          </Col>
          <Col lg="4">
            <AppTextField
              id="fullName"
              name="fullName"
              fullWidth
              type="text"
              label="Full Name"
              isRequired={true}
              placeholder="Enter Full Name"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4">
            <AppSelect
              id="restaurant"
              name="restaurant"
              label="Restaurant"
              menuItems={getRestaurantList()}
              value={formik.values.restaurant}
              onChange={formik.handleChange}
              error={formik.errors.restaurant}
              isRequired={true}
              touched={formik.touched.restaurant}
            />
          </Col>
          <Col lg="4">
            <AppTextField
              id="email"
              name="email"
              type="text"
              fullWidth
              label="Email"
              isRequired={true}
              placeholder="Enter Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Col>

          <Col lg="4">
            <List sx={{ padding: 0 }}>
              <Box>
                <Typography variant="body1Regular">CONTACT NUMBER</Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: "5px" }} className="phoneContainer">
                <Col lg="12" className="phoneContainerBox">
                  <PhoneNumberField
                    id="contactNumber"
                    name="contactNumber"
                    country={"us"}
                    value={formik.values.contactNumber}
                    onChange={(val) => formik.setFieldValue("contactNumber", val)}
                    error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                    helperText={
                      formik.touched.contactNumber && formik.errors.contactNumber
                    }></PhoneNumberField>
                </Col>
              </Box>
            </List>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4">
            <AppTextField
              id="address"
              name="address"
              fullWidth
              type="text"
              label="ADDRESS"
              isRequired={true}
              placeholder="Enter Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Col>
          <Col lg="4">
            <List sx={{ padding: 0 }}>
              <Box>
                <Typography variant="body1Regular">DATE OF HIRE</Typography>
              </Box>
              <Box sx={{ display: "flex", marginTop: "5px" }} className="datesContainer">
                <Col lg="12" className="dateFieldContainer">
                  <AppDatePicker
                    views={["year", "month", "day"]}
                    inputFormat="dd MMM yyyy "
                    maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
                    key={"hireDate"}
                    value={formik.values.hireDate}
                    minDate={new Date().setFullYear(new Date().getFullYear() - 10)}
                    onChange={(val) => formik.setFieldValue("hireDate", val)}
                    error={formik.errors.hireDate}
                    touched={formik.touched.hireDate}
                  />
                </Col>
              </Box>
            </List>
          </Col>
          <Col lg="4">
            <AppTextField
              id="reportingManager"
              name="reportingManager"
              fullWidth
              type="text"
              label="Reporting Manager"
              isRequired={true}
              placeholder="Enter  Name"
              value={formik.values.reportingManager}
              onChange={formik.handleChange}
              error={formik.touched.reportingManager && Boolean(formik.errors.reportingManager)}
              helperText={formik.touched.reportingManager && formik.errors.reportingManager}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4">
            <Form variant="filled">
              <FormControl component="fieldset">
                <div className="d-flex align-items-center justify-content-center">
                  <Box className="me-4">
                    <Typography variant="body1Regular" className="me-2">
                      Assign Special User
                    </Typography>
                    <Tooltip title="A special user is one who gets access to the mobile app, despite being a corporate user. As a special user, you can play the Trivia, Raffle, and see other exciting features that are played on the mobile app.">
                      <InfoIcon sx={{ width: "16px" }} />
                    </Tooltip>
                  </Box>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="specialUser"
                    value={formik.values.specialUser}
                    onChange={(event) => {
                      formik.setFieldValue("specialUser", event.currentTarget.value)
                    }}>
                    {options.map((option) => (
                      <FormControlLabel
                        key={option}
                        value={option.toString()}
                        control={<Radio color="secondary" />}
                        label={option === "true" ? "Yes" : "No"}
                      />
                    ))}
                  </RadioGroup>
                </div>
                <FormHelperText error={true}>
                  {formik.touched.specialUser && formik.errors.specialUser}
                </FormHelperText>
              </FormControl>
            </Form>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="12">
            {/* {isEditContent &&
                            <AppButton style={{ padding: "14px 40px", marginRight: "10px", }}>
                                Send Email
                            </AppButton>
                        } */}
            <AppButton
              onClick={formik.handleSubmit}
              style={{ padding: "14px 40px", backgroundColor: theme.palette.secondary.main }}>
              Save User
            </AppButton>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdminUserForm
