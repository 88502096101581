import { Box, CircularProgress, Grid } from "@mui/material";
import Header from "components/Header/Header";
import AppButtonGradient from "components/StyledComponents/AppButtonGradient";
import CustomDivider from "components/StyledComponents/Divider";
import { Formik } from "formik";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import { formatDate } from "helpers/functions";
import { validationSchemaDMVGrandCru } from "helpers/validationSchema";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import {
  getGrandCruGoalsAction,
  getGrandCruVideoDataAction,
  updateGrandCruVideoAction
} from "redux/DMV/actions";
import { DMVActions } from "redux/DMV/slice/user";
import BasicDetailsForm from "./components/BasicDetailsForm";
import GoalsForm from "./components/GoalsForm";

const GrandCru = () => {
  const history = useHistory();
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { videoData, thumbnail, video, isVideoSubmitted, loading } =
    useSelector((store) => store?.dmv?.grandCru || {});
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({
    id: null,
    title: "",
    description: "",
    date: new Date(),
    video: null,
    videoUrl: "",
    thumbnail: null
  });

  const handleSubmit = (values) => {
    setIsFormSubmitting(true);
    const payload = {
      id: values.id,
      title: values.title,
      description: values.description,
      date: formatDate(values.date),
      url: values.videoUrl || null,
      ...(thumbnail ? { thumbnail } : {})
    };
    if (values.video && video) {
      payload.video = video;
    } else if (values.videoUrl) {
      payload.video = null;
    }
    dispatch(updateGrandCruVideoAction(payload));
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Grand Cru - DMV";
    dispatch(DMVActions.initGrandCru());
    dispatch(getGrandCruGoalsAction());
    dispatch(getGrandCruVideoDataAction());
  }, []);

  useEffect(() => {
    if (videoData?.id) {
      setInitialData({
        id: videoData.id,
        title: videoData.title || "",
        description: videoData.description || "",
        date: new Date(videoData.date),
        video: videoData.video
          ? { id: videoData.video, url: videoData.video_url }
          : null,
        videoUrl: videoData.url || null,
        thumbnail: videoData.thumbnail_url
          ? { id: "", url: videoData.thumbnail_url }
          : null
      });
    }
  }, [videoData]);

  useEffect(() => {
    if (isVideoSubmitted) {
      dispatch(DMVActions.setGrandCruVideoSubmitted(false));
      history.push("/dmv/dashboard");
    }
  }, [isVideoSubmitted]);

  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header title="Grand Cru" breadCrumbs={breadCrumbs} back={true} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Formik
            initialValues={initialData}
            enableReinitialize={true}
            validationSchema={validationSchemaDMVGrandCru}
            onSubmit={handleSubmit}>
            {(formik) => (
              <>
                <Prompt
                  when={formik.dirty && !isFormSubmitting}
                  message={
                    "Changes you made may not be saved. Are you sure you want to leave this page ?"
                  }
                />
                <BasicDetailsForm formik={formik} />
                <Box height={35} />
                <Grid container>
                  <Grid item xs={2}>
                    <AppButtonGradient
                      disabled={Boolean(loading)}
                      onClick={formik.handleSubmit}
                      sx={{ height: "48px", width: "100%" }}
                      variant="filled">
                      {loading ? (
                        <Box className="d-flex">
                          <CircularProgress
                            size={20}
                            sx={{ color: "#FFFFFF", marginRight: "5px" }}
                          />
                          <span>Please Wait...</span>
                        </Box>
                      ) : (
                        "Submit"
                      )}
                    </AppButtonGradient>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
          <CustomDivider style={{ margin: "35px 0px" }} />
          <GoalsForm setIsFormSubmitting={setIsFormSubmitting} />
        </Col>
      </Row>
    </Container>
  );
};

export default GrandCru;
