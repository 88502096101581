import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import WinePicture from "assets/images/AdditionalResources/cooperhawk_wine_club_dinner.png";
import Card from "components/Card/Card";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";

function ModalContent({ data, handleClose }) {
  const theme = useTheme();
  const styles = {
    card: {
      paddingLeft: "36px",
      paddingRight: "36px",
      paddingTop: "36px",
      position: "relative"
    },
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "5px",
      right: "5px",
      backgroundColor: theme.palette.background.main
    }
  };

  return (
    <Card style={styles.card}>
      <IconButton onClick={handleClose} sx={styles.closeBtn}>
        <CloseIcon sx={{ height: "14px", width: "14px" }} />
      </IconButton>
      {data.type === "image" && (
        <img
          style={{ borderRadius: 12 }}
          src={WinePicture}
          alt="Wine club member dinner"
        />
      )}
      {data.type === "video" && (
        <ReactPlayer url="https://youtu.be/Fztv0tWhIuY" />
      )}
      <Box className="d-flex justify-content-center align-items-center mt-3">
        <Typography variant="body1Regular">{data.title}</Typography>
      </Box>
    </Card>
  );
}

export default ModalContent;
