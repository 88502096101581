import React, { useEffect, useState } from "react"
import DMVButtonNav from "./components/DMVButtonNav"
import { useDispatch } from "react-redux"
import { DMVActions } from "redux/DMV/slice/user"

const tabItems = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

function DMVNavBar(props) {
  const [menu] = useState(menuNav)
  const dispatch = useDispatch()

  function handleButtonNav(item) {
    dispatch(DMVActions.setDMV_Menu_Link(item))
  }

  useEffect(() => {
    if (!props.activeTab) {
      dispatch(DMVActions.setDMV_Menu_Link(tabItems.WINE_REGION))
    }
  }, [])

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <DMVButtonNav
            style={{ marginRight: 12 }}
            onPress={() => handleButtonNav(item?.value)}
            key={i}
            isActive={props.activeTab === item.value ? true : false}>
            {item.name}
          </DMVButtonNav>
        )
      })}
    </>
  )
}

export default DMVNavBar

const menuNav = [
  {
    name: "Wine Region",
    isActive: true,
    value: tabItems?.WINE_REGION
  },
  {
    name: "Beverage",
    isActive: false,
    value: tabItems?.BEVERAGE
  },
  {
    name: "Spirits",
    isActive: false,
    value: tabItems?.SPIRITS
  }
]
