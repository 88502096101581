import React from "react";
import { List, ListItem, Typography } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import Card from "components/Card/Card";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomDivider from "components/StyledComponents/Divider";

function AreaDirectorTriviaAnalyticsSnapshotUI({ routeNext }) {
  const history = useHistory();

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Trivia Analytics</Typography>
          <span onClick={() => history.push(routeNext)}>
            <IconButtonWithBorder aria-label="Trivia Analytics">
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Active Players
          </Typography>
          <Typography variant="body1Regular">{data.activePlayers}</Typography>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Change from previous week
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            {data.activePlayerChanges + "%"}
          </Typography>
        </ListItem>
        <CustomDivider />
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Correct answers
          </Typography>
          <Typography variant="body1Regular">
            {data.correctAnswers + "%"}
          </Typography>
        </ListItem>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "12px 0 12px 0" }}>
          <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
            Change from previous week
          </Typography>
          <Typography
            variant="body1Regular"
            sx={(theme) => ({ color: theme.palette.green.main })}>
            {data.correctAnswerChanges + "%"}
          </Typography>
        </ListItem>
      </List>
    </Card>
  );
}

export default AreaDirectorTriviaAnalyticsSnapshotUI;

const data = {
  activePlayers: 50,
  activePlayerChanges: 20,
  correctAnswers: 70,
  correctAnswerChanges: 30
};
