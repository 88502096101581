import React, { useState } from "react"
import { FormControl, Grid } from "@mui/material"
import { useFormik } from "formik"
import { validationSchemaCSV } from "helpers/validationSchema"
import AppButton from "components/StyledComponents/AppButton"
import DragAndDropUser from "./DragAndDropUser"
import { useDispatch } from "react-redux"
import { uploadQuestionBulkAction } from "redux/L&D/actions"

function UploadTabUser({ onClose, isEdit = false }) {
  const [allFilesSelected, setAllFilesSelected] = useState({
    csvUploaded: false
  })
  const [isDisabled, setDisable] = useState(false)
  const dispatch = useDispatch()

  const styles = {
    container: {
      marginTop: "5px"
    },
    uploadBtnContainer: {
      padding: "12px 79px"
    }
  }

  const formik = useFormik({
    initialValues: {
      csvUrl: ""
    },
    validationSchema: validationSchemaCSV,

    onSubmit: (values) => {
      dispatch(uploadQuestionBulkAction({ data: values.csvUrl, isEdit: isEdit }))
      onClose()
      // dispatch(getAdminUsersListAction({
      //   page: currentPage,
      //   orderBy: orderBy
      // }))
    }
  })

  return (
    <FormControl className="mt-4" style={{ width: "100%" }}>
      <Grid container sx={styles.container}>
        {/* Drag and Drop PDF */}
        <Grid item sm={12}>
          <DragAndDropUser
            setDisable={setDisable}
            type="CSV"
            setAllFilesSelected={setAllFilesSelected}
            onChange={(url) => formik.setFieldValue("csvUrl", url)}
          />
        </Grid>

        {/* Upload Button */}
        {allFilesSelected.csvUploaded && (
          <Grid
            item
            sm={12}
            className="d-flex justify-content-center align-items-center"
            style={{ paddingTop: "0px" }}>
            {isDisabled && (
              <AppButton onClick={formik.handleSubmit} className={styles.uploadBtn}>
                Upload
              </AppButton>
            )}
          </Grid>
        )}
      </Grid>
    </FormControl>
  )
}

export default UploadTabUser
