import { Box, Dialog, DialogContent, IconButton, Grid, Typography, styled } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppSelect from "components/StyledComponents/AppSelect"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { validationSchemaTriviaMonth } from "helpers/validationSchema"
import styles from "../EditQuestion/EditQuestion.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { addQuestionToTriviaAction, getTriviaMonthListAction } from "redux/L&D/actions/user"
import { useHistory } from "react-router-dom"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const AddToTrivia = ({ onClose = () => {}, data = {}, isIncorrect }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { triviaMonthList } = useSelector((state) => state?.learningDevelopment)
  const [months, setMonths] = useState([])
  const handleSubmit = (values) => {
    const payload = {
      ...data,
      trivia: values?.month
    }
    const handleRoute = () => {
      history.push("/learning_development/dashboard/trivia-contest")
    }
    dispatch(addQuestionToTriviaAction({ payload: payload, handleRoute: handleRoute }))
    onClose()
  }

  useEffect(() => {
    dispatch(getTriviaMonthListAction(isIncorrect))
  }, [isIncorrect])

  useEffect(() => {
    let resData = []
    if (triviaMonthList?.length > 0) {
      for (let i of triviaMonthList) {
        resData.push({
          value: i.id,
          label: i.trivia_month
        })
      }
    }
    setMonths(resData)
  }, [triviaMonthList])
  return (
    <StyledDialog open={true} maxWidth="">
      <DialogContent id="editQuestionDialog" className={styles.addTriviaDialogBox}>
        <Formik
          initialValues={[]}
          validationSchema={validationSchemaTriviaMonth}
          enableReinitialize={true}
          onSubmit={handleSubmit}>
          {(formik) => (
            <React.Fragment>
              <Box className="d-flex justify-content-center position-relative">
                <Typography variant="h2" className="my-3">
                  Add Questionnaire
                </Typography>
                <Box className={styles.closeButton}>
                  <span role="button" onClick={onClose}>
                    <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                      <CloseIcon sx={{ height: "14px", width: "14px" }} />
                    </IconButton>
                  </span>
                </Box>
              </Box>
              <Box height={40} />
              <Box sx={{ padding: "0 11px" }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={6}>
                    <AppSelect
                      id="month"
                      name="month"
                      menuItems={months}
                      label="Trivia Month"
                      isRequired={true}
                      value={formik.values.month}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
                <Box className="d-flex justify-content-center">
                  <AppButton
                    className="mt-3 px-5"
                    variant="outlined"
                    disabled={formik.values.month ? false : true}
                    onClick={formik.handleSubmit}>
                    Save
                  </AppButton>
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default AddToTrivia
