import React, { useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import MiniCalendar from "components/DashboardCards/MiniCalendar"
import Header from "components/Header/Header"

// import PerformanceSummaryCard from "./components/PerformanceSummaryCard.js";

// import TitleCard from "../../../../components/TitleCard/index.js";
// import GaugeChart from "components/GaugeChart/gaugeChart.js";

import { useHistory } from "react-router-dom"
import Top5LeadersUI from "components/DashboardCards/Top5LeadersUI/Top5LeadersUI"
// import Card from "components/Card/Card";
import GMTriviaAnalyticsSnapshotUI from "./components/TriviaAnalytics/index.js"
// import PerformanceTrackingGraph from "components/PerformanceTrackingGraph";
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { getLeaderboardDataAction, getWineMonthAction } from "redux/marketing/actions/user.js"
import {
  getLeaderBoardGMListAction,
  getTriviaDashboardAnalyticsAction
} from "redux/GM/actions/user.js"
import { formatDate, getLastDayOfMonth } from "helpers/functions"
import { appActions } from "redux/app/slice/app.js"
import { makeStyles } from "@material-ui/core"
import FeatureItemOfMonthSnapShot from "components/FeatureItemOfMonthSnapShot/index.js"

const useStyles = makeStyles(() => ({
  rowGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    "@media (max-width: 1300px)": {
      gridTemplateColumns: "1fr 1fr"
    },
    "@media (max-width: 900px)": {
      gridTemplateColumns: "1fr"
    }
  }
}))

function GMDashboard() {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const { leaderBoardListGM } = useSelector((store) => store?.gm || [])

  const { date } = useSelector((store) => store?.app?.filters || {})
  const { dashboardData } = useSelector((store) => store?.gm?.triviaAnalytics || [])
  const { results } = useSelector((store) => store?.marketing?.leaderboard?.data || {})

  const { listWineMonthData } = useSelector((store) => store?.marketing?.wineOfTheMonth || [])

  function getTop5Leader() {
    return results?.map((res, index) => ({
      id: index,
      key: res?.user?.name,
      field: res?.coins_earned,
      restaurantName: res?.user?.rest_name ?? ""
    }))
  }

  useEffect(() => {}, [leaderBoardListGM])

  useEffect(() => {
    const currentDate = formatDate(new Date())
    dispatch(getWineMonthAction(getLastDayOfMonth(currentDate)))
    dispatch(getLeaderboardDataAction({ date: currentDate, page: 1 }))
    dispatch(getLeaderBoardGMListAction({ date: formatDate(new Date()), page: 1 }))
    dispatch(getTriviaDashboardAnalyticsAction())
  }, [])

  useEffect(() => {
    document.title = "Dashboard - GM"
  }, [])

  useEffect(() => {
    getTop5Leader()
    dispatch(appActions.initFilters())
  }, [])

  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header
            title="General Manager Dashboard"
            back={false}
            // notificationIcon="/gm/dashboard/notifications"
            // isNotify={true}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FeatureItemOfMonthSnapShot
            nextRoute={"/gm/dashboard/featured-Items-of-the-Month"}
            listData={listWineMonthData}></FeatureItemOfMonthSnapShot>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12">
          <Row className={classes.rowGrid}>
            {/* <Col lg="12">
              <TitleCard>Statistics</TitleCard>
            </Col> */}
            <Col lg="12" className="calendarCtr">
              <MiniCalendar
                style={{ height: 400 }}
                onClick={() => history.push("/gm/dashboard/calendar")}
              />
            </Col>
            <Col lg="12">
              <GMTriviaAnalyticsSnapshotUI
                style={{ height: 400 }}
                date={moment(date).format("MMM'YY")}
                analyticsData={dashboardData}
                routeNext="/gm/dashboard/trivia-analytics"
              />
            </Col>
            <Col lg="12">
              <Top5LeadersUI
                style={{ height: 400 }}
                date={moment(date).format("MMM'YY")}
                tableData={getTop5Leader()}
                routeNext="/gm/dashboard/leaderboard"
              />
            </Col>
            {/* In Next phase */}
            {/* <Col lg="12">
              <PerformanceSummaryCard />
            </Col> */}
          </Row>
        </Col>
        {/* <Col lg="6"> */}
        {/* <Col lg="12">
            <TitleCard>Analytics</TitleCard>
          </Col> */}

        {/* In Next Phase */}
        {/* <Col lg="12">
            <Card
              style={{ marginTop: 24, marginBottom: 24, paddingBottom: 12 }}>
              <GaugeChart
                isIconHeading={true}
                routeNext="/gm/dashboard/notifications/performance-management"
                headingTitle="% of Goal"
                isHeading={true}></GaugeChart>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <GaugeChart
                isIconHeading={true}
                routeNext="/gm/dashboard/performance-management-progress"
                headingTitle="% of improved"
                isHeading={true}></GaugeChart>
            </Card>
          </Col>
          <Col lg="12" style={{ marginTop: 44 }}>
            <PerformanceTrackingGraph
              height={300}
              isHeading={true}
              position="bottom"
              alignLegend="center"
              data={data2}
              id={1}></PerformanceTrackingGraph>
          </Col> */}
        {/* </Col> */}
      </Row>
    </Container>
  )
}

export default GMDashboard

//   {
//     day: "Oct 01",
//     TRTeam: 11.6,
//     MentorTRTeam: 1.0
//   },
//   {
//     day: "oct 02",
//     TRTeam: 11.8,
//     MentorTRTeam: 4.0
//   },
//   {
//     day: "oct 15",
//     TRTeam: 12.2,
//     MentorTRTeam: 7.0
//   },
//   {
//     day: "oct 22",
//     TRTeam: 12.6,
//     MentorTRTeam: 10.5
//   },
//   {
//     day: "oct 29",
//     TRTeam: 13.6,
//     MentorTRTeam: 13.6
//   },
//   {
//     day: "Nov 05",
//     TRTeam: 13.0,
//     MentorTRTeam: 15.0
//   },
//   {
//     day: "Nov 12",
//     TRTeam: 14.0,
//     MentorTRTeam: 17.0
//   },
//   {
//     day: "Nov 19",
//     TRTeam: 15.0,
//     MentorTRTeam: 18.5
//   }
// ];
