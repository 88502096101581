import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  Typography
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "../GenerateQyestion.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import AppButton from "components/StyledComponents/AppButton"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "react-bootstrap"
import SearchIcon from "@mui/icons-material/Search"
import Card from "components/Card/Card"
import ImageDisplay from "components/ImageDisplayer"
import classes from "pages/private/marketing/menu/menu.module.scss"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { toast } from "react-toastify"
import { useDebounce } from "hooks/utils"
import { useTheme } from "@mui/system"
import SearchTextField from "components/StyledComponents/SearchTextField"
import DMVGenerateQuestionNavBar from "components/DMVGenerateQuestionNavBar"
import {
  getGenerateQuestionDMVBeverageAction,
  getGenerateQuestionDMVSpiritsAction,
  getGenerateQuestionDMVWineRegionAction
} from "redux/DMV/actions/user"
import { DMVActions } from "redux/DMV/slice/user"
import AppPagination from "components/StyledComponents/AppPagination"

const menuItems = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

const DMVSelectMenuItems = ({ onClose = () => {} }) => {
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState("")
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const {
    DMVgenerate_question_MenuLink,
    wineRegionData,
    beverageData,
    SelectedDMVMenuItems,
    generateQuestionSpiritData
  } = useSelector((state) => state.dmv)

  const debouncedSearchTerm = useDebounce(searchValue, 500)

  const handleSearchField = (e) => {
    setSearchValue(e.target.value)
  }

  //Trivia_menu_search

  const [selectedItem, setSelectedItem] = useState(SelectedDMVMenuItems || [])

  //checks item is selected or not
  const checkSelected = (ID) => {
    let isItemSelected = selectedItem.filter((item) => item?.id === ID)
    if (isItemSelected?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const handleSelect = (data) => {
    //check that item is already selected or not
    const isAvailable = selectedItem.filter((item) => item?.id === data?.id)
    //if item is already selected remove that item from list
    if (isAvailable?.length > 0) {
      const updatedList = selectedItem.filter((item) => item?.id !== data?.id)
      setSelectedItem(updatedList)
    } else {
      //if selected items count is more then 5 throw error
      if (selectedItem?.length === 5) {
        return toast.error("Only five items can be selected", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "dark"
        })
      }

      const newData = {
        ...data,
        category:
          DMVgenerate_question_MenuLink === menuItems?.WINE_REGION
            ? "WINE_REGION"
            : DMVgenerate_question_MenuLink === menuItems?.BEVERAGE
            ? "BEVERAGE"
            : "SPIRITS"
      }
      //add new item in list
      setSelectedItem([...selectedItem, newData])
    }
  }

  const handlePreview = () => {
    if (selectedItem?.length > 0) {
      dispatch(DMVActions.setDMVMenuItems(selectedItem))
      onClose()
    } else {
      toast.error("Please select atleast one menu item!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark"
      })
    }
  }

  useEffect(() => {
    dispatch(DMVActions.setDMVGenerate_Question_MenuLink("wine_region"))
  }, [])

  useEffect(() => {
    if (DMVgenerate_question_MenuLink === menuItems.BEVERAGE) {
      dispatch(getGenerateQuestionDMVBeverageAction({ search: debouncedSearchTerm }))
    } else if (DMVgenerate_question_MenuLink === menuItems.WINE_REGION) {
      dispatch(getGenerateQuestionDMVWineRegionAction({ search: debouncedSearchTerm }))
    } else if (DMVgenerate_question_MenuLink === menuItems.SPIRITS) {
      dispatch(
        getGenerateQuestionDMVSpiritsAction({ page: currentPage, search: debouncedSearchTerm })
      )
    }
  }, [DMVgenerate_question_MenuLink, debouncedSearchTerm, currentPage])

  // useEffect(() => {
  //   if (
  //     generate_question_MenuLink !== menuItems.BAR_MENU &&
  //     generate_question_MenuLink !== menuItems.WINE &&
  //     debouncedSearchTerm !== ""
  //   ) {
  //     dispatch(getMenuDinnerLunchSearchAction({ debouncedSearchTerm, generate_question_MenuLink }))
  //   }
  // }, [debouncedSearchTerm])

  return (
    <StyledDialog open={true} maxWidth="">
      <DialogContent id="editQuestionDialog" className={styles.dialogBox}>
        <React.Fragment>
          <Box className="d-flex justify-content-center position-relative">
            <Typography variant="h2">Select Menu Item</Typography>
            <Box className={styles.closeButton}>
              <span role="button" onClick={onClose}>
                <IconButton sx={(theme) => ({ color: theme.palette.text.primary })}>
                  <CloseIcon sx={{ height: "14px", width: "14px" }} />
                </IconButton>
              </span>
            </Box>
          </Box>
          <Box height={40} />
          <Box sx={{ padding: "0 11px" }}>
            <Row className="mt-4">
              <Col lg="12">
                <SearchTextField
                  id="search"
                  name="search"
                  value={searchValue}
                  onChange={handleSearchField}
                  fullWidth
                  type="search"
                  placeholder="Search Menu Item"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          backgroundColor: theme.palette.secondary.main
                        }}>
                        <SearchIcon htmlColor="#C4C4C4" />
                      </InputAdornment>
                    )
                  }}
                  style={{
                    border: "1px solid #C4C4C4",
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 6
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <DMVGenerateQuestionNavBar
                  activeTab={DMVgenerate_question_MenuLink}></DMVGenerateQuestionNavBar>
              </Col>
            </Row>
            {selectedItem?.length > 0 && (
              <Row className="mt-3" style={{ marginLeft: 1 }}>
                <Typography variant="caption" color={theme.palette.secondary.main}>
                  {selectedItem?.length} of 5 selected
                </Typography>
              </Row>
            )}
            <>
              {/* Beverage items list */}
              {DMVgenerate_question_MenuLink === menuItems?.BEVERAGE && (
                <Card>
                  <Grid container spacing={2}>
                    {beverageData?.map((item, index) => (
                      <Card className="mb-2" key={index}>
                        <div className="d-flex justify-content-start align-item-center mb-4">
                          <Typography variant="h2">{item?.food_category}</Typography>
                          <div className="d-flex align-items-center"></div>
                        </div>
                        <Grid
                          container
                          spacing={2}
                          justifyContent={item?.food_list?.length === 0 ? "center" : ""}
                          className="mb-4">
                          {item?.food_list?.length ? (
                            item?.food_list?.map((data) => (
                              <Grid
                                item
                                xs={4}
                                className={classes.triviaItems}
                                sx={{ border: "none" }}
                                key={data?.id}>
                                <div className="d-flex align-items-center">
                                  {checkSelected(data?.id) ? (
                                    <IconButton
                                      sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                      <CheckCircleIcon onClick={() => handleSelect(data)} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                      <RadioButtonUncheckedIcon
                                        onClick={() => handleSelect(data)}
                                      />
                                    </IconButton>
                                  )}
                                </div>
                                <div
                                  className={classes.imageContainer}
                                  style={{ height: 120, width: 120 }}>
                                  <ImageDisplay data={data?.image_url}></ImageDisplay>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    maxWidth: 300,
                                    overflowWrap: "break-word"
                                  }}>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                  </div>
                                </div>
                              </Grid>
                            ))
                          ) : (
                            <Typography
                              className="mt-4"
                              style={{
                                opacity: 0.6,
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                              }}>
                              No Items Found
                            </Typography>
                          )}
                        </Grid>
                      </Card>
                    ))}
                  </Grid>
                </Card>
              )}

              {/*  Wine Region items list */}
              {DMVgenerate_question_MenuLink === menuItems?.WINE_REGION && (
                <>
                  {wineRegionData?.map((item, index) => (
                    <Card className="mb-2" key={index}>
                      <div className="d-flex justify-content-start align-item-center mb-4">
                        <Typography variant="h2">{item?.food_category}</Typography>
                        <div className="d-flex align-items-center"></div>
                      </div>
                      <Grid
                        container
                        spacing={2}
                        justifyContent={item?.food_list?.length === 0 ? "center" : ""}
                        className="mb-4">
                        {item?.food_list?.length ? (
                          item?.food_list?.map((data) => (
                            <Grid
                              item
                              xs={4}
                              className={classes.triviaItems}
                              key={data?.id}
                              sx={{ border: "none" }}>
                              <div className="d-flex align-items-center">
                                {checkSelected(data?.id) ? (
                                  <IconButton
                                    sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                    <CheckCircleIcon onClick={() => handleSelect(data)} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                    <RadioButtonUncheckedIcon onClick={() => handleSelect(data)} />
                                  </IconButton>
                                )}
                              </div>
                              <div
                                className={classes.imageContainer}
                                style={{ height: 120, width: 120 }}>
                                <ImageDisplay data={data?.image}></ImageDisplay>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  maxWidth: 300,
                                  overflowWrap: "break-word"
                                }}>
                                <div className="d-flex justify-content-start align-items-center">
                                  <Typography variant="h4">{data?.name ?? "-"}</Typography>
                                </div>
                              </div>
                            </Grid>
                          ))
                        ) : (
                          <Typography
                            className="mt-4"
                            style={{
                              opacity: 0.6,
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}>
                            No Items Found
                          </Typography>
                        )}
                      </Grid>
                    </Card>
                  ))}
                </>
              )}

              {/*  Spirits items list */}
              {DMVgenerate_question_MenuLink === menuItems?.SPIRITS && (
                <>
                  <Card className="mb-2">
                    <Grid
                      container
                      spacing={2}
                      justifyContent={generateQuestionSpiritData?.count === 0 ? "center" : ""}
                      className="mb-4">
                      {generateQuestionSpiritData?.results?.map((item, index) => (
                        <Grid
                          item
                          xs={4}
                          key={index}
                          className={classes.triviaItems}
                          sx={{ border: "none" }}>
                          <div className="d-flex align-items-center">
                            {checkSelected(item?.id) ? (
                              <IconButton sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                <CheckCircleIcon onClick={() => handleSelect(item)} />
                              </IconButton>
                            ) : (
                              <IconButton sx={(theme) => ({ color: theme.palette.secondary.main })}>
                                <RadioButtonUncheckedIcon onClick={() => handleSelect(item)} />
                              </IconButton>
                            )}
                          </div>
                          <div
                            className={classes.imageContainer}
                            style={{ height: 120, width: 120 }}>
                            <ImageDisplay data={item?.image_url}></ImageDisplay>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              maxWidth: 300,
                              overflowWrap: "break-word"
                            }}>
                            <div className="d-flex justify-content-start align-items-center">
                              <Typography variant="h4">{item?.name ?? "-"}</Typography>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Card>
                  <AppPagination
                    onPageChange={setCurrentPage}
                    totalPages={generateQuestionSpiritData?.pages}
                    currentPage={currentPage}
                  />
                </>
              )}
            </>
            <Box className="d-flex justify-content-center">
              <AppButton className="mt-3 px-5" variant="outlined" onClick={handlePreview}>
                Preview Menu Item
              </AppButton>
            </Box>
          </Box>
        </React.Fragment>
      </DialogContent>
    </StyledDialog>
  )
}

export default DMVSelectMenuItems
