import React, { Fragment } from "react";
import { Typography } from "@mui/material";
import "./WinnerList.scss";

function WinnerList({ userImg, userName }) {
  return (
    <Fragment>
      <div className="d-flex winners__List">
        <div className="WinnerImg">
          <img src={userImg}></img>
        </div>
        <div>
          <Typography variant="emphasisText" className="text-uppercase">
            {userName}
          </Typography>
        </div>
      </div>
    </Fragment>
  );
}

export default WinnerList;
