import React, { useEffect } from "react";
import { Redirect, Route } from "react-router";
import PublicLayout from "../layout/publicLayout";
import { useIsLoggedIn } from "hooks/state";
import { useSelector } from "react-redux";
import { Roles } from "helpers/role";



const PublicWrapper = ({ component: Component, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();
  const role = useSelector((res) => res.user?.userData?.role);

  useEffect(() => {

  }, [role])


  switch (role) {
    case "DMV":
      return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn !== true ? (
              <PublicLayout {...props}>
                <Component {...props} />
              </PublicLayout>
            ) : (
              <Redirect
                to={{
                  pathname: "/dmv/dashboard",
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      );
    default:
      return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn !== true ? (
              <PublicLayout {...props}>
                <Component {...props} />
              </PublicLayout>
            ) : (
              <Redirect
                to={{
                  pathname: `/${Roles[role] === "super-admin" ? "marketing" : Roles[role]}/dashboard`,
                  state: { from: props.location }
                }}
              />
            )
          }
        />
      );
  }
};

export default PublicWrapper;
