import { Grid, Typography } from "@mui/material"
import Card from "components/Card/Card"
import DetailField from "components/DetailField"
import React from "react"

const WineCharacteristics = ({ data, alcoholType }) => {
  return (
    <>
      <Typography variant="body1Medium">
        {alcoholType === "wine" ? "Wine Characteristics" : "Spirit Characteristics"}
      </Typography>
      {data.map((wineCharacteristic) => (
        <Card key={wineCharacteristic.id} className="mb-4">
          <Grid container spacing={3}>
            <Grid item md={3} sm={4}>
              <DetailField
                label="Characteristic"
                value={wineCharacteristic?.characteristic ?? "-"}
              />
            </Grid>
            <Grid item md={3} sm={4}>
              <DetailField label="Level" value={wineCharacteristic?.level ?? "-"} />
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  )
}

export default WineCharacteristics
