import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "components/Header/Header";
import GaugeChart from "components/GaugeChart/gaugeChart.js";
// import { useHistory } from "react-router-dom";
import Card from "components/Card/Card";
import TitleCard from "components/TitleCard";
import WineOfTheMonth from "components/DashboardCards/WineOfTheMonth";
import InviteRateRankingsCard from "./components/InviteRateRankingsCard";
import MiniCalendar from "components/DashboardCards/MiniCalendar";
import { useHistory } from "react-router-dom";
import AreaDirectorTriviaAnalyticsSnapshotUI from "./components/TriviaAnalytics";
import TopFrontlineMembers from "./components/TopFrontlineMembers";
import { useDispatch, useSelector } from "react-redux";
import { getWineMonthAction } from "redux/marketing/actions";
import moment from "moment";

const AreaDirectorDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { listWineMonthData } = useSelector(
    (store) => store?.marketing?.wineOfTheMonth || {}
  );

  useEffect(() => {
    document.title = "Dashboard - Area Director";
  }, []);

  useEffect(() => {
    dispatch(getWineMonthAction(moment(new Date).format('YYYY-MM-DD')));
  }, []);
  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header
            title="Area Director Dashboard"
            back={false}
            notificationIcon="/area-director/dashboard/notifications"
            isNotify={true}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="12">
          <Row>
            <Col lg="12">
              <TitleCard>Statistics</TitleCard>
            </Col>
            <Col lg="12" className="calendarCtr">
              <MiniCalendar
                onClick={() =>
                  history.push("/area-director/dashboard/calendar")
                }
              />
            </Col>
            <Col lg="12">
              <InviteRateRankingsCard routeNext="/area-director/dashboard/performance-summary" />
            </Col>
            <Col lg="12">
              <TopFrontlineMembers routeNext="/area-director/dashboard/leaderboard" />
            </Col>
            <Col lg="12">
              <AreaDirectorTriviaAnalyticsSnapshotUI routeNext="/area-director/dashboard/trivia-analytics" />
            </Col>
            <Col lg="12">
              <WineOfTheMonth
              tableRowData={listWineMonthData}
                routeNext={"/area-director/dashboard/featured-Items-of-the-Month"}
              />
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Col lg="12">
            <TitleCard>Performance Management</TitleCard>
          </Col>
          <Col lg="12">
            <Card
              style={{ marginTop: 24, marginBottom: 24, paddingBottom: 12 }}>
              <GaugeChart
                isIconHeading={true}
                routeNext="/area-director/dashboard/percentage-of-goal"
                headingTitle="% of Goal"
                isHeading={true}></GaugeChart>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <GaugeChart
                isIconHeading={true}
                routeNext="/area-director/dashboard/performance-management-progress"
                headingTitle="% of improved"
                isHeading={true}></GaugeChart>
            </Card>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default AreaDirectorDashboard;
