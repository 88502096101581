import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme
} from "@mui/material"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import { toTitleCase } from "helpers/functions"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 800,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

const Preview = ({ open, onClose, data }) => {
  const theme = useTheme()
  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    },
    main_grid: {
      display: "grid",
      placeItems: "center",
      marginTop: "24px"
    },
    quarterly_card: {
      display: "grid",
      width: "340px",
      height: "200px",
      borderRadius: "24px",
      overflow: "hidden",
      position: "relative"
    },
    monthly_card: {
      display: "grid",
      borderRadius: "24px",
      overflow: "hidden",
      position: "relative",
      width: "340px",
      height: "360px"
    },
    backgroundImageContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${data?.image_url})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      filter: "opacity(0.7)"
    },
    contentContainer: {
      position: "relative",
      zIndex: 1
    }
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Grid>
          <Typography variant="h2" sx={{ textTransform: "capitalize" }}>
            {toTitleCase(data?.card_type)} Gift Card Preview
          </Typography>
        </Grid>
        <Grid item sx={styles.main_grid}>
          <Grid
            sx={data?.card_type === "QUARTERLY" ? styles.quarterly_card : styles.monthly_card}
            p={3}>
            <div style={styles.backgroundImageContainer}></div>
            <Grid item style={styles.contentContainer}>
              <Grid item>
                <Typography variant="h2">{data?.header}</Typography>
              </Grid>
            </Grid>
            <Grid item alignSelf="flex-end" style={styles.contentContainer}>
              <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <Avatar alt="Winner" sx={{ width: 36, height: 36 }} />
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography variant="caption3">
                      {data?.card_type === "QUARTERLY" ? "Q4 WINNER" : "LAST MONTH’S RAFFLE WINNER"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption4">Winner Name</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  )
}

export default Preview
