import React, { Fragment, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "components/Header/Header";
import alert from "assets/icons/alert.svg";
import { useState } from "react";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import PiggyBankTable from "./components/PiggyBankTable";
import { useSelector } from "react-redux";
import AppButton from "components/StyledComponents/AppButton";
import { Formik } from "formik";
import { useTheme } from "@mui/material";

const LeaderBoardColumns = [
  {
    id: "Rank",
    label: "Rank",
    minWidth: 50
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 170
  },
  {
    id: "Employee Class",
    label: "Employee Class  ",
    align: "center",
    minWidth: 170
  },
  {
    id: "Trivia Questions Answered Correctly",
    label: "Trivia Questions Answered Correctly  ",
    align: "center",
    minWidth: 150
  },
  {
    id: "Signups / Upgrades",
    label: "Signups / Upgrades   *",
    align: "center",
    minWidth: 180
  },
  {
    id: "Fairness Factor",
    label: "Fairness Factor   =",
    align: "center",
    minWidth: 170
  },
  {
    id: "Coins Earned",
    label: "Coins Earned",
    align: "center",
    icon: alert,
    minWidth: 150
  }
];

function DMVPiggyBank() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const theme = useTheme();
  const [usersData, setUsersData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialData, setInitialData] = useState({});
  const formikRef = useRef(null);
  const { leaderBoardUserList } = useSelector(
    (store) => store?.learningDevelopment
  );

  const styles = {
    btnEdit: {
      width: "250px",
      marginRight: 16
    },
    btnCancel: {
      color: theme.palette.red.main,
      borderColor: theme.palette.red.main,
      width: 250,
      marginRight: "16px",
      "&:hover": {
        borderColor: theme.palette.red.main,
        backgroundColor: theme.palette.background.default
      }
    },
    submitBtn: {
      width: 250,
      marginTop: 50,
      marginBottom: 100
    }
  };

  function createLeaderBoardListData() {
    setUsersData(
      leaderBoardUserList?.map((res, index) => ({
        id: res.user.id,
        rank: res.rank,
        name: res.user.name,
        image: `https://randomuser.me/api/portraits/men/3${index + 1}.jpg`,
        employeeClass: res.user.job_class,
        correctAnswers: res.correct_answer,
        signUp_Upgrades: "-",
        coins: res.cooper_coins,
        fairnessFactor: res.fairness_factor
      }))
    );
  }

  const handleSubmit = () => {};

  const initializeFormik = () => {
    setInitialData({
      userSet: leaderBoardUserList?.map((user) => ({
        correctAnswers: user.correct_answer,
        signUp_Upgrades: user.signups || 0
      }))
    });
  };

  const toggleEditMode = () => {
    setIsEditMode((isEditing) => {
      if (isEditing) {
        formikRef.current.resetForm();
      }
      return !isEditing;
    });
  };

  useEffect(() => {
    createLeaderBoardListData();
    initializeFormik();
  }, [leaderBoardUserList]);

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Piggy Bank";
  }, []);

  return (
    <Fragment>
      <Container fluid className="p-0  ">
        <Row>
          <Col lg="12">
            <Header title="Piggy Bank" breadCrumbs={breadCrumbs}>
              {isEditMode ? (
                <AppButton styles={styles.btnCancel} onClick={toggleEditMode}>
                  Cancel
                </AppButton>
              ) : (
                <AppButton styles={styles.btnEdit} onClick={toggleEditMode}>
                  Edit
                </AppButton>
              )}
            </Header>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Formik
              innerRef={formikRef}
              initialValues={initialData}
              enableReinitialize={true}
              onSubmit={handleSubmit}>
              {(formik) => (
                <>
                  <PiggyBankTable
                    isEditMode={isEditMode}
                    columns={LeaderBoardColumns}
                    rows={usersData}
                    formik={formik}
                  />
                  {isEditMode && (
                    <AppButton
                      styles={styles.submitBtn}
                      onClick={formik.handleSubmit}>
                      Submit
                    </AppButton>
                  )}
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default DMVPiggyBank;
