import { Grid, Typography, useTheme } from "@mui/material"
import Header from "components/Header/Header"
import { createBreadcrumbs } from "helpers/breadCrumbs"
import React, { useEffect, useState } from "react"
import styles from "./menu.module.scss"
import AppButton from "components/StyledComponents/AppButton"
import trashIcon from "assets/icons/trash.svg"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpwardRounded"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownwardRounded"
import AddNewFields from "./components/AddNewFields"

const MenuForm = () => {
  const theme = useTheme()
  const [breadCrumbs, setBreadcrumbs] = useState([])
  const [selectedMenu, setSelectedMenu] = useState("")
  const [menuField, setMenuField] = useState(DinnerForm)

  const handleMenuCategory = (name) => {
    setSelectedMenu(name)
    if (name === "Dinner" || name === "Lunch") setMenuField(DinnerForm)
    else if (name === "Bar Menu") setMenuField(BarForm)
    else if (name === "Wine By Glass") setMenuField(WineForm)
  }

  const handleDelete = (index) => {
    const newMenuField = menuField.filter((item) => item.index !== index)
    setMenuField(newMenuField)
  }

  const handleMoveUp = (index) => {
    const itemIndex = menuField.findIndex((item) => item.index === index)
    if (itemIndex > 0) {
      const newMenuField = [...menuField]
      const temp = newMenuField[itemIndex - 1]
      newMenuField[itemIndex - 1] = newMenuField[itemIndex]
      newMenuField[itemIndex] = temp
      setMenuField(newMenuField)
    }
  }

  const handleMoveDown = (index) => {
    const itemIndex = menuField.findIndex((item) => item.index === index)
    if (itemIndex < menuField.length - 1) {
      const newMenuField = [...menuField]
      const temp = newMenuField[itemIndex + 1]
      newMenuField[itemIndex + 1] = newMenuField[itemIndex]
      newMenuField[itemIndex] = temp
      setMenuField(newMenuField)
    }
  }

  const handleNewFields = (fields) => {
    setMenuField([...menuField, ...fields])
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs())
    document.title = "Menu Management"
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Menu Management" breadCrumbs={breadCrumbs} />
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <Grid
            item
            sx={{ background: theme.palette.primary.main, borderRadius: "12px", padding: "24px" }}>
            <Typography variant="body1Regular" sx={{ color: theme.palette.text.secondary }}>
              Select Category
            </Typography>
            <Grid item mt={3}>
              {Menu?.map((itm, index) => (
                <Grid
                  item
                  key={index}
                  sx={{
                    borderRadius: "4px",
                    padding: "10px",
                    backgroundColor:
                      selectedMenu === itm?.name ? theme.palette.primary.hover : "transparent",
                    border: "1px solid",
                    borderColor:
                      selectedMenu === itm?.name ? "transparent" : theme.palette.secondary.main,
                    marginBottom: "10px"
                  }}
                  onClick={() => handleMenuCategory(itm?.name)}>
                  <Typography>{itm?.name}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid item className={styles.arrowMenu}>
            <Grid item className={styles.arrowMenuContent}>
              {menuField?.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  key={index}
                  className={styles.itemLabel}>
                  <Grid item>
                    <Typography variant="body1Regular" sx={{ color: theme.palette.text.secondary }}>
                      {item?.fieldName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} container justifyContent="flex-end" columnGap={2}>
                    <img
                      onClick={() => handleDelete(item.index)}
                      style={{ cursor: "pointer" }}
                      src={trashIcon}
                      alt=""
                    />
                    {index !== 0 ? (
                      <ArrowUpwardIcon
                        fontSize="medium"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleMoveUp(item.index)}
                      />
                    ) : null}
                    {index !== menuField?.length ? (
                      <ArrowDownwardIcon
                        fontSize="medium"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleMoveDown(item.index)}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              ))}
              <Grid item>
                <AddNewFields handleNewFields={handleNewFields} />
              </Grid>
            </Grid>
            <Grid item container justifyContent="flex-end">
              <AppButton>Download Template</AppButton>
            </Grid>
            <Grid item className={styles.arrow}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default MenuForm

const Menu = [
  {
    id: "52dfa0f3-664a-49d9-aaf5-55c24a458527",
    icon: "https://s3.amazonaws.com/cooperhawk.media/media/IMAGE/Dinner981814c6-0fa6-4347-ab66-e535ebd65da0.png",
    name: "Dinner",
    description: null,
    restaurant: "2238201c-2bb0-4654-b35f-d312922dc017",
    image: "d414140a-ce41-4450-85f2-a826ba32e695"
  },
  {
    id: "b73a6a8d-a04a-4fe1-9fc8-7f83a4c9e16f",
    icon: "https://s3.amazonaws.com/cooperhawk.media/media/IMAGE/Lunch7f572f03-cb25-47e8-a660-19cf98fc9525.png",
    name: "Lunch",
    description: null,
    restaurant: "2238201c-2bb0-4654-b35f-d312922dc017",
    image: "6590b909-a208-461f-960e-f24a225ae277"
  },
  {
    id: "cfe746d6-92a5-42dd-9aa6-4249c0c62168",
    icon: "https://s3.amazonaws.com/cooperhawk.media/media/IMAGE/Bar_Menuc59d1bdc-ae98-4673-9fc6-df3d86a496cf.png",
    name: "Bar Menu",
    description: null,
    restaurant: "2238201c-2bb0-4654-b35f-d312922dc017",
    image: "56a5c7ca-450e-47e8-8889-85f86aebe4f3"
  },
  {
    id: "85b95ad4-cc27-441b-bc51-e78915526571",
    icon: "https://s3.amazonaws.com/cooperhawk.media/media/IMAGE/WineByGlassc6981626-c913-4d59-83a9-02ec431c3bf8.png",
    name: "Wine By Glass",
    description: null,
    image: "9d4deaec-a7be-4344-ad83-7931639eef89"
  },
  {
    id: "55730440-7414-47a7-9755-545543f78440",
    icon: null,
    name: "Spirits",
    description: null,
    image: null
  }
]

const DinnerForm = [
  { index: 1, fieldName: "Dish Name" },
  { index: 2, fieldName: "Upload Thumbnail" },
  { index: 3, fieldName: "Menu Description" },
  { index: 4, fieldName: "Allergens" }
]

const BarForm = [
  { index: 1, fieldName: "Cocktail Name" },
  { index: 2, fieldName: "Upload Thumbnail" },
  { index: 3, fieldName: "Ingredients" }
]

const WineForm = [
  { index: 1, fieldName: "Wine Name" },
  { index: 2, fieldName: "Producer" },
  { index: 3, fieldName: "Upload Thumbnail" }
]
