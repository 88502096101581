import React, { useState, useMemo, useCallback } from "react"
import { CartesianGrid, ResponsiveContainer, AreaChart, Area } from "recharts"
import { Typography } from "@mui/material"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import { Tooltip, IconButton } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSelector } from "react-redux"
import "./performanceChart.scss"

function PerformanceChart({ graphHeading, dataProps, datatype }) {
  const { singleUserData } = useSelector((state) => state?.marketing?.leaderboard ?? {})
  const [clicked, setClicked] = useState(null)

  const handleClicked = useCallback((key) => {
    setClicked(key)
  }, [])

  const TimCustomizedDot = (props) => {
    const { cx, cy, payload } = props

    if (datatype === undefined) return null

    const getXValue = (data) => {
      switch (data) {
        case "dot-0":
          return cx
        case "dot-2":
          return cx - 35
        default:
          return cx - 15
      }
    }
    if (datatype == "monthly") {
      return (
        <svg x={getXValue(props.key)} y={cy - 20} onClick={() => handleClicked("tim")}>
          <circle
            cx="20"
            cy="20"
            r="18"
            stroke="#ffffff"
            strokeWidth="3"
            fill="#AD916F"
            className="graphDot"
          />
          <text
            x="2.5%"
            y="3.5%"
            fontSize={12}
            fontWeight={100}
            stroke="#ffffff"
            strokeWidth="1px"
            dy="9px">
            {payload.tim + "%"}
          </text>
        </svg>
      )
    } else if (datatype == "annual") {
      return (
        <svg key={props?.key} x={cx - 20} y={cy - 20}>
          <circle
            cx="20"
            cy="20"
            r="12"
            stroke="#ffffff"
            strokeWidth="1"
            fill="#AD916F"
            className="graphDot"
          />
          <text
            x="3.5%"
            y="3.5%"
            fontSize={8}
            fontWeight={100}
            stroke="#ffffff"
            strokeWidth="1px"
            dy="9px"
            dx={payload.tim == 0 ? "2px" : 0}>
            {payload.tim + "%"}
          </text>
        </svg>
      )
    } else {
      return
    }
  }

  const AverageCustomizedDot = (props) => {
    const { cx, cy, payload } = props
    if (payload.average == null) return null

    const getXValue = (data) => {
      switch (data) {
        case "dot-0":
          return cx
        case "dot-2":
          return cx - 35
        default:
          return cx - 15
      }
    }

    return (
      <svg x={getXValue(props.key)} y={cy - 20} onClick={() => handleClicked("average")}>
        <circle cx="20" cy="20" r="18" stroke="#ffffff" strokeWidth="3" fill="#56473d" />
        <text
          x="2.5%"
          y="3.5%"
          fontSize={10}
          fontWeight={100}
          stroke="#ffffff"
          strokeWidth="1px"
          dy="9px">
          {payload.average.toFixed(0) + "%"}
        </text>
      </svg>
    )
  }

  const DataKey1 = (dataKey) => dataKey?.[Object.keys(dataKey)[0]]
  const DataKey2 = (dataKey) => dataKey?.[Object.keys(dataKey)[1]]

  const renderAreas = useMemo(
    () => (
      <>
        <Area
          type={datatype === "annual" ? "monotone" : "linear"}
          dataKey={DataKey1}
          strokeWidth={3}
          stroke="#AD916F"
          fill="#d7d4d2"
          dot={TimCustomizedDot}
        />
        <Area
          type={datatype === "annual" ? "natural" : "linear"}
          dataKey={DataKey2}
          strokeWidth={3}
          stroke="#212121"
          fill="transparent"
          dot={AverageCustomizedDot}
        />
        {clicked === "tim" && (
          <Area
            type={datatype === "annual" ? "monotone" : "linear"}
            dataKey={DataKey1}
            strokeWidth={0}
            stroke="#AD916F"
            fill="none"
            dot={TimCustomizedDot}
          />
        )}
        {clicked === "average" && (
          <Area
            type={datatype === "annual" ? "natural" : "linear"}
            dataKey={DataKey2}
            strokeWidth={0}
            stroke="#212121"
            fill="none"
            dot={AverageCustomizedDot}
          />
        )}
      </>
    ),
    [datatype, clicked]
  )

  return (
    <div style={{ background: "white", borderRadius: "12px" }}>
      <Typography
        style={{ color: "black", paddingTop: "20px" }}
        variant="h2"
        className="m-0 text-center">
        {graphHeading}
      </Typography>
      <div className="d-flex align-items-center justify-content-center mt-3">
        <div className="d-flex align-items-center justify-content-center me-4">
          <FiberManualRecordIcon className="me-1" sx={{ color: "#ae946c" }} fontSize="small" />
          <Typography
            style={{ color: "black" }}
            variant="tag1"
            onClick={() => handleClicked("tim")}
            sx={{ fontWeight: 900, color: "#ae946c !important", cursor: "pointer" }}
            className="m-0 text-center">
            {singleUserData?.user?.name
              ? singleUserData.user.name.length > 20
                ? `${singleUserData.user.name.slice(0, 20).toUpperCase()}...`
                : singleUserData.user.name.toUpperCase()
              : ""}
          </Typography>
        </div>
        <div className="d-flex align-items-center justify-content-space-between">
          <FiberManualRecordIcon className="me-1" sx={{ color: "#56473d" }} fontSize="small" />
          <Typography
            style={{ color: "black" }}
            variant="tag1"
            sx={{ fontWeight: 900, color: "#56473d", cursor: "pointer" }}
            className="m-0 text-center"
            onClick={() => handleClicked("average")}>
            {"AVERAGE"}
          </Typography>
          <Tooltip
            title={
              <div style={{ maxWidth: "150px", wordBreak: "break-word" }}>
                Click on the username or the average to view specific data
              </div>
            }
            placement="left"
            PopperProps={{
              modifiers: [
                {
                  name: "preventOverflow",
                  options: {
                    boundariesElement: "parentRef.current"
                  }
                }
              ]
            }}>
            <IconButton size="small" style={{ position: "absolute", right: "10px", margin:0}}>
              <InfoOutlinedIcon fontSize="small"sx={{color:"black"}} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <ResponsiveContainer className="performanceChartScoreCard">
        <AreaChart
          width="95%"
          height={500}
          data={dataProps}
          fill="red"
          margin={{
            top: 100,
            right: 0,
            left: 0,
            bottom: 100
          }}>
          <CartesianGrid
            style={{ borderRadius: "12px" }}
            fill="#ffffff"
            strokeOpacity={0}
            strokeDasharray="3 3"
          />
          {renderAreas}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default React.memo(PerformanceChart)
