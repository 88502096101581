import { Box, styled } from "@mui/material";
import Card from "components/Card/Card";
import React, { useEffect } from "react";

const NavBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: 6,
  "& .navItem": {
    color: theme.palette.text.primary,
    padding: "12px 46px 12px 26px",
    backgroundColor: "#181B1D",
    borderRadius: "6px 6px 0px 0px",
    opacity: 0.6,
    cursor: "pointer",
    ...theme.typography.h4,
    "&.tabActive": {
      opacity: 1,
      backgroundColor: theme.palette.background.default
    }
  }
}));

const TabSwitchContainer = ({ value, onChange, children }) => {
  useEffect(() => {
    onChange(tabs[0]);
  }, []);
  return (
    <>
      <NavBarContainer>
        {tabs.map((tab, index) => (
          <Box
            className={`navItem ${
              value?.value === tab?.value ? "tabActive" : ""
            }`}
            key={`tab-${index}`}
            onClick={() => value?.value !== tab?.value && onChange(tab)}>
            {tab.label}
          </Box>
        ))}
      </NavBarContainer>
      <Card className="mt-0" style={{ borderTopLeftRadius: 0 }}>
        {children}
      </Card>
    </>
  );
};

export default TabSwitchContainer;

const tabs = [
  { label: "All Videos", value: "allvideos", titleLabel: "All Videos" },
  { label: "Assigned", value: "assigned", titleLabel: "Assigned Videos" },
  { label: "Unassigned", value: "unassigned", titleLabel: "Unassigned Videos" }
];
