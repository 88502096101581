import React, { Fragment } from "react"
import { Box, Grid, Typography } from "@mui/material"
import styles from "../../trivia-contest/components/PendingQuestionTable/PendingQuestionTable.module.scss"

import { useTheme } from "@mui/system"

function BankQuestions({ question, isIncorrect }) {
  const theme = useTheme()
  const boxStyling = { marginTop: "25px", marginBottom: "18px" }

  return (
    <Fragment>
      <Box className={styles.question} sx={boxStyling} style={{ maxWidth: 700, minWidth: 500 }}>
        {question?.is_added_manually ? (
          <Typography
            variant="caption3"
            color={theme.palette.secondary.main}
            style={{ opacity: 1 }}
            className={styles.questionHeader}>
            {`${question?.category} | SCARPETTA ${
              question?.added_on ? ` | ${question?.added_on}` : ""
            }`}
            {isIncorrect && (
              <Typography variant="caption3" className={styles.incorrect_analytics}>
                {`${
                  question?.incorrect_percentage
                    ? ` incorrect %: ${question?.incorrect_percentage}%`
                    : ""
                }${question?.incorrect_count ? ` | count: ${question?.incorrect_count} ` : ""}`}
              </Typography>
            )}
          </Typography>
        ) : (
          <Typography
            variant="caption3"
            color={
              question?.question_platform === "SCARPETTA" ? theme.palette.secondary.main : "#4FACE9"
            }
            style={{ opacity: 1 }}
            className={styles.questionHeader}>
            {`${question?.food_item_name ? question?.food_item_name : question?.food_name} | ${
              question?.question_platform ?? ""
            }  ${
              question?.category && question?.question_platform === "SCARPETTA"
                ? ` | ${question?.category}`
                : ""
            }${question?.added_on ? ` | ${question?.added_on}` : ""}`}
            {isIncorrect && (
              <Typography
                variant="caption3"
                style={{ opacity: 1 }}
                color={theme.palette.red.main}
                className={styles.incorrect_analytics}>
                {`${
                  question?.incorrect_percentage
                    ? ` incorrect %: ${question?.incorrect_percentage}%`
                    : ""
                }${question?.incorrect_count ? ` | count: ${question?.incorrect_count}` : ""}`}
              </Typography>
            )}
          </Typography>
        )}
        <Box className={styles.questionTitle}>
          <Typography
            variant="h4"
            style={question?.title?.length > 80 ? { marginTop: 15 } : { margin: 0 }}>
            {question?.title}
          </Typography>
        </Box>
        <Grid container>
          {question?.answer?.map((option, index) => (
            <Grid key={option.id} item xs={6}>
              <Box
                key={option.id}
                className={`${styles.question_bank_option} ${
                  option?.is_correct ? styles.optionCorrect : ""
                }`}>
                <div className={styles.optionNumber}>
                  <Typography variant="tag1">{(index + 10).toString(36).toUpperCase()}</Typography>
                </div>
                <Typography
                  className={styles.optionName}
                  sx={{ marginLeft: "10px" }}
                  variant="body2Regular">
                  {option?.answer}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Fragment>
  )
}

export default BankQuestions
