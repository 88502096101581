import { Typography } from "@mui/material";
import Header from "components/Header/Header";
import SuggestQuestionCard from "components/SuggestQuestion";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  getNextContestListAction,
  getTriviaCategoriesAction
} from "redux/GM/actions";

const GMSuggestQuestion = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Suggest A Question";
    dispatch(getTriviaCategoriesAction());
    dispatch(getNextContestListAction());
  }, []);

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Row>
          <Col lg="12">
            <Header
              title="Trivia Calendar & Leaderboard"
              breadCrumbs={breadCrumbs}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Typography color="secondary" variant="body1Regular">
              Please add 4 options
            </Typography>
            <SuggestQuestionCard />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GMSuggestQuestion;
