import { Formik } from "formik"
import { validationSchemaVersionAdd } from "helpers/validationSchema"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { marketingActions } from "redux/marketing/slice/user"
import AppReleaseForm from "./components/ReleaseForm"
import { addVersionAction, updateVersionAction } from "redux/superAdmin/actions"

function ReleaseForm({ open = false, onClose = () => {}, isEdit = false }) {
  const { versionData } = useSelector((store) => store?.superAdmin || [])
  const dispatch = useDispatch()

  const initialValues = {
    platform: "",
    title: "",
    appVersion: "",
    status: "",
    isMandatory: false
  }

  function setInitialFormValue() {
    if (isEdit === false) {
      return initialValues
    } else {
      return {
        title: versionData?.title ?? "",
        platform: versionData?.device ?? "",
        appVersion: versionData?.version ?? "",
        status: versionData?.status ?? "",
        isMandatory: versionData?.is_mandatory ?? false
      }
    }
  }

  useEffect(() => {
    dispatch(marketingActions.setMenuImageId(""))
  }, [])

  function handleSubmit(e) {
    const payload = {
      title: e?.title,
      version: e?.appVersion,
      is_mandatory: e?.isMandatory,
      device: e?.platform,
      status: e?.status
    }

    if (isEdit === false) {
      dispatch(addVersionAction(payload))
    } else {
      dispatch(updateVersionAction(payload))
    }

    onClose()
  }

  return (
    <>
      <Formik
        initialValues={setInitialFormValue()}
        enableReinitialize={true}
        validationSchema={validationSchemaVersionAdd}
        onSubmit={handleSubmit}>
        {(formik) => (
          <AppReleaseForm
            isEdit={isEdit}
            onClose={onClose}
            formik={formik}
            open={open}></AppReleaseForm>
        )}
      </Formik>
    </>
  )
}

export default ReleaseForm
