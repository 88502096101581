import React, { useState } from "react"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Menu, MenuItem, Typography } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
import { useTheme } from "@mui/system"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useDispatch } from "react-redux"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { useEffect } from "react"
import Paragraph from "components/ReadMoreParagraph"
import { DMVActions } from "redux/DMV/slice/user"
import { deleteDMVWineMenuItemAction, getDMVWineItemsAction } from "redux/DMV/actions/user"

function DMV_WineMenuListItems({
  foodList,
  isEdit,
  categoryIdData,
  setMenuItemsModal = () => {},
  setIsEditModalItem = () => {}
}) {
  const theme = useTheme()
  const [modal, setDeleteModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event, id, categoryId) => {
    setAnchorEl(event.currentTarget)
    dispatch(DMVActions.setDMVItemID(id))
    dispatch(DMVActions.setDMVMenuCategoryId(categoryId))
    dispatch(getDMVWineItemsAction())
  }

  function handleMenuItemDelete() {
    dispatch(deleteDMVWineMenuItemAction())
  }

  function handleMenuDeleteModal() {
    handleClose()
    setDeleteModal(true)
    setAnchorEl(null)
  }

  function handleCloseModal() {
    setDeleteModal(false)
    dispatch(DMVActions.setDMVItemID(""))
  }

  function handleEditMenuItemModal() {
    setAnchorEl(null)
    setIsEditModalItem(true)
    setMenuItemsModal(true)
  }

  useEffect(() => {}, [])

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are You Sure You Want To Delete This Wine Region ?`}
        description={" "}
        style={{ textAlign: "center" }}
        onClose={handleCloseModal}
        onDelete={() => handleMenuItemDelete()}
        open={modal}></DeleteConfirmationDialog>
      <div
        className={classes.itemContainer}
        style={{
          border: `${isEdit ? `1px solid ${theme.palette.secondary.main}` : "none"}`,
          borderRadius: `${isEdit ? "6px" : "0px"}`,
          padding: `${isEdit ? "8px" : "0px"}`
        }}>
        <div className={classes.imageContainer}>
          <ImageDisplay data={foodList?.image}></ImageDisplay>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: 375,
            overflowWrap: "break-word"
          }}>
          <div>
            <Typography variant="h4">{foodList?.name ?? "-"}</Typography>
          </div>

          <div>
            <div className="mt-2">
              <Typography variant="tag1">{"Geographic Location:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.place ?? "-"}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Terroir:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.terroir ?? "-"}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Famous Vineyards:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.famous_vineyards ?? "-"}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Unique Fact:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.unique_facts ?? "-"}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Most Famous Wines:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {foodList?.most_famous_wines.replaceAll("\n", ", ")}
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"White Grape Varietals:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {
                  <Paragraph
                    text={foodList?.white_grape_varietals.replaceAll("\n", ", ") ?? ""}
                    maxCharCount={70}></Paragraph>
                }
              </Typography>
            </div>
            <div className="mt-2">
              <Typography variant="tag1">{"Red Grape Varietals:"}</Typography>
              <br></br>
              <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                {
                  <Paragraph
                    text={foodList?.red_grape_varietals.replaceAll("\n", ", ") ?? ""}
                    maxCharCount={70}></Paragraph>
                }
              </Typography>
            </div>
          </div>
        </div>
        {isEdit && (
          <div>
            <MoreVertIcon
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, foodList?.id, categoryIdData)}
              sx={{ cursor: "pointer" }}></MoreVertIcon>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}>
              <MenuItem
                onClick={() => handleEditMenuItemModal()}
                sx={{ width: "150px", color: theme.palette.secondary.main }}>
                Edit
              </MenuItem>
              <MenuItem onClick={handleMenuDeleteModal} sx={{ color: theme.palette.red.main }}>
                Delete
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
    </>
  )
}

export default DMV_WineMenuListItems
