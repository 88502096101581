import { useEffect, useState } from "react";

// custom hooks around few util functions
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export const useActivity = (initValue) => useState(initValue || false);

export const useVideoUploader = ({ onUpload }) => {
  const [internalError, setInternalError] = useState(false);
  const [progress, setProgress] = useState(0);

  const checkFileType = (file) => {
    if (file?.type?.startsWith("video/")) {
      return true;
    }
    setInternalError(`Unsupported File Type`);
    return false;
  };

  const checkFileName = (file) => {
    if (file?.name?.length <= 200) {
      return true;
    }
    setInternalError(`File name exceeded 200 characters`);
    return false;
  };

  const onUploadProgress = () => {
    // Todo: Needs to be implemented
    setProgress(0);
  };

  const handleUpload = (file) => {
    setInternalError("");
    if (checkFileType(file) && checkFileName(file)) {
      onUpload(file);
    }
  };

  return {
    progress,
    internalError,
    handleUpload,
    onUploadProgress
  };
};
