import React, { useEffect, useState } from "react";
import { List, ListItem, Typography } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import "./gaugeChart.scss";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

function GaugeChart({
  isHeading,
  headingTitle,
  routeNext,
  isIconHeading = false
}) {
  const history = useHistory();
  const [needleValue] = useState(60);

  const doughnutDatabase = {
    labels: { display: false },
    datasets: [
      {
        label: "Users Gained",
        legends: { display: false },
        data: [100, 100, 100, 100, 100],

        borderColor: "#212121",
        weight: 2,
        backgroundColor: [
          "#cc4b37",
          "#e67c1b",
          "#ffae00",
          "#9cc43b",
          "#3adb76"
        ],

        hoverOffset: 0
      },
      {
        label: "Users Lost",
        legends: { display: false },
        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
        needleValue: needleValue,
        borderColor: "#212121",
        weight: 0.5,
        backgroundColor: ["#555555", "#3b3b3b"],
        hoverOffset: 0,
        borderWidth: 1
      }
    ]
  };

  const doughnutPlugins = [
    {
      id: "gaugeNeedle",
      afterDraw: (chart) => {
        const {
          ctx,
          data,
          chartArea: { width, height }
        } = chart;
        ctx.save();

        var needleValue = data.datasets[1].needleValue;
        var dataTotal = data.datasets[1].data.reduce((a, b) => a + b, 0);
        var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
        const cx = width / 2;
        const cy = chart._metasets[0].data[0].y;

        ctx.translate(cx, cy);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(180, -2);
        ctx.lineTo(height - (height / 4 - 71), -5);
        ctx.lineTo(height - (height / 4 - 71), 5);
        ctx.fillStyle = "#4face9";
        ctx.fill();
        ctx.rotate(-angle);
        ctx.translate(-cx, -cy);
        ctx.beginPath();
        ctx.fill();
        ctx.restore();
      }
    }
  ];

  const doughnutOptions = {
    spacing: 2,
    responsive: true,
    maintainAspectRatio: false,
    borderWidth: 2,
    cutout: "80%",
    rotation: -90,
    circumference: 180,
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: false
      }
    }
  };

  useEffect(() => {}, [doughnutPlugins]);

  return (
    <>
      {isHeading && (
        <List>
          <ListItem
            sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
            <Typography variant="h2">{headingTitle}</Typography>
            {isIconHeading && (
              <span onClick={() => history.push(routeNext)}>
                <IconButtonWithBorder aria-label="Go to Performance Summary">
                  <ArrowForwardIosIcon
                    sx={{
                      color: "white",
                      fontSize: "small"
                    }}
                  />
                </IconButtonWithBorder>
              </span>
            )}
          </ListItem>
        </List>
      )}
      <div className="gaugeChart">
        <Doughnut
          plugins={doughnutPlugins}
          data={doughnutDatabase}
          options={doughnutOptions}></Doughnut>

        <div className="gaugeChartData">
          <div>
            <h1>{needleValue}%</h1>
          </div>
          <div>
            <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
              Round 1
            </Typography>
          </div>
          <div>
            <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
              10 of 12 candidates improved
            </Typography>
          </div>
          <div>
            <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
              Week 6 of 8
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default GaugeChart;
