import * as React from "react";
import Card from "components/Card/Card";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { TextField } from "@mui/material";
import "./MiniCalendar.scss";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function MiniCalendar({ onClick = () => {}, style={} }) {
  const styles = {
    routeBtn: {
      position: "absolute",
      right: "4%",
      top: "8%"
    }
  };

  return (
    <Card style={{ paddingBottom: 12, paddingLeft: 0, paddingRight: 0,...style }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          label="Week picker"
          onChange={() => {}}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="yyyy/MM/dd"
        />
      </LocalizationProvider>
      <span style={styles.routeBtn} onClick={onClick}>
        <IconButtonWithBorder aria-label="Wine Of The Month">
          <ArrowForwardIosIcon
            sx={{
              color: "white",
              fontSize: "small"
            }}
          />
        </IconButtonWithBorder>
      </span>
    </Card>
  );
}

export default MiniCalendar;
