import React from "react";
import classes from "./Shimmer.module.scss";

const widthShimmer = "100%";

function Shimmer({ type, height, styles }) {
  const style = {
    width: getWidth(),
    height: getHeight(),
    borderRadius: getBorderRadius(),
    ...styles
  };

  function getWidth() {
    if (type === "circle") {
      return height;
    } else {
      return widthShimmer;
    }
  }

  function getHeight() {
    if (type === "circle") {
      return widthShimmer;
    } else {
      return height;
    }
  }

  function getBorderRadius() {
    if (type === "circle") {
      return "50%";
    }
    if (type === "rounded") {
      return "12px";
    }
    return "0px";
  }

  return <div className={classes.shimmerLoader} style={style}></div>;
}

export default Shimmer;
