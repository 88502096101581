import { Box, styled, Typography } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import CustomDivider from "components/StyledComponents/Divider"
import { FieldArray } from "formik"
import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import { learningDevelopmentActions } from "redux/L&D/slice/user"
import QuestionForm from "./QuestionForm"

const Card = styled("div")(({ theme }) => {
  return {
    padding: 24,
    margin: 12,
    borderRadius: 12,
    marginRight: 0,
    marginLeft: 0,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    position: "relative"
  }
})

const AddQuestions = ({ formik, questionImages }) => {
  const uid = useRef(1)
  const dispatch = useDispatch()

  const addNewQuestion = (questionArray) => {
    questionArray.push({
      uid: uid.current++,
      duration: 60,
      category: "",
      filterCategory: "",
      difficultyLevel: "",
      question: "",
      optionSet: [{ uid: `${uid.current}${0}`, name: "", isCorrect: false }],
      categoryName: ""
    })
  }

  const deleteQuestion = (index, arrayHelper) => {
    arrayHelper.remove(index)
    let newImagesArr = [...questionImages]
    newImagesArr.splice(index, 1)
    dispatch(learningDevelopmentActions.setUploadedImagesArr(newImagesArr))
  }

  return (
    <FieldArray
      key="questionArray"
      name="questionSet"
      render={(arrayHelper) => (
        <>
          {formik.values.questionSet?.map((question, index) => (
            <Box
              sx={{ width: "100%" }}
              id={`question-${question.uid}-${index}`}
              key={`question-${question.uid}-${index}`}>
              {index ? <CustomDivider sx={{ margin: "23px 0px 35px" }} /> : null}
              <Box className="d-flex justify-content-between align-items-center">
                <Box className="d-flex justify-content-start align-items-center">
                  <Typography className="mb-2 me-2" variant="h2">{`Question ${
                    index + 1
                  }`}</Typography>
                  <Typography color="secondary" variant="body1Regular">
                    Please add 4 options per question
                  </Typography>
                </Box>
                {formik?.values?.questionSet?.length > 1 ? (
                  <Typography
                    role="button"
                    onClick={() => deleteQuestion(index, arrayHelper)}
                    color="error"
                    variant="body1Regular">
                    Delete
                  </Typography>
                ) : null}
              </Box>
              <Card sx={{ width: "100%" }}>
                <QuestionForm
                  formik={formik}
                  questionIndex={index}
                  questionImageData={questionImages?.[index]}
                />
              </Card>
            </Box>
          ))}
          <Box className="d-flex justify-content-between mt-4 w-100">
            <AppButton
              disabled={
                formik.values?.questionSet?.[formik.values?.questionSet.length - 1]?.optionSet
                  ?.length !== 4
              }
              onClick={() => addNewQuestion(arrayHelper)}
              sx={{ padding: "13px 34px" }}>
              Add Another Question
            </AppButton>
            {formik?.values?.questionSet?.length > 1 ? (
              <Box className="d-flex">
                <AppButton onClick={formik.handleSubmit} sx={{ padding: "13px 80px" }}>
                  Add To Question Bank
                </AppButton>
              </Box>
            ) : null}
          </Box>
        </>
      )}
    />
  )
}

export default AddQuestions
