import { Box, Grid, styled, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import TransparentImageIcon from "assets/icons/TransparentImageIcon.svg";
import AppButton from "components/StyledComponents/AppButton";
import RedCloseIcon from "assets/icons/RedCloseIcon.svg";
import DashedBorder from "assets/icons/DashedBorderBlue.svg";
import UploadIcon from "assets/icons/UploadIconBlue.svg";
import styles from "./MembershipsForm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createWineClubMembershipsAction,
  uploadMembershipImageAction
} from "redux/DMV/actions";
import { DMVActions } from "redux/DMV/slice/user";
// import { removeDecimalUpto1 } from "helpers/functions";
import { useFormik } from "formik";
import { validationSchemaMembershipImage } from "helpers/validationSchema";
import { Col, Row } from "react-bootstrap";
import AppButtonGradient from "components/StyledComponents/AppButtonGradient";
import ShowImagePopup from "components/ShowImagePopup";
import ImageCropper from "components/ImageCropper";


const fileType = [
  "image/jpeg",
  "image/png",
  "image/jpg"
]

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: 8,
  "& span": {
    color: theme.palette.error.main
  }
}));

const MembershipsForm = () => {
  const [isInternalError, setIsInternalError] = useState(false);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [openCrop, setCrop] = useState(false);

  const [fileDetails, setFileDetails] = useState(null);
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const { membershipImage, membershipImageURL } = useSelector(
    (store) => store?.dmv?.wineClubMembership ?? {}
  );

  const handleSubmit = () => {
    const payload = {
      membership: [
        {
          image: membershipImage,
          membership_type: "3-bottle membership",
          membership_level: 3
        },
        {
          image: membershipImage,
          membership_type: "2-bottle membership",
          membership_level: 2
        },
        {
          image: membershipImage,
          membership_type: "1-bottle membership",
          membership_level: 1
        }
      ]
    };
    dispatch(createWineClubMembershipsAction(payload));
  };

  const formik = useFormik({
    initialValues: { membershipImage: null },
    validationSchema: validationSchemaMembershipImage,
    onSubmit: handleSubmit
  });

  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const onBrowseClick = () => {
    inputFile.current.click();
  };

  const onFilesSelected = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      // const isAspectRatioCorrect = await checkAspectRatio(files[0]);
      if(fileType.includes(files[0].type)){
        setCrop(true)
        setFileDetails(files[0])
        setImageObjectUrl(URL.createObjectURL(files[0]));
      }else{
        alert("Please Select image file format")
      }
   

    }
  };

  // const checkAspectRatio = (file) => {
  //   let img = new Image();
  //   const promise = new Promise((resolve, reject) => {
  //     img.onload = () => {
  //       const width = img.naturalWidth;
  //       const height = img.naturalHeight;
  //       if (width > height) {
  //         setIsInternalError("Please Upload Image with 1:4 aspect ratio");
  //         return resolve(false);
  //       } else {
  //         const ratio = width / height;
  //         if (removeDecimalUpto1(ratio) !== removeDecimalUpto1(1 / 4)) {
  //           setIsInternalError("Please Upload Image with 1:4 aspect ratio");
  //           return resolve(false);
  //         }
  //       }
  //       setIsInternalError(false);
  //       resolve(true);
  //     };
  //     img.onerror = reject;
  //   });
  //   img.src = window.URL.createObjectURL(file);

  //   return promise;
  // };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onImageDelete = () => {
    setIsInternalError("");
    formik.setFieldValue(`membershipImage`, null);
    dispatch(DMVActions.setMembershipImage(null));
    dispatch(DMVActions.setMembershipImageURL(null));
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length) {
      setCrop(true)
      setFileDetails(files[0])
      setImageObjectUrl(URL.createObjectURL(files[0]));
      // const isAspectRatioCorrect = await checkAspectRatio(files[0]);
      // if (isAspectRatioCorrect) uploadImage(files[0]);
    }
  };

  function handleCroppedImage(image,formik) {

    let data = new FormData();
    data.append("media", image);
    data.append("media_type", "IMAGE");
    data.append("content_type", image.type);
    dispatch(uploadMembershipImageAction(data));
    formik.setFieldValue(`membershipImage`, image);
  }


  // const uploadImage = (selectedImage) => {

  // };

  const toggleImagePopup = () => {
    setIsImagePopupOpen(!isImagePopupOpen);
  };

  const showImage = () => {
    let imageUrl = TransparentImageIcon;
    let name = "";
    if (formik?.values?.membershipImage) {
      imageUrl = URL.createObjectURL(formik?.values?.membershipImage);
      name = formik?.values?.membershipImage?.name;
    } else if (membershipImageURL) {
      imageUrl = membershipImageURL;
      name = decodeURIComponent(membershipImageURL)?.split("/")?.pop();
    }
    return (
      <Box>
        <ShowImagePopup
          open={isImagePopupOpen}
          onClose={toggleImagePopup}
          data={{ image: imageUrl, title: name }}
        />
        <Box
          className={styles.uploadCtr}
          style={{ height: "auto", margin: "0px 0px 50px" }}>
          <Box className={styles.imageListItem}>
            <Box
              role="button"
              className={styles.imageItemInnerCtr}
              onClick={toggleImagePopup}>
              <img
                style={{ height: "32px" }}
                src={imageUrl}
                alt=""
                className="me-4"
              />
              <Typography className="ms-1 me-3" variant="body1Regular">
                {name}
              </Typography>
            </Box>
            <img
              className={styles.deleteBtn}
              src={RedCloseIcon}
              onClick={onImageDelete}
              alt="delete"
            />
          </Box>
        </Box>
      </Box>
    );
  };


  function showCropperModal(imageObjectURL, fileDetails) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image,formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={0}
        onClose={setCrop}
        openCrop={openCrop}
      >
      </ImageCropper>
    )
  }

  return (
    <Grid container spacing={2}>
       {showCropperModal(imageObjectURL,fileDetails)}
      <Grid item xs={12}>
        <StyledLabel variant="body1Regular">
          Upload Membership Image <span>*</span>
        </StyledLabel>
      </Grid>
      <Grid item xs={12}>
        {formik?.values?.membershipImage || membershipImageURL ? (
          showImage()
        ) : (
          <Box>
            <div
              className={styles.uploadCtr}
              onDragOver={handleDragOver}
              style={{ margin: 0 }}
              onDrop={handleDrop}>
              <img src={DashedBorder} alt="" className={styles.borderImage} />
              <Box className={styles.innerUploadCtr}>
                <img src={UploadIcon} alt="" />
                <Typography variant="h3Italic">Drag And Drop</Typography>
                <Typography
                  className={styles.imgUploadHelperText}
                  variant="body1Regular">
                  or select files from device
                </Typography>
                <AppButton
                  className={styles.browseBtn}
                  styles={{ borderColor: "#4FACE9" }}
                  onClick={onBrowseClick}>
                  Browse
                </AppButton>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={onFilesSelected}
                  accept="image/png, image/jpeg, image/jpg"
                />
              </Box>
            </div>
            {formik?.touched?.membershipImage &&
            (isInternalError || formik?.errors?.membershipImage) ? (
              <div
                style={{ color: "#A9402F", fontSize: "0.75rem", marginTop: 3 }}>
                {isInternalError || formik?.errors?.membershipImage}
              </div>
            ) : null}
          </Box>
        )}
        <Row>
          <Col lg="2" className="mt-5">
            <AppButtonGradient
              onClick={formik.handleSubmit}
              sx={{ height: "48px", width: "100%" }}
              variant="filled">
              Submit
            </AppButtonGradient>
          </Col>
        </Row>
      </Grid>
    </Grid>
  );
};

export default MembershipsForm;
