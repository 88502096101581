import React from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/system";

function SubButtonNav({ children, isActive = false, onPress = () => { }, style = {} }) {
    const themes = useTheme();

    return (
        <Button
            onClick={onPress}
            style={{
                background: 'transparent',
                color: `${isActive ? themes.palette.secondary.main : themes.palette.text.primary}`,
                padding: "6px 12px 6px 12px",
                opacity: `${isActive ? "1" : "0.8"}`,
                borderBottom: `${isActive ? `2px solid ${themes.palette.secondary.main}` : "none"}`,
                borderRadius: '0',
                marginRight: "6px",
                textTransform: "none",
                ...style
            }}
            disableRipple={true}
            variant="contained">
            {children}
        </Button>
    );
}

export default SubButtonNav;
