import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography } from "@mui/material"
import styles from "./ImageCropper.module.scss"
import CloseIcon from "@mui/icons-material/Close"
import { Row } from "react-bootstrap"
import { Col } from "react-bootstrap"
import AppButton from "components/StyledComponents/AppButton"
import { useTheme } from "@mui/system"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  }
}))

function ImageCropper({
  onClose = () => {},
  openCrop = false,
  srcImage,
  setSelectedImage = () => {},
  fileDetail,
  aspectRatio = 4 / 3
}) {
  const [imageNew, setImage] = useState(null)
  const [crop, setCrop] = useState()

  const [aspect, setAspectRation] = useState(aspectRatio)

  useEffect(() => {
    setAspectRation(aspectRatio)
  }, [aspectRatio])

  function base64ImageToBlob(str) {
    // extract content type and base64 payload from original string
    const pos = str.indexOf(";base64,")
    const type = str.substring(5, pos)
    const b64 = str.substr(pos + 8)

    // decode base64
    const imageContent = atob(b64)

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    const buffer = new ArrayBuffer(imageContent.length)
    const view = new Uint8Array(buffer)

    // fill the view, using the decoded base64
    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n)
    }

    // convert ArrayBuffer to Blob
    const blob = new Blob([buffer], { type: type })
    return blob
  }

  function getCroppedImage() {
    const canvas = document.createElement("canvas")
    const imageData = imageNew.target
    const scaleX = imageData.naturalWidth / imageData.width
    const scaleY = imageData.naturalHeight / imageData.height
    ;(canvas.width = crop.width), (canvas.height = crop.height)
    const ctxImage = canvas.getContext("2d")

    ctxImage.drawImage(
      imageData,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const base64Image = canvas.toDataURL("image/png")

    const convertedFile = base64ImageToBlob(base64Image)
    const newFile = new File([convertedFile], fileDetail.name, { type: fileDetail.type })
    setSelectedImage(newFile)
    if (newFile) {
      onClose(false)
      setCrop(null)
      setImage(null)
    }
  }

  function handleClose() {
    onClose(false)
    setCrop(null)
    setImage(null)
  }

  const theme = useTheme()
  return (
    <StyledDialog open={openCrop} onClose={() => onClose(false)} maxWidth="">
      <DialogContent id="imageUploadDialogCropper" className={styles.dialogBox}>
        <Box className={styles.copperBox}>
          <Box className={styles.headerCropper}>
            <Typography variant="h2">Crop Image</Typography>
            <Box className={styles.closeButton} onClick={() => onClose(false)}>
              <CloseIcon sx={{ height: "14px", width: "14px" }} />
            </Box>
          </Box>
        </Box>
        <Row className="m-0 ">
          <Col lg="12" className="d-flex justify-content-center">
            <ReactCrop
              style={{ background: "transparent" }}
              aspect={aspect}
              crop={crop}
              onChange={(c) => setCrop(c)}>
              <img src={srcImage} onLoad={setImage} />
            </ReactCrop>
          </Col>
        </Row>

        <Row className="m-0 mb-4 mt-4">
          <Col lg="12" className="d-flex justify-content-end">
            <AppButton className="me-4" onClick={handleClose}>
              Cancel
            </AppButton>
            <AppButton
              onClick={getCroppedImage}
              styles={{
                backgroundColor: theme.palette.secondary.main
              }}>
              Update
            </AppButton>
          </Col>
        </Row>
      </DialogContent>
    </StyledDialog>
  )
}

export default ImageCropper
