import React from "react";
import { Row } from "react-bootstrap";
import classes from "./restaurantInformation.module.scss";
import { Col } from "react-bootstrap";
import { Typography } from "@mui/material";
import AppButtonFilled from "components/StyledComponents/AppButtonFilled";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useEffect } from "react";
import Card from "components/Card/Card";
import NavHeader from "../navHeader/index";
import { useState } from "react";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import { useHistory } from "react-router-dom";

const StyledTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  "& .MuiTableCell-root": {
    padding: "14px 24px 14px 0px",
    backgroundColor: theme.palette.background.default,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

function RestaurantInformation() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Restaurant Information";
  }, []);

  return (
    <>
      <NavHeader
        isRestaurantActive={true}
        breadCrumbs={breadCrumbs}></NavHeader>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px"
        }}>
        <Row>
          <Col lg="12" className={classes.headerClient}>
            <Typography variant="h2" className="d-flex align-items-center">
              Restaurant Information
            </Typography>
            <AppButtonFilled
              onClick={() => history.push("/dmv/dashboard/add-restaurant")}>
              + Add Restaurant
            </AppButtonFilled>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="mt-4">
            <Paper className={classes.container}>
              <TableContainer sx={{ maxHeight: 600 }}>
                <StyledTable stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {RestaurantInfoTableHead.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}>
                          <Typography
                            style={{ opacity: "0.6" }}
                            variant="body2Regular">
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {RestaurantTableRow.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell>
                            <div className={`${classes.rank} ${classes.aqua}`}>
                              {row.no}
                            </div>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1Regular">
                              {row.channelName}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1Regular">
                              {row.client}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1Regular">
                              {row.type}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1Regular">
                              {row.generalManager}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1Regular">
                              {row.areaDirector}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="body1Regular">
                              {row.superAdmin}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </Paper>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default RestaurantInformation;

const RestaurantInfoTableHead = [
  {
    id: 1,
    label: "S.No",
    minWidth: "10"
  },
  {
    id: 2,
    label: "Channel Name",
    minWidth: "170"
  },
  {
    id: 3,
    label: "Client",
    align: "center",
    minWidth: "170"
  },
  {
    id: 4,
    label: "Type",
    align: "center",
    minWidth: "170"
  },
  {
    id: 5,
    label: "General Manager",
    align: "center"
  },
  {
    id: 6,
    label: "Area Director",
    align: "center"
  },
  {
    id: 7,
    label: "Super Admin",
    align: "center"
  }
];

const RestaurantTableRow = [
  {
    id: 1,
    no: 1,
    channelName: "Tampa,FL",
    client: "Coopers Hawk",
    type: "Restaurant",
    generalManager: "Ryan Russell",
    areaDirector: "Michael Lewis",
    superAdmin: "Tim Hudson"
  },
  {
    id: 2,
    no: 2,
    channelName: "Waterford Lakes,FL",
    client: "Coopers Hawk",
    type: "Restaurant",
    generalManager: "Ryan Russell",
    areaDirector: "Michael Lewis",
    superAdmin: "Tim Hudson"
  },
  {
    id: 3,
    no: 3,
    channelName: "Naples,FL",
    client: "Coopers Hawk",
    type: "Restaurant",
    generalManager: "Ryan Russell",
    areaDirector: "Michael Lewis",
    superAdmin: "Tim Hudson"
  },
  {
    id: 4,
    no: 4,
    channelName: "Sarasota,FL",
    client: "Coopers Hawk",
    type: "Restaurant",
    generalManager: "Ryan Russell",
    areaDirector: "Michael Lewis",
    superAdmin: "Tim Hudson"
  },
  {
    id: 5,
    no: 5,
    channelName: "Doral,Fl",
    client: "Coopers Hawk",
    type: "Restaurant",
    generalManager: "Ryan Russell",
    areaDirector: "Michael Lewis",
    superAdmin: "Tim Hudson"
  }
];
