import { Box, styled, Typography, useTheme } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "components/Card/Card";
import CustomDivider from "components/StyledComponents/Divider";
import { useHistory } from "react-router-dom";

const StyledContainer = styled(Box)(() => ({
  marginTop: 12,
  "& .listItem": {
    marginBottom: 12
  },
  "& .rank": {
    height: 30,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 4
  }
}));

const RankList = ({ list = [], rankColor }) => {
  return (
    <StyledContainer>
      {list.map((item, index) => (
        <Box
          key={`${rankColor}-${index}`}
          className="d-flex justify-content-between align-items-center listItem">
          <Box className="d-flex align-items-center">
            <Typography variant="body1Regular" sx={{ opacity: 0.6 }}>
              {item.label}
            </Typography>
          </Box>
          <Typography variant="body1Regular">{item.value}</Typography>
        </Box>
      ))}
    </StyledContainer>
  );
};

const InviteRateRankingsCard = ({ routeNext }) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Card className="d-flex flex-column">
      <Box className="w-100 d-flex justify-content-between">
        <Typography variant="h2">Invite Rate Rankings </Typography>
        <Box className="d-flex">
          <span onClick={() => history.push(routeNext)} className="ms-3">
            <IconButtonWithBorder>
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </Box>
      </Box>
      <Typography
        variant="caption1"
        sx={{ color: theme.palette.green.main, fontSize: 12, mt: 3 }}>
        YOUR TOP
      </Typography>
      <RankList list={topList} rankColor={theme.palette.green.main} />
      <CustomDivider />
      <Typography
        variant="caption1"
        sx={{ color: theme.palette.green.main, fontSize: 12, mt: 3 }}>
        COMPANY TOP INVITE RATE
      </Typography>
      <RankList list={companyList} rankColor={theme.palette.green.main} />
      <CustomDivider className="mb-1" />
      <Typography
        variant="caption1"
        sx={{ color: theme.palette.red.main, fontSize: 12, mt: 1 }}>
        YOUR BOTTOM
      </Typography>
      <RankList list={bottomList} rankColor={theme.palette.red.main} />
    </Card>
  );
};

export default InviteRateRankingsCard;

const topList = [
  {
    label: "Tampa Bay",
    value: "14.3%"
  },
  {
    label: "Rank",
    value: "5 of 50"
  }
];

const companyList = [
  {
    label: "Indianapolis",
    value: "15.0%"
  }
];

const bottomList = [
  {
    label: "jacksonville",
    value: "5.1%"
  },
  {
    label: "Rank",
    value: "48 of 50"
  }
];
