import React from "react";
import {
  XAxis,
  YAxis,
  Area,
  Line,
  ComposedChart,
  ResponsiveContainer,
  CartesianGrid,
  Legend
} from "recharts";
import "./PerformanceTrackingGraph.scss";
import Card from "components/Card/Card";
import { List, ListItem, Typography } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useHistory } from "react-router-dom";

const getIntersectionColor = (_intersection, isLast) => {
  if (isLast) {
    return _intersection.line1isHigherNext ? "#CC4B3733" : "#3ADB7633";
  }

  return _intersection.line1isHigher ? "#CC4B3733" : "#3ADB7633";
};

// Solution is not very scalable
// - need to tweak it to use nulls
const PerformanceTrackingGraph = (props) => {
  const { data, id } = props;
  const history = useHistory();
  // add `range` to data for Area
  const dataWithRange = data.map((d) => ({
    ...d,
    range:
      d.TRTeam !== undefined && d.MentorTRTeam !== undefined
        ? [d.TRTeam, d.MentorTRTeam]
        : []
  }));

  // need to find intersections as points where we to change fill color
  const intersections = data
    .map((d, i) =>
      intersect(
        i,
        d.TRTeam,
        i + 1,
        data[i + 1]?.TRTeam,
        i,
        d.MentorTRTeam,
        i + 1,
        data[i + 1]?.MentorTRTeam
      )
    )
    .filter((d) => d && !isNaN(d.x));

  // filtering out segments without intersections & duplicates (in case end current 2 segments are also
  // start of 2 next segments)
  const filteredIntersections = intersections.filter(
    (d, i) => i === intersections.length - 1 || d.x !== intersections[i - 1]?.x
  );

  const renderLegend = (data) => {
    const { payload } = data;
    return (
      <div className="legendTrackingHead">
        {
          <div
            className="legendTracking"
            style={{ justifyContent: `${props.alignLegend}` }}>
            <span>
              <span
                className="legendBefore"
                style={{ backgroundColor: "#4face9" }}></span>
              {payload[2]?.dataKey}
            </span>
            <span>
              <span
                className="legendBefore"
                style={{ backgroundColor: "#AD916F" }}></span>
              {payload[1]?.dataKey}
            </span>
          </div>
        }
      </div>
    );
  };

  function TRTeamLabel(label) {
    const { x, y, value } = label;

    return (
      <text
        x={x + 20}
        y={y - 10}
        dy={-10}
        fill="#ffffff"
        fontSize={12}
        textAnchor="middle">
        {`${value + "%"}`}
      </text>
    );
  }
  function MentorTRTeamLabel(label) {
    const { x, y, value } = label;

    return (
      <text
        x={x + 20}
        y={y - 10}
        dy={-10}
        fill="#ffffff"
        fontSize={12}
        textAnchor="middle">
        {`${value + "%"}`}
      </text>
    );
  }

  function CommonDot(dot) {
    const { cx, cy, payload } = dot;
    if (payload.MentorTRTeam == payload.TRTeam) {
      return (
        <svg x={cx - 15} y={cy - 20}>
          <circle cx="20" cy="20" r="14" opacity={0.6} fill="#FCBB86"></circle>
          <circle
            cx="20"
            cy="20"
            r="8"
            stroke="#fcbb86"
            strokeWidth="4"
            fill="#383838"></circle>
        </svg>
      );
    }
  }

  return (
    <Card>
      {props.isHeading && (
        <List>
          <ListItem
            sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
            <Typography variant="h2">Tracking</Typography>
            <span
              onClick={() =>
                history.push("/gm/dashboard/performance-summary/tracking")
              }>
              <IconButtonWithBorder aria-label="Go to Performance Summary">
                <ArrowForwardIosIcon
                  sx={{
                    color: "white",
                    fontSize: "small"
                  }}
                />
              </IconButtonWithBorder>
            </span>
          </ListItem>
        </List>
      )}

      <ResponsiveContainer
        className="PerformanceTrackingGraph"
        width="100%"
        height={props.height}>
        <ComposedChart
          data={dataWithRange}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }}>
          <defs>
            <linearGradient id={id}>
              {filteredIntersections.length ? (
                filteredIntersections.map((intersection, i) => {
                  const nextIntersection = filteredIntersections[i + 1];

                  let closeColor = "";
                  let startColor = "";

                  const isLast = i === filteredIntersections.length - 1;

                  if (isLast) {
                    closeColor = getIntersectionColor(intersection);
                    startColor = getIntersectionColor(intersection, true);
                  } else {
                    closeColor = getIntersectionColor(intersection);
                    startColor = getIntersectionColor(nextIntersection);
                  }

                  const offset =
                    intersection.x /
                    (data.filter(
                      (d) =>
                        d.TRTeam !== undefined && d.MentorTRTeam !== undefined
                    ).length -
                      1);

                  return (
                    <>
                      <stop
                        offset={offset}
                        stopColor={closeColor}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset={offset}
                        stopColor={startColor}
                        stopOpacity={0.9}
                      />
                    </>
                  );
                })
              ) : (
                <stop
                  offset={0}
                  stopColor={
                    data[0].TRTeam > data[0].MentorTRTeam
                      ? "#CC4B3733"
                      : "#3ADB7633"
                  }
                />
              )}
            </linearGradient>
          </defs>
          <CartesianGrid
            strokeDasharray="1 0"
            strokeOpacity={0.2}
            vertical={false}
          />
          <XAxis
            dataKey="day"
            tickLine={false}
            tick={{ fill: "#ffffff" }}
            allowDecimals={true}
          />
          <YAxis
            tickCount={10}
            tickLine={false}
            axisLine={false}
            tickFormatter={(tick) => {
              return `${tick}%`;
            }}
            padding={{ bottom: 40 }}
            tick={{ fill: "#ffffff" }}
            allowDecimals={true}
            strokeWidth={1}
            label={{
              value: "Invite Rate",
              angle: -90,
              position: "insideLeft",
              fill: "#ffffff"
            }}
          />

          <Area
            type="natural"
            dataKey="range"
            stroke="#8884d8"
            strokeWidth={0}
            fill={`url(#${id})`}
          />

          <Line
            type="natural"
            dataKey="TRTeam"
            allowDecimals={true}
            strokeWidth={3}
            stroke="#AD916F"
            label={<TRTeamLabel />}
            dot={() => {}}
          />
          <Line
            type="natural"
            dataKey="MentorTRTeam"
            allowDecimals={true}
            strokeWidth={3}
            label={<MentorTRTeamLabel />}
            stroke="#4face9"
            dot={<CommonDot></CommonDot>}
          />

          <Legend
            height={50}
            verticalAlign={props.position}
            content={renderLegend}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  );
};

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  const line1isHigher = y1 > y3;
  const line1isHigherNext = y2 > y4;

  return { x, y, line1isHigher, line1isHigherNext };
}

export default PerformanceTrackingGraph;
