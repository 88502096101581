import React from "react";
import { Container, Row } from "react-bootstrap";
import Header from "components/Header/Header";
import { Col } from "react-bootstrap";
import ButtonNav from "components/ButtonNav";
import { useHistory } from "react-router-dom";

function BasePeriodNav({
  breadCrumbs,
  isPeriodGraphActive = false,
  isPeriodTableActive = false,
  isIncrementalActive = false,
  isPOSActive = false
}) {
  const history = useHistory();
  return (
    <Container fluid className="p-0">
      <Row>
        <Col lg="12">
          <Header title="Tampa TR Graph" breadCrumbs={breadCrumbs} />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <ButtonNav
            isActive={isPeriodGraphActive}
            onPress={() => history.push("/dmv/dashboard/base-period-graph")}>
            Base Period Graph
          </ButtonNav>
          <ButtonNav
            isActive={isPeriodTableActive}
            onPress={() => history.push("/dmv/dashboard/base-period-table")}>
            Base Period Table
          </ButtonNav>
          <ButtonNav
            isActive={isIncrementalActive}
            onPress={() =>
              history.push("/dmv/dashboard/incremental-membership")
            }>
            Incremental Membership
          </ButtonNav>
          <ButtonNav
            isActive={isPOSActive}
            onPress={() => history.push("/dmv/dashboard/pos-data")}>
            POS Data
          </ButtonNav>
        </Col>
      </Row>
    </Container>
  );
}

export default BasePeriodNav;
