import { Box, Grid, Tooltip, Typography } from "@mui/material"
import React, { useRef, useState } from "react"
import TransparentImageIcon from "assets/icons/TransparentImageIcon.svg"
import RedCloseIcon from "assets/icons/RedCloseIcon.svg"
import { bytesToSize, removeDecimalUpto1 } from "helpers/functions"
import { UploadFieldContainer } from "./components/FieldContainer"
import StyledLabel from "components/StyledComponents/StyledLabel"
import AppButtonGradient from "components/StyledComponents/AppButtonGradient"
import AppButton from "components/StyledComponents/AppButton"
import InfoIcon from "@mui/icons-material/Info"

const ImageUploadField = ({
  label,
  isRequired,
  placeholder = "",
  error,
  touched,
  value,
  onChange = () => {},
  onDelete = () => {},
  aspectWidth,
  aspectHeight,
  isVariant = false,
  imageSizeInBytes,
  disabled,
  isToolTip = false,
  renderToolTip,
  ...props
}) => {
  const inputRef = useRef(null)
  const [isInternalError, setIsInternalError] = useState(false)

  const isError = !value && (isInternalError || (touched === true && error))

  const checkSize = ({ size }) => {
    if (!imageSizeInBytes || size < imageSizeInBytes) {
      return true
    }
    setIsInternalError(`Image Size Exceeded ${bytesToSize(imageSizeInBytes)}`)
  }

  const checkAspectRatio = (file) => {
    let img = new Image()
    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth
        const height = img.naturalHeight
        const ratio = width / height
        if (removeDecimalUpto1(ratio) !== removeDecimalUpto1(aspectWidth / aspectHeight)) {
          setIsInternalError(`Please Upload Image with ${aspectWidth}:${aspectHeight} aspect ratio`)
          return resolve(false)
        }
        resolve(true)
      }
      img.onerror = reject
    })
    img.src = window.URL.createObjectURL(file)

    return promise
  }

  const checkFileType = (file) => {
    const types = ["image/png", "image/jpeg", "image/jpg"]
    if (types.includes(file.type)) {
      return true
    }
    setIsInternalError(`Unsupported File Type`)
    return false
  }

  const checkFileName = (file) => {
    if (file?.name?.length <= 200) {
      return true
    }
    setIsInternalError(`File name exceeded 200 characters`)
    return false
  }

  const onImageUploadClick = async (e) => {
    setIsInternalError(false)
    const { files } = e.target
    if (files?.[0] && checkFileType(files[0]) && checkFileName(files[0]) && checkSize(files[0])) {
      if (!aspectWidth || !aspectHeight) {
        return onChange(files[0])
      }
      const isAspectRatioCorrect = await checkAspectRatio(files[0])
      if (isAspectRatioCorrect) onChange(files[0])
    }
  }

  const viewImage = () => {
    const url = value?.url || URL.createObjectURL(value)
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  const showImage = () => {
    let image = {}
    if (value?.url) {
      image.name = decodeURIComponent(value.url)?.split("/")?.pop()
      image.url = value.url
    } else if (value?.name) {
      image.name = value.name
      image.size = value.size
      image.url = URL.createObjectURL(value)
    }

    return (
      <Box className="d-flex w-100 h-100 imageDataContainer">
        <Box className="imageDataContainerInner flex-grow-1 flex-shrink-1" onClick={viewImage}>
          <Box className="imageContainer">
            <img
              src={image.url ?? TransparentImageIcon}
              alt=""
              style={{ maxWidth: 12, maxHeight: 12 }}
            />
          </Box>
          <Box className="imageData d-flex justify-content-center">
            <Typography variant="body2Regular">{image.name}</Typography>
            {image.size ? (
              <Typography variant="tag1" sx={{ color: "#ADB5BD" }}>
                {bytesToSize(image.size)}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <img
          className="flex-shrink-0"
          role="button"
          src={RedCloseIcon}
          onClick={onDelete}
          alt=""
          style={{ width: 20 }}
        />
      </Box>
    )
  }

  return (
    <Grid container {...props}>
      {label ? (
        <StyledLabel variant="body1Regular">
          {label}
          {isToolTip && (
            <Tooltip title={renderToolTip}>
              <InfoIcon className="ms-1" fontSize="small"></InfoIcon>
            </Tooltip>
          )}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <UploadFieldContainer
          className={isError ? "error" : disabled ? "disabled" : ""}
          onClick={() => !disabled && inputRef.current?.click()}>
          {value ? (
            showImage()
          ) : (
            <Box className="d-flex w-100 h-100 noImageDataContainer">
              <Box className="placeholder">{placeholder}</Box>
              {isVariant ? (
                <AppButton disabled={disabled}>Upload</AppButton>
              ) : (
                <AppButtonGradient variant="filled" disabled={disabled}>
                  Upload
                </AppButtonGradient>
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={onImageUploadClick}
                onClick={(event) => {
                  event.target.value = null
                }}
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg"
              />
            </Box>
          )}
        </UploadFieldContainer>
      </Grid>
      {isError ? (
        <div style={{ color: "#A9402F", fontSize: "0.75rem", marginTop: 3 }}>
          {isInternalError ? isInternalError : error}
        </div>
      ) : null}
    </Grid>
  )
}

export default ImageUploadField
