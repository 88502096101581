import { Box, IconButton, styled, Typography } from "@mui/material"
import Card from "components/Card/Card"
import Modal from "components/Modal/Modal"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import AppButton from "components/StyledComponents/AppButton"

const StyledCard = styled(Card)(({ theme }) => ({
  paddingLeft: "36px",
  paddingRight: "36px",
  paddingTop: "36px",
  position: "relative",
  margin: 0,
  minWidth: 580,
  "& .closeBtn": {
    color: theme.palette.text.primary,
    position: "absolute",
    top: "20px",
    right: "20px",
    backgroundColor: theme.palette.background.main
  }
}))

const DeleteConfirmationDialog = ({
  open,
  onClose = () => {},
  onDelete = () => {},
  btnText = "",
  title = "Delete",
  description = "",
  style = {},
  type = ""
}) => {
  const handleDelete = () => {
    onDelete()
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <StyledCard>
        <IconButton onClick={onClose} className="closeBtn">
          <CloseIcon sx={{ height: "14px", width: "14px" }} />
        </IconButton>
        <Box className="w-100 d-flex flex-column justify-content-center align-items-center">
          <Box style={style} className="mt-4">
            <Typography variant="h2" className="mb-3">
              {title}
            </Typography>
          </Box>
          <Typography variant="body1Regular" style={style}>
            {description ? description : "Are you sure you want to delete this item ?"}
          </Typography>
          <Box className="mt-5 d-flex justify-content-around">
            <AppButton
              onClick={onClose}
              className="me-4"
              sx={{ height: "48px", width: 150 }}
              variant="outlined">
              Cancel
            </AppButton>
            <AppButton
              onClick={handleDelete}
              variant="outlined"
              sx={(theme) => ({
                height: "48px",
                width: 150,
                "&:hover": {
                  border: "none",
                  background:
                    type === "warning" ? theme.palette.secondary.main : theme.palette.error.main
                }
              })}>
              {btnText ? btnText : "Delete"}
            </AppButton>
          </Box>
        </Box>
      </StyledCard>
    </Modal>
  )
}

export default DeleteConfirmationDialog
