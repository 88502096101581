import React, { useEffect, useState } from "react"
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Grid,
  styled,
  InputLabel,
  OutlinedInput
} from "@mui/material"
import { makeStyles } from "@material-ui/core"
import CheckBox from "components/CheckBox"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { toTitleCase } from "helpers/functions"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
    maxWidth: "100%"
  }
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.body1Regular,
  height: 48,
  width: "100%",
  border: `1px solid ${theme.palette.text.secondary} !important`,
  borderRadius: 6,
  padding: "0px",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  backgroundColor: theme.palette.primary.main,
  "& .MuiSelect-root:before": {
    borderBottom: "none !important"
  },
  "& .MuiSelect-select": {
    paddingLeft: "16px"
  },
  "&:focus": {
    borderRadius: 6,
    borderColor: `${theme.palette.text.primary} !important`
  },
  "& .MuiSvgIcon-root": {
    marginRight: "12px",
    color: theme.palette.text.primary
  },
  "&.Mui-error": {
    borderColor: "#A9402F !important"
  },
  "& .MuiSelect-select.Mui-disabled": {
    color: "#969698 !important",
    WebkitTextFillColor: "#969698"
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main} !important`,
  width: "100%",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.hover} !important`
  },
  color: theme.palette.text.primary,
  ...theme.typography.body1Regular
}))

const CheckBoxSelect = ({
  label,
  style = {},
  menuItems = [],
  value = [],
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = useState(value)

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])

  const handleOptionChange = (event) => {
    const newValue = event.target.value
    setSelectedOptions(newValue)
    onChange(newValue)
  }

  const handleSelectAll = () => {
    const allValues = menuItems.map((option) => option.value)
    setSelectedOptions(allValues)
    onChange(allValues)
  }

  const handleClearAll = () => {
    const allValues = []
    setSelectedOptions(allValues)
    onChange(allValues)
  }

  const renderSelectedValue = () => {
    if (selectedOptions.length === 0) {
      return `Select ${label}`
    }

    if (selectedOptions.length === menuItems.length) {
      return `${label}: All`
    }

    const firstOptionLabel = selectedOptions[0]
    const formattedFirstOptionLabel =
      typeof firstOptionLabel === "string"
        ? toTitleCase(firstOptionLabel.replace(/_/g, " "))
        : firstOptionLabel

    return label === "Rank"
      ? `${label}: ${formattedFirstOptionLabel}` +
          (selectedOptions.length > 1 ? ` +${selectedOptions.length - 1}` : "")
      : `${formattedFirstOptionLabel}` +
          (selectedOptions.length > 1 ? ` +${selectedOptions.length - 1}` : "")
  }

  return (
    <Grid container flexDirection="column" style={style}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <FormControl className={classes.formControl}>
        <StyledSelect
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          IconComponent={KeyboardArrowDownIcon}
          variant="standard"
          disableunderline={true}
          multiple
          value={selectedOptions}
          onChange={handleOptionChange}
          renderValue={() => renderSelectedValue()}
          displayEmpty
          input={<OutlinedInput />}
          inputProps={{ "aria-label": "Without label" }}
          {...props}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <StyledMenuItem onClick={handleSelectAll}>All</StyledMenuItem>
            </Grid>
            <Grid item xs={6}>
              <StyledMenuItem
                disabled={selectedOptions?.length ? false : true}
                style={{ justifyContent: "flex-end" }}
                onClick={handleClearAll}>
                Reset
              </StyledMenuItem>
            </Grid>
          </Grid>
          {menuItems.map((option) => (
            <StyledMenuItem key={option.value} value={option.value}>
              <ListItemText primary={option.label} />
              <CheckBox
                checked={selectedOptions && selectedOptions.includes(option.value)}
                width={24}
              />
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Grid>
  )
}

export default CheckBoxSelect
