import { Box, Grid, Typography } from "@mui/material"
import React, { useState } from "react"
import RedCloseIcon from "assets/icons/RedCloseIcon.svg"
import { bytesToSize } from "helpers/functions"
import AppButtonGradient from "../StyledComponents/AppButtonGradient"
import { useVideoUploader } from "hooks/utils"
import { UploadFieldContainer } from "./components/FieldContainer"
import StyledLabel from "components/StyledComponents/StyledLabel"
import VideoUploadPopup from "./components/VideoUploadPopup/VideoUploadPopup"
import AppButton from "components/StyledComponents/AppButton"

const VideoUploadField = ({
  label,
  isRequired,
  placeholder = "",
  error,
  touched,
  value,
  videoUrl,
  importOnly,
  uploadOnly,
  onUrlUpload = () => {},
  onChange = () => {},
  onDelete = () => {},
  maxSizeInMB,
  disabled,
  isVariant = false,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const videoUploader = useVideoUploader({
    onUpload: onChange,
    maxSize: maxSizeInMB
  })

  const isError = !value && (videoUploader.internalError || (touched === true && error))

  const toggleUploadPopup = () => {
    setOpen((prev) => !prev)
  }

  const onVideoUpload = (file) => {
    videoUploader.handleUpload(file)
  }

  const viewVideo = () => {
    if (isError) {
      return
    }
    let url = ""
    if (videoUrl) {
      url = videoUrl
    } else {
      url = value?.url || URL.createObjectURL(value)
    }
    return url
    // const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    // if (newWindow) newWindow.opener = null;
  }

  const showVideo = () => {
    let video = {}
    if (value?.url) {
      video.name = value.url
    } else if (videoUrl) {
      video.name = videoUrl
    } else {
      video.name = value.name
      video.size = value.size
    }

    return (
      <Box className="d-flex w-100 h-100 imageDataContainer">
        <Box
          style={{ cursor: isError ? "auto" : "pointer" }}
          className="imageDataContainerInner flex-grow-1 flex-shrink-1"
          onClick={viewVideo}>
          <Box className="imageData d-flex justify-content-center">
            <Typography variant="body2Regular">{video.name ?? videoUrl}</Typography>
            {video.size ? (
              <Typography variant="tag1" sx={{ color: "#ADB5BD" }}>
                {bytesToSize(video.size)}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <img
          className="flex-shrink-0"
          role="button"
          src={RedCloseIcon}
          onClick={onDelete}
          alt=""
          style={{ width: 20 }}
        />
      </Box>
    )
  }

  return (
    <Grid container {...props}>
      {open ? (
        <VideoUploadPopup
          {...{
            onVideoUpload,
            onUrlUpload,
            onClose: toggleUploadPopup,
            importOnly,
            uploadOnly
          }}
        />
      ) : null}
      {label ? (
        <StyledLabel variant="body1Regular">
          {label}
          {isRequired ? <span>*</span> : null}
        </StyledLabel>
      ) : null}
      <Grid item xs={12}>
        <UploadFieldContainer className={isError ? "error" : disabled ? "disabled" : ""}>
          {value || videoUrl ? (
            showVideo()
          ) : (
            <Box
              className="d-flex w-100 h-100 noImageDataContainer"
              onClick={disabled !== true && toggleUploadPopup}>
              <Box className="placeholder">{placeholder}</Box>
              {isVariant ? (
                <AppButton disabled={disabled}>Upload</AppButton>
              ) : (
                <AppButtonGradient variant="filled" disabled={disabled}>
                  Upload
                </AppButtonGradient>
              )}
            </Box>
          )}
        </UploadFieldContainer>
      </Grid>
      {isError ? (
        <div style={{ color: "#A9402F", fontSize: "0.75rem", marginTop: 3 }}>
          {videoUploader.internalError ? videoUploader.internalError : error}
        </div>
      ) : null}
    </Grid>
  )
}

export default VideoUploadField
