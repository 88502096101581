import { Grid, Typography } from "@mui/material"
import Card from "components/Card/Card"
import DetailField from "components/DetailField"
import React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: 100,
  borderRadius: 4,
  backgroundColor: theme.palette.primary.main,
  margin: "24px 0 0 24px",
  "& img": {
    width: "100%",
    objectFit: "contain",
    backgroundColor: "#15181a"
  }
}))

const MenuPairing = ({ data, alcoholType }) => {
  return (
    <>
      <Typography variant="body1Medium">
        {alcoholType === "wine" ? "Menu Pairing" : "Classic Cocktails"}
      </Typography>
      {data.map((tastingData, index) => (
        <Card key={tastingData.id} className="mb-4">
          <Grid container spacing={3}>
            {tastingData.image ? (
              <Grid xs={4} md={2}>
                <ImageContainer>
                  <img src={tastingData.image} alt="" />
                </ImageContainer>
              </Grid>
            ) : null}
            <Grid item md={4} sm={6}>
              <DetailField
                label={
                  alcoholType === "wine"
                    ? `Menu Item ${index + 1}`
                    : `Classic Cocktail ${index + 1}`
                }
                value={tastingData?.name ?? "-"}
              />
            </Grid>
            {tastingData?.has_tooltip ? (
              <>
                <Grid item md={3} sm={4}>
                  <DetailField
                    label="Tooltip Heading"
                    value={tastingData?.tooltip_heading ?? "-"}
                  />
                </Grid>
                <Grid item md={3} sm={4}>
                  <DetailField
                    label="Tooltip Details"
                    value={tastingData?.tooltip_details ?? "-"}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Card>
      ))}
    </>
  )
}

export default MenuPairing
