import { List, ListItem, Typography } from "@mui/material";
import Card from "components/Card/Card";
import ListWithStatus from "components/List/ListBodyWithStatus";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import React from "react";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const TastingNotesConfigCard = () => {
  const history = useHistory();

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Tasting Notes Config</Typography>
          <span
            onClick={() =>
              // history.push("/dmv/dashboard/tasting-notes-config")
              history.push("/super-admin/dashboard/tasting-notes-config")
            }>
            <IconButtonWithBorder islight={true}>
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>

        <ListWithStatus data={data} />
      </List>
    </Card>
  );
};

export default TastingNotesConfigCard;

const data = [
  {
    name: "Tasting Note Config",
    status: "Done"
  },
  {
    name: "Wine Characteristics",
    status: "Done"
  }
];
