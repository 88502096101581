import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DMVActions } from "redux/DMV/slice/user"
import { getDMVSpiritSubMenuistAction, getImportSpiritAction } from "redux/DMV/actions/user"
import ButtonNav from "components/ButtonNav"
import classes from "pages/private/marketing/menu/menu.module.scss"
import { Grid } from "@mui/material"

function CMSSpiritsSubMenuNavBar(props) {
  const [menu, setMenu] = useState([])
  const dispatch = useDispatch()

  const { menuCategory, SpiritSubMenu } = useSelector((state) => state.dmv)
  // const { newSpiritCount } = useSelector((state) => state?.marketing?.menu || [])
  useEffect(() => {
    dispatch(getDMVSpiritSubMenuistAction())
  }, [])

  useEffect(() => {
    let menuNav = []
    if (SpiritSubMenu?.length > 0) {
      menuNav = SpiritSubMenu?.map((item, index) => ({
        isActive: index === 0 ? true : false,
        name: item?.name,
        value: item?.id
      }))
    }
    setMenu(menuNav)
  }, [SpiritSubMenu])

  useEffect(() => {
    dispatch(getImportSpiritAction())
  }, [menuCategory])

  function handleButtonNav(name) {
    let itemsLink = {}
    if (props?.isEdit) {
      itemsLink = {
        name: name,
        list: "Detailed View"
      }
    } else {
      itemsLink = {
        name: name,
        list: "Summary View"
      }
    }
    dispatch(DMVActions.setSubFilterValue(itemsLink))
  }

  return (
    <>
      {menu?.map((item, i) => {
        return (
          <div key={i} style={{ position: "relative", display: "flex" }}>
            <ButtonNav
              style={{ marginRight: 16, marginBottom: 10 }}
              onPress={() => handleButtonNav(item?.value)}
              key={i}
              isActive={props.activeTab === item.value ? true : false}>
              {item.name}
            </ButtonNav>
            {SpiritSubMenu?.map((count, i) => (
              <>
                {count?.name === item?.name && count?.dmv_spirit_count > 0 ? (
                  <Grid item key={i} className={classes.ellipse_spirit_gold}>
                    {count?.dmv_spirit_count}
                  </Grid>
                ) : null}
              </>
            ))}
          </div>
        )
      })}
    </>
  )
}

export default CMSSpiritsSubMenuNavBar
