import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import React, { useState } from "react"
import styles from "./PerformanceSummaryTable.module.scss"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import UserDetailModal from "../UserDetailModal"
import avatar from "assets/images/user/avatarUser.svg"
import AppPagination from "components/StyledComponents/AppPagination"
import TableNoRecords from "components/TableNoRecords"
import { marketingActions } from "redux/marketing/slice/user"
import { useDispatch } from "react-redux"
import { appActions } from "redux/app/slice/app"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useEffect } from "react"

const statusColor = {
  TOP_SERVER: "top-server",
  DOING_GREAT: "doing-great",
  AVERAGE: "average",
  UNDER_PERFORMING: "under-performing",
  POOR: "poor"
}

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid ${theme.palette.background.default}`
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    padding: "24px 24px 12px 0px",
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const PerformanceSummaryTable = ({ columns = [], rows = [], setPage, currentPage, totalPage }) => {
  const [showUserDetail, setShowUserDetail] = useState(false)
  const dispatch = useDispatch()
  const onUserDetailModalClose = () => {
    setShowUserDetail(false)
  }

  const getRankColor = (rowData) => {
    switch (rowData?.performance_status) {
      case statusColor.TOP_SERVER:
        return "#3ADB76"
      case statusColor.DOING_GREAT:
        return "#9CC43B"
      case statusColor.AVERAGE:
        return "#FFAE00"
      case statusColor.UNDER_PERFORMING:
        return "#E67C1B"
      case statusColor.POOR:
        return "#CC4B37"
    }
    if (!rowData.has_app) return "#CC4B37"
  }

  function handleRowData(data) {
    dispatch(marketingActions.setUserId(data.user.id))
    setShowUserDetail(true)
  }

  const [sort, setSort] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const handleSortDirection = (dir, id) => {
    setSortDirection(dir)
    handleSort(dir, id)
  }

  const handleSort = (dir, id) => {
    if (dir === "asc") {
      setSort(id)
      dispatch(appActions.setOrderBy(id))
    } else {
      setSort(`-${id}`)
      dispatch(appActions.setOrderBy(`-${id}`))
    }
  }

  useEffect(() => {}, [sort, sortDirection])

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <UserDetailModal open={showUserDetail} onClose={onUserDetailModalClose} />
        <TableContainer>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}
                      {column.icon && (
                        <img className={styles.columnIcon} src={column.icon} alt="" />
                      )}
                      {column?.isSorting && (
                        <span style={{ cursor: "pointer" }}>
                          {sortDirection === "desc" && sort === `-${column?.sortId}` ? (
                            <ArrowDownwardIcon
                              onClick={() => handleSortDirection("asc", column?.sortId)}
                              fontSize="small"></ArrowDownwardIcon>
                          ) : (
                            <ArrowUpwardIcon
                              onClick={() => handleSortDirection("desc", column?.sortId)}
                              fontSize="small"></ArrowUpwardIcon>
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableNoRecords colSpan={9} label="No Data Found" />
              ) : (
                rows.map((row, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>
                        <div className={styles.rank} style={{ borderColor: getRankColor(row) }}>
                          {row.rank}
                        </div>
                      </TableCell>
                      <TableCell sx={{ cursor: "pointer" }} onClick={() => handleRowData(row)}>
                        <div className={`${styles.tableAvatar}`}>
                          <div
                            className={`${styles.tableAvatar__img} ${
                              row.rank === 1 && styles.borderImg
                            }`}>
                            <img src={row?.user?.image_url ? row?.user?.image_url : avatar} />
                          </div>
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                              <Typography
                                className="me-2"
                                variant="body2Regular"
                                sx={(theme) =>
                                  row.has_app ? {} : { color: theme.palette.red.main }
                                }>
                                {row?.user?.name}
                              </Typography>
                              {row.isBiggestOpportunityServer ? <span role="button"></span> : null}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {row?.has_app ? <CheckIcon /> : <CloseIcon color="red" />}
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.correct_answers}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.questions_played}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.coins_earned}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {row?.correct_percentage ? row?.correct_percentage + "%" : ""}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </Paper>
      <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
    </>
  )
}

export default PerformanceSummaryTable
