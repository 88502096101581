import React from "react"
import { Button, styled } from "@mui/material"

const CustomButton = styled(Button)(({ theme }) => ({
  border: `2px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.background.main,
  color: theme.palette.white.main,
  ...theme.typography.body1Regular,
  padding: 13,
  lineHeight: "22px",
  textTransform: "none",

  "&:disabled": {
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
    cursor: "not-allowed",
    pointerEvents: "fill"
  },

  "&:hover": {
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: 'transparent',
    opacity: 0.8,
  }
}))

const AppButton = ({ children, styles = {}, ...props }) => {
  return (
    <CustomButton style={styles} {...props}>
      {children}
    </CustomButton>
  )
}
export default AppButton
