import React, { useState } from "react";
import { List, ListItem, Typography } from "@mui/material";
import Card from "components/Card/Card";
import ListWith2Items from "components/List/ListBodyWith2Items";

function SignUpSummary() {
  const [tableData] = useState(TABLE_DATA);

  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">Signups Summary</Typography>
        </ListItem>

        <ListWith2Items tableData={tableData} />
      </List>
    </Card>
  );
}

export default SignUpSummary;

const TABLE_DATA = [
  {
    id: 0,
    key: "Total Signups",
    field: "203"
  },
  {
    id: 1,
    key: "Total Non-Member Guests",
    field: "5,814"
  },
  {
    id: 2,
    key: "Invite Rate",
    field: "3.5%"
  }
];
