import { Grid } from "@mui/material";
import Header from "components/Header/Header";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";

import { validationSchemaFrontLineUser } from "helpers/validationSchema";
import { formatDate } from "helpers/functions";
import FrontLineUserForm from "../frontLineUserForm";
import { useDispatch } from 'react-redux';
import { EditFrontlineUsersDataAction, getFrontlineUsersDataAction } from 'redux/superAdmin/actions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


// const isSpecial = {
//   true: "true",
//   false: "false"
// }

const initData = {
  fullName: "",
  email: "",
  contactNumber: "",
  userType: "",
  role: "",
  restaurant: "",
  address: "",
  hire_date: "",
  reportingManager: ""
}

const EditFrontLineUser = () => {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const [initialFormValue, setInitialFormValue] = useState(initData);
  const history = useHistory()
  const { frontlineUserData } = useSelector((store) => store?.superAdmin?.frontlineUser ?? {})

  const dispatch = useDispatch();


  function initFormData() {
    const initData = {
      fullName: frontlineUserData?.full_name,
      email: frontlineUserData?.email,
      contactNumber: frontlineUserData?.mobile,
      userType: frontlineUserData?.role,
      role: frontlineUserData?.job_class,
      restaurant: frontlineUserData?.rest_name,
      address: frontlineUserData?.address,
      hireDate: new Date(frontlineUserData?.hire_date),
      // specialUser: frontlineUserData?.special_user === true ? isSpecial.true.toString() : isSpecial.false.toString(),
      reportingManager: frontlineUserData?.reporting_manager ?? "-"
    }
    setInitialFormValue(initData);
  }

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    initFormData();
  }, [frontlineUserData]);

  function handleSubmit(values) {
    const payload = {
      full_name: values?.fullName,
      email: values?.email,
      mobile: values?.contactNumber,
      job_class: values?.role,
      role: values?.userType,
      rest_name: values?.restaurant,
      address: values?.address,
      hire_date: formatDate(new Date(values?.hireDate)),
      reporting_manager: values?.reportingManager,
      // special_user:values?.specialUser
    }
    dispatch(EditFrontlineUsersDataAction(payload));
    history.replace("/super-admin/dashboard/frontline-user-list")
  }


  useEffect(() => {
    dispatch(getFrontlineUsersDataAction())
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header title="Edit Frontline User" breadCrumbs={breadCrumbs}></Header>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaFrontLineUser}
        initialValues={initialFormValue}
      >
        {(formik) => (
          <FrontLineUserForm isEditContent={true} formik={formik}></FrontLineUserForm>
        )}
      </Formik>
    </>
  );
};

export default EditFrontLineUser;
