import Header from "components/Header/Header";
import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Card from "components/Card/Card";
import Typography from "@mui/material/Typography";
import ExistingBenefitsTable from "../components/ExistingBenefitsTable";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getExistingBenefitsAction } from "redux/DMV/actions";

function ViewWineClubMemberShip() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const dispatch = useDispatch();
  const { existingBenefits } = useSelector(
    (store) => store?.dmv?.wineClubMembership ?? {}
  );

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Wine Club MemberShip - DMV";
    dispatch(getExistingBenefitsAction());
  }, []);
  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header
            title="Wine Club Memberships"
            breadCrumbs={breadCrumbs}
            back={true}></Header>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="mt-5 mb-5">
            <Typography className="mb-3" variant="h2">
              Existing Benefits
            </Typography>
            <ExistingBenefitsTable data={existingBenefits} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ViewWineClubMemberShip;
