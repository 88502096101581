import React, { useState } from "react";

import UserMetricsNavBar from "../userMetricsNavBar";
import { useEffect } from "react";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import Card from "components/Card/Card";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import DMVLineCharts from "components/DMVLineCharts";

function videoAnalytics() {
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  // const history = useHistory();
  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Client Information";
  }, []);

  return (
    <>
      <UserMetricsNavBar
        breadCrumbs={breadCrumbs}
        isVideoActive={true}></UserMetricsNavBar>
      <Card
        style={{
          borderTopLeftRadius: "0px",
          marginTop: "0px",
          background: "linear-gradient(180deg, #1C1F21 3.23%, #0F1214 10.3%)",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}>
        <Container fluid className="mt-3">
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <DMVLineCharts
                  height={300}
                  isHeading={true}
                  heading="Weekly Downloads"></DMVLineCharts>
              </Card>
            </Col>
            <Col lg="6" className="pe-0">
              <Card>
                <DMVLineCharts
                  height={300}
                  isHeading={true}
                  heading="Grand Cru Trip"></DMVLineCharts>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="ps-0">
              <Card>
                <DMVLineCharts
                  height={300}
                  isHeading={true}
                  heading="Training Videos"></DMVLineCharts>
              </Card>
            </Col>
            <Col lg="6" className="pe-0">
              <Card>
                <DMVLineCharts
                  height={300}
                  isHeading={true}
                  heading="Performance Management Videos"></DMVLineCharts>
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  );
}

export default videoAnalytics;
