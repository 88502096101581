import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import TableDivider from "../TableDivider";
import styles from "./PMProgressTable.module.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import WeeklyMeetingsPopup from "../WeeklyMeetingsPopup";
import InfoIcon from "@mui/icons-material/Info";

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.default,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    },
    "&:not(:first-of-type):not(:last-child)": {
      borderBottom: `2px solid #373737`
    }
  },
  "& .MuiTableRow-root": {
    width: "calc(100% - 48px)",
    "&:nth-of-type(6)": {
      "& .MuiTableCell-root": {
        borderBottom: "none"
      }
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "relative",
    "&:last-child": {
      paddingRight: 0
    }
  }
}));

const PMProgressTable = ({
  candidatesImproved,
  candidatesNotImproved,
  columns
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onPopupClose = () => {
    setIsPopupOpen(false);
  };

  const handlePopupOpen = (isImprovedCandidate) => {
    if (!isImprovedCandidate) {
      setIsPopupOpen(true);
    }
  };

  const getCandidatesList = (data, isImprovedCandidatesList) => (
    <>
      {data.map((row) => (
        <TableRow key={row.id}>
          <TableCell style={{ width: 24 }} />
          <TableCell>
            <div
              className={`${styles.rank} ${
                isImprovedCandidatesList ? styles.green : styles.red
              }`}>
              {row.rank}
            </div>
          </TableCell>
          <TableCell>
            <div className={`${styles.tableAvatar}`}>
              <div
                className={`${styles.tableAvatar__img} ${
                  row.rank === 1 && styles.borderImg
                }`}>
                <img src={row.image} />
              </div>
              <div
                className="d-flex align-items-center"
                onClick={() => handlePopupOpen(isImprovedCandidatesList)}
                style={!isImprovedCandidatesList ? { cursor: "pointer" } : {}}>
                <Typography
                  sx={{ flexShrink: 0, marginRight: "7px" }}
                  variant="body2Regular">
                  {row.name}
                </Typography>
                {!isImprovedCandidatesList ? (
                  <InfoIcon sx={{ width: "15px" }} />
                ) : null}
              </div>
            </div>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">
              {row.percentGoal + "%"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">
              {row.lostOpportunity}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">{row.jobClass}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">{row.nonWCM}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">
              {row.inviteRate + "%"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">
              {row.benchmarkInviteRate + "%"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">
              {row.performanceGap + "%"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2Regular">{row.tenure}</Typography>
          </TableCell>
          <TableCell style={{ width: 24 }} />
        </TableRow>
      ))}
    </>
  );

  return (
    <Paper sx={{ width: "100%" }}>
      <WeeklyMeetingsPopup open={isPopupOpen} onClose={onPopupClose} />
      <TableContainer>
        <CustomTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 24 }} />
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}
                    {column.icon && (
                      <img
                        className={styles.columnIcon}
                        src={column.icon}
                        alt=""
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
              <TableCell style={{ width: 24 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {getCandidatesList(candidatesImproved, true)}
            <TableDivider>
              <Typography variant="tag1">Improved Candidates</Typography>
              <ArrowUpwardIcon sx={{ height: "16px" }} />
            </TableDivider>
            {getCandidatesList(candidatesNotImproved)}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Paper>
  );
};

export default PMProgressTable;
