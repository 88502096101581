import { Box, Dialog, DialogContent, IconButton, styled, Typography } from "@mui/material"
import React, { useRef, useState } from "react"
import styles from "./UserDetailModal.module.scss"
import CheckIcon from "@mui/icons-material/Check"
import "./UserDetailModal.module.scss"
import PerformanceScoreCardGraph from "../PerformanceScoreCardGraph"
import Slider from "react-slick"
import { Col } from "react-bootstrap"
import { Row } from "react-bootstrap"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder"
import { useSelector } from "react-redux"
import avatar from "assets/images/user/avatarUser.svg"
import moment from "moment"
import { useEffect } from "react"
import CloseIcon from "@mui/icons-material/Close"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: 12,
    border: "none",
    width: 740,
    padding: 0,
    boxSizing: "border-box",
    overflow: "visible"
  }
}))

const sliderSettings = {
  dots: false,
  infinite: true,
  adaptiveHeight: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1
}
const sliderMonthsSettings = {
  dots: false,
  infinite: false,
  adaptiveHeight: false,
  arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3
}

function divideArrayIntoSubsets(array, monthIndex) {
  const filteredArray = array.slice(0, monthIndex)

  const subsets = []
  const subsetSize = 3

  for (let i = 0; i < filteredArray.length - subsetSize; i += subsetSize) {
    const subset = filteredArray.slice(i, i + subsetSize)
    subsets.push(subset)
  }

  const remainder = filteredArray.length % subsetSize
  if (remainder !== 0) {
    const start = filteredArray.length - subsetSize
    const lastSubset = filteredArray.slice(start)
    subsets.push(lastSubset)
  }

  return subsets
}

const UserDetailModal = ({ open, onClose }) => {
  const sliderRef = useRef(null)
  const sliderRefMonths = useRef(null)
  const [viewBtn, setViewBtn] = useState("MONTHLY VIEW")
  const [dataViewType, setDataViewType] = useState("monthly")
  const [index, setIndex] = useState(0)
  const { singleUserData } = useSelector((state) => state?.marketing?.leaderboard ?? {})

  const currentDate = new Date()
  const currentMonthIndex = currentDate.getMonth()
  const updatedMonths = Months.slice(0, currentMonthIndex + 1)
  const updatedIndex = Math.ceil(updatedMonths?.length / 3)

  function getGraphData(data) {
    if (!data) return
    // const AnnualData = singleUserData?.graph_response?.monthly?.user
    //   ? Object?.values(data?.graph_response?.monthly?.user)?.map((items) => ({
    //       tim: items ?? null
    //     }))
    //   : []

    const AnnualData = Months.slice(0, currentMonthIndex + 1).map((month) => ({
      tim: singleUserData?.graph_response?.monthly?.user?.[month?.toLocaleLowerCase()] ?? null
    }))

    const tim = data?.graph_response?.monthly?.user
      ? Object.values(data?.graph_response?.monthly?.user)
      : []
    const average = data?.graph_response?.monthly?.team
      ? Object.values(data?.graph_response?.monthly?.team)
      : []

    const monthly = average?.map((_, index) => {
      return {
        tim: tim[index],
        average: average[index]
      }
    })

    const subArray = divideArrayIntoSubsets(monthly, updatedMonths.length)
    return {
      monthly: subArray[index] ?? [],
      Annual: AnnualData ?? []
    }
  }

  const [triviaContestData, setTriviaContestData] = useState(getGraphData(singleUserData)?.monthly)

  const handleViewBtn = () => {
    if (viewBtn === "MONTHLY VIEW") {
      setViewBtn("ANNUAL VIEW")
      setIndex(0)
      setTriviaContestData(getGraphData(singleUserData)?.Annual)
      setDataViewType("annual")
      sliderRefMonths.current.slickGoTo(0)
    } else{
      setViewBtn("MONTHLY VIEW")
      setIndex(0)
      setTriviaContestData(getGraphData(singleUserData)?.monthly)
      setDataViewType("monthly")
    } 
  }

  function handleNextMonth() {
    if (index < updatedIndex - 1) {
      sliderRefMonths.current.slickNext()
      setIndex(index + 1)
    } // else if (index > 3) { // return // }
  }
  function handlePrevMonth() {
    if (index > 0) {
      sliderRefMonths.current.slickPrev()
      setIndex(index - 1)
    } // else if (index < 0) { // return // }
  }

  useEffect(() => {
    if (dataViewType === "monthly") {
      setTriviaContestData(getGraphData(singleUserData)?.monthly)
    }
  }, [index, singleUserData])

  useEffect(() => {
    if (open) {
      setViewBtn("MONTHLY VIEW")
      setDataViewType("monthly")
      setIndex(0)
      setTriviaContestData(getGraphData(singleUserData)?.monthly)
    }
  }, [open, singleUserData])

  function handleClose() {
    setIndex(0)
    setViewBtn("MONTHLY VIEW")
    setDataViewType("monthly")
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      maxWidth=""
      PaperProps={{
        sx: {
          overflow: "visible"
        }
      }}>
      <DialogContent className="d-flex">
        <Box className={styles.closeModal}>
          <IconButton onClick={() => onClose()}>
            <CloseIcon sx={{ height: "20px", width: "20px", color: "#000" }} />
          </IconButton>
        </Box>
        <Box className={styles.userDataContainer}>
          <Box className={styles.imgContainer}>
            <img
              src={singleUserData?.user?.image_url ? singleUserData?.user?.image_url : avatar}
              alt=""
            />
          </Box>
          <Box className="d-flex flex-column align-items-center">
            <Typography variant="h2" className="mb-2 mt-2">
              {singleUserData?.user?.name
                ? singleUserData?.user?.name?.length > 20
                  ? `${singleUserData?.user?.name?.slice(0, 20)}...`
                  : singleUserData?.user?.name
                : ""}
            </Typography>
            <Typography variant="caption3" className="mt-1 mb-2 text-uppercase">
              {singleUserData?.user?.job_class ?? ""}
            </Typography>
            <Typography variant="caption3" className={styles.rank}>
              {`RANK ${singleUserData?.user_rank ?? ""}`}
            </Typography>
          </Box>
          <Box className={styles.dataContainer}>
            <Box className={styles.dataItem}>
              <Typography variant="body1Regular" className={styles.key}>
                Location
              </Typography>
              <Typography variant="body1Regular">
                {singleUserData?.user?.restaurant ?? ""}
              </Typography>
            </Box>
            <Box className={styles.dataItem}>
              <Typography variant="body1Regular" className={styles.key}>
                Coaching Moments
              </Typography>
              <Box className="d-flex">
                {/* <InfoIcon sx={{ width: "19px", marginRight: "12px" }} /> */}
                <CheckIcon sx={{ width: "19px" }} />
              </Box>
            </Box>
            <Box className={styles.dataItem}>
              <Typography variant="body1Regular" className={styles.key}>
                Hire Date
              </Typography>
              <Typography variant="body1Regular">
                {singleUserData?.hire_date
                  ? moment(singleUserData?.hire_date).format("DD MMM YYYY")
                  : "-"}
              </Typography>
            </Box>
            <Box className={styles.dataItem}>
              <Typography variant="body1Regular" className={styles.key}>
                Trivia Correct %
              </Typography>
              <Typography variant="body1Regular">
                {singleUserData?.user_correct_percentage ?? "" + "%"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.graphContainer}>
          <Row className={styles.top}>
            {/* <Col
              lg="12"
              className="w-100 px-4"
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                className="d-flex align-items-center"
                onClick={() => sliderRef.current.slickPrev()}>
                <ArrowBackIosNewIcon style={{ color: "#212121" }} className={styles.arrowBtn} />
              </div>
              <div
                className="d-flex align-items-center"
                onClick={() => sliderRef.current.slickNext()}>
                <ArrowForwardIosIcon className={styles.arrowBtn} style={{ color: "#212121" }} />
              </div>
            </Col> */}
          </Row>

          <div>
            <Slider ref={sliderRef} {...sliderSettings}>
              <PerformanceScoreCardGraph
                dataProps={triviaContestData}
                datatype={dataViewType}
                graphHeading="Trivia Correct %"></PerformanceScoreCardGraph>
            </Slider>
          </div>
          <Row className={styles.bottom}>
            <Col
              lg="12"
              className="w-100 px-4"
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="d-flex align-items-center">
                {viewBtn === "ANNUAL VIEW" ? (
                  " "
                ) : (
                  <ArrowBackIosNewIcon
                    onClick={handlePrevMonth}
                    style={{ color: "#57473c" }}
                    className={styles.arrowBtn}
                  />
                )}
              </div>
              <div onClick={handleViewBtn}>
                <IconButtonWithBorder
                  style={{ background: "#d7d4d2", border: "2px solid #57473c" }}
                  aria-label="Score Card">
                  <Typography
                    style={{
                      padding: "0 24px 0 24px",
                      color: "#57473c",
                      fontWeight: "900",
                      fontSize: 12
                    }}
                    variant="body2Regular"
                    className={styles.key}>
                    {viewBtn}
                  </Typography>
                </IconButtonWithBorder>
              </div>
              <div className="d-flex align-items-center">
                {viewBtn === "ANNUAL VIEW" ? (
                  " "
                ) : (
                  <ArrowForwardIosIcon
                    onClick={handleNextMonth}
                    className={styles.arrowBtn}
                    style={{ color: "#57473c" }}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className={styles.month}>
            <Col lg="12" className="w-100 mt-2">
              {viewBtn === "ANNUAL VIEW" ? (
                " "
              ) : (
                <Slider ref={sliderRefMonths} {...sliderMonthsSettings}>
                  {updatedMonths.map((month, id) => (
                    <p className={styles.monthsText} key={id}>
                      {month}
                    </p>
                  ))}
                </Slider>
              )}
            </Col>
          </Row>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default UserDetailModal

// const userData = {
//   avatar: "https://randomuser.me/api/portraits/men/51.jpg",
//   name: "Terrence Long",
//   designation: "Server",
//   rank: 20,
//   location: "Tampa Bay, FL",
//   hireDate: "15 Jan 2020",
//   inviteRate: 1.6,
//   upgradeRate: 0.0,
//   signups: 0,
//   appUsage: 16
// }

const Months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"]
