import { createSlice } from "@reduxjs/toolkit";

const user = createSlice({
  name: "user",
  initialState: {
    userData: {},
    userWithId:{}
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserWithData: (state, action) => {
      state.userWithId = action.payload;
    }
  }
});

export default user;
export const userActions = user.actions;
