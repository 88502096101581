import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { Typography,Tooltip } from "@mui/material";
import crown from "assets/icons/crown.svg";
import styles from "./LeaderboardList.module.scss";
import { useTheme } from "@mui/system";
import TableNoRecords from "components/TableNoRecords";
import AppPagination from "components/StyledComponents/AppPagination";
import avatar from "assets/images/user/avatarUser.svg"
import InfoIcon from "@mui/icons-material/Info";

const LeaderBoardList = ({ columns, rowsData = [], setPage, currentPage, totalPage }) => {
  const themes = useTheme();
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: `${themes.palette.background.main}`,
      "& .MuiPaper-root": {
        backgroundColor: "red"
      },
      "& .MuiTableRow-hover": {
        backgroundColor: "red"
      },
      "& .MuiTableCell-root": {
        padding: "16px 16px",
        backgroundColor: `${themes.palette.background.main}`,
        borderBottom: "1px solid #353535"
      }
    }
  }));
  const classes = useStyles();



  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 700 }}>
          <Table
            className={classes.root}
            stickyHeader
            aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                    {column.label}{" "}
                      {column?.tooltip && (
                        <Tooltip title={column.tooltip}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData.length === 0 ? (<TableNoRecords colSpan={9} label="No Data Found" />) : rowsData.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      <span
                        style={{
                          border: "2px solid #3ADB76",
                          borderRadius: 6,
                          padding: "12px 10px"
                        }}>
                        {row.rank}
                      </span>
                    </TableCell>
                    <TableCell>
                      <div className={`${styles.tableAvatar}`}>
                        <div
                          className={`${styles.tableAvatar__img} ${row.rank === 1 && styles.borderImg
                            }`}>
                          <img src={row.image ? row.image : avatar} />
                        </div>
                        <div className="d-flex flex-column">
                          <Typography variant="body2Regular">
                            {row.name}
                          </Typography>
                          {row.rank === 1 && (
                            <div
                              className="mt-1 d-flex"
                              style={{ color: "#E67C1B" }}>
                              <img
                                width={12}
                                className="me-2"
                                height={12}
                                src={crown}
                                alt=""
                              />
                              <Typography variant="tag1">
                                Highest Ranked
                              </Typography>
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    {row.employeeClass !== undefined ? <TableCell align="center">{row.employeeClass}</TableCell> : ""}
                    {row.restaurantName !== undefined ? <TableCell align="center">{row.restaurantName}</TableCell> : ""}
                    <TableCell align="center">{row.correctAnswers}</TableCell>
                    {/* <TableCell align="center">{row.signUp_Upgrades}</TableCell>
                    <TableCell align="center">{row.fairnessFactor}</TableCell> */}
                    <TableCell align="center">{row.coins}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {rowsData?.length ?
        <AppPagination
          onPageChange={setPage}
          totalPages={totalPage}
          currentPage={currentPage}
        />
        : ""
      }
    </>
  );

};

export default LeaderBoardList;
