import React from "react";
import { List, ListItem, Typography } from "@mui/material";
import IconButtonWithBorder from "components/StyledComponents/IconButtonWithBorder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Card from "components/Card/Card";
import ListWith2Items from "components/List/ListBodyWith2Items";
import { useHistory } from "react-router-dom";

function AdminCard({ data, heading, navigateTo }) {

  const history = useHistory();


  return (
    <Card style={{ paddingBottom: 12 }}>
      <List>
        <ListItem
          sx={{ justifyContent: "space-between", padding: "0 0 16px 0" }}>
          <Typography variant="h2">{heading}</Typography>
          <span onClick={() => history.push(navigateTo)}>
            <IconButtonWithBorder aria-label="Go to Performance Summary">
              <ArrowForwardIosIcon
                sx={{
                  color: "white",
                  fontSize: "small"
                }}
              />
            </IconButtonWithBorder>
          </span>
        </ListItem>

        <ListWith2Items tableData={data} />
      </List>
    </Card>
  );
}

export default AdminCard;
