import React from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import { Formik } from "formik"
import { validationSchemaCategoryModal } from "helpers/validationSchema"
import { useDispatch, useSelector } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import {
  addBeverageCategoryAction,
  addSpiritCategoryAction,
  addWineRegionCategoryAction,
  editBeverageCategoryAction,
  editSpiritCategoryAction,
  editWineRegionCategoryAction
} from "redux/DMV/actions/user"

const buttonText = {
  CANCEL: "Cancel",
  SAVE: "Save",
  SAVE_AND_ADD: "Save and Add Item"
}

const menuText = {
  WINE_REGION: "wine_region",
  BEVERAGE: "beverage",
  SPIRITS: "spirits"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: 580,
    padding: 35,
    boxSizing: "border-box"
  }
}))

function checkFoodCategory(data, currentValue) {
  if (data?.food_category === currentValue) {
    return false
  } else {
    return true
  }
}

function CategoryModal({
  open = false,
  onClose = () => {},
  isEdit = false,
  data = {},
  setMenuItemsModal = () => {}
}) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const { DMVmenu_Category_ID, dmvMenuLink } = useSelector((state) => state.dmv)

  function handleButton(formik, btnValue) {
    if (btnValue?.trim() === buttonText.CANCEL) {
      onClose()
    } else if (btnValue?.trim() === buttonText.SAVE) {
      formik.handleSubmit()
      const payload = {
        name: formik.values?.category ?? "",
        categoryID: DMVmenu_Category_ID ?? ""
      }
      if (dmvMenuLink === menuText.WINE_REGION) {
        if (isEdit) {
          dispatch(editWineRegionCategoryAction(payload))
        } else {
          dispatch(addWineRegionCategoryAction(payload))
        }
      }
      if (dmvMenuLink === menuText.BEVERAGE) {
        if (isEdit) {
          dispatch(editBeverageCategoryAction(payload))
        } else {
          dispatch(addBeverageCategoryAction(payload))
        }
      }
      if (dmvMenuLink === menuText.SPIRITS) {
        if (isEdit) {
          dispatch(editSpiritCategoryAction(payload))
        } else {
          dispatch(addSpiritCategoryAction(payload))
        }
      }

      onClose()
    }
  }

  function handleSubmitBtn(formik, btnValue) {
    if (btnValue?.trim() === buttonText.SAVE) {
      formik.handleSubmit()
      if (checkFoodCategory(data, formik.values?.category)) {
        const payload = {
          name: formik.values?.category ?? "",
          categoryID: DMVmenu_Category_ID
        }
        if (dmvMenuLink === menuText.WINE_REGION) {
          if (isEdit) {
            dispatch(editWineRegionCategoryAction(payload))
          } else {
            dispatch(addWineRegionCategoryAction(payload))
          }
        }
        if (dmvMenuLink === menuText.BEVERAGE) {
          if (isEdit) {
            dispatch(editBeverageCategoryAction(payload))
          } else {
            dispatch(addBeverageCategoryAction(payload))
          }
        }
        if (dmvMenuLink === menuText.SPIRITS) {
          if (isEdit) {
            dispatch(editSpiritCategoryAction(payload))
          } else {
            dispatch(addSpiritCategoryAction(payload))
          }
        }
        onClose()
      } else {
        onClose()
      }
    } else if (btnValue?.trim() === buttonText.SAVE_AND_ADD) {
      formik.handleSubmit()
      const payload = {
        name: formik.values?.category ?? ""
      }
      if (dmvMenuLink === menuText.WINE_REGION) {
        dispatch(addWineRegionCategoryAction(payload))
      }
      if (dmvMenuLink === menuText.BEVERAGE) {
        dispatch(addBeverageCategoryAction(payload))
      }
      if (dmvMenuLink === menuText.SPIRITS) {
        dispatch(addSpiritCategoryAction(payload))
      }
      setMenuItemsModal(true)
      onClose()
    }
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2">
            {" "}
            {isEdit ? "Edit" : "Add New"}{" "}
            {dmvMenuLink === menuText.WINE_REGION
              ? "Wine Region "
              : dmvMenuLink === menuText.BEVERAGE
              ? "Beverage "
              : "Spirits "}
            Category
          </Typography>
        </Box>
        <Formik
          initialValues={{
            category: data ? data?.food_category ?? "" : ""
          }}
          enableReinitialize={true}
          onSubmit={handleButton}
          validationSchema={validationSchemaCategoryModal}>
          {(formik) => (
            <>
              <Box flexDirection="column" className="mt-4">
                <React.Fragment>
                  <Box className="d-flex justify-content-between w-100 py-2">
                    <AppTextField
                      id="category"
                      name="category"
                      fullWidth
                      isUpperCase={!false}
                      label={`Category`}
                      isRequired={true}
                      placeholder="Enter Category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      error={formik.touched.category && Boolean(formik.errors.category)}
                      helperText={formik.errors.category}
                    />
                  </Box>
                </React.Fragment>
              </Box>
              <Box className="mt-5 d-flex justify-content-around">
                <AppButton
                  disabled={
                    Boolean(formik?.errors.category) || formik?.values.category?.length === 0
                  }
                  onClick={(e) => handleButton(formik, e.target.innerText)}
                  sx={{ height: "48px", width: 150 }}
                  variant="outlined">
                  {isEdit ? buttonText.CANCEL : buttonText.SAVE}
                </AppButton>
                <AppButton
                  disabled={
                    Boolean(formik?.errors.category) || formik?.values.category?.length === 0
                  }
                  onClick={(e) => handleSubmitBtn(formik, e.target.innerText)}
                  sx={() => ({
                    height: "48px",
                    padding: "10px 50px"
                  })}>
                  {isEdit ? buttonText.SAVE : buttonText.SAVE_AND_ADD}
                </AppButton>
              </Box>
            </>
          )}
        </Formik>
      </DialogContent>
    </StyledDialog>
  )
}

export default CategoryModal
