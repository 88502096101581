import { styled, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  height: "4px",
  padding: 0,
  borderBottom: "none !important",
  "& .MuiTableCell-root": {
    padding: 0,
    height: 4,
    overflow: "visible",
    "& .divider": {
      height: 4,
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      "& .dividerContent": {
        height: 30,
        width: 187,
        borderRadius: 4,
        backgroundColor: theme.palette.secondary.main,
        top: "-13px",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }
    }
  }
}));

const TableDivider = ({ children }) => {
  return (
    <CustomTableRow>
      <TableCell colSpan={12}>
        <Box className="divider">
          <Box className="dividerContent">{children}</Box>
        </Box>
      </TableCell>
    </CustomTableRow>
  );
};

export default TableDivider;
