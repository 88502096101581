import { Box, Grid, Typography } from "@mui/material"
import Card from "components/Card/Card"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextField from "components/StyledComponents/AppTextField"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { uploadTastingNotesFlavourImageAction } from "redux/DMV/actions"
import { DMVActions } from "redux/DMV/slice/user"

const MenuPairing = ({ formik, index, arrayHelper, isEditMode, alcoholType }) => {
  const dispatch = useDispatch()
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [openCrop, setCrop] = useState(false)

  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const handleImageUpload = (image) => {
    if (image) {
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  const toggleDeletePopup = () => {
    setIsDeletePopupOpen((prev) => !prev)
  }

  const handleImageDelete = () => {
    dispatch(DMVActions.setTastingNotesFlavourImage({ index, id: null }))
    formik.setFieldValue(`menuPairing[${index}].image`, null)
  }

  const handleDeleteTastingNote = () => {
    arrayHelper.remove(index)
  }

  const onDeleteClick = () => {
    if (isEditMode) {
      toggleDeletePopup()
    } else {
      arrayHelper.remove(index)
    }
  }

  function handleCroppedImage(image, formik) {
    let data = new FormData()
    data.append("media", image)
    data.append("media_type", "IMAGE")
    data.append("content_type", image.type)
    dispatch(uploadTastingNotesFlavourImageAction({ index, data }))
    formik.setFieldValue(`menuPairing[${index}].image`, image)
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <Card className="mt-4">
      {showCropperModal(imageObjectURL, fileDetails, formik)}
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Tasting Note"
        onDelete={handleDeleteTastingNote}
        onClose={toggleDeletePopup}
      />
      <Box className="d-flex justify-content-between align-items-center">
        <Typography variant="h2">
          {alcoholType === "wine" ? `Menu Pairing ${index + 1}` : `Classic Cocktail ${index + 1}`}
        </Typography>
        {formik.values?.menuPairing?.length > 1 ? (
          <Typography
            role="button"
            onClick={() => onDeleteClick()}
            color="error"
            className="body1Regular">
            Delete
          </Typography>
        ) : null}
      </Box>
      <Grid container spacing={4} key={`menuPairing${index}`} className="mt-2">
        <Grid item sm={12} md={6} lg={4}>
          <AppTextField
            label={alcoholType === "wine" ? `Menu Item ${index + 1}` : `Cocktail Name ${index + 1}`}
            placeholder="Enter Menu Item"
            isUpperCase={true}
            fullWidth
            value={formik.values.menuPairing[index].name}
            onChange={formik.handleChange(`menuPairing[${index}].name`)}
            error={
              formik.touched?.menuPairing?.[index]?.name &&
              Boolean(formik.errors?.menuPairing?.[index]?.name)
            }
            helperText={
              formik.touched?.menuPairing?.[index]?.name &&
              formik.errors?.menuPairing?.[index]?.name
            }
            isDisabled={alcoholType === "" ? true : false}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <ImageUploadField
            placeholder="Upload image"
            label="Upload Item Image"
            value={formik.values?.menuPairing?.[index]?.image}
            onChange={handleImageUpload}
            onDelete={handleImageDelete}
            error={formik.errors?.menuPairing?.[index]?.image}
            touched={formik.touched?.menuPairing?.[index]?.image}
            disabled={alcoholType === "" ? true : false}
            isVariant={true}
          />
          {
            <Typography
              role="text"
              sx={{ opacity: 0.6, fontSize: 12 }}
              className="body2Regular mt-2">
              Note - Please upload a image with bright background
            </Typography>
          }
        </Grid>
      </Grid>
    </Card>
  )
}

export default MenuPairing
