import { Box, Grid, Typography } from "@mui/material"
import Card from "components/Card/Card"
import DraggableOptions from "components/DraggableOptions"
import AppSelect from "components/StyledComponents/AppSelect"
import AppTextField from "components/StyledComponents/AppTextField"
import ImageUploadPopup from "pages/private/learning_development/trivia-contest/components/ImageUploadPopup"
import React, { useEffect, useLayoutEffect, useState } from "react"
import ImageIcon from "assets/icons/ImageIcon.svg"
import { Formik } from "formik"
import { validationSchemaSuggestQuestion } from "helpers/validationSchema"
import CustomDivider from "components/StyledComponents/Divider"
import AppButton from "components/StyledComponents/AppButton"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { GMActions } from "redux/GM/slice/user"
import { getMonthYearString } from "helpers/functions"
import CloseIcon from "assets/icons/closeIcon.svg"
import { suggestQuestionAction, uploadSuggestedQuestionImageAction } from "redux/GM/actions"

const difficultyLevelChoices = [
  { label: "Beginner", value: "Beginner" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Advance", value: "Advance" }
]

const SuggestQuestionCard = () => {
  const [questionText, setQuestionText] = useState("")
  const [suggestedForList, setSuggestedForList] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const { image, triviaCategories, isQuestionUploaded, nextContestList } = useSelector(
    (store) => store?.gm?.suggestQuestion
  )
  const { id: user_id, role: user_role } = useSelector((store) => store.user.userData)

  const onImagePopupOpen = () => {
    setIsImagePopupOpen(true)
  }

  const onImagePopupClose = () => {
    setIsImagePopupOpen(false)
  }

  const onImageUpload = (newImage) => {
    let data = new FormData()
    data.append("media", newImage)
    data.append("media_type", "IMAGE")
    data.append("content_type", newImage.type)

    dispatch(uploadSuggestedQuestionImageAction(data))
  }

  const onImageDelete = () => {
    setSelectedImage(null)
    dispatch(GMActions.setSuggestedQuestionImage(null))
  }

  const getOptionValues = (values) => {
    return values?.optionSet
  }

  const getOptionPath = () => {
    return `optionSet`
  }

  const onQuestionTextChange = (e, formik) => {
    setQuestionText(e.target.value)
    const timeOutId = setTimeout(() => formik.setFieldValue(`question`, e.target.value), 800)
    return () => clearTimeout(timeOutId)
  }

  const handleSubmit = (values) => {
    const payload = {
      ...(image?.id ? { image: image.id } : {}),
      title: values.question,
      category: values.triviaCategory,
      level: values.difficultyLevel,
      duration: values.duration,
      status: "Pending",
      user: user_id,
      suggested_by: user_id,
      is_suggested: true,
      answers: values?.optionSet?.map((option) => ({
        answer: option.name,
        is_correct: option.isCorrect
      }))
    }
    dispatch(
      suggestQuestionAction({
        triviaId: values.suggestedFor,
        data: { questions: [payload] }
      })
    )
  }

  const onDurationChange = (e, formik) => {
    const { value } = e.target
    if (value > 60 || value < 0) {
      return
    }
    formik.setFieldValue(`duration`, value)
  }

  useEffect(() => {
    setSuggestedForList(
      nextContestList?.map((contest) => ({
        label: `${getMonthYearString(contest?.start_date ? contest?.start_date : "")} Contest`,
        value: contest?.id
      })) ?? []
    )
  }, [nextContestList])

  useEffect(() => {
    if (isQuestionUploaded) {
      history.goBack()
    }
  }, [isQuestionUploaded])

  useLayoutEffect(() => {
    dispatch(GMActions.setSuggestedQuestionImage(null))
  }, [])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        userCategory: user_id,
        suggestedFor: "",
        duration: 60,
        question: "",
        triviaCategory: "",
        difficultyLevel: "",
        optionSet: [{ name: "", isCorrect: false }]
      }}
      validationSchema={validationSchemaSuggestQuestion}
      onSubmit={handleSubmit}>
      {(formik) => (
        <React.Fragment>
          <ImageUploadPopup
            aspectHeight={1}
            aspectWidth={1}
            onClose={onImagePopupClose}
            open={isImagePopupOpen}
            {...{
              selectedImage,
              setSelectedImage,
              onImageUpload,
              onImageDelete
            }}
          />
          <Card>
            <Box sx={{ padding: "0 11px" }}>
              <Grid container spacing={4}>
                <Grid item md={4} sm={6}>
                  <AppTextField
                    id={`userCategory`}
                    name={`userCategory`}
                    label="User Category"
                    isRequired={true}
                    value={user_role}
                    isDisabled={true}
                    fullWidth
                    error={formik.touched.userCategory && Boolean(formik.errors.userCategory)}
                    helperText={formik.touched.userCategory && formik.errors.userCategory}
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <AppSelect
                    id={`suggestedFor`}
                    name={`suggestedFor`}
                    menuItems={suggestedForList}
                    label="Suggested For"
                    isRequired={true}
                    value={formik?.values?.suggestedFor}
                    onChange={formik.handleChange}
                    error={formik.errors.suggestedFor}
                    touched={formik.touched.suggestedFor}
                  />
                </Grid>
              </Grid>
              <CustomDivider sx={{ margin: "34px 0px" }} />
              <Grid container spacing={4}>
                <Grid item md={4} sm={6}>
                  <AppSelect
                    id={`triviaCategory`}
                    name={`triviaCategory`}
                    menuItems={triviaCategories?.map((e) => ({
                      label: e?.name,
                      value: e?.id
                    }))}
                    label="Trivia Category"
                    isRequired={true}
                    value={formik?.values?.triviaCategory}
                    onChange={formik.handleChange}
                    error={formik.errors.triviaCategory}
                    touched={formik.touched.triviaCategory}
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <AppSelect
                    id={`difficultyLevel`}
                    name={`difficultyLevel`}
                    menuItems={difficultyLevelChoices}
                    label="Difficulty Level"
                    isRequired={true}
                    value={formik?.values?.difficultyLevel}
                    onChange={formik.handleChange}
                    error={formik.errors.difficultyLevel}
                    touched={formik.touched.difficultyLevel}
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <AppTextField
                    type="number"
                    label="Duration"
                    value={formik?.values?.duration}
                    onChange={(e) => onDurationChange(e, formik)}
                    className="flex-grow-1 flex-shrink-1"
                    fullWidth
                    error={formik.touched.duration && Boolean(formik.errors.duration)}
                    helperText={formik.touched.duration && formik.errors.duration}
                  />
                </Grid>
              </Grid>
              <Box height={35} />
              <Typography variant="h2" sx={{ fontSize: 20 }}>
                Question
              </Typography>
              <Box height={19} />
              <Box className="d-flex align-items-start">
                <AppTextField
                  placeholder="Enter Question"
                  key={`question`}
                  value={questionText}
                  onChange={(e) => onQuestionTextChange(e, formik)}
                  className="flex-grow-1 flex-shrink-1"
                  fullWidth
                  error={formik.touched.question && Boolean(formik.errors.question)}
                  helperText={formik.touched.question && formik.errors.question}
                />
                <img
                  role="button"
                  src={ImageIcon}
                  onClick={onImagePopupOpen}
                  className="flex-shrink-0 ms-4"
                  alt="Upload Image"
                />
              </Box>
              {image?.url ? (
                <Box className="mt-4 position-relative" sx={{ width: "fit-content" }}>
                  <img style={{ height: 182, borderRadius: 6 }} src={image.url} alt="" />
                  <img
                    role="button"
                    src={CloseIcon}
                    alt=""
                    className="position-absolute"
                    style={{ top: 12, right: 12 }}
                    onClick={onImageDelete}
                  />
                </Box>
              ) : null}
              <DraggableOptions {...{ formik, getOptionValues, getOptionPath }} />
            </Box>
          </Card>
          <AppButton
            // disabled={!(formik.isValid && formik.dirty)}
            className="mt-4"
            onClick={formik.handleSubmit}>
            Send Suggestion
          </AppButton>
        </React.Fragment>
      )}
    </Formik>
  )
}

export default SuggestQuestionCard
