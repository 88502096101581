import { takeLatest } from "redux-saga/effects";
import {
  suggestQuestion,
  getTriviaCategories,
  uploadSuggestedQuestionImage,
  getNextContestList,
  getLeaderBoardGMList,
  getTriviaDashboardAnalytics,
  getGMCalendarData,
  getGMCorrectAnswers,
  getGMInCorrectAnswers,
  getTop5Ranks,
  getSuggestedQuestionData
} from "./user";
import {
  getTriviaCategoriesAction,
  suggestQuestionAction,
  getNextContestListAction,
  uploadSuggestedQuestionImageAction,
  getLeaderBoardGMListAction,
  getTriviaDashboardAnalyticsAction,
  getGMCalendarDataAction,
  getGMInCorrectAnswersAction,
  getTop5RanksActions,
  getSuggestedQuestionDataAction
} from "../actions/index";

export default function* GMActionWatcher() {
  yield takeLatest(suggestQuestionAction, suggestQuestion);
  yield takeLatest(getTriviaCategoriesAction, getTriviaCategories);
  yield takeLatest(getNextContestListAction, getNextContestList);
  yield takeLatest(
    uploadSuggestedQuestionImageAction,
    uploadSuggestedQuestionImage
  );
  yield takeLatest(
    getLeaderBoardGMListAction,
    getLeaderBoardGMList
  );
  yield takeLatest(
    getTriviaDashboardAnalyticsAction,
    getTriviaDashboardAnalytics
  );
  yield takeLatest(
    getGMCalendarDataAction,
    getGMCalendarData
  );
  yield takeLatest(
    getGMInCorrectAnswersAction,
    getGMInCorrectAnswers
  );
  yield takeLatest(
    getGMInCorrectAnswersAction,
    getGMCorrectAnswers
  );
  yield takeLatest(
    getTop5RanksActions,
    getTop5Ranks
  );
  yield takeLatest(
    getSuggestedQuestionDataAction,
    getSuggestedQuestionData
  );
}


