import React, { useRef, useState } from "react";
import {
  Backdrop,
  Box,
  IconButton,
  List,
  ListItem,
  Typography
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog";
import { useTheme } from "@mui/system";
import CustomDivider from "components/StyledComponents/Divider";
import { useDispatch } from "react-redux";
import { marketingActions } from "redux/marketing/slice/user";
import { useSelector } from "react-redux";
import {
  deleteWineMonthAction,
  getSingleWineMonthDataAction
} from "redux/marketing/actions";

import EditWineOfTheMonthModal from "pages/private/marketing/wine-of-the-month/editWineOftheMonth";

function MonthlyCardTitle({ title, wineName, editable, idMonth }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(false);
  const rowId = useRef(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useSelector((store) => store?.marketing?.wineOfTheMonth || {});

  const handleClick = (e, id) => {
    e.stopPropagation();
    rowId.current = id;
    setAnchorEl(e.currentTarget);
    dispatch(marketingActions.setWineOfTheMonthID(rowId.current));
    dispatch(getSingleWineMonthDataAction());
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleFormClose = () => {
    setModalOpen(false);
  };

  const handleEditFormPopUp = () => {
    setModalOpen(true);
    handleClose();
  };

  const toggleDeletePopup = () => {
    handleClose();
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };
  const styles = {
    titleContainer: {
      margin: "16px",
      position: "relative"
    },
    titles: {
      display: "flex",
      flexDirection: "row",
      padding: "0",
      justifyContent: "space-between"
    },
    moreIcon: {
      padding: "0",
      color: theme.palette.text.primary,
      "&:hover": { color: theme.palette.aqua.main }
    },
    menu: {
      position: "absolute",
      top: "27px",
      right: "0",
      backgroundColor: theme.palette.background.secondary,
      zIndex: "1400",
      borderRadius: "12px",
      width: "13rem",
      padding: "0.5rem"
    },
    deleteBtn: {
      color: theme.palette.error.main
    }
  };
  function handleDelete() {
    dispatch(deleteWineMonthAction(id));
  }
  return (
    <Box sx={styles.titleContainer}>
      <List sx={styles.titles}>
        <Typography variant="tag1">{title}</Typography>
        {editable ? (
          <IconButton
            onClick={(e) => handleClick(e, idMonth)}
            sx={styles.moreIcon}
            aria-label="Options">
            <MoreVert />
          </IconButton>
        ) : null}
      </List>
      <Typography variant="caption1">{wineName}</Typography>

      <Backdrop
        sx={{ color: "#fff", zIndex: 1300 }}
        open={open}
        onClick={handleClose}></Backdrop>

      {open && (
        <List sx={styles.menu}>
          <ListItem sx={{ cursor: "pointer" }} onClick={handleEditFormPopUp}>
            <Typography variant="body1Regular">Edit</Typography>
          </ListItem>
          <CustomDivider />
          <ListItem sx={{ cursor: "pointer" }} onClick={toggleDeletePopup}>
            <Typography sx={styles.deleteBtn} variant="body1Regular">
              Delete
            </Typography>
          </ListItem>
        </List>
      )}
      <EditWineOfTheMonthModal open={modalOpen} onClose={handleFormClose} />
      <DeleteConfirmationDialog
        open={isDeletePopupOpen}
        title="Delete Wine of the Month"
        onDelete={handleDelete}
        onClose={toggleDeletePopup}
      />
    </Box>
  );
}

export default React.memo(MonthlyCardTitle);
