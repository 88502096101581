import TicketIcon from "../icons/TicketIcon.svg";
import TriviaQuestionIcon from "../icons/TriviaQuestionIcon.svg";
import WineGlassIcon from "../icons/WineGlassIcon.svg";
import CutleryIcon from "../icons/CutleryIcon.svg";
import WineOfTheMonth from "assets/images/WineOfTheMonth.jpg";

export const INITIAL_EVENTS = [
  {
    id: 0,
    icon: TicketIcon,
    title: "Drawing of Raffle Tickets",
    start: "2022-09-01",
    activePlayers: "05",
    checked: "15",
    type: "tickets"
  },
  {
    id: 20,
    title: "",
    start: "2022-09-02",
    activePlayers: "13",
    checked: "22",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 1,
    icon: TriviaQuestionIcon,
    image: WineOfTheMonth,
    title: "Trivia Question #1",
    start: "2022-09-06",
    activePlayers: "28",
    checked: "30",
    type: "trivia",
    question: "Which country is March's wine of the month from 1?",
    options: ["USA", "Spain", "Chile", "Portugal"],
    answer: "USA",
    contestDuration: "30"
  },

  {
    id: 2,
    title: "",
    start: "2022-09-03",
    activePlayers: "13",
    checked: "22",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 3,
    title: "",
    start: "2022-09-04",
    activePlayers: "19",
    checked: "28",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },

      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 4,
    title: "",
    start: "2022-09-05",
    activePlayers: "21",
    checked: "50",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },

      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 5,
    title: "",
    start: "2022-09-09",
    activePlayers: "10",
    checked: "10",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 6,
    title: "",
    start: "2022-09-08",
    activePlayers: "10",
    checked: "10",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },

      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 7,
    title: "",
    start: "2022-09-08",
    activePlayers: "22",
    checked: "15",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },

      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 8,
    icon: TriviaQuestionIcon,
    image: WineOfTheMonth,
    title: "Trivia Question #2",
    start: "2022-09-13",
    activePlayers: "12",
    checked: "50",
    type: "trivia",
    question: "Which country is March's wine of the month from? 2",
    options: ["USA", "Spain", "Chile", "Portugal"],
    answer: "USA",
    contestDuration: "20"
  },
  {
    id: 9,
    title: "",
    start: "2022-09-10",
    activePlayers: "05",
    checked: "12",
    display: "background",
    backgroundColor: "rgba(204, 75, 55, 0.15)",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 10,
    title: "",
    start: "2022-09-11",
    activePlayers: "11",
    checked: "60",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },

      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 11,
    title: "",
    start: "2022-09-12",
    activePlayers: "29",
    checked: "28",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 12,
    title: "",
    start: "2022-09-16",
    activePlayers: "14",
    checked: "70",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },

      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 13,
    title: "",
    start: "2022-09-14",
    activePlayers: "05",
    checked: "11",
    display: "background",
    backgroundColor: "rgba(204, 75, 55, 0.15)",
    type: "dailyStats",
    stats: {
      trAttendants: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      servers: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      bartenders: {
        invites: "0",
        upgrades: "0",
        total: "0"
      },
      total: {
        invites: "12",
        upgrades: "0",
        total: "12"
      },
      guestStats: [
        { key: "WCM Guests", field: "100" },
        { key: "Non-WCM Guests", field: "100" },
        { key: "Total", field: "200" }
      ],
      inviteStats: [
        { key: "Invite Rate", field: "12.0%" },
        { key: "Upgrade Rate", field: "0.0%" }
      ]
    }
  },
  {
    id: 14,
    icon: CutleryIcon,
    title: "Wine Club Member Dinner",
    start: "2022-09-15",
    activePlayers: "10",
    checked: "30",
    type: "dinner"
  },
  {
    id: 15,
    icon: TriviaQuestionIcon,
    image: WineOfTheMonth,
    title: "Trivia Question #3",
    start: "2022-09-20",
    activePlayers: "17",
    checked: "22",
    type: "trivia",
    question: "Which country is March's wine of the month from? 3",
    options: ["USA", "Spain", "Chile", "Portugal"],
    answer: "USA",
    contestDuration: "30"
  },
  {
    id: 16,
    icon: WineGlassIcon,
    title: "Wine Of The Month",
    start: "2022-09-22",
    activePlayers: "50",
    checked: "50",
    type: "wine",
    display: "background",
    backgroundColor: "rgba(164,127,81,0.2)"
  },
  {
    id: 17,
    icon: TriviaQuestionIcon,
    image: WineOfTheMonth,
    title: "Trivia Question #4",
    start: "2022-09-27",
    type: "trivia",
    question: "Which country is March's wine of the month from? 4",
    options: ["USA", "Spain", "Chile", "Portugal"],
    answer: "USA",
    contestDuration: "40"
  }
];
