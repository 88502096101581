import React, { useEffect, useState } from "react"
import { Box, Dialog, DialogContent, styled, Typography, IconButton } from "@mui/material"
import AppButton from "components/StyledComponents/AppButton"
import AppTextField from "components/StyledComponents/AppTextField"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/system"
import { Col, Row } from "react-bootstrap"
import ImageUploadField from "components/FileUpload/ImageUploadField"
import ImageCropper from "components/ImageCropper"
import AppTextArea from "components/StyledComponents/AppTextArea"
import { useDispatch } from "react-redux"
import { uploadMenuItemAction } from "redux/marketing/actions"
import { marketingActions } from "redux/marketing/slice/user"

const buttonText = {
  EDIT: "Update Item",
  SAVE: "Save Item"
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "transparent",
    maxWidth: 1000,
    width: "100%"
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 12,
    border: "none",
    width: "100%",
    padding: 35,
    boxSizing: "border-box"
  }
}))

function MenuItemsForm({ formik, open = false, onClose = () => {}, isEdit = false }) {
  const theme = useTheme()
  const [uploadType, setUploadType] = useState("")
  const [openCrop, setCrop] = useState(false)
  const [fileDetails, setFileDetails] = useState(null)
  const [imageObjectURL, setImageObjectUrl] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    formik.resetForm({
      dishName: "",
      uploadThumbnail: "",
      menuDescription: [],
      allergens: [],
      chefDescription: ""
    })
    dispatch(marketingActions.setMenuImageId(""))
  }, [open])

  const styles = {
    closeBtn: {
      color: theme.palette.text.primary,
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: theme.palette.background.main
    }
  }

  const handleImageDelete = (formik) => {
    formik.setFieldValue("uploadThumbnail", null)
  }
  const onImageUpload = (image) => {
    setUploadType("")
    if (image) {
      setUploadType("uploadThumbnail")
      setCrop(true)
      setFileDetails(image)
      setImageObjectUrl(URL.createObjectURL(image))
    }
  }

  function handleCroppedImage(image, formik) {
    if (uploadType === "uploadThumbnail") {
      let data = new FormData()
      data.append("media", image)
      data.append("media_type", "IMAGE")
      data.append("content_type", image.type)
      formik.setFieldValue("uploadThumbnail", image)
      dispatch(uploadMenuItemAction(data))
      setUploadType("")
    }
  }

  function showCropperModal(imageObjectURL, fileDetails, formik) {
    return (
      <ImageCropper
        setSelectedImage={(image) => handleCroppedImage(image, formik)}
        srcImage={imageObjectURL}
        fileDetail={fileDetails}
        aspectRatio={uploadType === "uploadThumbnail" && 1}
        onClose={setCrop}
        openCrop={openCrop}></ImageCropper>
    )
  }

  return (
    <StyledDialog open={open}>
      <DialogContent>
        {showCropperModal(imageObjectURL, fileDetails, formik)}
        <Box className="d-flex justify-content-end">
          <IconButton onClick={() => onClose()} sx={styles.closeBtn}>
            <CloseIcon sx={{ height: "14px", width: "14px" }} />
          </IconButton>
        </Box>
        <Box className="w-100 d-flex justify-content-center mb-2">
          <Typography variant="h2"> {isEdit ? "Edit" : "Add New"} Wine Region</Typography>
        </Box>

        <Box flexDirection="column" className="mt-4">
          <React.Fragment>
            <Box className=" w-100 py-2">
              <Row className="w-100 m-0">
                <Col lg="6">
                  <AppTextField
                    id="region"
                    name="region"
                    isUpperCase={!false}
                    type="text"
                    fullWidth
                    label="Region Name"
                    isRequired={true}
                    placeholder="Enter Region Name"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                    error={formik.touched.region && Boolean(formik.errors.region)}
                    helperText={formik.errors.region}
                  />
                </Col>
                <Col lg="6">
                  <ImageUploadField
                    label="Upload Thumbnail"
                    isVariant={true}
                    placeholder="Upload Image"
                    value={formik.values.uploadThumbnail}
                    onChange={(e) => onImageUpload(e)}
                    onDelete={() => handleImageDelete(formik)}
                    error={formik.errors.uploadThumbnail}
                    touched={formik.touched.uploadThumbnail}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="Place"
                    placeholder="Enter Geographic Location"
                    label="Geographic Location"
                    fullWidth
                    isUpperCase={true}
                    value={formik.values.Place}
                    onChange={formik.handleChange}
                    error={formik.touched.Place && Boolean(formik.errors.Place)}
                    helperText={formik.errors.Place}></AppTextField>
                </Col>
              </Row>{" "}
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="terroir"
                    placeholder="Enter Terroir"
                    label="Terroir"
                    fullWidth
                    isUpperCase={true}
                    value={formik.values.terroir}
                    onChange={formik.handleChange}
                    error={formik.touched.terroir && Boolean(formik.errors.terroir)}
                    helperText={formik.errors.terroir}></AppTextField>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="famous_vineyards"
                    placeholder="Enter Famous Vineyards"
                    label="Famous Vineyards"
                    fullWidth
                    isUpperCase={true}
                    value={formik.values.famous_vineyards}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.famous_vineyards && Boolean(formik.errors.famous_vineyards)
                    }
                    helperText={formik.errors.famous_vineyards}></AppTextField>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextField
                    id="unique_fact"
                    placeholder="Enter Unique Fact"
                    label="Unique Fact"
                    fullWidth
                    isUpperCase={true}
                    value={formik.values.unique_fact}
                    onChange={formik.handleChange}
                    error={formik.touched.unique_fact && Boolean(formik.errors.unique_fact)}
                    helperText={formik.errors.unique_fact}></AppTextField>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    id="famous_wines"
                    placeholder="Enter Most Famous Wines"
                    label={"Most Famous Wines"}
                    isUpperCase={true}
                    minRows={4}
                    maxRows={15}
                    error={formik.errors?.famous_wines}
                    touched={formik.errors?.famous_wines}
                    value={formik.values.famous_wines}
                    onChange={formik.handleChange("famous_wines")}></AppTextArea>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    id="white_grape_varietals"
                    label="White Grape Varietals"
                    minRows={5}
                    maxRows={15}
                    placeholder="White Grape Varietals"
                    value={formik.values.white_grape_varietals}
                    onChange={formik.handleChange("white_grape_varietals")}
                    error={formik.errors?.white_grape_varietals}
                    touched={formik.errors?.white_grape_varietals}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg="12">
                  <AppTextArea
                    id="red_grape_varietals"
                    label="Red Grape Varietals"
                    minRows={5}
                    maxRows={15}
                    placeholder="Red Grape Varietals"
                    value={formik.values.red_grape_varietals}
                    onChange={formik.handleChange("red_grape_varietals")}
                    error={formik.errors?.red_grape_varietals}
                    touched={formik.errors?.red_grape_varietals}
                  />
                </Col>
              </Row>
            </Box>
          </React.Fragment>
        </Box>
        <Box className="mt-5 d-flex justify-content-around">
          <AppButton
            onClick={formik.handleSubmit}
            sx={() => ({
              height: "48px",
              padding: "10px 50px"
            })}>
            {isEdit ? buttonText.EDIT : buttonText.SAVE}
          </AppButton>
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}

export default MenuItemsForm
