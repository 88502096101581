import React, { useEffect } from "react"
import Header from "components/Header/Header"
import { Container, Row, Col } from "react-bootstrap"
// import AdditionalResources from "./components/AdditionalResources/index";

import Top5LeadersUI from "components/DashboardCards/Top5LeadersUI/Top5LeadersUI"
import AppButton from "components/StyledComponents/AppButton"
import settings from "assets/icons/settings.png"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getLeaderboardDataAction, getWineMonthAction } from "redux/marketing/actions"
import moment from "moment"
import {
  getPendingQuestionsAction,
  getRestaurantsListAction,
  getTop5RanksTriviaAction,
  getTriviaContestListAction
} from "redux/L&D/actions"
import { formatDate, getLastDayOfMonth } from "helpers/functions"
import { appActions } from "redux/app/slice/app"
import MiniCalendar from "components/DashboardCards/MiniCalendar"
import { makeStyles } from "@material-ui/core"
import FeatureItemOfMonthSnapShot from "components/FeatureItemOfMonthSnapShot/index.js"
import MenuSnapShot from "components/MenuSnapshot"
import { marketingActions } from "redux/marketing/slice/user"

const useStyles = makeStyles(() => ({
  rowGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    "@media (max-width: 1300px)": {
      gridTemplateColumns: "1fr 1fr"
    },
    "@media (max-width: 900px)": {
      gridTemplateColumns: "1fr"
    }
  }
}))

function Dashboard() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const { listWineMonthData } = useSelector((store) => store?.marketing?.wineOfTheMonth || [])
  const { leaderBoardListGM } = useSelector((store) => store?.gm || [])
  const { date } = useSelector((store) => store?.app?.filters || {})
  const { results } = useSelector((store) => store?.marketing?.leaderboard?.data || {})
  const { leaderBoardUserList } = useSelector((store) => store?.learningDevelopment || [])

  useEffect(() => {}, [leaderBoardListGM])

  useEffect(() => {}, [leaderBoardUserList])

  useEffect(() => {
    document.title = "Dashboard - Marketing"
    dispatch(getTriviaContestListAction({ page: 1 }))
    dispatch(getPendingQuestionsAction())
  }, [])

  useEffect(() => {
    dispatch(appActions.initFilters())
    dispatch(getRestaurantsListAction())
    dispatch(getTop5RanksTriviaAction())
  }, [])

  useEffect(() => {
    const currentDate = formatDate(new Date())
    dispatch(
      marketingActions.setMenuLink({ list: "Summary View", category: "Entrees", item: "dinner" })
    )
    dispatch(getLeaderboardDataAction({ date: currentDate, page: 1 }))

    dispatch(
      getWineMonthAction({
        date: getLastDayOfMonth(currentDate)
      })
    )
  }, [])

  function getTop5Leader() {
    return results?.map((res, index) => ({
      id: index,
      key: res?.user?.name,
      field: res?.correct_percentage,
      restaurantName: res?.user?.rest_name ?? ""
    }))
  }

  return (
    <Container fluid className="p-0">
      <Row>
        <Header
          title="Marketing Dashboard"
          back={false}
          // notificationIcon={"notification"}
          // isNotify={true}
        >
          <Link to="/marketing/dashboard/contest-configuration">
            <AppButton styles={{ marginRight: "15px" }}>
              <img src={settings} style={{ marginRight: "10px" }} />
              Raffle Configuration
            </AppButton>
          </Link>
        </Header>
      </Row>
      <Row>
        <Col lg="12">
          <MenuSnapShot path={"/marketing/dashboard/explore-menu"}></MenuSnapShot>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FeatureItemOfMonthSnapShot listData={listWineMonthData}></FeatureItemOfMonthSnapShot>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12">
          <Row className={classes.rowGrid}>
            {/* <Col lg="12">
              <TitleCard>Statistics</TitleCard>
            </Col> */}
            <Col lg="12" className="calendarCtr">
              <MiniCalendar
                style={{ height: 400 }}
                onClick={() => history.push("/marketing/dashboard/calendar")}
              />
            </Col>
            <Col lg="12">
              <Top5LeadersUI
                style={{ height: 400 }}
                date={moment(date).format("MMM'YY")}
                tableData={getTop5Leader()}
                routeNext="/marketing/dashboard/leaderboard"
              />
            </Col>
            {/* In Next phase */}
            {/* <Col lg="12">
              <PerformanceSummaryCard />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Dashboard
