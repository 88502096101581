import React from "react"
import { Button, Grid, Typography } from "@mui/material"
import { useTheme } from "@mui/system"

function QuestionButtonNav({ children, isActive = false, onPress = () => {}, style = {} }) {
  const themes = useTheme()

  return (
    <Button
      onClick={onPress}
      style={{
        background: `${
          isActive ? themes.palette.linearGradient.main : themes.palette.background.main
        }`,
        // opacity: `${isActive ? "1" : "0"}`,
        padding:
          children !== "Add Question"
            ? `${isActive ? "8px 26px 8px 26px" : "5px 26px 5px 26px"}`
            : `${isActive ? "19px 26px" : "18px 26px"}`,
        marginRight: "6px",
        textTransform: "none",
        border: `${isActive ? "none" : `2px solid ${themes.palette.secondary.main}`}`,
        color: themes.palette.white.main,
        ...style
      }}
      variant="contained">
      <Grid item>
        <Typography>{children}</Typography>
        {children !== "Add Question" && (
          <Typography variant="caption4">Vanta AI Enabled</Typography>
        )}
      </Grid>
    </Button>
  )
}

export default QuestionButtonNav
