import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import InfoIcon from "@mui/icons-material/Info"
import { useRef } from "react"
import { Backdrop, Menu, MenuItem } from "@mui/material"
import { useTheme } from "@mui/system"
import AppPagination from "components/StyledComponents/AppPagination"
import { useDispatch } from "react-redux"
import { superAdminAction } from "redux/superAdmin/slice/user"
import moment from "moment"
import TableNoRecords from "components/TableNoRecords"
import { appActions } from "redux/app/slice/app"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import DeleteConfirmationDialog from "components/DeleteConfirmationDialog"
import { deleteEventAction, getEventDetailsAction } from "redux/superAdmin/actions"
import { useHistory } from "react-router-dom"

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "12px 24px"
}))

const orderStat = {
  ASC: "start_date",
  DESC: "-start_date"
}

const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  borderCollapse: "collapse !important",
  "& .MuiTableCell-root": {
    padding: "12px 24px 12px 0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: "none",
    boxSizing: "border-box",
    "&:last-child": {
      paddingRight: 0
    }
  },
  "& .MuiTableRow-root": {
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  "& .MuiTableCell-head": {
    verticalAlign: "bottom",
    position: "sticky",
    borderBottom: `2px solid #272a2c`,
    "&:last-child": {
      paddingRight: 0
    }
  }
}))

const EventListTable = ({
  columns = [],
  rows = [],
  setPage,
  currentPage,
  totalPage,
  itemsPerPage = 12
}) => {
  const [anchorEl, setAnchorEl] = useState(false)
  const rowId = useRef(null)
  const openMenu = Boolean(anchorEl)
  const [order, setOrder] = useState(orderStat.DESC)
  const [deleteEvent, setDeleteEvent] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const themes = useTheme()
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const onOptionsClick = (e, id) => {
    e.stopPropagation()
    rowId.current = id
    setAnchorEl(e.currentTarget)
    dispatch(superAdminAction.setEventID(rowId.current))
    dispatch(getEventDetailsAction())
  }

  function handleSort() {
    if (order === orderStat.DESC) {
      setOrder("start_date")
      dispatch(appActions.setOrderBy("start_date"))
    } else {
      setOrder("-start_date")
      dispatch(appActions.setOrderBy("-start_date"))
    }
  }

  useEffect(() => {
    setOrder("-start_date")
    dispatch(appActions.setOrderBy("-start_date"))
  }, [])

  const formatTime = (date, time) => {
    if (!date || !time) return ""
    const initDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const temp = new Date(initDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        temp.getFullYear(),
        temp.getMonth(),
        temp.getDate(),
        temp.getHours(),
        temp.getMinutes(),
        temp.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().format("hh:mm A")
  }

  function convertDate(date, time) {
    const forDate = moment(date).format("ddd MMM DD YYYY") + " " + time
    const newDate = new Date(forDate)
    const convertedUTCDate = new Date(
      Date.UTC(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        newDate.getHours(),
        newDate.getMinutes(),
        newDate.getSeconds()
      )
    )
    return moment(convertedUTCDate).local().format("MMM DD YYYY")
  }

  function handleDelete() {
    setDeleteEvent(true)
  }

  function handleDeleteEvent() {
    dispatch(deleteEventAction(rowId.current))
    rowId.current = null
  }

  function handleEditEvent() {
    history.push("/super-admin/dashboard/event-listing/edit-event")
  }

  useEffect(() => {}, [order])

  return (
    <>
      <DeleteConfirmationDialog
        title={`Are you sure you want to Delete this Event?`}
        description="  "
        style={{ textAlign: "center", width: "80%" }}
        onClose={() => setDeleteEvent(false)}
        onDelete={() => handleDeleteEvent()}
        open={deleteEvent}
      />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 700 }}>
          <CustomTable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    <Typography variant="body2Regular" style={{ opacity: 0.6 }}>
                      {column.label}{" "}
                      {column?.tooltip && (
                        <Tooltip title={column.tooltip}>
                          <InfoIcon fontSize="small" />
                        </Tooltip>
                      )}
                      {column?.isSorting && (
                        <span onClick={handleSort} style={{ cursor: "pointer" }}>
                          {order === "-start_date" ? (
                            <ArrowDownwardIcon fontSize="small"></ArrowDownwardIcon>
                          ) : (
                            <ArrowUpwardIcon fontSize="small"></ArrowUpwardIcon>
                          )}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">
                        <div>{currentPage * itemsPerPage - (itemsPerPage - 1) + index}</div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex flex-column">
                          <Typography variant="body2Regular">
                            {row?.name ? row?.name : "-"}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {convertDate(row?.start_date, row?.start_time)} at{" "}
                          {formatTime(row?.start_date, row?.start_time)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {formatTime(row?.start_date, row?.end_time)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">{row?.event_type}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2Regular">
                          {convertDate(row?.rsvp_deadline_date, row?.rsvp_deadline_time)} at{" "}
                          {formatTime(row?.rsvp_deadline_date, row?.rsvp_deadline_time)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                        <span onClick={(e) => onOptionsClick(e, row?.id)}>
                          <MoreHorizIcon
                            sx={() => ({
                              height: "24px",
                              width: "24px"
                            })}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableNoRecords colSpan={8} label="No Events Found" />
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>
        <Backdrop sx={{ color: "#fff", zIndex: 1300 }} open={openMenu} onClick={handleMenuClose}>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{
              sx: {
                marginTop: "15px",
                backgroundColor: themes.palette.background.default,
                borderRadius: "12px",
                "& .MuiList-root": {
                  backgroundColor: themes.palette.background.default,
                  width: "225px",
                  boxSizing: "border-box",
                  padding: "10px 0"
                }
              }
            }}>
            <StyledMenuItem onClick={handleEditEvent}>
              <Typography variant="body1Regular">Edit</Typography>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleDelete}>
              <Typography sx={{ color: themes.palette.red.main }} variant="body1Regular">
                Delete
              </Typography>
            </StyledMenuItem>
          </Menu>
        </Backdrop>
      </Paper>
      {rows?.length ? (
        <AppPagination onPageChange={setPage} totalPages={totalPage} currentPage={currentPage} />
      ) : (
        ""
      )}
    </>
  )
}

export default EventListTable
