import { Box, IconButton, styled } from "@mui/material";
import React, { useState } from "react";
import SortingToggleIcon from "assets/icons/SortingToggleIcon.svg";
import AppTextField from "components/StyledComponents/AppTextField";
import GreenCheckedIcon from "assets/icons/GreenCheckedIcon.svg";
import ClearIcon from "@mui/icons-material/Clear";

const StyledTextField = styled(AppTextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    border: "none !important",
    borderTopLeftRadius: "0px !important",
    borderBottomLeftRadius: "0px !important"
  },
  "& .MuiFilledInput-root": {
    border: `1px solid ${theme.palette.text.secondary} !important`,
    borderRadius: 6
  },
  "& .Mui-error": {
    borderColor: `${theme.palette.error.main} !important`,
    "& .MuiInputBase-input": {
      border: "none"
    }
  },
  "& .startAdornament": {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    paddingRight:10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2
  }
}));

const OptionTemplate = ({
  formik,
  index,
  dragStart,
  dragEnter,
  drop,
  arrayHelper,
  getOptionValues = () => {},
  getOptionPath = () => {}
}) => {
  const [optionText, setOptionText] = useState("");
  const optionErrors = getOptionValues(formik?.errors)?.[index] || {};
  const optionTouched = getOptionValues(formik?.touched)?.[index] || {};

  const onCorrectOptionClick = (index) => {
    if (getOptionValues(formik.values)?.[index]?.isCorrect) {
      formik.setFieldValue(`${getOptionPath()}.[${index}].isCorrect`, false);
    } else {
      const newOptions = [...(getOptionValues(formik.values) || [])];
      newOptions.forEach((option) => {
        if (option.isCorrect) {
          option.isCorrect = false;
        }
      });
      newOptions[index].isCorrect = true;
      formik.setFieldValue(`${getOptionPath()}`, newOptions);
    }
  };

  const onOptionTextChange = (e) => {
    setOptionText(e.target.value);
    const timeOutId = setTimeout(
      () =>
        formik.setFieldValue(
          `${getOptionPath()}.[${index}].name`,
          e.target.value
        ),
      800
    );
    return () => clearTimeout(timeOutId);
  };

  useState(() => {
    setOptionText(getOptionValues(formik.values)?.[index]?.name);
  }, []);

  return (
    <li
      key={`optionContainer-${index}`}
      style={{ listStyleType: "none", marginBottom: 20 }}
      className="optionItem"
      data-id={index}>
      <Box sx={{ maxWidth: "848px" }} className="d-flex">
        <Box
          className="d-flex justify-content-center align-items-start"
          sx={{ cursor: "grab" }}
          draggable
          onDragStart={(e) => dragStart(e, index)}
          onDragEnter={(e) => dragEnter(e, arrayHelper)}
          onDragEnd={() => drop(arrayHelper)}>
          <img
            src={SortingToggleIcon}
            alt="sort option"
            className="flex-shrink-0 me-1 mt-3"
          />
        </Box>
        <StyledTextField
          fullWidth
          placeholder="Enter Option"
          className="flex-shrink-1 flex-grow-1 me-5"
          error={optionTouched.name && Boolean(optionErrors.name)}
          helperText={optionTouched.name && optionErrors.name}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <Box className="startAdornament">
                {(index + 10).toString(36).toUpperCase()}
              </Box>
            )
          }}
          value={optionText}
          onChange={onOptionTextChange}
        />
        <span
          onClick={() => onCorrectOptionClick(index)}
          className="flex-shrink-0 ms-1">
          <IconButton sx={{ height: 48, width: 48, padding: 0 }}>
            {getOptionValues(formik.values)?.[index]?.isCorrect ? (
              <img src={GreenCheckedIcon} alt="Checked" />
            ) : (
              <Box
                sx={(theme) => ({
                  height: "100%",
                  width: "100%",
                  backgroundColor: theme.palette.background.main,
                  borderRadius: "4px"
                })}></Box>
            )}
          </IconButton>
        </span>
        {getOptionValues(formik.values).length > 1 ? (
          <span
            role="button"
            onClick={() => arrayHelper.remove(index)}
            className="ms-5">
            <ClearIcon sx={{ width: "30px", height: "48px" }} />
          </span>
        ) : null}
      </Box>
    </li>
  );
};

export default OptionTemplate;
