import {
  Box,
  CircularProgress,
  FormHelperText,
  Typography
} from "@mui/material";
import Card from "components/Card/Card";
import ImageUploadField from "components/FileUpload/ImageUploadField";
import Header from "components/Header/Header";
import AppButtonGradient from "components/StyledComponents/AppButtonGradient";
import AppTextField from "components/StyledComponents/AppTextField";
import { FieldArray, Formik } from "formik";
import { createBreadcrumbs } from "helpers/breadCrumbs";
import { validationSchemaAddCategories } from "helpers/validationSchema";
import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import {
  createTriviaCategoriesAction,
  getTriviaCategoriesAction,
  updateTriviaCategoriesAction,
  uploadTriviaCategoryImageAction
} from "redux/DMV/actions";
import { DMVActions } from "redux/DMV/slice/user";

const DMVTriviaCategory = () => {
  const loading = useSelector((store) => store.app.loading);
  const {
    images,
    isSubmitted,
    list = []
  } = useSelector((store) => store?.dmv?.triviaCategories ?? {});

  const history = useHistory();
  const [breadCrumbs, setBreadcrumbs] = useState([]);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const dispatch = useDispatch();
  const isEdit = useRef(false);
  const [initialData, setInitialData] = useState({ categorySet: [] });

  const onImageUpload = (image, formik, index) => {
    let data = new FormData();
    data.append("media", image);
    data.append("media_type", "IMAGE");
    data.append("content_type", image.type);
    dispatch(uploadTriviaCategoryImageAction({ index, data }));
    formik.setFieldValue(`categorySet[${index}].image`, image);
    formik.setFieldTouched(`categorySet[${index}].image`);
  };

  const handleDeleteImage = (formik, index) => {
    dispatch(DMVActions.setTriviaCategoryImage({ index, id: null }));
    formik.setFieldValue(`categorySet[${index}].image`, null);
  };

  const handleSubmit = (values) => {
    setIsFormSubmitting(true);
    const data = values.categorySet.map(({ name, id }, index) => ({
      ...(id ? { id } : {}),
      name,
      ...(images?.[index] ? { icon: images?.[index] } : {})
    }));
    if (isEdit.current) {
      const payload = {
        categories: data
      };
      dispatch(updateTriviaCategoriesAction(payload));
    } else {
      const payload = data?.map((el) => (el.is_repeated = true));
      payload[0].is_repeated = false;
      payload[-1].is_repeated = false;
      dispatch(createTriviaCategoriesAction(data));
    }
  };

  const initFormik = () => {
    isEdit.current = Boolean(list?.length);
    let data = [];
    for (let i = 0; i < 5; ++i) {
      let obj = {
        name: list?.[i]?.name ?? "",
        image: list?.[i]?.icon ? { id: "", url: list?.[i]?.icon } : null
      };
      if (list?.[i]?.id) {
        obj.id = list?.[i]?.id;
      }
      data.push(obj);
    }
    setInitialData({
      categorySet: data
    });
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    setBreadcrumbs(createBreadcrumbs());
    document.title = "Trivia Category - DMV";
    dispatch(DMVActions.initTriviaCategories());
    dispatch(getTriviaCategoriesAction());
  }, []);

  useEffect(() => {
    initFormik();
  }, [list]);

  useEffect(() => {
    if (isSubmitted) {
      dispatch(DMVActions.setIsTriviaCategoriesSubmitted(false));
      history.push("/dmv/dashboard");
    }
  }, [isSubmitted]);

  return (
    <Container fluid className="p-0  ">
      <Row>
        <Col lg="12">
          <Header
            title="Trivia Categories"
            breadCrumbs={breadCrumbs}
            back={true}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Formik
            initialValues={initialData}
            enableReinitialize={true}
            validationSchema={validationSchemaAddCategories}
            onSubmit={handleSubmit}>
            {(formik) => (
              <>
                <Prompt
                  when={formik.dirty && !isFormSubmitting}
                  message={
                    "Changes you made may not be saved. Are you sure you want to leave this page ?"
                  }
                />
                <FieldArray
                  name={"categorySet"}
                  render={() => (
                    <>
                      {formik.values.categorySet.map((category, index) => (
                        <Card key={index} className="mt-4">
                          <Box className="d-flex justify-content-between align-items-center mb-4">
                            <Typography variant="h2">{`Category ${
                              index + 1
                            }`}</Typography>
                          </Box>
                          <Row>
                            <Col lg="4">
                              <AppTextField
                                value={formik.values.categorySet[index].name}
                                onChange={formik.handleChange(
                                  `categorySet[${index}].name`
                                )}
                                fullWidth={true}
                                placeholder="Enter Trivia Category"
                                isRequired={true}
                                label="Trivia Category"
                                error={
                                  formik.touched.categorySet?.[index]?.name &&
                                  Boolean(
                                    formik.errors?.categorySet?.[index]?.name
                                  )
                                }
                                helperText={
                                  formik.touched.categorySet?.[index]?.name &&
                                  formik.errors.categorySet?.[index]?.name
                                }
                              />
                            </Col>
                            <Col lg="4">
                              <ImageUploadField
                                label="Upload Icon"
                                isRequired={true}
                                aspectWidth={1}
                                aspectHeight={1}
                                value={formik.values.categorySet[index].image}
                                onChange={(e) =>
                                  onImageUpload(e, formik, index)
                                }
                                placeholder="Upload Icon For Trivia"
                                onDelete={() =>
                                  handleDeleteImage(formik, index)
                                }
                                error={
                                  formik.errors.categorySet?.[index]?.image
                                }
                                touched={
                                  formik.touched.categorySet?.[index]?.image
                                }
                              />
                            </Col>
                          </Row>
                        </Card>
                      ))}
                      {!Array.isArray(formik.errors.categorySet) &&
                      Boolean(formik.touched.categorySet) &&
                      Boolean(formik.errors.categorySet) ? (
                        <FormHelperText error={true}>
                          {formik.errors.categorySet}
                        </FormHelperText>
                      ) : null}
                    </>
                  )}
                />
                <Row>
                  <Col lg="2" className="mt-5">
                    <AppButtonGradient
                      onClick={formik.handleSubmit}
                      disabled={loading}
                      sx={{ height: "48px", width: "100%" }}
                      variant="filled">
                      {loading ? (
                        <Box className="d-flex">
                          <CircularProgress
                            size={20}
                            sx={{ color: "#FFFFFF", marginRight: "5px" }}
                          />
                          <span>Please Wait...</span>
                        </Box>
                      ) : (
                        "Submit"
                      )}
                    </AppButtonGradient>
                  </Col>
                </Row>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default DMVTriviaCategory;
