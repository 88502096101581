import React, { useState } from "react"
import classes from "pages/private/DVM_Console/DMV_Menu/dmvmenu.module.scss"
import cms_classes from "pages/private/marketing/menu/menu.module.scss"
import { Typography } from "@mui/material"
import ImageDisplay from "components/ImageDisplayer"
import Paragraph from "components/ReadMoreParagraph"

function DMV_SpiritSummary({ foodList, isCMS }) {
  const [showDetails, setShowDetails] = useState(false)

  function checkDetailView() {
    if (!showDetails) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <div className={isCMS ? cms_classes.itemContainer_1 : classes.itemContainer}>
        <div className={classes.imageContainer}>
          <ImageDisplay data={foodList?.image_url}></ImageDisplay>
        </div>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
            maxWidth: 375,
            overflowWrap: "break-word"
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}>
            <div>
              <Typography variant="h3">{foodList?.name ?? "-"}</Typography>
            </div>
            <div
              className={isCMS ? cms_classes.view : classes.view}
              onClick={() => setShowDetails(!showDetails)}>
              {!showDetails ? "+" : "-"}
            </div>
          </div>
          <div>
            {checkDetailView() ? (
              <div className="mt-2">
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.description ?? "-"}
                </Typography>
              </div>
            ) : (
              <div className="mt-2">
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.description
                    ? foodList?.description?.length > 165
                      ? foodList?.description?.slice(0, 165) + "..."
                      : foodList?.description
                    : "-"}
                </Typography>
              </div>
            )}
            {checkDetailView() && foodList?.origin ? (
              <div className="mt-2">
                <Typography variant="tag1">{"Region:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.origin ?? "-"}
                </Typography>
              </div>
            ) : (
              foodList?.origin && (
                <div className="mt-2">
                  <Typography variant="tag1">{"Region:"}</Typography>
                  <br></br>
                  <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                    {foodList?.origin
                      ? foodList?.origin?.length > 100
                        ? foodList?.origin?.slice(0, 100) + "..."
                        : foodList?.origin
                      : "-"}
                  </Typography>
                </div>
              )
            )}
            {checkDetailView() && foodList?.unique_facts?.length > 0 && (
              <div className="mt-2">
                <Typography variant="tag1">{"Unique Facts:"}</Typography>
                <br></br>
                {foodList?.unique_facts?.length === 1
                  ? foodList?.unique_facts?.map((item, index) =>
                      item?.fact !== "" ? (
                        <Typography
                          style={{ display: item?.fact ? "list-item" : "", marginLeft: 15 }}
                          key={index}
                          variant="tagItalic"
                          sx={{ opacity: 0.6 }}>
                          <Paragraph
                            text={item?.fact ?? ""}
                            maxCharCount={70}
                            key={index}
                            variant="tagItalic"
                            sx={{ opacity: 0.6 }}></Paragraph>
                        </Typography>
                      ) : null
                    )
                  : foodList?.unique_facts?.map((item, index) => (
                      <Typography
                        style={{ display: item?.fact ? "list-item" : "", marginLeft: 15 }}
                        key={index}
                        variant="tagItalic"
                        sx={{ opacity: 0.6 }}>
                        <Paragraph
                          text={item?.fact ?? ""}
                          maxCharCount={70}
                          key={index}
                          variant="tagItalic"
                          sx={{ opacity: 0.6 }}></Paragraph>
                      </Typography>
                    ))}
              </div>
            )}
            {checkDetailView() && foodList?.ingredient?.length > 0 && (
              <div className="mt-2">
                <Typography variant="tag1">{"Ingredients:"}</Typography>
                <br></br>
                {foodList?.ingredient?.map((item, index) => (
                  <Typography key={index} variant="tagItalic" sx={{ opacity: 0.6 }}>
                    {`${item?.name ?? ""}`}
                    {item?.measurement
                      ? foodList?.ingredient?.length - 1 > index
                        ? ` (${item?.measurement}), `
                        : item?.measurement
                        ? ` (${item?.measurement}) `
                        : ""
                      : foodList?.ingredient?.length - 1 > index
                      ? ", "
                      : ""}
                  </Typography>
                ))}
              </div>
            )}
            {checkDetailView() && foodList?.nose && (
              <div className="mt-2">
                <Typography variant="tag1">{"Nose:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.nose ?? "-"}
                </Typography>
              </div>
            )}
            {checkDetailView() && foodList?.palate && (
              <div className="mt-2">
                <Typography variant="tag1">{"Palate:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.palate ?? ""}
                </Typography>
              </div>
            )}
            {checkDetailView() && foodList?.finish && (
              <div className="mt-2">
                <Typography variant="tag1">{"Finish:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.finish ?? ""}
                </Typography>
              </div>
            )}
            {checkDetailView() && foodList?.distillation && (
              <div className="mt-2">
                <Typography variant="tag1">{"Distillation:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.distillation ?? ""}
                </Typography>
              </div>
            )}
            {checkDetailView() && foodList?.aging && (
              <div className="mt-2">
                <Typography variant="tag1">{"Aging:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.aging ?? ""}
                </Typography>
              </div>
            )}
            {checkDetailView() && foodList?.bottling && (
              <div className="mt-2">
                <Typography variant="tag1">{"Bottling:"}</Typography>
                <br></br>
                <Typography variant="tagItalic" sx={{ opacity: 0.6 }}>
                  {foodList?.bottling ?? ""}
                </Typography>
              </div>
            )}
            {/* <Typography
              className={isCMS ? cms_classes.text : classes.text}
              sx={{ cursor: "pointer" }}
              onClick={() => setShowDetails(!showDetails)}>
              {!showDetails ? "+ View More" : "- View Less"}
            </Typography> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default DMV_SpiritSummary
